import { UserAuctionRegistrationDto } from './../../models/user/UserAuctionRegistrationDto';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';
import { ShowHistoryDto } from '../../models/user/ShowHistoryDto';
import { AuctionExtUtil } from '../../util/AuctionExtUtil';
import { EChartsOption } from 'echarts';
import { UserService } from '../../services/user.service';
import { ServerDataFetchService } from '../../services/server-data-fetch.service';

@Component({
  selector: 'app-lot-bid-history',
  templateUrl: './lot-bid-history.component.html',
  styleUrls: ['./lot-bid-history.component.sass']
})
export class LotBidHistoryComponent implements OnInit, OnDestroy {
  bidHistoryList$ = new BehaviorSubject<Array<ShowHistoryDto>>([]);

  @Input() auctionId?: string;
  @Input() lotId?: string;
  @Input() isShowHeader?: boolean;
  @Input() isShowChartView$ = new BehaviorSubject<boolean>(false);


  isShowChartView: boolean = false;
  isLoading: boolean = false;
  myPaddleNo?: string;
  chartViewSubscription$?: Subscription;
  currentUserAuctionRegistration$?: Subscription;
  userRegistrationDto?: UserAuctionRegistrationDto;

  chartOption?: EChartsOption;

  myAuctionRegistrationSubscription$?: Subscription;

  constructor(
    private bidderService: LandingAndBidderService,
    private datePipe: DatePipe,
    private ngbModal: NgbModal,
    private userService: UserService,
    private serverDataFetchService: ServerDataFetchService
  ) { }


  async ngOnInit() {
    let userEntityDto = this.userService.getUserEntity();

    // sync call to get user auction registration
    if (userEntityDto) {
      this.userRegistrationDto = await this.serverDataFetchService.getUserAuctionRegistrationDataSync(this.auctionId!, userEntityDto.userId!);
    } else {
      this.userRegistrationDto = undefined;
    }

     this.myAuctionRegistrationSubscription$ = this.bidderService.getMyUserAuctionRegistrationWrappers$.subscribe( items => {
      if(items && items.length > 0){
        let registration = items.filter(item => item.auctionId == this.auctionId).map(item => item.userAuctionRegistrationDto);;
        if(registration){
          this.userRegistrationDto = registration[0];
        }
      }
    })

    this.chartViewSubscription$ = this.isShowChartView$.subscribe((data) => {
      if (data) {
        this.isShowChartView = data;
      } else {
        this.isShowChartView = false;
      }
    })

    this.loadAuctionBidHistoryInfo();
  }

  loadAuctionBidHistoryInfo() {
    this.isLoading = true;

    let user = this.userService.getUserEntity();

    this.bidderService.getAuctionBidHistoryInfo(this.auctionId!, this.lotId!, user!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let bidHistoryList = apiResponseDto.data as ShowHistoryDto[];
          if (bidHistoryList && bidHistoryList.length > 0) {
            this.bidHistoryList$.next(bidHistoryList);

            let tempBidHistory = [...bidHistoryList].reverse();
            this.populateChartsData(tempBidHistory);
          }
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
      }
    })
  }

  refreshData() {
    this.loadAuctionBidHistoryInfo();
  }

  getFormattedPrice(locale: string, price: any) {
    return AuctionExtUtil.getFormattedPrice(locale, price);
  }

  getDisplayDate(date?: string) {
    if (date != null) {
      return AuctionExtUtil.getDateInMMMdd(date!, this.datePipe);
    } else {
      return '';
    }
  }

  populateChartsData(bidHistoryList: ShowHistoryDto[]) {
    bidHistoryList.sort((a, b) => +AuctionExtUtil.convertedDateFrom12h(a.date!, a.time!)! - +AuctionExtUtil.convertedDateFrom12h(b.date!, b.time!)!);

    let xAxisDataList = bidHistoryList.map(item => this.datePipe.transform(AuctionExtUtil.convertedDate(item.date!, item.time!), 'MMM dd H:mm')!);
    let valuesList = bidHistoryList.map(item => item?.bidPrice!);
    let visualMapList = [];

    let isUserLoggedIn = this.userService.getUserEntity() != null;

    for (let i = 0; i < bidHistoryList.length; i++) {
      const item = bidHistoryList[i];
      let color = isUserLoggedIn ? item?.paddleNo! == this.userRegistrationDto?.paddleNo ? 'green' : 'red' : 'blue';
      visualMapList.push({ gt: i, lt: i + 1, color: color });
    }

    this.chartOption = {
      grid: {
        containLabel: true
      },
      dataZoom: [{
        type: 'inside',
        id: 'insideX',
        xAxisIndex: 0,
        start: 0,
        end: 100,
        zoomOnMouseWheel: true,
        moveOnMouseMove: true,
        moveOnMouseWheel: false
      }],
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          let index = params[0]?.dataIndex;
          let item = bidHistoryList[index];
          let dateTime = this.datePipe.transform(AuctionExtUtil.convertedDate(item.date!, item.time!), 'MMM dd H:mm:ss a');
          let paddleNo = item.paddleNo;
          let bidPrice = item.currency?.symbol + this.getFormattedPrice(item.currency?.locale!, item.bidPrice);

          return `Date: ${dateTime}<br>Bidder No: #${paddleNo}<br>Bid: ${bidPrice}`;
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxisDataList
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: bidHistoryList[0].currency?.symbol + '{value}'
        },
        axisPointer: {
          snap: true
        }
      },
      visualMap: {
        show: false,
        dimension: 0,
        pieces: visualMapList
      },
      series: [
        {
          name: 'Bids',
          type: 'line',
          smooth: true,
          data: valuesList
        }
      ],
    }
  }

  closeModal() {
    this.ngbModal.dismissAll()
  }

  ngOnDestroy(): void {
    if (this.currentUserAuctionRegistration$) {
      this.currentUserAuctionRegistration$.unsubscribe();
    }
    if (this.chartViewSubscription$) {
      this.chartViewSubscription$.unsubscribe();
    }
    if(this.myAuctionRegistrationSubscription$){
      this.myAuctionRegistrationSubscription$.unsubscribe();
    }
  }
}
