import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LandingAndBidderService } from '../../services/LandingAndBidder.service';
import { FormControl, Validators } from '@angular/forms';
import { FirestoreListenerService } from '../../services/firestore-listener.service';
import { AuctionQuestionDto } from '../../models/user/AuctionQuestionDto';
import { BehaviorSubject } from 'rxjs';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { AuctionLotEntityDto } from '../../models/user/AuctionLotEntityDto';
import { UserService } from '../../services/user.service';
import { Timestamp } from '@angular/fire/firestore';
import { UUID } from 'angular2-uuid';
import { AuctionQuestionStatus } from '../../enums/AuctionQuestionStatus';

@Component({
  selector: 'app-ask-auction-question',
  templateUrl: './ask-auction-question.component.html',
  styleUrls: ['./ask-auction-question.component.sass']
})
export class AskAuctionQuestionComponent implements OnInit, OnDestroy {
  @Input() selectedLot!: AuctionLotEntityDto;
  @Input() selectedAuctionQuestion?: AuctionQuestionDto;

  ctrlAskQuestion: FormControl = new FormControl('', Validators.required);

  isLoading: boolean = false;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  constructor(
    private activateModal: NgbActiveModal,
    private bidderService: LandingAndBidderService,
    private userService: UserService,
    private firestoreService: FirestoreListenerService
  ) {}

  ngOnInit(): void {
    if (this.selectedAuctionQuestion) {
      this.ctrlAskQuestion.patchValue(this.selectedAuctionQuestion.question);
    }
  }

  saveAskQuestion() {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);

    if (this.ctrlAskQuestion.invalid) {
      this.ctrlAskQuestion.markAsTouched();
      return;
    }

    this.isLoading = true;

    let userEntityDto = this.userService.getUserEntity();

    let auctionQuestion = new AuctionQuestionDto();

    if (!this.selectedAuctionQuestion) {
      auctionQuestion.id = UUID.UUID().toString();
      auctionQuestion.auctionHouseId = this.selectedLot.auctionHouseId;
      auctionQuestion.auctionId = this.selectedLot.auctionId;
      auctionQuestion.lotId = this.selectedLot.lotId;
      auctionQuestion.bidderId = userEntityDto.userId;
      auctionQuestion.status = AuctionQuestionStatus.PENDING;
      auctionQuestion.published = false;
    } else {
      auctionQuestion = this.selectedAuctionQuestion;
    }

    auctionQuestion.bidderName = userEntityDto.firstName + ' ' + userEntityDto.lastName;
    auctionQuestion.question = this.ctrlAskQuestion.value.trim();
    auctionQuestion.createdTimestamp = Timestamp.now();

    this.firestoreService.saveAuctionQuestion(auctionQuestion)
      .then(() => {
        this._showSuccessToast$.next(true);
        this.isLoading = false;

        setTimeout(() => {
          this._showSuccessToast$.next(false);
          this.closeModal();
        }, 2000);

      }).catch((err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
      });
  }

  closeModal() {
    this.activateModal.close();
  }

  ngOnDestroy(): void {

  }
}
