<div class="ms-0 px-2" [ngClass]="isShowHeader ? 'bid-history' : isShowChartView ? 'chart-view' : 'bid-history-class'">
  <header class="m-2" *ngIf="isShowHeader">
    <div class="row justify-content-between">
      <div class="col-6 col-md-9 d-flex align-items-center ">
        <h2>BID HISTORY</h2>
      </div>
      <div class="col-auto text-end">
        <button class="btn btn-new-class  me-2" (click)="refreshData()">
          <img src="assets/icons/refresh_dark.svg" class=" c-pointer svg-icon-class">
        </button>
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider" *ngIf="isShowHeader"></div>
  <div class="mb-3" *ngIf="isLoading">
    <div class="e-spinner"></div>
  </div>
  
  <div class="mt-3" *ngIf="!isShowChartView">
    <div class="table-scroll" *ngIf="(bidHistoryList$ | async)!.length > 0 && !isLoading; else dataNotFoundTemplate">
      <table class="table table-bordered" [ngClass]="isShowHeader ? 'text-black' : 'text-white'">
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Time</th>
            <th scope="col">BIDDER #</th>
            <th scope="col">Bid</th>
          </tr>
        </thead>
        <tbody>
          <tr class="thickness" *ngFor="let item of bidHistoryList$ | async; index as i"
            [ngStyle]="{'text-decoration' : item.bidPlaced == false ? '2px line-through' : 'none', 'text-decoration-color': item.bidPlaced == false ? 'red': 'black'}">

            <td>{{getDisplayDate(item?.date)}}</td>
            <td>{{item?.time}}</td>
            <td [ngClass]="userRegistrationDto?.paddleNo! == item?.paddleNo ? 'font-weight-bold': ''">
              {{userRegistrationDto?.paddleNo! == item?.paddleNo ? 'YOU' : '#' + item?.paddleNo}}
            </td>
            <td [ngClass]="userRegistrationDto?.paddleNo! == item?.paddleNo ? 'font-weight-bold': ''">
              {{item?.currency?.symbol}}{{getFormattedPrice(item?.currency?.locale!, item?.bidPrice)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Bids History Chart View -->
  <div *ngIf="isShowChartView">
    <div echarts [options]="chartOption" class="demo-chart" *ngIf="(bidHistoryList$ | async)!.length > 0 && chartOption else dataNotFoundTemplate"></div>
  </div>
</div>

<ng-template #dataNotFoundTemplate>
  <div *ngIf="!isLoading">
    <p class="text-center" style="font-size: 18pt;">No Bid History</p>
  </div>

</ng-template>