import { RedirectLoaderComponent } from './shared/components/redirect-loader/redirect-loader.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { AllAuctionsComponent } from './shared/components/user/all-auctions/all-auctions.component';
import { ComingSoonComponent } from './shared/components/coming-soon/coming-soon.component';
import { UnsubscribeNotificationComponent } from './app/shared/components/unsubscribe-notification/unsubscribe-notification.component';
import { AuctionCalendarComponent } from './shared/components/auction-calendar/auction-calendar.component';
import { ProfileStageComponent } from './layouts/bidder/profile-stages/profile-stage/profile-stage.component';


const routes: Routes = [
  // {path : '', component: LandingDashboardComponent},
  // {path : 'list', component: AuctionSearchComponent},
  // {path : 'auction', component: AuctionDetailsComponent},
  // {path : 'aucmanage', component: AuctionManageComponent},
  // {path : 'aucdetails', component: AuctionDetailsComponent},
  // {path : 'agent', component: AgentDashboardComponent},
  // {path : 'admin', component: AdminDashboardComponent},
  // {path : 'pagenotfound', component: PageNotFoundComponent},
  // {path : 'elements', component: ElementsComponent},
  // {path : 'sadmin', component: SadminDashboardComponent},
  {path : 'calendar', component: AuctionCalendarComponent},
  {path : 'event', component: ProfileStageComponent},
  // {path : 'detail-page', component: ListingContactDetailComponent},

  {
    path: 'Bidder',
    loadChildren: () => import('./shared/routing-module/bidder/bidder.module').then(m => m.BidderModule)
  },
  {
    path: '',
    loadChildren: () => import('./shared/routing-module/landing/landing.module').then(m => m.LandingModule)
  },
  { path: 'allAuctions', component: AllAuctionsComponent },
  { path: 'coming-soon', component: ComingSoonComponent },
  { path: 'redirect-loader', component: RedirectLoaderComponent },

  { path: 'unsubscribe', component: UnsubscribeNotificationComponent },
  {
    path: '**',
    component: PageNotFoundComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [
  // LandingDashboardComponent,
  // AgentDashboardComponent,
  // AdminDashboardComponent,
  PageNotFoundComponent,
]
