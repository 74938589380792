<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>REGISTER TO BID</h2>
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3">
      <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit()">

        <div formArrayName="requirements" [ngClass]="{'disabled': isLoading}">
          <div class="e-field ea-valid-required" *ngFor="let item of bidderRequirements; index as i"
            [formGroupName]="i">
            <div class="mb-2 mt-3">{{i+1}}. {{item?.question}}</div>
            <div class="form-floating my-2" *ngIf="item.questionType == bidderRequirementType.TEXT || item.questionType == bidderRequirementType.NUMERIC">
              <input [type]="item.questionType == bidderRequirementType.NUMERIC ? 'number' : 'text'" class="form-control"
                formControlName="userInput" placeholder="Provide Your answer" id="userInput"
                [ngClass]="{'is-invalid': rc[i].get('userInput').invalid && (rc[i].get('userInput').dirty || rc[i].get('userInput').touched)}">
              <label for="userInput">Provide Your answer</label>
              <div class="text-danger" *ngIf="isSubmitted || rc[i].get('userInput').invalid && (rc[i].get('userInput').dirty || rc[i].get('userInput').touched)">
                <p *ngIf="rc[i].get('userInput').errors?.required">Required</p>
              </div>

              <div class="form-check form-switch mt-2" *ngIf="!readOnly">
                <input class="form-check-input" type="checkbox" role="switch" id="switch" [checked]="showCommentBox.includes(rc[i].get('id').value)"
                  (change)="changeCommentBoxToggle(rc[i].get('id').value)">
                <label for="switch" class="form-check-label ms-2 mt-1">Comment on this question?</label>
              </div>
              <div class="mt-3" *ngIf="showCommentBox.includes(rc[i].get('id').value)">
                <label class="form-label mb-0">Comments:</label>
                <textarea class="form-control" rows="3" formControlName="comments"></textarea>
              </div>
              <div *ngIf="rc[i].get('comments').invalid && (rc[i].get('comments').dirty || rc[i].get('comments').touched)">
                <p class="text-danger" *ngIf="rc[i].get('comments').errors?.required">Required</p>
              </div>
            </div>

            <div class="my-3" *ngIf="item.questionType == bidderRequirementType.FILE_UPLOAD">
              Please upload Your Text Id
              <div class="row align-items-center">
                <div class="col-sm-6">
                  <div class="mb-3" *ngIf="!getFileNameOfSelectedFile(item.id!)">
                    <small class="small-class"> No File Selected</small>
                  </div>
                  <div class="overflow-class" *ngIf="getFileNameOfSelectedFile(item.id!)">
                    {{getFileNameOfSelectedFile(item.id!)}}</div>
                </div>
                <div class="col-sm-6 text-sm-end">
                  <input #fileRef onclick="this.value=null;" type="file" id="removeDocument" class="d-none" (change)="chooseDocument($event, item.id!, fileRef)" />
                  <span (click)="removeDocument(item.id!, fileRef)" *ngIf="getFileNameOfSelectedFile(item.id!) && !readOnly">
                    <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
                  </span>
                  <button class="btn-new-class btn-sm btn " (click)="previewDocument('LOCAL', item, false)" *ngIf="!readOnly && getSelectedFile(item.id!)">
                    <fa-icon [icon]="['fas', 'eye']" class=" fa-lg"></fa-icon>
                  </button>
                  <button class="btn-new-class btn-sm btn mx-3" (click)="previewDocument('SERVER', item, false)" *ngIf="readOnly">
                    <fa-icon [icon]="['fas', 'eye']" class=" fa-lg"></fa-icon>
                  </button>
                  <button class="btn-new-class btn-sm btn " (click)="fileRef.click()" *ngIf="!readOnly">
                    <fa-icon [icon]="['fas', 'upload']" class=" fa-lg"></fa-icon>
                  </button>
                </div>
              </div>

              <div class="mb-3" *ngIf="isSubmitted && rc[i].get('userFile').invalid">
                <p class="text-danger" *ngIf="rc[i].get('userFile').errors?.required">
                  Required
                </p>
              </div>

              <div class="form-check form-switch" *ngIf="!readOnly">
                <input class="form-check-input" type="checkbox" role="switch" id="changeCommentBoxToggle" [checked]="showCommentBox.includes(rc[i].get('id').value)"
                  (change)="changeCommentBoxToggle(rc[i].get('id').value)">
                <label class="form-check-label ms-2 mt-1">Comment on this question?</label>
              </div>
              <div class="mt-3" *ngIf="showCommentBox.includes(rc[i].get('id').value)">
                <label class="form-label mb-0">Comments:</label>
                <textarea class="form-control" rows="3" formControlName="comments"></textarea>
              </div>
              <div *ngIf="rc[i].get('comments').invalid && (rc[i].get('comments').dirty || rc[i].get('comments').touched)">
                <p class="text-danger" *ngIf="rc[i].get('comments').errors?.required">Required</p>
              </div>
            </div>

            <div class="my-3" *ngIf="item.questionType == bidderRequirementType.TEXT_FILE_UPLOAD">
              <div class="form-floating my-2">
                <input type="text" class="form-control"
                  formControlName="userInput" placeholder="Provide Your answer" id="userInput"
                  [ngClass]="{'is-invalid': rc[i].get('userInput').invalid && (rc[i].get('userInput').dirty || rc[i].get('userInput').touched)}">
                <label for="userInput">Provide Your answer</label>
                <div class="text-danger" *ngIf="isSubmitted || rc[i].get('userInput').invalid && (rc[i].get('userInput').dirty || rc[i].get('userInput').touched)">
                  <p *ngIf="rc[i].get('userInput').errors?.required">Required</p>
                </div>
              </div>

              Please upload Your Text Id
              <div class="row align-items-center">
                <div class="col-sm-6">
                  <div class="mb-3" *ngIf="!getFileNameOfSelectedFile(item.id!)">
                    <small class="small-class"> No File Selected</small>
                  </div>
                  <div class="overflow-class" *ngIf="getFileNameOfSelectedFile(item.id!)">
                    {{getFileNameOfSelectedFile(item.id!)}}</div>
                </div>
                <div class="col-sm-6 text-sm-end">
                  <input #fileRef onclick="this.value=null;" id="removeDocument1" type="file" class="d-none" (change)="chooseDocument($event, item.id!, fileRef)" />
                  <span (click)="removeDocument(item.id!, fileRef)" *ngIf="getFileNameOfSelectedFile(item.id!) && !readOnly">
                    <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
                  </span>
                  <button class="btn-new-class btn-sm btn ms-2" (click)="previewDocument('LOCAL', item, false)" *ngIf="!readOnly && getSelectedFile(item.id!)">
                    <fa-icon [icon]="['fas', 'eye']" class=" fa-lg"></fa-icon>
                  </button>
                  <button class="btn-new-class btn-sm btn" (click)="previewDocument('SERVER', item, false)" *ngIf="readOnly">
                    <fa-icon [icon]="['fas', 'eye']" class=" fa-lg"></fa-icon>
                  </button>
                  <button class="btn-new-class btn-sm btn ms-2" (click)="fileRef.click()" *ngIf="!readOnly">
                    <fa-icon [icon]="['fas', 'upload']" class=" fa-lg"></fa-icon>
                  </button>
                </div>
              </div>

              <div class="mb-3" *ngIf="isSubmitted && rc[i].get('userFile').invalid">
                <p class="text-danger" *ngIf="rc[i].get('userFile').errors?.required">
                  Required
                </p>
              </div>

              <div class="form-check form-switch" *ngIf="!readOnly">
                <input class="form-check-input" id="switch1" type="checkbox" role="switch" [checked]="showCommentBox.includes(rc[i].get('id').value)"
                  (change)="changeCommentBoxToggle(rc[i].get('id').value)">
                <label class="form-check-label ms-2 mt-1">Comment on this question?</label>
              </div>
              <div class="mt-3" *ngIf="showCommentBox.includes(rc[i].get('id').value)">
                <label class="form-label mb-0">Comments:</label>
                <textarea class="form-control" rows="3" formControlName="comments"></textarea>
              </div>
              <div *ngIf="rc[i].get('comments').invalid && (rc[i].get('comments').dirty || rc[i].get('comments').touched)">
                <p class="text-danger" *ngIf="rc[i].get('comments').errors?.required">Required</p>
              </div>
            </div>

            <div class="my-2" *ngIf="item.questionType == bidderRequirementType.SINGLE_CHOICE">
              <div class="form-check" *ngFor="let inputChoiceText of item.adminQuestionChoices; index as i1">
                <input class="form-check-input" type="radio" id="flexRadioDefault{{i1}}" [value]="inputChoiceText"
                  formControlName="userAnswerChoices">
                <label class="form-check-label" for="flexRadioDefault{{i1}}">
                  {{inputChoiceText}}
                </label>
              </div>
              <div
                *ngIf="rc[i].get('userAnswerChoices').invalid && (rc[i].get('userAnswerChoices').dirty || rc[i].get('userAnswerChoices').touched)">
                <p class="text-danger" *ngIf="rc[i].get('userAnswerChoices').errors?.required">Required</p>
              </div>

              <div class="form-check form-switch" *ngIf="!readOnly">
                <input class="form-check-input" type="checkbox" id="switch2" role="switch" [checked]="showCommentBox.includes(rc[i].get('id').value)"
                  (change)="changeCommentBoxToggle(rc[i].get('id').value)">
                <label class="form-check-label ms-2 mt-1">Comment on this question?</label>
              </div>
              <div class="mt-3" *ngIf="showCommentBox.includes(rc[i].get('id').value)">
                <label class="form-label mb-0">Comments:</label>
                <textarea class="form-control" rows="3" formControlName="comments"></textarea>
              </div>
              <div *ngIf="rc[i].get('comments').invalid && (rc[i].get('comments').dirty || rc[i].get('comments').touched)">
                <p class="text-danger" *ngIf="rc[i].get('comments').errors?.required">Required</p>
              </div>
            </div>

            <div class="my-2" *ngIf="item.questionType == bidderRequirementType.MULTIPLE_CHOICE">
              <div formArrayName="userAnswerChoices">
                <div class="form-check" *ngFor="let control of inputChoiceControls(item.id!); index as i2">
                  <input class="form-check-input"  type="checkbox" id="flexCheckDefault{{i2}}" [formControl]="control"
                    checked="false">
                  <label class="form-check-label" for="flexCheckDefault{{i2}}">
                    {{item.adminQuestionChoices![i2]}}
                  </label>
                </div>
                <div
                  *ngIf="rc[i].get('userAnswerChoices').invalid && (rc[i].get('userAnswerChoices').dirty || rc[i].get('userAnswerChoices').touched)">
                  <p class="text-danger" *ngIf="rc[i].get('userAnswerChoices').errors?.required">Required</p>
                  <p class="text-danger" *ngIf="rc[i].get('userAnswerChoices').errors?.atLeastOneChecked">Required</p>
                </div>
              </div>

              <div class="form-check form-switch" *ngIf="!readOnly">
                <input class="form-check-input" type="checkbox" id="switch4" role="switch" [checked]="showCommentBox.includes(rc[i].get('id').value)"
                  (change)="changeCommentBoxToggle(rc[i].get('id').value)">
                <label class="form-check-label ms-2 mt-1">Comment on this question?</label>
              </div>
              <div class="mt-3" *ngIf="showCommentBox.includes(rc[i].get('id').value)">
                <label class="form-label mb-0">Comments:</label>
                <textarea class="form-control" rows="3" formControlName="comments"></textarea>
              </div>
              <div *ngIf="rc[i].get('comments').invalid && (rc[i].get('comments').dirty || rc[i].get('comments').touched)">
                <p class="text-danger" *ngIf="rc[i].get('comments').errors?.required">Required</p>
              </div>
            </div>

            <div class="my-2" *ngIf="item.questionType == bidderRequirementType.DATE">
              <div class="row mb-2" formArrayName="userAnswerDates">
                <div class="col-12" *ngFor="let dateControl of rc[i].get('userAnswerDates').controls; let j = index">
                  <div class="input-group">
                    <span class="border border-end-0 pt-3 px-1">
                      <img src="assets/icons/date_light.svg" class="svg-icon-class c-pointer">
                    </span>
                    <div class="form-floating">
                      <input type="text" ngbDatepicker #date="ngbDatepicker" (click)="date.toggle()" id="datePicker"
                        [ngClass]="{'border-danger': dateControl.invalid && (dateControl.dirty || dateControl.touched)}"
                        class="form-control border-start-0" placeholder="End Date" [formControl]="dateControl">
                      <label for="datePicker" class="">Date Answer</label>
                    </div>
                  </div>
                  <div *ngIf="dateControl.invalid && (dateControl.dirty || dateControl.touched)">
                    <p class="text-danger" *ngIf="dateControl.errors?.required">Required</p>
                  </div>
                </div>
              </div>

              <div class="form-check form-switch" *ngIf="!readOnly">
                <input class="form-check-input" type="checkbox" role="switch" id="switch6" [checked]="showCommentBox.includes(rc[i].get('id').value)"
                  (change)="changeCommentBoxToggle(rc[i].get('id').value)">
                <label class="form-check-label ms-2 mt-1">Comment on this question?</label>
              </div>
              <div class="mt-3" *ngIf="showCommentBox.includes(rc[i].get('id').value)">
                <label class="form-label mb-0">Comments:</label>
                <textarea class="form-control" rows="3" formControlName="comments"></textarea>
              </div>
              <div *ngIf="rc[i].get('comments').invalid && (rc[i].get('comments').dirty || rc[i].get('comments').touched)">
                <p class="text-danger" *ngIf="rc[i].get('comments').errors?.required">Required</p>
              </div>
            </div>

            <div class="my-2" *ngIf="item.questionType == bidderRequirementType.DATE_RANGE">
              <div class="row mb-2" formArrayName="userAnswerDates">
                <div class="col-6">
                  <div class="input-group">
                    <span class="border border-end-0 pt-3 px-1">
                      <img src="assets/icons/date_light.svg" class="svg-icon-class c-pointer">
                    </span>
                    <div class="form-floating">
                      <input type="text" ngbDatepicker #startDate="ngbDatepicker" id="datePicker2" (click)="startDate.toggle()"
                        [ngClass]="{'border-danger': rc[i].get('userAnswerDates').controls[0].invalid && (rc[i].get('userAnswerDates').controls[0].dirty || rc[i].get('userAnswerDates').controls[0].touched)}"
                        class="form-control border-start-0" placeholder="End Date" [formControl]="rc[i].get('userAnswerDates').controls[0]">
                      <label for="datePicker2" class="">Date Answer</label>
                    </div>
                  </div>
                  <div *ngIf="rc[i].get('userAnswerDates').controls[0].invalid && (rc[i].get('userAnswerDates').controls[0].dirty || rc[i].get('userAnswerDates').controls[0].touched)">
                    <p class="text-danger" *ngIf="rc[i].get('userAnswerDates').controls[0].errors?.required">Required</p>
                  </div>
                </div>

                <div class="col-6">
                  <div class="input-group">
                    <span class="border border-end-0 pt-3 px-1">
                      <img src="assets/icons/date_light.svg" class="svg-icon-class c-pointer">
                    </span>
                    <div class="form-floating">
                      <input type="text" ngbDatepicker #endDate="ngbDatepicker"  id="datePicker3" (click)="endDate.toggle()"
                        [ngClass]="{'border-danger': rc[i].get('userAnswerDates').controls[1].invalid && (rc[i].get('userAnswerDates').controls[1].dirty || rc[i].get('userAnswerDates').controls[1].touched)}"
                        class="form-control border-start-0" placeholder="End Date" [formControl]="rc[i].get('userAnswerDates').controls[1]">
                      <label for="datePicker3" class="">Date Answer</label>
                    </div>
                  </div>
                  <div *ngIf="rc[i].get('userAnswerDates').controls[1].invalid && (rc[i].get('userAnswerDates').controls[1].dirty || rc[i].get('userAnswerDates').controls[1].touched)">
                    <p class="text-danger" *ngIf="rc[i].get('userAnswerDates').controls[1].errors?.required">Required</p>
                  </div>
                </div>
              </div>

              <div class="form-check form-switch" *ngIf="!readOnly">
                <input class="form-check-input" type="checkbox" role="switch" [checked]="showCommentBox.includes(rc[i].get('id').value)"
                  (change)="changeCommentBoxToggle(rc[i].get('id').value)">
                <label class="form-check-label ms-2 mt-1">Comment on this question?</label>
              </div>
              <div class="mt-3" *ngIf="showCommentBox.includes(rc[i].get('id').value)">
                <label class="form-label mb-0">Comments:</label>
                <textarea class="form-control" rows="3" formControlName="comments"></textarea>
              </div>
              <div *ngIf="rc[i].get('comments').invalid && (rc[i].get('comments').dirty || rc[i].get('comments').touched)">
                <p class="text-danger" *ngIf="rc[i].get('comments').errors?.required">Required</p>
              </div>
            </div>

          </div>
        </div>

        <div class="form-check" [ngClass]="{'disabled': isLoading || readOnly}">
          <input class="form-check-input" type="checkbox" formControlName="terms" id="flexCheckDefault1">
          <label class="form-check-label" for="flexCheckDefault1">
            <!-- I accept <span class="c-pointer text-decoration-underline" (click)="openHtmlViewModal('PRIVACY')">Privacy Policy</span>
             and <span class="c-pointer text-decoration-underline" (click)="openHtmlViewModal('TERMS')">Terms & condition</span>
            of a auction and agree to participate on {{selectedAuction?.sequenceNo}} # {{selectedAuction?.auctionName}} -->

            I accept and agree the
            <span class="c-pointer text-decoration-underline" >Terms &
              condition</span>
            and <span class="c-pointer text-decoration-underline" >Privacy
              Policy</span>
            to participate on {{selectedAuction?.sequenceNo}} # {{selectedAuction?.auctionName}}
          </label>
        </div>
        <div *ngIf="fc.terms.invalid && (fc.terms.dirty || fc.terms.touched)">
          <p class="text-danger" *ngIf="fc.terms.errors?.required">Required</p>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="showSuccessToast$ | async"
            [message]="'Auction Registration saved successfully.'"></app-message-toast>
          <app-message-toast *ngIf="showErrorToast$ | async" [isError]="true" [message]="errorMsg"></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading"></div>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="!readOnly && !(showSuccessToast$ | async) && !isLoading">
          <button type="button" class="btn btn-new-class c-pointer" (click)="handleValidSubmit()">ADD</button>
        </div>
      </form>
    </div>
  </div>
</div>
