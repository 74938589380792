import { DataRedirectionService } from 'src/app/shared/services/data-redirection.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { LandingAndBidderService } from './shared/services/LandingAndBidder.service';
import { OrganizationUiDto } from './shared/models/user/AuctionHouseDto';
import { AuctionExtUtil } from './shared/util/AuctionExtUtil';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from './shared/services/user.service';
import { ApplicationListenerService } from './shared/services/application-listener.service';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { AuctionExtConstant } from './shared/util/AuctionExtConstant';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnDestroy, AfterViewInit {

  currentAuctionHouseSubscription$?: Subscription
  @ViewChild("ErrorTemp") errorTempRef!: NgbModal;

  activatedRouteSubscription?: Subscription;
  routerSubscription?: Subscription;

  @ViewChild('appLoader', { static: false })
  appLoader?: LoaderComponent;

  constructor(
    private ngbModal: NgbModal,
    private dataRedirectionService: DataRedirectionService,
    // private location: LocationStrategy,
    private meta: Meta,
    private title: Title,
    private translate: TranslateService,
    private bidderService: LandingAndBidderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private applicationListenerService: ApplicationListenerService

  ) {
    this.translate.use("en");
  }

  async ngOnInit() {
    this.dataRedirectionService.disableAngularLoader();
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.bidderService.getCurrentOrganizationUiDto$.subscribe((data) => {
      if (data) {
        this.updateMetaTag(data);

        if (data.primaryColor && data.secondaryColor) {
          document.documentElement.style.setProperty('--clr-primary', data.primaryColor!, 'important');
          document.documentElement.style.setProperty('--clr-secondary', data.secondaryColor!, 'important');
        }
      }
    })
  }

  async ngAfterViewInit(){
    await this.applicationListenerService.loadApplicationInitialData();
    this.dataRedirectionService.disableApplicationDataLoader()
      // if param is not present, consider it as a page refresh event
      let sessionId = localStorage.getItem("AUC_SESSION_ID");
      if (sessionId) {
        this.appLoader?.openLoaderIcon(AuctionExtConstant.LOADER_NOTIFICATION, "Loading ...");
        await this.handleRedirectionWithSessionId();
      }else{
        this.dataRedirectionService.navigationToHomePage();
      }

  }

  private async handleRedirectionWithSessionId() {

    let sessionInfo = await this.dataRedirectionService.getUserSessionInfo();

    if (sessionInfo) {
      this.userService.setUser(sessionInfo);
      await this.dataRedirectionService.initializeUserListeners();
    }
    this.appLoader?.closeLoaderIcon(AuctionExtConstant.LOADER_NOTIFICATION);
    this.dataRedirectionService.navigateToPage();

  }

  openErrorTem() {
    this.ngbModal.open(this.errorTempRef, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    })
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  updateMetaTag(auctionHouseDto: OrganizationUiDto) {
    let metaTitle = this.truncateString(auctionHouseDto.name!, 55);
    let metaDescription = this.truncateString(auctionHouseDto.description!, 120);

    this.title.setTitle(metaTitle);
    this.meta.updateTag({ name: 'description', content: `${metaDescription}` });
  }

  truncateString(input: string, length: number) {
    if (input) {
      let formattedInput = input.replace(/<\/?[^>]+(>|$)/g, "");
      formattedInput = formattedInput.replace(/&#[0-9]+;/g, "");
      formattedInput = formattedInput.replace(/&nbsp;/g, ' ');
      formattedInput = formattedInput.replace(/&amp;/g, '&');
      return AuctionExtUtil.truncateString(formattedInput, length);
    }
    return '';
  }

  ngOnDestroy(): void {
    if (this.currentAuctionHouseSubscription$) {
      this.currentAuctionHouseSubscription$.unsubscribe()
    }

    if(this.activatedRouteSubscription){
      this.activatedRouteSubscription.unsubscribe();
    }

    if(this.routerSubscription){
      this.routerSubscription.unsubscribe();
    }
  }

}
