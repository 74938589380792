<div class="btn-group" ngbDropdown autoClose="Inside" container="body">
  <button type="button" class="btn p-0 mr-2 pt-2 text-primary" ngbDropdownToggle>
    <span>
      <div class={{selectedClass}}></div>
    </span>
    <span class="cust-padd">{{selectedCode}}</span>
  </button>
  <div ngbDropdownMenu class="w-25">
    <!-- SYMBOL SEARCH -->
    <div class="row text-center margin px-3">
      <div class="col-md-11 fix-inputGroup">
        <div class="input-group input-group-sm mb-2">
          <div class="input-group-prepend">
            <span class="input-group-text form-control-sm text-primary">
              <fa-icon [icon]="['fas','magnifying-glass']"></fa-icon>
            </span>
          </div>
          <input #input autocomplete="no" id="search" type="text" (keyup)="search(input.value)" placeholder=" Search Country"
            autocomplete="nofill" class="form-control" />
        </div>
      </div>
    </div>

    <div class="scroll-area-xs">
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(0)">
        <div class="language-icon me-2 fi fi-bh"></div>
        <span>Bahrain</span>
        <span>(+973)</span>
        <span hidden>8</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(1)">
        <div class="language-icon me-2 fi fi-au"></div>
        <span>Australia</span>
        <span>(+61)</span>
        <span hidden>10</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(2)">
        <div class="language-icon me-2 fi fi-at"></div>
        <span>Austria</span>
        <span>(+43)</span>
        <span hidden>10</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(3)">
        <div class="language-icon me-2 fi fi-bd"></div>
        <span>Bangladesh</span>
        <span>(+880)</span>
        <span hidden>11</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(4)">
        <div class="language-icon me-2 fi fi-be"></div>
        <span>Belgium</span>
        <span>(+32)</span>
        <span hidden>10</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(5)">
        <div class="language-icon me-2 fi fi-ca"></div>
        <span>Canada</span>
        <span>(+1)</span>
        <span hidden>10</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(6)">
        <div class="language-icon me-2 fi fi-hr"></div>
        <span>Croatia</span>
        <span>(+385)</span>
        <span hidden>10</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(7)">
        <div class="language-icon me-2 fi fi-cy"></div>
        <span>Cyprus</span>
        <span>(+357)</span>
        <span hidden>8</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(8)">
        <div class="language-icon me-2 fi fi-ee"></div>
        <span>Estonia</span>
        <span>(+372)</span>
        <span hidden>8</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(9)">
        <div class="language-icon me-2 fi fi-fi"></div>
        <span>Finland</span>
        <span>(+358)</span>
        <span hidden>10</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(10)">
        <div class="language-icon me-2 fi fi-fr"></div>
        <span>France</span>
        <span>(+33)</span>
        <span hidden>10</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(11)">
        <div class="language-icon me-2 fi fi-de"></div>
        <span>Germany</span>
        <span>(+49)</span>
        <span hidden>12</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(12)">
        <div class="language-icon me-2 fi fi-gr"></div>
        <span>Greece</span>
        <span>(+30)</span>
        <span hidden>10</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(13)">
        <div class="language-icon me-2 fi fi-in"></div>
        <span>India</span>
        <span>(+91)</span>
        <span hidden>10</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(14)">
        <div class="language-icon me-2 fi fi-ie"></div>
        <span>Ireland</span>
        <span>(+353)</span>
        <span hidden>10</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(15)">
        <div class="language-icon me-2 fi fi-it"></div>
        <span>Italy</span>
        <span>(+39)</span>
        <span hidden>10</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(16)">
        <div class="language-icon me-2 fi fi-ke"></div>
        <span>Kenya</span>
        <span>(+254)</span>
        <span hidden>9</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(17)">
        <div class="language-icon me-2 fi fi-kw"></div>
        <span>Kuwait</span>
        <span>(+965)</span>
        <span hidden>8</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(18)">
        <div class="language-icon me-2 fi fi-lv"></div>
        <span>Latvia</span>
        <span>(+371)</span>
        <span hidden>8</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(19)">
        <div class="language-icon me-2 fi fi-lt"></div>
        <span>Lithuania</span>
        <span>(+370)</span>
        <span hidden>9</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(20)">
        <div class="language-icon me-2 fi fi-lu"></div>
        <span>Luxembourg</span>
        <span>(+352)</span>
        <span hidden>9</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(21)">
        <div class="language-icon me-2 fi fi-my"></div>
        <span>Malaysia</span>
        <span>(+60)</span>
        <span hidden>10</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(22)">
        <div class="language-icon me-2 fi fi-mt"></div>
        <span>Malta</span>
        <span>(+356)</span>
        <span hidden>8</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(23)">
        <div class="language-icon me-2 fi fi-nl"></div>
        <span>Netherlands</span>
        <span>(+31)</span>
        <span hidden>10</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(24)">
        <div class="language-icon me-2 fi fi-nz"></div>
        <span>New Zealand</span>
        <span>(+64)</span>
        <span hidden>10</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(25)">
        <div class="language-icon me-2 fi fi-ng"></div>
        <span>Nigeria</span>
        <span>(+234)</span>
        <span hidden>11</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(26)">
        <div class="language-icon me-2 fi fi-pt"></div>
        <span>Portugal</span>
        <span>(+351)</span>
        <span hidden>9</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(27)">
        <div class="language-icon me-2 fi fi-sa"></div>
        <span>Saudi Arabia</span>
        <span>(+966)</span>
        <span hidden>10</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(28)">
        <div class="language-icon me-2 fi fi-sk"></div>
        <span>Slovakia</span>
        <span>(+421)</span>
        <span hidden>10</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(29)">
        <div class="language-icon me-2 fi fi-si"></div>
        <span>Slovenia</span>
        <span>(+386)</span>
        <span hidden>9</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(30)">
        <div class="language-icon me-2 fi fi-za"></div>
        <span>South Africa</span>
        <span>(+27)</span>
        <span hidden>9</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(31)">
        <div class="language-icon me-2 fi fi-es"></div>
        <span>Spain</span>
        <span>(+34)</span>
        <span hidden>9</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(32)">
        <div class="language-icon me-2 fi fi-ae"></div>
        <span>United Arab Emirates</span>
        <span>(+971)</span>
        <span hidden>9</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(33)">
        <div class="language-icon me-2 fi fi-gb"></div>
        <span>United Kingdom</span>
        <span>(+44)</span>
        <span hidden>10</span>
      </button>
      <button type="button" class="dropdown-item country-code-list" (click)="handleCountySelection(34)">
        <div class="language-icon me-2 fi fi-us"></div>
        <span>United States</span>
        <span>(+1)</span>
        <span hidden>10</span>
      </button>
    </div>
  </div>
</div>