import { FinancialQuestionTemplate } from "./FinancialQuestionTemplate";
import { QuestionnaireTemplate } from "./QuestionnaireTemplate";
import { SectionTemplate } from "./SectionTemplate";
import { TechnicalQuestionTemplate } from "./TechnicalQuestionTemplate";

export class QuestionnaireWrapperDto {
  questionnaireTemplate?: QuestionnaireTemplate;
  sectionTemplateList?: Array<SectionTemplate>;
  technicalQuestionTemplates?: Array<TechnicalQuestionTemplate>;
  financialQuestionTemplates?: Array<FinancialQuestionTemplate>;
}