import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-company-privacy-policy',
  templateUrl: './company-privacy-policy.component.html',
  styleUrls: ['./company-privacy-policy.component.sass']
})
export class CompanyPrivacyPolicyComponent implements AfterViewInit {
  @ViewChild('privacyHeader') privacyHeader?: ElementRef<HTMLElement>
  
  constructor(
    private activeModal: NgbActiveModal
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.privacyHeader?.nativeElement.scrollIntoView()
    }, 100)
  }

  moveToTop(ref: HTMLElement) {
    ref.scrollIntoView()
  }
  closeModal() {
    this.activeModal.close()
  }
}
