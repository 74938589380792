import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  isSameDay,
  isSameMonth,
  isWithinInterval,
} from 'date-fns';
import { BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { AuctionExtUtil } from '../../util/AuctionExtUtil';
import { DataRedirectionService } from '../../services/data-redirection.service';
import { AuctionStatus } from '../../models/user/AuctionStatus';
import { CalendarService } from '../../services/calendar.service';
import SwiperCore, { SwiperOptions, Navigation, Pagination, Virtual, Autoplay } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { AuctionExtConstant } from '../../util/AuctionExtConstant';
import { SourcingCalenderEventDto } from '../../models/user/SourcingCalenderEventDto';

// install Swiper modules
SwiperCore.use([Virtual, Autoplay]);

@Component({
  selector: 'app-auction-calendar',
  templateUrl: './auction-calendar.component.html',
  styleUrls: ['./auction-calendar.component.sass'],
  providers: [],
})
export class AuctionCalendarComponent implements OnInit, AfterViewInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;


  isLoading: boolean = false;
  filterBy: string = 'ALL_AUCTION_THIS_MONTH';
  selectedCalenderEventList: SourcingCalenderEventDto [] = []
  selectedCalenderEvent?: SourcingCalenderEventDto | null = null ;
  selectedCalenderEvent$ = new BehaviorSubject<SourcingCalenderEventDto|null>(null)
  selectedMonth?: Date;

  allCalenderEventsSubscription$?: Subscription;

  allCalenderEvents: SourcingCalenderEventDto[] = [];
  liveCalenderEventList$ = new BehaviorSubject<SourcingCalenderEventDto[]>([]);
  allCalenderEventForMonth$ = new BehaviorSubject<number>(0);

  liveCalenderEventForMonth$ = new BehaviorSubject<number>(0);
  liveWaitCalenderEventForMonth$ = new BehaviorSubject<number>(0);
  closingInCalenderEventForMonth$ = new BehaviorSubject<number>(0);

  liveCalenderEventForSelectedDate$ = new BehaviorSubject<number>(0);
  liveWaitCalenderEventForSelectedDate$ = new BehaviorSubject<number>(0);
  closingInCalenderEventForSelectedDate$ = new BehaviorSubject<number>(0);

  filterCalenderEventList$ = new BehaviorSubject<SourcingCalenderEventDto[]>([]);

  constructor(
    private calendarService: CalendarService,
    private dataRedirectionService: DataRedirectionService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loadSourcingCalenderData();
    this.allCalenderEventsSubscription$ =
      this.calendarService.getAllSourcingEventDtos$.subscribe((data) => {
        if (data && data.length > 0) {
          this.allCalenderEvents = data;

          if(this.allCalenderEvents && this.allCalenderEvents.length > 0){
            this.liveCalenderEventList$.next(this.allCalenderEvents.filter(item => item.status == AuctionStatus.LIVE || item.status == AuctionStatus.LIVE_WAIT));
            this.populateCalenderEventSummaryData();
          }
        } else {
          this.allCalenderEvents = [];
        }
      });
  }


  async loadSourcingCalenderData() {
    try {
      this.isLoading = true;
      let apiResponseDto = await firstValueFrom(this.calendarService.fetchSourcingCalenderData());
      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        let sourcingCalenderDtos = apiResponseDto.data as SourcingCalenderEventDto[];
        if(sourcingCalenderDtos && sourcingCalenderDtos.length > 0){
          this.calendarService.setAllSourcingEventDtos(sourcingCalenderDtos!);
        }

      }
    } catch (error) {
      console.error(error);
    }
    this.isLoading = false;
  }

  isDateInSelectedMonth(startDate: Date, endDate: Date,  selectedDate: Date): boolean {
    return isSameMonth(startDate, selectedDate) ||  isSameMonth(endDate, selectedDate)
  }

  isAuctionDateValidForShow(startDate: Date, endDate: Date, selectedDate: Date): boolean {
    return isWithinInterval(selectedDate, { start: startDate, end: endDate })
  }

  onDateSelected(selectedDate : Date) {
    this.selectedCalenderEvent = null;
    this.selectedCalenderEvent$.next(this.selectedCalenderEvent);

    let temp = this.liveCalenderEventList$.value;
    this.selectedCalenderEventList = [];
    if(temp && temp.length > 0){
      let  data  = temp.filter(item => this.isAuctionDateValidForShow(AuctionExtUtil.convertedDate(item.startDate!, '00:00')!,AuctionExtUtil.convertedDate(item.endDate!, '23:59')!, selectedDate));
      this.selectedCalenderEventList = data;
    }

    if(temp && temp.length > 0){
      let  liveWait  = temp.filter(item => item.status == "LIVE_WAIT" && this.isAuctionDateValidForShow(AuctionExtUtil.convertedDate(item.startDate!, '00:00')!,AuctionExtUtil.convertedDate(item.endDate!, '23:59')!, selectedDate));
      if(liveWait && liveWait.length > 0){
        this.liveWaitCalenderEventForSelectedDate$.next(liveWait.length);
      }else{
        this.liveWaitCalenderEventForSelectedDate$.next(0);
      }

    }

    if(temp && temp.length > 0){
      let  live  = temp.filter(item => item.status == "LIVE" &&  this.isAuctionDateValidForShow(AuctionExtUtil.convertedDate(item.startDate!, '00:00')!,AuctionExtUtil.convertedDate(item.endDate!, '23:59')!, selectedDate));
      if(live && live.length > 0){
        this.liveCalenderEventForSelectedDate$.next(live.length);
      }else{
        this.liveCalenderEventForSelectedDate$.next(0);
      }
    }

    if(temp && temp.length > 0){
      let  closingIn  = temp.filter(item => (item.status == "LIVE" || item.status == "LIVE_WAIT") && isSameDay(AuctionExtUtil.convertedDate(item.endDate!, '00:00')!, selectedDate));
      if(closingIn && closingIn.length > 0){
        this.closingInCalenderEventForSelectedDate$.next(closingIn.length);
      }else{
        this.closingInCalenderEventForSelectedDate$.next(0);
      }
    }

    this.changeDetectorRef.detectChanges();

    if (this.swiper && this.swiper.swiperRef) {
      this.swiper?.swiperRef.update()
    }
  }

  onMonthSelected(date: Date){
    this.clearData();
    this.selectedMonth = date;
    this.populateCalenderEventSummaryData();
  }

  populateCalenderEventSummaryData(){
    if(!this.selectedMonth){
      this.selectedMonth = new Date();
    }
    let temp = this.liveCalenderEventList$.value;

    if(temp && temp.length > 0){

      let  allData  = temp.filter(item => this.isDateInSelectedMonth(AuctionExtUtil.convertedDate(item.startDate!, item.startTime!)!, AuctionExtUtil.convertedDate(item.endDate!, item.endTime!)!, this.selectedMonth!));
      if(allData){
        this.allCalenderEventForMonth$.next(allData.length);
      }else{
        this.allCalenderEventForMonth$.next(0);
      }

      let  liveWaitData  = temp.filter(item => item.status == "LIVE_WAIT" && this.isDateInSelectedMonth(AuctionExtUtil.convertedDate(item.startDate!, item.startTime!)!, AuctionExtUtil.convertedDate(item.endDate!, item.endTime!)!, this.selectedMonth!));
      if(liveWaitData){
        this.liveWaitCalenderEventForMonth$.next(liveWaitData.length);
      }else{
        this.liveWaitCalenderEventForMonth$.next(0);
      }

      let  liveData  = temp.filter(item => item.status == "LIVE" && this.isDateInSelectedMonth(AuctionExtUtil.convertedDate(item.startDate!, item.startTime!)!, AuctionExtUtil.convertedDate(item.endDate!, item.endTime!)!, this.selectedMonth!));
      if(liveData){
        this.liveCalenderEventForMonth$.next(liveData.length);
      }else{
        this.liveCalenderEventForMonth$.next(0);
      }

      let  closingInData  = temp.filter(item => (item.status == "LIVE" || item.status == "LIVE_WAIT") && isSameMonth(AuctionExtUtil.convertedDate(item.endDate!, item.endTime!)!, this.selectedMonth!));
      if(closingInData){
        this.closingInCalenderEventForMonth$.next(closingInData.length);
      }else{
        this.closingInCalenderEventForMonth$.next(0);
      }

      let allAuctionOfSelectMonth =  temp.filter(item => this.isDateInSelectedMonth(AuctionExtUtil.convertedDate(item.startDate!, item.startTime!)!, AuctionExtUtil.convertedDate(item.endDate!, item.endTime!)!, this.selectedMonth!));
      if(allAuctionOfSelectMonth && allAuctionOfSelectMonth.length > 0){
        this.filterCalenderEventList$.next(allAuctionOfSelectMonth);
      }else{
        this.filterCalenderEventList$.next([]);
      }

    }

  }

  filterCalenderEventData(filterBy : string){
    this.clearData()

    this.filterBy = filterBy;
    let toReturn: SourcingCalenderEventDto[]  = [];
    let temp = this.liveCalenderEventList$.value;


    if(temp && temp.length > 0){
        if(filterBy == "ALL_AUCTION_THIS_MONTH"){
          toReturn = temp.filter(item => this.isDateInSelectedMonth(AuctionExtUtil.convertedDate(item.startDate!, item.startTime!)!, AuctionExtUtil.convertedDate(item.endDate!, item.endTime!)!, this.selectedMonth!));
        }
        else if(filterBy == "LIVE_AUCTION_THIS_MONTH"){
          toReturn = temp.filter(item => item.status == "LIVE" && this.isDateInSelectedMonth(AuctionExtUtil.convertedDate(item.startDate!, item.startTime!)!, AuctionExtUtil.convertedDate(item.endDate!, item.endTime!)!, this.selectedMonth!));
        }else if(filterBy == "LIVE_WAIT_AUCTION_THIS_MONTH"){
          toReturn = temp.filter(item => item.status == "LIVE_WAIT" && this.isDateInSelectedMonth(AuctionExtUtil.convertedDate(item.startDate!, item.startTime!)!, AuctionExtUtil.convertedDate(item.endDate!, item.endTime!)!, this.selectedMonth!));
        }else if(filterBy == "CLOSING_AUCTION_THIS_MONTH"){
          toReturn = temp.filter(item => (item.status == "LIVE" || item.status == "LIVE_WAIT") && isSameMonth(AuctionExtUtil.convertedDate(item.endDate!, item.endTime!)!, this.selectedMonth!));
        }
    }
    this.filterCalenderEventList$.next(toReturn);
  }

  ngAfterViewInit(): void { }

  getDisplayDate(date?: string, time?: string) {
    return AuctionExtUtil.getDisplayDateAdmin(date, time);
  }

  navigateToHome() {
    this.dataRedirectionService.navigationToHomePage()
  }

  swiperConfig: SwiperOptions = {
    spaceBetween: 50,
    pagination: { clickable: true, dynamicBullets: true, dynamicMainBullets: 3 },
    scrollbar: { draggable: true },
    modules: [Pagination, Navigation],
    centerInsufficientSlides: true,
    slidesPerView: 1,
    autoplay: {
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },
  };


  onNavigationNext() {
    this.swiper?.swiperRef.slideNext(1000);
  }

  onNavigationPrev() {
    this.swiper?.swiperRef.slidePrev(1000);
  }

  clearData(){
    this.selectedCalenderEvent = null;
    this.selectedCalenderEvent$.next(this.selectedCalenderEvent);
    this.liveWaitCalenderEventForSelectedDate$.next(0);
    this.liveCalenderEventForSelectedDate$.next(0);
    this.closingInCalenderEventForSelectedDate$.next(0);
    this.selectedCalenderEventList = [];
  }

  onCalenderEventSelected(auction: SourcingCalenderEventDto) {
    this.clearData()

    setTimeout(() => {
      this.selectedCalenderEvent = auction;
      this.selectedCalenderEvent$.next(auction);
      this.changeDetectorRef.detectChanges()
    }, 200);
  }

}
