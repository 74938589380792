<div class="d-flex">
    <span class="material-symbols-outlined">error</span>
    <div>
        <div>This is Information message.</div>
        <div class="mb-3">
            <label for="login-username" class="form-label">Username / Email</label>
            <input type="email" class="form-control" id="login-username" />
          </div>
          <div class="mb-3">
            <label for="login-password" class="form-label">Password</label>
            <input type="password" class="form-control" id="login-password" />
          </div>
        <div><button type="button">Close</button></div>
    </div>
</div>


<!-- <span class="material-symbols-outlined">error</span>
<span class="material-symbols-outlined">warning</span>
<span class="material-symbols-outlined">info</span>
<span class="material-symbols-outlined">priority_high</span>
<span class="material-symbols-outlined">help</span>
<span class="material-symbols-outlined">contact_support</span> -->