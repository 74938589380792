<div class="my-3">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>Proxy Bid</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="m-3">
    <form class="needs-validation" novalidate>
      <div class="col-12">
        <div class="mb-2">
          <label for="Full Name" class="form-label">Full Name</label>
          <div class="e-fieldtext">
            <input name="" id="fullName" type="text" placeholder="Full Name" class="form-control" />
          </div>
        </div>

      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <div class="mb-2">
              <label for="Email" class="form-label">Email</label>
              <div class="e-fieldtext">
                <input name="" id="email" type="text" placeholder="Email" class="form-control" />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-2">
              <label for="Phone" class="form-label">Phone</label>
              <div class="e-fieldtext">
                <input name="" type="text" placeholder="Phone" id="phone" class="form-control" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <div class="mb-2">
              <label for="City" class="form-label">City</label>
              <input name="" type="text" placeholder="City" id="city" class="form-control" />
            </div>
          </div>
          <div class="col-6">
            <div class="mb-2">
              <label for="Address" class="form-label">Address</label>
              <input name="" type="text" placeholder="Address" class="form-control" id="address" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <div class="mb-2">
              <label for="Company Name" class="form-label">Company Name</label>
              <input name="" type="text" placeholder="Company Name" id="companyName" class="form-control" />
            </div>
          </div>
          <div class="col-6">
            <div class="mb-2">
              <label for="Registration Fee" class="form-label">Registration Fee</label>
              <input name="" id="registrationFee" type="text" placeholder="Registration Fee" class="form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class="d-grid gap-2 mb-3">
        <button type="button" class="btn btn-new-class">Register</button>
      </div>
    </form>
  </div>
</div>