<div class="mt-5">
  <div class="col-md-6 m-md-auto">
    <div class="text-orange" *ngIf="!userEntityDto?.companyName">
      <img src="assets/icons/pending-icon.svg" class="svg-icon-class c-pointer me-2"> Your basic details are pending
    </div>
    <div class="text-success" *ngIf="_showSuccessToast$|async">
      <img src="assets/icons/filter-check.svg" class="svg-icon-class-md c-pointer me-2">
      Your basic details are saved
    </div>
    <div class="text-danger" *ngIf="_showErrorToast$|async">
      <img src="assets/icons/filter-check.svg" class="svg-icon-class-md c-pointer me-2">
      {{errorMsg}}
    </div>
    <div>
      <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit()">
        <div class="row mt-1">
          <div class="col-md-6  mb-4">
            <div class="form-floating">
              <input type="text" class="form-control" id="firstName" formControlName="firstName"
                [ngClass]="{'is-invalid': fc['firstName'].invalid && (fc['firstName'].dirty || fc['firstName'].touched)}" />
              <label for="firstName">First Name</label>
              <div *ngIf="fc['firstName'].invalid && (fc['firstName'].dirty || fc['firstName'].touched)">
                <p class="text-danger" *ngIf="fc['firstName'].errors?.['required']">Required</p>
              </div>
            </div>
          </div>
          <div class="col-md-6  mb-4">
            <div class="form-floating">
              <input class="form-control" id="lastName" formControlName="lastName"
                [ngClass]="{'is-invalid': fc['lastName'].invalid && (fc['lastName'].dirty || fc['lastName'].touched)}" />
              <label for="lastName">Last Name</label>
              <div *ngIf="fc['lastName'].invalid && (fc['lastName'].dirty || fc['lastName'].touched)">
                <p class="text-danger" *ngIf="fc['lastName'].errors?.['required']">Required</p>
              </div>
            </div>
          </div>
          <div class="col-md-6  mb-4">
            <div class="form-floating">
              <input class="form-control" formControlName="companyName" id="companyName"
                [ngClass]="{'is-invalid': fc['companyName'].invalid && (fc['companyName'].dirty || fc['companyName'].touched)}" />
              <label for="companyName">Company Name</label>
              <div *ngIf="fc['companyName'].invalid && (fc['companyName'].dirty || fc['companyName'].touched)">
                <p class="text-danger" *ngIf="fc['companyName'].errors?.['required']">Required</p>
              </div>
            </div>
          </div>
          <div class="col-md-6  mb-4">
            <div class="form-floating">
              <input class="form-control" formControlName="designation" id="designation" />
              <label for="designation">Designation (Optional)</label>
            </div>
          </div>
          <div class="col-md-6  mb-4">
            <div class="form-floating">
              <input class="form-control" id="emailId" formControlName="emailId" />
              <label for="emailId">Email Id</label>
            </div>
          </div>
          <div class="col-md-6  mb-4">
            <div class="input-group">
              <span class="input-group-addon">
                <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEvent($event)"
                  [countryCode$]="countryCodeForMobileNo$"></app-country-code-select>
              </span>
              <div class="form-floating">
                <input class="form-control" id="mobileNo" formControlName="mobileNo"
                  [ngClass]="{'is-invalid': fc['mobileNo'].invalid && (fc['mobileNo'].dirty || fc['mobileNo'].touched)}" />
                <label for="mobileNo">Phone Number</label>
                <div *ngIf="fc['mobileNo'].invalid && (fc['mobileNo'].dirty || fc['mobileNo'].touched)">
                  <p class="text-danger" *ngIf="fc['mobileNo'].errors?.['required']">Required</p>
                  <p class="text-danger" *ngIf="fc['mobileNo'].errors?.['pattern']">Invalid</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6  mb-4">
            <div class="form-floating">
              <input class="form-control" id="companyUrl" formControlName="companyUrl" />
              <label for="companyUrl">Company Website (Optional)</label>
            </div>
          </div>
          <div class="col-md-6  mb-4">
            <div class="input-group">
              <span class="input-group-addon">
                <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEventForFaxNo($event)"
                  [countryCode$]="countryCodeForFaxNo$"></app-country-code-select>
              </span>
              <div class="form-floating">
                <input class="form-control" formControlName="faxNo" id="faxNo"
                  [ngClass]="{'is-invalid': fc['faxNo'].invalid && (fc['faxNo'].dirty || fc['faxNo'].touched)}" />
                <label for="faxNo">Fax Number (Optional)</label>
                <div *ngIf="fc['faxNo'].invalid && (fc['faxNo'].dirty || fc['faxNo'].touched)">
                  <p class="text-danger" *ngIf="fc['faxNo'].errors?.['pattern']">Invalid</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input name="" type="text" id="companyLogo" (click)="companyLogo.click()" 
                    class="form-control c-pointer" [value]="this._fileDisplayName$ | async"/>
                  <input #companyLogo name="companyLogo" onclick="this.value=null;" type="file" class="d-none"
                  (change)="chooseFile($event)" />
                  <label for="companyLogo">Add Company Logo (for invoice)</label>
                  <div *ngIf="fc['companyLogo'].invalid && (fc['companyLogo'].dirty || fc['companyLogo'].touched)">
                    <p class="text-danger" *ngIf="fc['companyLogo'].errors?.['required']">Required</p>
                  </div>
                </div>
              </div>

             <div class="col-md-6">
              <div class="row">
                  <div class="col-6">
                    <img alt="" [src]="imageUrl" *ngIf="!currentFileInfoDto"
                      class="modal-logo-image" />
                    <img alt="" src="/downloadLandingBlob?fileId={{currentFileInfoDto!.fileId}}"
                      class="modal-logo-image"
                      *ngIf="currentFileInfoDto" />
                  </div>
              <div class="col-6 mt-3 text-end" *ngIf="currentFileInfoDto || currentFile">
                <button class="btn btn-sm btn-new-class me-2"  (click)="openViewImageModal(imageUrl)">
                  <img src="assets/icons/view_dark.svg"  class="svg-icon-class c-pointer">
                </button>
    
                <span (click)="deleteImage()"
                  [ngClass]="{'disabled' : isLoading }">
                  <img src="assets/icons/delete_danger.svg"  class="svg-icon-class c-pointer">
                </span>
              </div>
            </div>
             </div>
    
            </div>
          </div>
        </div>

        <div class="d-grid gap-2 mb-4" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="col-12 text-center" *ngIf="(!isLoading && !(_showSuccessToast$ | async))">
          <button class="btn btn-primary-clr-class btn-sm" (click)="handleValidSubmit()">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>

<app-loader #appLoader></app-loader>