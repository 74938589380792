import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-password-change',
  templateUrl: './user-password-change.component.html',
  styleUrls: ['./user-password-change.component.sass']
})
export class UserPasswordChangeComponent {
  constructor(
    private modalService: NgbModal,
  ){

  }


  closeModal() {
    this.modalService.dismissAll();
  }
}
