import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { AuctionLotEntityDto } from '../../models/user/AuctionLotEntityDto';

import { SwiperOptions, Navigation, Pagination } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { LandingAndBidderService } from '../../services/LandingAndBidder.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { UserAuctionRegistrationDto } from '../../models/user/UserAuctionRegistrationDto';
import { UserAuctionEligibleStatus } from '../../enums/UserAuctionEligibleStatus';

@Component({
  selector: 'app-lot-carousel',
  templateUrl: './lot-carousel.component.html',
  styleUrls: ['./lot-carousel.component.sass']
})
export class LotCarouselComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() auctionLotEntityDto?: AuctionLotEntityDto;
  @Input() isMultiLot: boolean = false;
  @Input() isGalleryView: boolean = false;

  @ViewChild('swiperCarousel', { static: false }) swiperCarousel?: SwiperComponent;
  @ViewChildren('videoCarousel') videoCarousels?: Array<ElementRef<HTMLVideoElement>>;
  @ViewChildren('iframeCarousel') iframeCarousels?: Array<ElementRef<HTMLIFrameElement>>;

  auctionEntityDto?: AuctionEntityDto
  userRegistrationDto?: UserAuctionRegistrationDto;

  auctionSubscription$?: Subscription
  myAuctionRegistrationSubscription$?: Subscription;


  isUserLoggedIn: boolean = false;
  isShowSwiper: boolean = false;

  isNewMessagesAvailable$ = new BehaviorSubject<boolean>(false);

  swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: false,
    pagination: { clickable: true, enabled: true, dynamicBullets: true, dynamicMainBullets: 4 },
    scrollbar: { draggable: true },
    modules: [Pagination, Navigation],
    centerInsufficientSlides: true
  };

  constructor(
    private bidderService: LandingAndBidderService,
    private cdRef: ChangeDetectorRef,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.auctionSubscription$ = this.bidderService.getSelectedAuction$.subscribe((data) => {
      if (data) {
        this.auctionEntityDto = data;

        this.isShowSwiper = false;

        setTimeout(() => {
        this.isShowSwiper = true;
        this.cdRef.detectChanges();
        }, 200)
      }
    })

    this.isUserLoggedIn = this.userService.getUserEntity() != null;


    this.myAuctionRegistrationSubscription$ = this.bidderService.getMyUserAuctionRegistrationWrappers$.subscribe( items => {
      if(items && items.length > 0){
        let registration = items.filter(item => item.auctionId == this.auctionEntityDto?.auctionId).map(item => item.userAuctionRegistrationDto);
        if(registration){
          this.userRegistrationDto = registration[0];
        }
      }
    })
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.auctionSubscription$) {
      this.auctionSubscription$.unsubscribe()
    }

    if(this.myAuctionRegistrationSubscription$){
      this.myAuctionRegistrationSubscription$.unsubscribe();
    }
  }

  isValidUrl = (urlString: string) => {
    try {
      return Boolean(new URL(urlString));
    }
    catch (e) {
      return false;
    }
  }

  onSlideChange() {
    this.stopCarouselVideo()
  }

  onNavigationPrev(event: any) {
    event.stopPropagation();
    this.swiperCarousel?.swiperRef.slidePrev()
  }

  onNavigationNext(event: any) {
    event.stopPropagation();
    this.swiperCarousel?.swiperRef.slideNext();
  }

  stopCarouselVideo() {
    if (this.videoCarousels && this.videoCarousels.length > 0) {
      this.videoCarousels.forEach((item) => {
        item?.nativeElement.pause();
      })
    }

    if (this.iframeCarousels && this.iframeCarousels.length > 0) {
      this.iframeCarousels.forEach((item) => {
        let element = item.nativeElement;
        if (element) {
          var iframeSrc = element.src;
          element.src = iframeSrc;
        }
      })
    }
  }

  isShowCarouselNavigation() {
    return this.auctionEntityDto?.liveStreaming || (this.auctionLotEntityDto?.lotImages && this.auctionLotEntityDto?.lotImages!.length > 1)
  }
}
