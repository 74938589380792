import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-company-terms-conditions',
  templateUrl: './company-terms-conditions.component.html',
  styleUrls: ['./company-terms-conditions.component.sass']
})
export class CompanyTermsConditionsComponent implements AfterViewInit {
  @ViewChild('termsHeader') termsHeader?: ElementRef<HTMLElement>

  constructor(
    private activeModal: NgbActiveModal,
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.termsHeader?.nativeElement.scrollIntoView()
    }, 100)
  }

  moveToTop(ref: HTMLElement) {
    ref.scrollIntoView()
  }

  closeModal() {
    this.activeModal.close()
  }
}
