import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UserEntityDto } from '../../models/UserEntityDto';
import { UserEntityInfoDto } from '../../models/user/UserEntityInfoDto';
import { AuctionExtUtil } from '../../util/AuctionExtUtil';
import { CountryCodeDto } from '../../models/CountryCodeDto';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.sass']
})
export class UserProfileComponent implements OnInit {
  @Input() userDto?: UserEntityDto | UserEntityInfoDto;
  @Output() onEditProfile = new EventEmitter<boolean>(false);

  constructor() {}

  ngOnInit(): void {

  }

  toggleEditMode() {
    this.onEditProfile.emit(true);
  }

  getCountryCode(countryCode: CountryCodeDto) {
    return AuctionExtUtil.getCountryCode(countryCode);
  }
}
