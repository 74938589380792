import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyPrivacyPolicyComponent } from '../company-privacy-policy/company-privacy-policy.component';

@Component({
  selector: 'app-cookies-alert',
  templateUrl: './cookies-alert.component.html',
  styleUrls: ['./cookies-alert.component.sass']
})
export class CookiesAlertComponent {
  @Output() checkCookieAlert = new EventEmitter<void>();

  constructor(
    public ngbModal: NgbModal
  ) { }

  openPrivacyModal() {
    this.ngbModal.open(CompanyPrivacyPolicyComponent, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  saveCookieAlert() {
    sessionStorage.setItem('COOKIE_ALERT', 'SUCCESS');
    this.checkCookieAlert.next();
  }

  closeModal() {
    this.ngbModal.dismissAll()
  }
}
