
<div class="">
  <div class=" col-12 ">

    <div class="row">
      <div class="col-md-6">
      </div>
    <div class="col-md-6 text-md-end">
     <div class="row ms-2 mt-1 justify-content-md-end me-2">
      <div class="col-md-2">
      </div>
      <div class="col-2">
        <button class="btn btn-new-class btn-sm me-2 mt-1" (click)="refreshMyBids()">
          <img src="assets/icons/refresh_dark.svg" class=" c-pointer svg-icon-class">
        </button>
       </div>
       <div class="col-8 col-md-6">
        <div class="input-group">
          <input placeholder="Search" id="ctrlSearch" type="text" class="form-control border-end-0 rounded-0 border bg-white"
          [formControl]="ctrlSearch" />
          <span class="input-group-append">
            <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5"
             type="button">
              <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
            </button>
          </span>
        </div>
       </div>

     </div>
    </div>
    </div>
  </div>


  <div class="m-3">
    <div class="table-responsive">
      <table class="table table-bordered medium-font mb-0">
        <thead class="table-dark">
          <tr>
            <th class="">Auction Title</th>
            <th class=" ">lot Title</th>
            <th class="th-color">Bid On</th>
            <th class="th-color">Bid Amount</th>
            <th class="th-color">Proxy bid Amount</th>
            <th class="th-color">Status</th>
            <th class="th-color">Lot Bid History</th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <ng-container *ngFor="let item of (filteredMyBidsData$ | async); index as i">
            <tr>
              <td class="font-weight-normal" (click)="rowCollapse(i, item?.auctionId, item?.lotId)" >{{item?.auctionSequence}}# {{item?.auctionName}}</td>
              <td class="font-weight-normal" >{{item?.lotSequence}}# {{item?.lotName}}</td>
              <td class="font-weight-normal" (click)="rowCollapse(i, item?.auctionId, item?.lotId)">{{getBidTimestamp(item)}}</td>
              <td class="font-weight-normal" (click)="rowCollapse(i, item?.auctionId, item?.lotId)">{{getFormattedPrice(item?.currency, item?.userBid?.bidPrice)}}</td>
              <td class="font-weight-normal" (click)="rowCollapse(i, item?.auctionId, item?.lotId)">
                {{ item?.userMaxBid?.bucketPrice ? getFormattedPrice(item?.currency, item?.userMaxBid?.bucketPrice) : '-'}}
              </td>
              <td class="font-weight-normal"><span class="badge text-center w-100"  [ngClass]="isMyBidIsHighest(item) ? 'bg-success' : 'bg-danger'">
                {{isMyBidIsHighest(item) ? "Highest Bid" : "Outbid"}}
              </span></td>
              <td class="font-weight-normal">
                  <div class="row">
                    <div class="text-center dropdown" ngbDropdown container="body" placement="bottom-right">
                      <span ngbDropdownToggle data-bs-toggle="dropdown">
                        <img src="assets/icons/elipsis_vertical_light.svg" class=" c-pointer svg-icon-class">
                      </span>
                      <ul class="dropdown-menu shadow" ngbDropdownMenu>
                        <li class="dropdown-item c-pointer" (click)="openBidHistory(item.auctionId, item.lotId)"><a
                             ngbDropdownItem>Show Lot Bid History</a>
                        </li>
                        <li>
                          <hr class="dropdown-divider " >
                        </li>
                        <li class="dropdown-item c-pointer" (click)="openBidHistoryByAuctionIdAndLotIdAndUserId(bidHistoryTemp, item)"><a
                            ngbDropdownItem>Show My Bids History</a></li>
                      </ul>
                    </div>
                  </div>
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="!isLoading && !((filteredMyBidsData$ | async) && (filteredMyBidsData$ | async)!.length > 0)">
            <td colspan="7" class="text-center">
              <h5 class="heading-new text-dark">No Bids Available</h5>
          </td>

        </tr>
        </tbody>
      </table>
    </div>
  </div>
        <div colspan="5" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="col-12 mt-2" *ngIf="(filteredMyBidsData$ | async)!.length > 0">
          <div class="row justify-content-between">
            <div class="col-md-6 mb-2">
              <div class="row">
                <div class="col-md-4 col-sm-5 col-6 pe-0">
                  <span class="span-class">Show rows per page </span>
                </div>
                <div class="col-6 ps-0">
                  <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-left">
                    <span ngbDropdownToggle data-bs-toggle="dropdown">
                      <button class="btn btn-sm btn-outline-secondary px-2">{{pageSize}}</button>
                    </span>
                    <ul ngbDropdownMenu class="text-center">
                      <li ngbDropdownItem (click)="changePageSize(50)">
                        50
                      </li>
                      <hr class="hr" />
                      <li ngbDropdownItem (click)="changePageSize(100)">
                        100
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <ngb-pagination [collectionSize]="this.myBidsWrapperDtos.length!"
                [(page)]="page" [pageSize]="pageSize" (pageChange)="paginateBidderHistoryList()">
              </ngb-pagination>
            </div>
          </div>
        </div>



</div>

<ng-template #bidHistoryTemp>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Your Bid History Data</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="col-12">
        <div class="m-3 table-responsive">
          <table class="table table-bordered medium-font mb-0">
            <thead class="table-secondary">
              <tr>
                <th>BID AMOUNT</th>
                <th >BID TYPE</th>
                <th >BID ON</th>


              </tr>
            </thead>
            <tbody class="bg-white">
              <tr *ngFor="let item of userBidsHistoryUIDataList; index as i">
                <td [ngClass]="{'font-weight-bold': item?.maxBid}">{{getFormattedPrice(this.selectedMyBidWrapper.currency,(item?.maxBid ? item.bucketSize : item.bidPrice))}}</td>
                <td [ngClass]="{'font-weight-bold': item?.maxBid}">{{item?.maxBid ? "Proxy Bid" : "Manual"}}</td>
                <td>{{getDisplayDate(item?.bidTimeUtc!, this.selectedMyBidWrapper.timeZone!, false)}}</td>


              </tr>
            </tbody>
          </table>
          <div colspan="5" *ngIf="isDataLoading">
            <div class="e-spinner"></div>
          </div>
        </div>
  </div>
    </div>
</div>
</ng-template>
