import { FirestoreAggregationService } from './../../../services/firestore-aggregation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AuctionStatus } from 'src/app/shared/models/user/AuctionStatus';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';
import { DataRedirectionService } from 'src/app/shared/services/data-redirection.service';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { Timestamp } from '@angular/fire/firestore';
import { AuctionSearchData } from 'src/app/shared/models/user/AuctionSearchData';
import { AuctionCardWrapperDto } from 'src/app/shared/models/AuctionCardWrapperDto';
import { ApplicationListenerService } from 'src/app/shared/services/application-listener.service';

@Component({
  selector: 'app-all-auctions',
  templateUrl: './all-auctions.component.html',
  styleUrls: ['./all-auctions.component.sass'],
})
export class AllAuctionsComponent implements OnInit, OnDestroy {
  currentSortByTitle: string = 'END_DATE_DESC';

  ctrlUpcoming: FormControl = new FormControl(true);
  ctrlRunning: FormControl = new FormControl(true);
  ctrlClosed: FormControl = new FormControl(true);
  ctrlSearchAuction: FormControl = new FormControl('');

  allAuctionCardWrapperDtos: Array<AuctionCardWrapperDto> = [];
  tempAuctionCardWrapperDtos: Array<AuctionCardWrapperDto> = [];
  tempAuctionIdsList: Array<string> = [];

  selectedLocationsList: Array<string> = [];
  selectedCategoriesList: Array<string> = [];

  filteredAuctionCardWrapperDtos$ = new BehaviorSubject<
    Array<AuctionCardWrapperDto>
  >([]);

  allAuctionsListSubscription$?: Subscription;

  reachedBottomOnce = false;

  selectedStatusList$ = new BehaviorSubject<string[]>([]);

  isLoading: boolean = false;
  totalAuctionPageSize: number = AuctionExtConstant.SHOW_MORE_SIZE;
  currentPage: number = 1;
  itemsPerPage: number = AuctionExtConstant.PAGE_SIZE;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event): void {
    // Check if the user has reached the bottom of the page
    let size = this.filteredAuctionCardWrapperDtos$.value.length;
    if (this.isScrolledToBottom() && this.totalAuctionPageSize > size) {
      if (!this.reachedBottomOnce) {
        console.log('reached the bottom of the page');
        this.updateFilteredAuctionCardWrapperDtos();
        this.reachedBottomOnce = true;
      }
    } else {
      this.reachedBottomOnce = false;
    }
  }

  constructor(
    private dataRedirectionService: DataRedirectionService,
    private bidderService: LandingAndBidderService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      let selectedStatus = params['status'];
      if (selectedStatus) {
        if (selectedStatus == 'RUNNING') {
          this.ctrlUpcoming.patchValue(false);
          this.ctrlRunning.patchValue(true);
          this.ctrlClosed.patchValue(false);
        } else if (selectedStatus == 'UPCOMING') {
          this.ctrlUpcoming.patchValue(true);
          this.ctrlRunning.patchValue(false);
          this.ctrlClosed.patchValue(false);
        } else if (selectedStatus == 'CLOSED') {
          this.ctrlUpcoming.patchValue(false);
          this.ctrlRunning.patchValue(false);
          this.ctrlClosed.patchValue(true);
        }
      }
    });

    this.allAuctionsListSubscription$ =
      this.bidderService.allAuctionCardWrapperDtos$.subscribe((data) => {
        if (data) {
          this.allAuctionCardWrapperDtos = data;
          this.filterAuctionCardWrapperDtos();
        }
      });

    this.ctrlRunning.valueChanges.subscribe(() => {
      this.filterAuctionCardWrapperDtos();
    });

    this.ctrlUpcoming.valueChanges.subscribe(() => {
      this.filterAuctionCardWrapperDtos();
    });

    this.ctrlClosed.valueChanges.subscribe(() => {
      this.filterAuctionCardWrapperDtos();
    });

    this.ctrlSearchAuction.valueChanges.subscribe(() => {
      this.searchAuctionCardWrapperDtos();
    });
  }

  updateSelectedStatus() {
    this.selectedStatusList$.next([]);
    let statusList: string[] = [];

    if (this.ctrlRunning.value) {
      statusList.push(AuctionStatus.LIVE);
    }

    if (this.ctrlUpcoming.value) {
      statusList.push(AuctionStatus.LIVE_WAIT);
    }

    if (this.ctrlClosed.value) {
      statusList.push(AuctionStatus.CLOSE);
    }

    this.selectedStatusList$.next(statusList);
  }

  searchAuctionCardWrapperDtos() {
    if (this.ctrlSearchAuction.value != '') {
      this.tempAuctionCardWrapperDtos = [];
      this.filteredAuctionCardWrapperDtos$.next(this.tempAuctionCardWrapperDtos);

      let allAuctionCardWrapperDtos = [...this.allAuctionCardWrapperDtos];

      let searchValue = this.ctrlSearchAuction.value.trim().toLowerCase();

      let tempAuctionCardWrapperDtos = allAuctionCardWrapperDtos.filter(item => item.auctionEntityDto!.auctionName?.toLowerCase().includes(searchValue)
        || item.auctionEntityDto!.sequenceNo?.toString().includes(searchValue) || item.auctionEntityDto!.auctionType?.toLowerCase().includes(searchValue)
        || item.auctionEntityDto!.listingContactDtos?.filter(m => m.name?.toLowerCase().includes(searchValue)).length! > 0
        || item.auctionEntityDto!.notOfLots?.toString().includes(searchValue) || item.auctionEntityDto!.noOfActiveLots?.toString().includes(searchValue)
        || item.auctionEntityDto!.addresses?.filter(m => m.addressLine1?.toLowerCase().includes(searchValue)
          || m.addressLine2?.toLowerCase().includes(searchValue) || m.city?.toLowerCase().includes(searchValue)
          || m.country?.toLowerCase().includes(searchValue) || m.landmark?.toLowerCase().includes(searchValue)).length! > 0
      );

      this.tempAuctionCardWrapperDtos = tempAuctionCardWrapperDtos;

      this.sortAuctionList(this.currentSortByTitle);

      this.currentPage = 1;
      this.totalAuctionPageSize = AuctionExtConstant.SHOW_MORE_SIZE;

      let filteredAuctionCardWrapperDtos = this.tempAuctionCardWrapperDtos.slice(0, this.itemsPerPage);
      this.filteredAuctionCardWrapperDtos$.next(filteredAuctionCardWrapperDtos);

      this.currentPage++;
    }
  }

  filterAuctionCardWrapperDtos() {
    let allAuctionCardWrapperDtos = [...this.allAuctionCardWrapperDtos];
    this.updateSelectedStatus();

    this.isLoading = true;
    this.currentPage = 1;
    this.totalAuctionPageSize = AuctionExtConstant.SHOW_MORE_SIZE;

    let runningAuctionCardWrapperDtos: AuctionCardWrapperDto[] = [];
    let upcomingAuctionCardWrapperDtos: AuctionCardWrapperDto[] = [];
    let closedAuctionCardWrapperDtos: AuctionCardWrapperDto[] = [];

    if (this.ctrlRunning.value == true) {
      runningAuctionCardWrapperDtos = allAuctionCardWrapperDtos.filter(item => item.auctionEntityDto?.status == AuctionStatus.LIVE);
    } else {
      runningAuctionCardWrapperDtos = []
    }

    if (this.ctrlUpcoming.value == true) {
      upcomingAuctionCardWrapperDtos = allAuctionCardWrapperDtos.filter(item => item.auctionEntityDto?.status == AuctionStatus.LIVE_WAIT);
    } else {
      upcomingAuctionCardWrapperDtos = []
    }

    if (this.ctrlClosed.value == true) {
      closedAuctionCardWrapperDtos = allAuctionCardWrapperDtos.filter(item => item.auctionEntityDto?.status == AuctionStatus.CLOSE);
    } else {
      closedAuctionCardWrapperDtos = []
    }

    this.tempAuctionCardWrapperDtos = [...upcomingAuctionCardWrapperDtos, ...runningAuctionCardWrapperDtos, ...closedAuctionCardWrapperDtos];

    this.sortAuctionList(this.currentSortByTitle);

    let filteredAuctionCardWrapperDtos = this.tempAuctionCardWrapperDtos.slice(0, this.itemsPerPage);
    this.filteredAuctionCardWrapperDtos$.next(filteredAuctionCardWrapperDtos);

    this.currentPage++;
  }

  showMore() {
    this.totalAuctionPageSize = this.totalAuctionPageSize + AuctionExtConstant.SHOW_MORE_SIZE;
    this.updateFilteredAuctionCardWrapperDtos();
  }

  getCompanyName(companyId?: string) {

    return null;
  }

  isScrolledToBottom(): boolean {
    // Calculate the scroll position and window height
    const scrollPosition =
      window.scrollY ||
      window.pageYOffset ||
      document.documentElement.scrollTop;
    const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    // Calculate the scroll position at the bottom of the page
    const scrollBottom = scrollPosition + windowHeight;

    // Calculate the total page height
    const bodyHeight = document.body.scrollHeight - 461;

    // Check if the scroll position is near the bottom of the page
    return scrollBottom >= bodyHeight;
  }

  sortAuctionList(sort: string) {
    this.currentSortByTitle = sort;

    let filteredAuctionCardWrapperDtos = this.filteredAuctionCardWrapperDtos$.value;

    if (this.currentSortByTitle == 'START_DATE_DESC') {
      this.tempAuctionCardWrapperDtos.sort(
        (a, b) =>
          this.getRemainingTime(
            b.auctionEntityDto!.startDate!,
            b.auctionEntityDto!.startTime!,
            b.auctionEntityDto!.timeZone!
          ) -
          this.getRemainingTime(
            a.auctionEntityDto!.startDate!,
            a.auctionEntityDto!.startTime!,
            a.auctionEntityDto!.timeZone!
          )
      );
      filteredAuctionCardWrapperDtos.sort(
        (a, b) =>
          this.getRemainingTime(
            b.auctionEntityDto!.startDate!,
            b.auctionEntityDto!.startTime!,
            b.auctionEntityDto!.timeZone!
          ) -
          this.getRemainingTime(
            a.auctionEntityDto!.startDate!,
            a.auctionEntityDto!.startTime!,
            a.auctionEntityDto!.timeZone!
          )
      );
    } else if (this.currentSortByTitle == 'START_DATE_ASC') {
      this.tempAuctionCardWrapperDtos.sort(
        (a, b) =>
          this.getRemainingTime(
            a.auctionEntityDto!.startDate!,
            a.auctionEntityDto!.startTime!,
            a.auctionEntityDto!.timeZone!
          ) -
          this.getRemainingTime(
            b.auctionEntityDto!.startDate!,
            b.auctionEntityDto!.startTime!,
            b.auctionEntityDto!.timeZone!
          )
      );
      filteredAuctionCardWrapperDtos.sort(
        (a, b) =>
          this.getRemainingTime(
            a.auctionEntityDto!.startDate!,
            a.auctionEntityDto!.startTime!,
            a.auctionEntityDto!.timeZone!
          ) -
          this.getRemainingTime(
            b.auctionEntityDto!.startDate!,
            b.auctionEntityDto!.startTime!,
            b.auctionEntityDto!.timeZone!
          )
      );
    } else if (this.currentSortByTitle == 'END_DATE_DESC') {
      this.tempAuctionCardWrapperDtos.sort(
        (a, b) =>
          this.getRemainingTime(
            b.auctionEntityDto!.endDate!,
            b.auctionEntityDto!.endTime!,
            b.auctionEntityDto!.timeZone!
          ) -
          this.getRemainingTime(
            a.auctionEntityDto!.endDate!,
            a.auctionEntityDto!.endTime!,
            a.auctionEntityDto!.timeZone!
          )
      );
      filteredAuctionCardWrapperDtos.sort(
        (a, b) =>
          this.getRemainingTime(
            b.auctionEntityDto!.endDate!,
            b.auctionEntityDto!.endTime!,
            b.auctionEntityDto!.timeZone!
          ) -
          this.getRemainingTime(
            a.auctionEntityDto!.endDate!,
            a.auctionEntityDto!.endTime!,
            a.auctionEntityDto!.timeZone!
          )
      );
    } else if (this.currentSortByTitle == 'END_DATE_ASC') {
      this.tempAuctionCardWrapperDtos.sort(
        (a, b) =>
          this.getRemainingTime(
            a.auctionEntityDto!.endDate!,
            a.auctionEntityDto!.endTime!,
            a.auctionEntityDto!.timeZone!
          ) -
          this.getRemainingTime(
            b.auctionEntityDto!.endDate!,
            b.auctionEntityDto!.endTime!,
            b.auctionEntityDto!.timeZone!
          )
      );
      filteredAuctionCardWrapperDtos.sort(
        (a, b) =>
          this.getRemainingTime(
            a.auctionEntityDto!.endDate!,
            a.auctionEntityDto!.endTime!,
            a.auctionEntityDto!.timeZone!
          ) -
          this.getRemainingTime(
            b.auctionEntityDto!.endDate!,
            b.auctionEntityDto!.endTime!,
            b.auctionEntityDto!.timeZone!
          )
      );
    }

    this.isLoading = false;

    this.filteredAuctionCardWrapperDtos$.next(filteredAuctionCardWrapperDtos);
  }

  getRemainingTime(date: string, time: string, timeZone: string) {
    return AuctionExtUtil.getRemainingTime(date + ' ' + time, timeZone!);
  }

  navigateToHomePage() {
    this.dataRedirectionService.navigationToHomePage();
  }

  navigateToClosedAuction() {
    this.dataRedirectionService.clearData();
    this.dataRedirectionService.setCurrentPageInSession(
      AuctionExtConstant.CLOSED_AUCTIONS
    );
    this.router.navigate(['/closedAuctions'], { skipLocationChange: true });
  }

  getSortByTitle(sortBy: string) {
    if (sortBy == 'START_DATE_ASC') {
      return 'Start Date - Ascending';
    } else if (sortBy == 'START_DATE_DESC') {
      return 'Start Date - Descending';
    } else if (sortBy == 'END_DATE_ASC') {
      return 'End Date - Ascending';
    } else if (sortBy == 'END_DATE_DESC') {
      return 'End Date - Descending';
    }
    return '';
  }

  isAuctionClosed(item: AuctionEntityDto) {
    return item.status == AuctionStatus.CLOSE;
  }

  updateFilteredAuctionCardWrapperDtos() {
    this.isLoading = true;

    let startIndex = (this.currentPage - 1) * this.itemsPerPage;
    let endIndex = startIndex + this.itemsPerPage;
    let itemsToAdd = this.tempAuctionCardWrapperDtos.slice(startIndex, endIndex);

    let filteredAuctionCardWrapperDtos = this.filteredAuctionCardWrapperDtos$.value;
    filteredAuctionCardWrapperDtos = filteredAuctionCardWrapperDtos.concat(itemsToAdd);
    this.filteredAuctionCardWrapperDtos$.next(filteredAuctionCardWrapperDtos);

    this.currentPage++;

    console.log('Current Page:', this.currentPage);
    console.log('localUpcomingAuctionCardWrapperDtos:', this.filteredAuctionCardWrapperDtos$.value.length);

    setTimeout(() => {
      this.isLoading = false;
    }, 1000)
  }

  ngOnDestroy(): void {
    if (this.allAuctionsListSubscription$) {
      this.allAuctionsListSubscription$.unsubscribe();
    }
  }
}
