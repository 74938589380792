import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { FileService } from '../../services/file.service';
import { FileInfoDto } from '../../models/FileInfoDto';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LandingAndBidderService } from '../../services/LandingAndBidder.service';
import { AuctionExtConstant } from '../../util/AuctionExtConstant';

@Component({
  selector: 'app-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.sass']
})
export class ShareButtonsComponent {
  @Input() button!: string;
  @Input() url?: string;
  @Input() fileInfoDto?: FileInfoDto;

  ngbModelRef?: NgbModalRef;

  @ViewChild('barCodeTemp') barCodeTemp?: NgbModalRef;




  // Create message body that includes the sharing link used for Email, SMS and WhatsApp buttons
  linkInDescription = {
    description: (p: any) => {
      return p.description ? `${p.description}\r\n${p.url}` : p.url;
    }
  };

  SHARE_BUTTONS = {
    facebook: {
      type: 'facebook',
      text: 'Facebook',
      ariaLabel: 'Share on Facebook',
      icon: ['fab', 'facebook-f'],
      color: '#4267B2',
      share: {
        desktop: 'https://www.facebook.com/sharer/sharer.php?'
      },
      params: {
        url: 'u'
      }
    },
    twitter: {
      type: 'twitter',
      text: 'Twitter',
      ariaLabel: 'Share on Twitter',
      icon: ['fab', 'twitter'],
      color: '#00acee',
      share: {
        desktop: 'https://twitter.com/intent/tweet?'
      },
      params: {
        url: 'url',
        description: 'text',
        tags: 'hashtags',
        via: 'via'
      }
    },
    linkedin: {
      type: 'linkedin',
      text: 'LinkedIn',
      ariaLabel: 'Share on LinkedIn',
      icon: ['fab', 'linkedin-in'],
      color: '#006fa6',
      share: {
        desktop: 'https://www.linkedin.com/shareArticle?'
      },
      params: {
        url: 'url',
        title: 'title',
        description: 'summary'
      }
    },
    whatsapp: {
      type: 'whatsapp',
      text: 'WhatsApp',
      ariaLabel: 'Share on WhatsApp',
      icon: ['fab', 'whatsapp'],
      color: '#25D366',
      share: {
        desktop: 'https://web.whatsapp.com/send?',
        android: 'whatsapp://send?',
        ios: 'https://api.whatsapp.com/send?'
      },
      params: {
        description: 'text'
      },
      paramsFunc: this.linkInDescription
    },
    copy: {
      type: 'copy',
      text: 'Copy URL',
      ariaLabel: 'Share',
      icon: ['fas', 'copy'],
      color: '#007bff'
    },

    BarCode: {
      type: 'BarCode',
      text: 'QRCode',
      ariaLabel: 'QRCode',
      icon: ['fas', 'qrcode'],
      color: '#007bff'
    },
  }

  constructor(
    private ngbModel: NgbModal,
    private fileService: FileService,
    private clipboard: Clipboard,
    private bidderService: LandingAndBidderService
  ) { }

  get getConfig() {
    let config = (this.SHARE_BUTTONS as any)[this.button];
    return config;
  }

  get getIcon() {
    let config = (this.SHARE_BUTTONS as any)[this.button];
    return config.icon;
  }

  share() {
    let data = this.bidderService._selectedAuctionLot$.value;
    if(data){
      this.bidderService.saveLotActivity(data!.auctionHouseId!, data?.auctionId!, data?.lotId!, AuctionExtConstant.SHARE).subscribe();
    }

    let url;
    if(this.button == 'BarCode'){
      this.openBarModel();
    }

    if (this.button != 'whatsapp' && this.button != 'copy') {
      url = `${this.getConfig.share.desktop}${this.getConfig.params.url}=${this.url}`;
    }

    if (this.button == 'whatsapp') {
      url = `${this.getConfig.share.desktop}${this.getConfig.params.description}=${this.url}`;
    }

    if (this.button == 'copy') {
      this.copyToClipboard()
    } else {
      window.open(url, '_blank');
    }

  }

  downloadQRCode() {
    this.fileService.downloadLandingFile(this.fileInfoDto?.fileId!).subscribe(data => {
      if (data) {
        let file = new Blob([data], { type: 'image/png'});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(file);;
        link.download = this.fileInfoDto?.fileName!; // Set the desired filename
        link.click();
      }
    })
  }



  copyToClipboard() {
    this.clipboard.copy(this.url!);
  }

  openBarModel(){
    this.ngbModelRef =  this.ngbModel.open(this.barCodeTemp, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  closeModal(){
    if(this.ngbModelRef){
      this.ngbModelRef.close();
    }
  }

}
