import { Timestamp } from "@angular/fire/firestore";

export class AuctionBidsHighestData {
  auctionHouseId?: string;
  auctionId?: string;
  previousHighestBidPrice?: number;
  previousHighestBidUserId?:string;
  bucketEmailId?: string;
  bucketPrice?: number;
  bucketUserId?: string;
  bucketUserName?: string;
  createdBy?: string;
  createdDate?: Timestamp;
  highestBidPrice?: number;
  highestBidTime?: string;
  highestBidUserId?: string;
  lotId?: string;
  totalBidPlaced?: number;
  updateTimeUtc?: Timestamp;
  updateTimeUtcMillis?: number;
  bidsUpdateTimeUtc?: Timestamp;
  bidsUpdateTimeUtcMillis?: number;
  maxBidsUpdateTimeUtc?: Timestamp;
  maxBidsUpdateTimeUtcMillis?: number;

  static parseAuctionBidsHighestData(data: any) : AuctionBidsHighestData {
    let auctionBidsHighestData = new AuctionBidsHighestData();
    auctionBidsHighestData.auctionHouseId = data.auctionHouseId;
    auctionBidsHighestData.auctionId = data.auctionId;
    auctionBidsHighestData.previousHighestBidPrice = Number(data.previousHighestBidPrice);
    auctionBidsHighestData.previousHighestBidUserId = data.previousHighestBidUserId;
    auctionBidsHighestData.bucketEmailId = data.bucketEmailId;
    auctionBidsHighestData.bucketPrice = Number(data.bucketPrice);
    auctionBidsHighestData.bucketUserId = data.bucketUserId;
    auctionBidsHighestData.bucketUserName = data.bucketUserName;
    auctionBidsHighestData.createdBy = data.createdBy;
    auctionBidsHighestData.createdDate = data.createdDate;
    auctionBidsHighestData.highestBidPrice = Number(data.highestBidPrice);
    auctionBidsHighestData.highestBidTime = data.highestBidTime;
    auctionBidsHighestData.highestBidUserId = data.highestBidUserId;
    auctionBidsHighestData.lotId = data.lotId;
    auctionBidsHighestData.totalBidPlaced = Number(data.totalBidPlaced);
    auctionBidsHighestData.updateTimeUtc = data.updateTimeUtc;
    auctionBidsHighestData.updateTimeUtcMillis = Number(data.updateTimeUtcMillis);

    auctionBidsHighestData.bidsUpdateTimeUtc = data.bidsUpdateTimeUtc;
    auctionBidsHighestData.bidsUpdateTimeUtcMillis = Number(data.bidsUpdateTimeUtcMillis);
    auctionBidsHighestData.maxBidsUpdateTimeUtc = data.maxBidsUpdateTimeUtc;
    auctionBidsHighestData.maxBidsUpdateTimeUtcMillis = Number(data.maxBidsUpdateTimeUtcMillis);
    return auctionBidsHighestData;
  }
}
