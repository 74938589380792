<div class="">
  <div class="row">
    <div class="col-md-6">
    </div>
  <div class="col-md-6 text-md-end">
   <div class="row ms-2 mt-1 justify-content-md-end me-2">
    <div class="col-md-4">
    </div>
     <div class="col-8 col-md-6">
      <div class="input-group" >
        <input type="text" id="ctrlSearch" placeholder="Search" [formControl]="ctrlSearch"
          class="form-control border-end-0 rounded-0 border bg-white" />
        <span class="input-group-append">
          <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button" >
            <span> <img src="assets/icons/search_light.svg" class="c-pointer svg-icon-class ms-2"></span>
          </button>
        </span>
      </div>
     </div>

   </div>
  </div>
  </div>
  <div class="m-3">
    <div class="table-responsive">
      <table class="table table-bordered medium-font mb-0">
        <thead class="table-dark">
          <tr>
            <th class="text-center "><span >#</span></th>
            <th class=" "><span>Project No.</span></th>
            <th class=" "><span>Project Name</span></th>
            <th class=" "><span>Event Name.</span></th>
            <th class=" "><span>Company Name</span></th>
            <th class=" "><span>Type</span></th>
            <th class=" "><span>Registration Details</span></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr *ngIf="isLoading" class="align-middle">
            <td colspan="7" class="text-center">
              <div class="e-spinner"></div>
            </td>
          </tr>
          <tr *ngIf="((filteredUserAuctionRegistrationWrappers$ | async)!.length == 0) && !isLoading" class="align-middle">
            <td colspan="7" class="text-center">
              <h5 class="heading-new text-dark">No Registrations Available</h5>
            </td>
          </tr>
          <tr *ngFor="let userRegistration of filteredUserAuctionRegistrationWrappers$ | async; index as i" class="align-middle">
            <td class="text-center font-weight-normal">
              {{i + 1}}
            </td>
            <td class="font-weight-normal">
              {{userRegistration?.resourceParentSequence}}
            </td>
            <td class="font-weight-normal">
              {{userRegistration?.resourceParentName}}
            </td>
            <td class="font-weight-normal">
              {{userRegistration?.resourceName}}
            </td>
            <td class="font-weight-normal">
              {{userRegistration?.companyName}}
            </td>
            <td class="font-weight-normal">
              {{userRegistration?.type}}
            </td>
            <td class="font-weight-normal">
              <button class="btn btn-link" *ngIf="currentIndex != i" (click)="openQuestionnaireModal(userRegistration, i)">
                View
              </button>
              <div class="spinner-border text-primary" role="status" *ngIf="currentIndex == i && isNavLoading">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12 mt-2" *ngIf="(filteredUserAuctionRegistrationWrappers$ | async)!.length > 0">
      <div class="row justify-content-between">
        <div class="col-md-6 mb-2">
          <div class="row">
            <div class="col-md-4 col-sm-5 col-6 pe-0">
              <span class="span-class">Show rows per page </span>
            </div>
            <div class="col-6 ps-0">
              <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
                <span ngbDropdownToggle data-bs-toggle="dropdown">
                  <button class="btn btn-sm btn-outline-secondary px-2">{{pageSize}}</button>
                </span>
                <ul ngbDropdownMenu class="text-center">
                  <li ngbDropdownItem (click)="changePageSize(50)">50</li>
                  <hr class="hr" />
                  <li ngbDropdownItem (click)="changePageSize(100)">100</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto text-md-end mb-2">
          <ngb-pagination [collectionSize]="(filteredUserAuctionRegistrationWrappers$ | async)?.length!" [(page)]="page"
            [pageSize]="pageSize" (pageChange)="paginateBidderHistoryList()">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
