import { Component } from '@angular/core';

@Component({
  selector: 'app-redirect-loader',
  templateUrl: './redirect-loader.component.html',
  styleUrls: ['./redirect-loader.component.sass']
})
export class RedirectLoaderComponent {

}
