import { AfterViewInit, Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LandingAndBidderService } from '../../services/LandingAndBidder.service';
import { CategoryDto } from '../../models/CategoryDto';
import { UserService } from '../../services/user.service';
import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';
import { UserEntityDto } from '../../models/UserEntityDto';
import { AuctionExtConstant } from '../../util/AuctionExtConstant';
import { BehaviorSubject } from 'rxjs';
import { UserEntityInfoDto } from '../../models/user/UserEntityInfoDto';
import { AuctionExtUtil } from '../../util/AuctionExtUtil';
import { GlobalBidderEntityDto } from '../../models/GlobalBidderEntityDto';
import { PreferenceCategoryDto } from '../../models/user/PreferenceCategoryDto';
import { TreeSelectionMode } from '../../enums/TreeSelectionMode';

@Component({
  selector: 'app-user-category-selection',
  templateUrl: './user-category-selection.component.html',
  styleUrls: ['./user-category-selection.component.sass']
})
export class UserCategorySelectionComponent implements OnInit, AfterViewInit {
  @Input() userId?: string;
  @Input() userDto!: UserEntityDto | UserEntityInfoDto | GlobalBidderEntityDto;
  @Input() isGlobalBidder: boolean = false;
  @Output() onSelectedCategories = new EventEmitter<PreferenceCategoryDto[]>;

  finalSelectedCategories: PreferenceCategoryDto[] = []
  TreeSelectionMode: typeof TreeSelectionMode = TreeSelectionMode;

  errorMsg: string | undefined;
  isLoading: boolean = false;
  loadingData: boolean = false;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  constructor(
    private activeModal: NgbActiveModal,
    private landingAndBidderService: LandingAndBidderService,
    private userService: UserService
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
  }

  onFilterChange(event: any) {
    console.log(event);
    // let tempMasterList = AuctionExtUtil.clone(this.masterCategories) as CategoryDto[];
    // let filterCategoryList = tempMasterList.filter(item => item.category!.trim().toLowerCase().includes(event.trim().toLowerCase()) ||
    // item.subcategory!.trim().toLowerCase().includes(event.trim().toLowerCase()) ||
    // item.subcategoryLevelOne!.trim().toLowerCase().includes(event.trim().toLowerCase())).map(item => item);

    // if (event.trim() != '') {
    //   this.treeItemsCategories = this.buildFilterCategoriesTree(filterCategoryList);
    // } else {
    //   this.treeItemsCategories = this.buildCategoriesTree()
    // }


    // this.ngxTreeview?.onFilterTextChange(event);
  }

  onCategorySelectedChange(preferenceCategories: PreferenceCategoryDto[]) {
    this.finalSelectedCategories = preferenceCategories;
  }

  closeModal() {
    this.activeModal.close();
  }

  emitSelectedCategories() {
    this.onSelectedCategories.emit(this.finalSelectedCategories);
    this.closeModal();
  }

  saveCategories() {
    if (!this.finalSelectedCategories.length) {
      this.errorMsg = "Please select at least one category!";
      this._showErrorToast$.next(true);
      return;
    }

    this.errorMsg = "";
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);

    this.isLoading = true;

    this.userService.subcategoryPreference(this.finalSelectedCategories).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let data = apiResponseDto.data as UserEntityDto;

          this.userService.setUserDto(data);


          this.isLoading = false;
          this._showSuccessToast$.next(true);

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal()
          }, 2000)
        } else {
          this.isLoading = false;


          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
        }
      },
      error: (err) => {
        this.isLoading = false;
        this.errorMsg = "Error while saving preference categories!";
        this._showErrorToast$.next(true);
      }
    })
  }
}
