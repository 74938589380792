export class Currency {
  name?: string;
  symbol?: string;
  locale?: string;
  code?: string;
  regex?: string;

  static default(): Currency {
    let currency = new Currency();
    currency.name = 'US Dollar';
    currency.symbol = '$';
    currency.locale = 'en-US';
    currency.code = 'USD';
    currency.regex = '^(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d+)?$';
    return currency;
  }
}