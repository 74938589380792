import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OpenAiService } from '../../services/open-ai.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Clipboard } from '@angular/cdk/clipboard';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-ai-description-generator',
  templateUrl: './ai-description-generator.component.html',
  styleUrls: ['./ai-description-generator.component.sass']
})
export class AiDescriptionGeneratorComponent {
  ctrlAuctionTitle: FormControl = new FormControl('', Validators.required);
  ctrlLotTitle: FormControl = new FormControl('', Validators.required);
  ctrlLotDescription: FormControl = new FormControl('', Validators.required);
  ctrlHashtags: FormControl = new FormControl('', Validators.required);
  ctrlHighlights: FormControl = new FormControl('', Validators.required);
  ctrlFeatures: FormControl = new FormControl('', Validators.required);

  fgAuctionHouse: FormGroup
  fgPrivacyPolicy: FormGroup
  fgTermCondition: FormGroup

  aiDescription?: string;
  isLoading: boolean = false;
  maxWordsType: string = 'SMALL';
  selectedAi?: string

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);

  aiGeneratorTypes = [
    { id: 'AUCTION_HOUSE_DESCRIPTION', title: 'Auction House Description', length: 150 },
    { id: 'AUCTION_TITLE', title: 'Auction Title', length: 20 },
    { id: 'PRIVACY_POLICY', title: 'Privacy Policy', length: 500 },
    { id: 'TERM_CONDITION', title: 'Terms and Conditions', length: 500 },
    { id: 'LOT_TITLE', title: 'Lot Title', length: 20 },
    { id: 'LOT_DESCRIPTION', title: 'Lot Description', length: 200 },
    { id: 'HASHTAGS', title: 'Hashtags', length: 20 },
    { id: 'HIGHLIGHTS', title: 'Highlights', length: 150 },
    { id: 'FEATURES', title: 'Features', length: 20 }
  ]

  constructor(
    private activeModal: NgbActiveModal,
    private openAiService: OpenAiService,
    private clipboard: Clipboard,
    private fb: FormBuilder
  ) {
    this.fgAuctionHouse = this.fb.group({
      companyName: ['', Validators.required],
      email: ['', Validators.required],
      mobileNo: ['', Validators.required],
      website: ['', Validators.required],
    })

    this.fgPrivacyPolicy = this.fb.group({
      headings: ['', Validators.required],
      companyName: ['', Validators.required],
      email: ['', Validators.required],
      mobileNo: ['', Validators.required],
    })

    this.fgTermCondition = this.fb.group({
      headings: ['', Validators.required],
      companyName: ['', Validators.required],
      email: ['', Validators.required],
      mobileNo: ['', Validators.required],
    })

    this.changeAiGenerator(this.aiGeneratorTypes[0].id)
  }

  get fcAuctionHouse() { return this.fgAuctionHouse.controls; }
  get fcPrivacyPolicy() { return this.fgPrivacyPolicy.controls; }
  get fcTermCondition() { return this.fgTermCondition.controls; }

  async generateDescription() {
    if (this.runValidator()) {
      return;
    }

    let prefixText = this.getPrefixTextOfAiGenerator();

    this.aiDescription = undefined;

    this.isLoading = true;
    this.aiDescription = await this.openAiService.getResponse(prefixText, this.getMaxWords());
    this.isLoading = false;
  }

  isGenerateButtonDisabled(){

    if (this.isLoading || this.selectedAi) {
      if (this.selectedAi == 'AUCTION_HOUSE_DESCRIPTION') {
        return this.fgAuctionHouse.invalid;
      } else if (this.selectedAi == 'AUCTION_TITLE') {
        return this.ctrlAuctionTitle.invalid;
      } else if (this.selectedAi == 'PRIVACY_POLICY') {
        return this.fgPrivacyPolicy.invalid;
      } else if (this.selectedAi == 'TERM_CONDITION') {
        return this.fgTermCondition.invalid;
      } else if (this.selectedAi == 'LOT_TITLE') {
        return this.ctrlLotTitle.invalid;
      } else if (this.selectedAi == 'LOT_DESCRIPTION') {
        return this.ctrlLotDescription.invalid;
      } else if (this.selectedAi == 'HASHTAGS') {
        return this.ctrlHashtags.invalid;
      } else if (this.selectedAi == 'HIGHLIGHTS') {
        return this.ctrlHighlights.invalid;
      } else if (this.selectedAi == 'FEATURES') {
        return this.ctrlFeatures.invalid;
      }

    }
    return false;
  }

  copyDescription() {
    this.clipboard.copy(this.aiDescription!);
    this._showSuccessToast$.next(true);
    setTimeout(() => {
      this._showSuccessToast$.next(false);
    }, 1500);
  }

  changeMaxWordsType(type: string) {
    this.maxWordsType = type;
  }

  changeAiGenerator(selectedAi: string) {
    this.selectedAi = selectedAi;

    // Reset Form Group
    this.fgAuctionHouse.reset()
    this.fgPrivacyPolicy.reset()
    this.fgTermCondition.reset()
    this.ctrlAuctionTitle.reset()
    this.ctrlFeatures.reset()
    this.ctrlHashtags.reset()
    this.ctrlHighlights.reset()
    this.ctrlLotDescription.reset()
    this.ctrlLotTitle.reset()
  }

  getMaxWords() {
    return this.maxWordsType == 'SMALL' ? 100
      : this.maxWordsType == 'FAIRLY_LARGE' ? 250
        : this.maxWordsType == 'LARGE' ? 500
          : 100;
  }

  getMaxWordsTitle() {
    return this.maxWordsType == 'FAIRLY_LARGE' ? 'Brief (Upto 250 words)'
      : this.maxWordsType == 'LARGE' ? 'Detailed  (Upto 500 words)'
        : 'Short (Upto 100 words)';
  }

  getPrefixTextOfAiGenerator(): string {
    let toReturn = ''

    if (this.selectedAi == 'AUCTION_HOUSE_DESCRIPTION') {
      let formValue = this.fgAuctionHouse.value;
      toReturn = `Write a short description for ${formValue.companyName}. Add email ${formValue.email}, phone number ${formValue.mobileNo}, Website ${formValue.website}`
    } else if (this.selectedAi == 'AUCTION_TITLE') {
      toReturn = `Write a short Auction title for ${this.ctrlAuctionTitle.value}`
    } else if (this.selectedAi == 'PRIVACY_POLICY') {
      let formValue = this.fgPrivacyPolicy.value;
      toReturn = `Write Privacy Policy for ${formValue.companyName} for the Auction Software usage. Include points like ${formValue.headings} and others. Add contact details email ${formValue.email} mobile number ${formValue.mobileNo}.`
    } else if (this.selectedAi == 'TERM_CONDITION') {
      let formValue = this.fgTermCondition.value;
      toReturn = `Write Terms and Conditions for ${formValue.companyName} for the Auction Software usage. Include points like ${formValue.headings} and others. Add contact details email ${formValue.email} mobile number ${formValue.mobileNo}.`
    } else if (this.selectedAi == 'LOT_TITLE') {
      toReturn = `Write a short Lot title for ${this.ctrlLotTitle.value}`
    } else if (this.selectedAi == 'LOT_DESCRIPTION') {
      toReturn = `Write short Auction description for ${this.ctrlLotDescription.value}`
    } else if (this.selectedAi == 'HASHTAGS') {
      toReturn = `Give hashtags for ${this.ctrlHashtags.value}`
    } else if (this.selectedAi == 'HIGHLIGHTS') {
      toReturn = `Write bulleted highlight features for ${this.ctrlHighlights.value}`
    } else if (this.selectedAi == 'FEATURES') {
      toReturn = `Write one word features and their attributes for the sale of ${this.ctrlFeatures.value}`
    }

    return toReturn;
  }

  runValidator(): boolean {
    let isFormInvalid = true

    if (this.selectedAi == 'AUCTION_HOUSE_DESCRIPTION') {
      isFormInvalid = this.fgAuctionHouse.invalid;
      this.fgAuctionHouse.markAllAsTouched();
    } else if (this.selectedAi == 'AUCTION_TITLE') {
      isFormInvalid = this.ctrlAuctionTitle.invalid;
      this.ctrlAuctionTitle.markAsTouched();
    } else if (this.selectedAi == 'PRIVACY_POLICY') {
      isFormInvalid = this.fgPrivacyPolicy.invalid;
      this.fgPrivacyPolicy.markAllAsTouched();
    } else if (this.selectedAi == 'TERM_CONDITION') {
      isFormInvalid = this.fgTermCondition.invalid;
      this.fgTermCondition.markAllAsTouched();
    } else if (this.selectedAi == 'LOT_TITLE') {
      isFormInvalid = this.ctrlLotTitle.invalid;
      this.ctrlLotTitle.markAsTouched();
    } else if (this.selectedAi == 'LOT_DESCRIPTION') {
      isFormInvalid = this.ctrlLotDescription.invalid;
      this.ctrlLotDescription.markAsTouched();
    } else if (this.selectedAi == 'HASHTAGS') {
      isFormInvalid = this.ctrlHashtags.invalid;
      this.ctrlHashtags.markAsTouched();
    } else if (this.selectedAi == 'HIGHLIGHTS') {
      isFormInvalid = this.ctrlHighlights.invalid;
      this.ctrlHighlights.markAsTouched();
    } else if (this.selectedAi == 'FEATURES') {
      isFormInvalid = this.ctrlFeatures.invalid;
      this.ctrlFeatures.markAsTouched();
    }

    return isFormInvalid;
  }

  closeModal() {
    this.activeModal.close()
  }
}
