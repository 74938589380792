export enum AuctionStatus {
  CREATE_NEW = "CREATE_NEW",
  CREATE_IN_PROGRESS = "CREATE_IN_PROGRESS",
  CREATE_SUBMITTED = "CREATE_SUBMITTED",
  DRAFT = "DRAFT",
  LIVE_WAIT = "LIVE_WAIT",
  LIVE = "LIVE",
  CLOSE = "CLOSE",
  DISCARD = "DISCARD",
  SOLD = "SOLD"
}
