<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>CHANGE PASSWORD</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
   <div class="model-body-top">
    <div class="m-3">
      <form class="needs-validation" novalidate>
        <div class="row">
          <div class="col-12">
            <div class="form-floating mb-2">
              <input type="text" class="form-control" id="crntPassword" placeholder="Current Password">
              <label for="crntPassword">Current Password</label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating mb-2">
              <input type="text" class="form-control" id="newPassword" placeholder="New Password">
              <label for="newPassword">New Password</label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating mb-2">
              <input type="text" class="form-control" id="rePassword" placeholder="Retype New Password">
              <label for="rePassword">Retype New Password</label>
            </div>
          </div>
        </div>
        <div class="d-grid gap-2 my-3">
          <button type="button" class="btn btn-new-class">UPDATE PASSWORD</button>
        </div>
      </form>
    </div>
   </div>
</div>
