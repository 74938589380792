<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>Auction Validations Errors</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
   <div class="model-body-top">
    <div class="py-2 px-3" *ngIf="auctionValidationErrorCodeDtoList">
      <table class="table mb-3" *ngIf="auctionErrorsList.length > 0">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Error</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of auctionErrorsList; index as i">
            <td>{{i+1}}</td>
            <td>{{getErrorMsg(item)}}</td>
          </tr>
        </tbody>
      </table>
  
      <table class="table" *ngIf="lotsErrorsList.length > 0">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Lot No.</th>
            <th scope="col">Lot Name</th>
            <th scope="col">Error</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of lotsErrorsList; index as i">
            <td>{{i+1}}</td>
            <td>{{item?.lotSequence}}</td>
            <td>{{item?.lotName}}</td>
            <td>{{getErrorMsg(item)}}</td>
          </tr>
        </tbody>
      </table>
  </div>
   </div>
</div>