import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { NgbModalRef, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { end } from '@popperjs/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { QuestionResponseType } from 'src/app/shared/enums/questionnaire/QuestionResponseType';
import { SourcingEnvelopeType } from 'src/app/shared/enums/questionnaire/SourcingEnvelopeType';
import { UserQuestionStatus } from 'src/app/shared/enums/questionnaire/UserQuestionStatus';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { FileSaveResponseDto } from 'src/app/shared/models/FileSaveResponseDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { UserEntityDto } from 'src/app/shared/models/UserEntityDto';
import { QuestionOptions } from 'src/app/shared/models/questionnaire/QuestionOptions';
import { QuestionnaireTemplate } from 'src/app/shared/models/questionnaire/QuestionnaireTemplate';
import { QuestionnaireWrapperDto } from 'src/app/shared/models/questionnaire/QuestionnaireWrapperDto';
import { TechnicalQuestionTemplate } from 'src/app/shared/models/questionnaire/TechnicalQuestionTemplate';
import { UserRfxQuestionsUiDto } from 'src/app/shared/models/questionnaire/UserRfxQuestionsDto';
import { UserRfxSubcategoryUiDto } from 'src/app/shared/models/questionnaire/UserRfxSubcategoryDto';
import { RfxSubcategoryEntityDto } from 'src/app/shared/models/rfx/RfxSubcategoryEntityDto ';
import { UserRfxSubcategoryStatus } from 'src/app/shared/models/rfx/UserRfxSubcategoryStatus';
import { DocumentArchiveEntityDto } from 'src/app/shared/models/user/DocumentArchiveEntityDto';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { FileService } from 'src/app/shared/services/file.service';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';

@Component({
  selector: 'app-rfx-questionnaire-technical-question',
  templateUrl: './rfx-questionnaire-technical-question.component.html',
  styleUrls: ['./rfx-questionnaire-technical-question.component.sass']
})
export class RfxQuestionnaireTechnicalQuestionComponent implements OnInit, OnDestroy{

  @Input() selectedTechnicalQuestion!: TechnicalQuestionTemplate;
  @Output() openNewSectionEvent = new EventEmitter<boolean>();

  questionResponseType: typeof QuestionResponseType = QuestionResponseType;

  subcategoryEntityDto?: RfxSubcategoryEntityDto;
  userEntityDto?: UserEntityDto
  questionnaireWrapperDto?: QuestionnaireWrapperDto;
  questionnaireTemplate?: QuestionnaireTemplate;
  technicalQuestionsList: TechnicalQuestionTemplate[] = []
  documentArchiveList: DocumentArchiveEntityDto[] = [];
  userRfxQuestions: UserRfxQuestionsUiDto[] = [];
  userRfxSubcategoryDto?: UserRfxSubcategoryUiDto;

  selectedDocuments: FileInfoDto[] = [];
  bidderResponse: any;

  // ctrlQuestion: FormControl = new FormControl('');
  ctrlBidderComments: FormControl = new FormControl('');
  ctrlDateStart: FormControl = new FormControl('', Validators.required);
  ctrlDateEnd: FormControl = new FormControl('', Validators.required);
  ctrlAlphaNumeric: FormControl = new FormControl('', Validators.required);



  isLoading: boolean = false;
  isUploadLoading: boolean = false;
  documentModalRef?: NgbModalRef;
  isQuestionSubmitted: boolean = false;

  countIncompleteQuestions: number = 0;
  countCompleteQuestions: number = 0;

  documentTypes = ['application/pdf'];
  errorMsg: string | undefined;
  currentFile?: File | null;
  fileUploadError: boolean = false;
  _fileDisplayName$ = new BehaviorSubject<string | null>("");
  _fileSize$ = new BehaviorSubject<string | null>("");

  _showErrorToast$ = new BehaviorSubject<Boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<Boolean>(false);

  questionnaireWrapperDtoSubscription$?: Subscription;
  selectedRfxSubscription$?: Subscription;
  userRfxQuestionsSubscription$?: Subscription;
  userRfxSubcategorySubscription$?: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private ngbModal: NgbModal,
    private questionnaireService: QuestionnaireService,
    private landingAndBidderService: LandingAndBidderService,
    private userService: UserService,
    private fileService: FileService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.userEntityDto = this.userService.getUserEntity();

    this.questionnaireWrapperDtoSubscription$ = this.questionnaireService.getQuestionnaireWrapper$.subscribe(data => {
      if (data) {
        this.questionnaireWrapperDto = data;
        this.questionnaireTemplate = data.questionnaireTemplate;

        this.technicalQuestionsList = this.questionnaireWrapperDto?.technicalQuestionTemplates?.filter(item => item.sectionId == this.selectedTechnicalQuestion.sectionId) ?? [];
        this.technicalQuestionsList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));
      }
    })

    this.selectedRfxSubscription$ = this.landingAndBidderService.getSelectedRfxSubcategory$.subscribe(data => {
      if (data) {
        this.subcategoryEntityDto = data;
      }
    })

    this.userRfxQuestionsSubscription$ = this.questionnaireService.getUserRfxQuestions$.subscribe(data => {
      if (data) {
        this.userRfxQuestions = data
        this.populateUserRfxQuestions();
      } else {
        this.userRfxQuestions = []
        this.populateUserRfxQuestions();
      }
    })

    this.userRfxSubcategorySubscription$ = this.landingAndBidderService.getUserRfxSubcategoryDto$.subscribe(data => {
      if (data) {
        this.userRfxSubcategoryDto = data;

        if (this.userRfxSubcategoryDto.status == UserRfxSubcategoryStatus.SUBMITTED) {
          this.isQuestionSubmitted = true;
        } else {
          this.isQuestionSubmitted = false;
        }
      } else {
        this.isQuestionSubmitted = false;
        this.userRfxSubcategoryDto = undefined;
      }
    })

    this.ctrlDateStart.valueChanges.subscribe((val) => {
      this.checkDates();
    })

    this.ctrlDateEnd.valueChanges.subscribe((val) => {
      this.checkDates();
    })

    this.loadDocumentArchive();
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.activeModal.close();
    }
  }

  openNewSection(){
    this.openNewSectionEvent.emit(true);
  }

  populateUserRfxQuestions() {
    // this.ctrlQuestion.patchValue(``);
    // this.ctrlQuestion.disable();
    // this.ctrlQuestion.updateValueAndValidity();

    let currentQuestion = this.userRfxQuestions.find(item => item.questionId == this.selectedTechnicalQuestion.questionId);
    if (currentQuestion) {
      if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.SINGLE_CHOICE) {
        this.bidderResponse = currentQuestion.bidderResponse;
      } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.MULTI_CHOICE) {
        this.bidderResponse = currentQuestion.bidderResponse;
      } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.DATE) {
        this.ctrlDateStart.patchValue(AuctionExtUtil.getNgbPickerDate(currentQuestion.bidderResponse));
      } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.DATE_RANGE) {
        this.ctrlDateStart.patchValue(AuctionExtUtil.getNgbPickerDate(currentQuestion.bidderResponse[0]));
        this.ctrlDateEnd.patchValue(AuctionExtUtil.getNgbPickerDate(currentQuestion.bidderResponse[1]));
      } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.ALPHANUMERIC) {
        this.ctrlAlphaNumeric.patchValue(currentQuestion.bidderResponse);
      }

      this.selectedDocuments = currentQuestion.bidderDocuments ?? [];
      this.ctrlBidderComments.patchValue(currentQuestion.bidderComments);

      this.ctrlDateStart.disable();
      this.ctrlDateEnd.disable();
      this.ctrlAlphaNumeric.disable();
      this.ctrlBidderComments.disable();
    } else {
      this.selectedDocuments = [];
    }

    this.countCompleteQuestions = this.userRfxQuestions.filter(item => item.status == UserQuestionStatus.COMPLETED && item.sectionId == this.selectedTechnicalQuestion.sectionId).length;
    this.countIncompleteQuestions = this.technicalQuestionsList.length - this.countCompleteQuestions;
  }

  resetCurrentQuestion() {
    this.bidderResponse = undefined;
    this.ctrlDateStart.reset();
    this.ctrlDateEnd.reset();
    this.ctrlAlphaNumeric.reset();
    this.ctrlBidderComments.reset();
    this.ctrlBidderComments.enable();
    this.ctrlDateStart.enable();
    this.ctrlDateEnd.enable();
    this.ctrlAlphaNumeric.enable();
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);
    this.populateUserRfxQuestions();
  }

  nextQuestion() {
    let currentSequenceNo = this.selectedTechnicalQuestion.sequenceNo;
    if (Number(currentSequenceNo) < this.technicalQuestionsList.length) {
      let index = this.technicalQuestionsList.findIndex(item => item.sequenceNo == currentSequenceNo);
      this.selectedTechnicalQuestion = this.technicalQuestionsList[index + 1];
      this.resetCurrentQuestion();
    }
  }

  prevQuestion() {
    let currentSequenceNo = this.selectedTechnicalQuestion.sequenceNo;
    if (Number(currentSequenceNo) > 1) {
      let index = this.technicalQuestionsList.findIndex(item => item.sequenceNo == currentSequenceNo);
      this.selectedTechnicalQuestion = this.technicalQuestionsList[index - 1];
      this.resetCurrentQuestion();
    }
  }

  isDisabledPrev() {
    return Number(this.selectedTechnicalQuestion.sequenceNo) == 1;
  }

  isDisabledNext() {
    return Number(this.selectedTechnicalQuestion.sequenceNo) == this.technicalQuestionsList.length;
  }

  clearOptionSelection() {
    this.bidderResponse = undefined;
  }

  formatBytes(size: any) {
    return AuctionExtUtil.formatBytes(size);
  }

  loadDocumentArchive() {
    this.userService.getDocumentArchives().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.documentArchiveList = apiResponseDto.data as DocumentArchiveEntityDto[];
      },
      error: (error) => {
        // this.listLoading$.next(false)
      }
    })
  }

  openDocumentModal(content: any) {
    if (this.isQuestionSubmitted) {
      return;
    }

    this.documentModalRef = this.ngbModal.open(content, {
      size: 'md', centered: true, backdrop: 'static', keyboard: false
    })
  }

  selectDocumentArchive(documentArchive: DocumentArchiveEntityDto) {
    let isDocumentSelected = this.selectedDocuments.find(item => item.fileId == documentArchive.fileId);
    if (!isDocumentSelected) {
      let fileInfoDto = new FileInfoDto();
      fileInfoDto.fileId = documentArchive.fileId;
      fileInfoDto.displayName = documentArchive.documentDisplayName;
      fileInfoDto.fileName = documentArchive.documentName;
      fileInfoDto.fileSize = documentArchive.size;
      fileInfoDto.fileType = 'application/pdf';

      this.selectedDocuments.push(fileInfoDto);
    } else {
      let index = this.selectedDocuments.findIndex(item => item.fileId == documentArchive.fileId);
      if (index > -1) {
        this.selectedDocuments.splice(index, 1);
      }
    }
  }

  removeSelectedDocument(fileId: string) {
    let index = this.selectedDocuments.findIndex(item => item.fileId == fileId);
    this.selectedDocuments.splice(index, 1);
  }

  chooseFile(event: any) {
    if (this.isQuestionSubmitted) {
      return;
    }

    this._showErrorToast$.next(false);
    this.currentFile = event.target.files[0];

    if (!this.documentTypes.includes(this.currentFile!.type)) {
      this.fileUploadError = true;
      this.errorMsg = "Extension not supported";
      this._showErrorToast$.next(true);
      this.currentFile = null;
      this._fileDisplayName$.next(null);
      return;
    }

    this._fileDisplayName$.next(this.getFileName(this.currentFile?.name!) as string);
    this._fileSize$.next(this.currentFile?.size + "");

    this.uploadFile();
  }

  getFileName(fileName: string) {
    let extensionName = fileName?.slice(fileName.lastIndexOf('.'), fileName.length)
    let name = fileName?.slice(0, fileName.lastIndexOf('.'));
    let finalFileName = AuctionExtUtil.removeSpecialCharacters(name!) as string
    return finalFileName + "" + extensionName;
  }

  uploadFile() {
    this.fileUploadError = false;
    this.isUploadLoading = true;
    this.errorMsg = "";

    let metaData = {
      'mimeType': this.currentFile?.type,
      'version': 0,
      'publicApi': false,
      'dataType': 'Rectangle'
    };

    let formData = new FormData();
    formData.append("file", this.currentFile!);
    formData.append('metaData', JSON.stringify(metaData));

    this.fileService.uploadFile(formData).subscribe(apiResponseDto => {
      if (apiResponseDto) {
        let fileSaveResponseDto = apiResponseDto.data as FileSaveResponseDto;
        if (apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.fileUploadError = false;
          this.isUploadLoading = false;

          let currentFileInfoDto = new FileInfoDto();
          currentFileInfoDto.fileId = fileSaveResponseDto.fileId;
          currentFileInfoDto.fileName = fileSaveResponseDto.fileName;
          currentFileInfoDto.displayName = fileSaveResponseDto.fileName;
          currentFileInfoDto.dataType = fileSaveResponseDto.dataType;
          currentFileInfoDto.fileSize = this.currentFile?.size.toString();
          currentFileInfoDto.fileType = this.currentFile?.type;
          currentFileInfoDto.uploadDate = new Date().toDateString();

          this.selectedDocuments.push(currentFileInfoDto);
        }
      } else {
        this.fileUploadError = true;
        this.isUploadLoading = false;
      }
    })
  }

  selectBidderResponse(questionOption: QuestionOptions) {
    if (this.isQuestionSubmitted) {
      return;
    }

    if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.SINGLE_CHOICE) {
      this.bidderResponse = questionOption;
    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.MULTI_CHOICE) {
      if (!this.bidderResponse) {
        this.bidderResponse = [];
      }

      let index = this.bidderResponse.findIndex((item: QuestionOptions) => item.optionSequence == questionOption.optionSequence);
      if (index != -1) {
        this.bidderResponse.splice(index, 1);
      } else {
        this.bidderResponse.push(questionOption);
      }
    }
  }

  isOptionSelected(questionOption?: QuestionOptions) {
    if (questionOption && this.bidderResponse) {
      if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.SINGLE_CHOICE) {
        return this.bidderResponse.optionSequence == questionOption.optionSequence;
      } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.MULTI_CHOICE) {
        let option = this.bidderResponse.find((item: QuestionOptions) => item.optionSequence == questionOption.optionSequence)
        return option != undefined;
      }
    }
    return false;
  }

  mergeUserRfxQuestionsDto() {
    let userRfxQuestionsDto = new UserRfxQuestionsUiDto();
    userRfxQuestionsDto.questionId = this.selectedTechnicalQuestion.questionId;
    userRfxQuestionsDto.sectionId = this.selectedTechnicalQuestion.sectionId;
    userRfxQuestionsDto.questionnaireId = this.selectedTechnicalQuestion.questionnaireId;
    userRfxQuestionsDto.orgCode = this.subcategoryEntityDto?.orgCode;
    userRfxQuestionsDto.rfxId = this.subcategoryEntityDto?.rfxId;
    userRfxQuestionsDto.rfxSubcategoryId = this.subcategoryEntityDto?.subcategoryId;
    userRfxQuestionsDto.envelopeType = SourcingEnvelopeType.TECHNICAL;
    userRfxQuestionsDto.name = this.userEntityDto?.name;
    userRfxQuestionsDto.firstName = this.userEntityDto?.firstName;
    userRfxQuestionsDto.lastName = this.userEntityDto?.lastName;
    userRfxQuestionsDto.companyId = this.userEntityDto?.companyId;
    userRfxQuestionsDto.emailId = this.userEntityDto?.primaryEmailId;
    userRfxQuestionsDto.responseType = this.selectedTechnicalQuestion.responseType;
    userRfxQuestionsDto.bidderDocuments = this.selectedDocuments;
    userRfxQuestionsDto.bidderComments = this.ctrlBidderComments.value;
    userRfxQuestionsDto.companyName = this.userEntityDto?.companyName;
    userRfxQuestionsDto.status = UserQuestionStatus.COMPLETED;

    if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.SINGLE_CHOICE || this.selectedTechnicalQuestion.responseType == QuestionResponseType.MULTI_CHOICE) {
      userRfxQuestionsDto.bidderResponse = this.bidderResponse;

    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.DATE) {
      userRfxQuestionsDto.bidderResponse = AuctionExtUtil.getDateFromNgDatePicker(this.datePipe, this.ctrlDateStart.value);

    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.DATE_RANGE) {
      let startDate = AuctionExtUtil.getDateFromNgDatePicker(this.datePipe, this.ctrlDateStart.value);
      let endDate = AuctionExtUtil.getDateFromNgDatePicker(this.datePipe, this.ctrlDateEnd.value);

      userRfxQuestionsDto.bidderResponse = [startDate, endDate];

    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.ALPHANUMERIC) {
      userRfxQuestionsDto.bidderResponse = this.ctrlAlphaNumeric.value.trim();
    }

    return userRfxQuestionsDto;
  }

  checkDates() {
    if (this.ctrlDateStart.value && this.ctrlDateEnd.value) {
      let startDateInStr = AuctionExtUtil.getDateFromNgDatePicker(this.datePipe, this.ctrlDateStart.value);
      let endDateInStr = AuctionExtUtil.getDateFromNgDatePicker(this.datePipe, this.ctrlDateEnd.value);
      let startDate = AuctionExtUtil.convertedDate(startDateInStr!, "23:59") as Date;
      let endDate = AuctionExtUtil.convertedDate(endDateInStr!, "23:59") as Date;
      let isNotValid = startDate.getTime() >= endDate.getTime();

      if (isNotValid) {
        this.errorMsg = "Start date is not greater than end date";
        this._showErrorToast$.next(true);
      } else {
        this.errorMsg = "";
        this._showErrorToast$.next(false);
      }

      return !isNotValid;
    }
    return false;
  }

  isValidBidderResponse() {
    if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.SINGLE_CHOICE) {
      return this.bidderResponse != undefined && this.bidderResponse != '';
    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.MULTI_CHOICE) {
      return this.bidderResponse && this.bidderResponse.length > 0;
    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.DATE) {
      return this.ctrlDateStart.valid;
    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.DATE_RANGE) {
      return this.ctrlDateStart.valid && this.ctrlDateEnd.valid && this.checkDates();
    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.ALPHANUMERIC) {
      return this.ctrlAlphaNumeric.valid && (this.ctrlAlphaNumeric.value as string).trim();
    }
  }

  saveQuestion() {
    let isUploadRequired = this.selectedTechnicalQuestion.questionOptions?.find((item : QuestionOptions) => item.uploadRequired);

    this._showErrorToast$.next(false);
    this.errorMsg = "";

    if (!this.isValidBidderResponse() && (isUploadRequired && this.selectedDocuments.length == 0)) {
      return;
    }

    let userRfxQuestionsDto = this.mergeUserRfxQuestionsDto()
    this.isLoading = true;

    this.questionnaireService.userRfxQuestions(userRfxQuestionsDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let data = apiResponseDto.data as UserRfxQuestionsUiDto[];
          this.questionnaireService.updateUserRfxQuestions(data);

          this._showSuccessToast$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            // Navigate to next question
            if (Number(this.selectedTechnicalQuestion.sequenceNo) == this.technicalQuestionsList.length) {
              this.openNewSection();
              this.closeModal();
            } else {
              this.nextQuestion();
            }
          }, 2000)
        } else {
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.errorMsg = "Error while saving rfx registration. Try again.";
        this._showErrorToast$.next(true);
        this.isLoading = false;

      }
    })
  }

  isDocumentSelected(fileId: string) {
    let document = this.selectedDocuments.find(item => item.fileId == fileId);
    return document != undefined;
  }

  getExpiryDate(fileId: string) {
    let documentArchive = this.documentArchiveList.find(item => item.fileId == fileId);
    if (documentArchive) {
      return documentArchive.expiryDate;
    }
    return '';
  }

  ngOnDestroy(): void {
    if (this.questionnaireWrapperDtoSubscription$) {
      this.questionnaireWrapperDtoSubscription$.unsubscribe();
    }
    if (this.selectedRfxSubscription$) {
      this.selectedRfxSubscription$.unsubscribe()
    }
    if (this.userRfxQuestionsSubscription$) {
      this.userRfxQuestionsSubscription$.unsubscribe()
    }
    if (this.userRfxSubcategorySubscription$) {
      this.userRfxSubcategorySubscription$.unsubscribe()
    }
  }


}
