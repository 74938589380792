<div class="my-3">
	<header class="m-2">
		<div class="row justify-content-between">
			<div class="col-9 d-flex align-items-center">
				<h2>{{title}}</h2>
			</div>
			<div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
		</div>
	</header>
	<div class="page-divider"></div>
	<div class="scroll-container">
    <div #htmlView></div>
	<div class="m-3">
		<div class="col-12 text-start">
			<p *ngIf="title != 'Highlights'" #viewContent style="white-space: normal;"></p>
			<ul *ngIf="title == 'Highlights'">
				<li *ngFor="let item of getContent(content!)">
					{{item}}
				</li>
			</ul>
		</div>
	</div>
	<button class="btn btn-sm btn-outline-primary p-3 floating-button" (click)="moveToTop(htmlView)">
    <img src="assets/icons/arrowupward.svg" class="svg-icon-class c-pointer">
  </button>
</div>
</div>