import { CountryCodeDto } from "./CountryCodeDto";

export class AddressDto {
  id?: string;
  addressType?: string;
  searchAddress?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  landmark?: string;
  latitude?: string;
  longitude?: string;
  countryShortName?: string;

  countryCode?: CountryCodeDto;
  mobileNo?: string;
}
