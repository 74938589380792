import { Component } from '@angular/core';

@Component({
  selector: 'app-user-verify-otp',
  templateUrl: './user-verify-otp.component.html',
  styleUrls: ['./user-verify-otp.component.sass']
})
export class UserVerifyOtpComponent {

}
