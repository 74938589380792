import { BehaviorSubject } from 'rxjs';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { FirestoreListenerService } from 'src/app/shared/services/firestore-listener.service';


import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';




@Injectable({
  providedIn: 'root'
})
export class NavigationBackService {

  currentUrl?: string ;
  backUrl?: string;

  stack?: any[]=[];

  // breadcrumbs: any = {
  //   "/Admin/Dashboard": "HOME",
  //   "/creator": "Auction Creator Dashboard",
  //   "/Bidder/lotDetails": "Auction Lot Details",
  //   "/Bidder/multiLotsList": "Auction Multi Lots"
  // }


  constructor(
    private router: Router,
  ) {

  //   router.events.subscribe((val) => {
  //     // see also
  //     if(val instanceof NavigationEnd){
  //       if(this.currentUrl){
  //         let data  = val as NavigationEnd
  //         if(data.url  == "/"){
  //           this.clearStack();
  //         }
  //         this.backUrl = this.currentUrl;

  //         this.currentUrl = data.url;
  //         if(!this.stack?.includes(this.currentUrl))
  //         {
  //           this.stack?.push(this.currentUrl);
  //         }
  //       }else{
  //         let data  = val as NavigationEnd
  //         this.currentUrl = data.url;
  //         if(!this.stack?.includes(this.currentUrl))
  //         {
  //           this.stack?.push(this.currentUrl);
  //         }
  //       }
  //       console.log("Current Url" + this.currentUrl + " : " +"Back Url" +  this.backUrl);
  //     }
  // });
  }

  /**
   *
   */
  // doBackNavigate(){
  //   this.stack?.pop();
  //   let lastUrl = this.stack?.[this.stack?.length -1];
  //   this.router.navigate([lastUrl], { skipLocationChange: true });
  // }

  // isShowBackButton(){
  //   return !(this.backUrl == '/');
  // }

  setBreadCums(object: any){
    this.stack?.push(object);
  }

  removeBreadCums(object: string){
    this.stack?.push(object);
  }

  // directNavigation(url: string){
  //   let index = this.stack?.findIndex(item => url == item );
  //   this.stack?.splice(index!+1!, this.stack?.length - index!+1);
  //   this.router.navigate([url], { skipLocationChange: true });
  // }


  // getComponentNameBasedOnUrl(url: string){
  //   return this.breadcrumbs[url] || url;
  // }


  clearStack(){
    this.stack = [];
  }


}
