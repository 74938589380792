import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TreeSelectionMode } from 'src/app/shared/enums/TreeSelectionMode';
import { CategoryDto } from 'src/app/shared/models/CategoryDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { UserEntityDto } from 'src/app/shared/models/UserEntityDto';
import { PreferenceCategoryDto } from 'src/app/shared/models/user/PreferenceCategoryDto';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';

@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.sass']
})
export class UserPreferencesComponent implements OnInit, OnDestroy {
  ctrlSearch: FormControl = new FormControl('');

  userEntityDto?: UserEntityDto;

  defaultCategories: string[] = [];
  selectedPreferenceCategory?: PreferenceCategoryDto;
  TreeSelectionMode: typeof TreeSelectionMode = TreeSelectionMode;

  errorMsg: string = '';
  isLoading: boolean = false;
  isCategoryOpened: boolean = false;
  isSubCategoryOpened: boolean = false;
  openedCategory?: string;
  openedSubCategory?: string;

  countCategories: number = 0;
  countSubCategories: number = 0;
  countSubCategoriesLevelOne: number = 0;

  currentCountCategory?: string;
  currentCountSubCategory?: string;
  currentCountSubCategoryLevelOne?: string;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  userEntityDtoSubscription$?: Subscription;

  constructor(
    private userService: UserService,
    private landingAndBidderService: LandingAndBidderService,
  ) { }

  ngOnInit(): void {
    this.userEntityDtoSubscription$ = this.userService.getUserEntity$.subscribe(data => {
      if (data) {
        this.userEntityDto = data;
        if (this.userEntityDto.preferenceCategories && this.userEntityDto.preferenceCategories.length > 0) {
          this.selectedPreferenceCategory = this.userEntityDto.preferenceCategories[0];
        }
      }
    })

    this.ctrlSearch.valueChanges.subscribe(() => {
      this.onFilterChange();
    })
  }

  onCategorySelectedChange(category: any) {
    console.log(category);
    if (this.openedCategory == category) {
      this.isCategoryOpened = false;
      this.openedCategory = undefined;
      this.isSubCategoryOpened = false;
      this.openedSubCategory = undefined;
    } else {
      this.isCategoryOpened = true;
      this.openedCategory = category;
    }

    // if (this.ctrlSearch.value == '') {
    //   let filteredMasterCategories = this.masterCategories.filter(item => item.category == category);
    //   let subCategories = filteredMasterCategories.map(item => item.subcategory!);
    //   this.subCategories = [...new Set(subCategories)];
    // }
  }

  onSubCategorySelectedChange(subCategory: any) {
    console.log(subCategory);
    if (this.openedSubCategory == subCategory) {
      this.isSubCategoryOpened = false;
      this.openedSubCategory = undefined;
    } else {
      this.isSubCategoryOpened = true;
      this.openedSubCategory = subCategory;
    }

    if (this.ctrlSearch.value == '') {
      // this.subCategoriesLevelOne = this.masterCategories.filter(item => item.category == this.openedCategory && item.subcategory == subCategory);
    }
  }

  onSelectSubCategoryLevelOne(subCategoryLevelOne: string) {
    // if (this.selectedCategories.includes(subCategoryLevelOne)) {
    //   let index = this.selectedCategories.findIndex(item => item == subCategoryLevelOne);
    //   this.selectedCategories.splice(index, 1);
    // } else {
    //   this.selectedCategories.push(subCategoryLevelOne);
    // }
  }

  isSubCategoryLevelOneChecked(subCategoryLevelOne: string) {
    // return this.selectedCategories.includes(subCategoryLevelOne);
    return false
  }

  isCategorySelected(category: string) {
    // let selectedCategories = this.masterCategories.filter(item => this.selectedCategories.includes(item.id!)).map(item => item.category);
    // return selectedCategories.includes(category);
    return false
  }

  isSubCategorySelected(subCategory: string) {
    // let selectedSubCategories = this.masterCategories.filter(item => this.selectedCategories.includes(item.id!)).map(item => item.subcategory);
    // return selectedSubCategories.includes(subCategory);
    return false
  }

  onSelectedCategory(preferenceCategory?: PreferenceCategoryDto) {
    this.selectedPreferenceCategory = preferenceCategory;
  }

  toggleEditCategory() {
    this.selectedPreferenceCategory = undefined;
  }

  handleValidSubmit() {
    this.errorMsg = "";
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);

    if (!this.selectedPreferenceCategory) {
      return;
    }

    this.isLoading = true;

    let userEntityDto: UserEntityDto = AuctionExtUtil.clone(this.userEntityDto);
    if (!userEntityDto?.preferenceCategories) {
      userEntityDto.preferenceCategories = [];
    }
    userEntityDto.preferenceCategories = [this.selectedPreferenceCategory];

    this.userService.updateUserDetails(userEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let data = apiResponseDto.data as UserEntityDto;
          this.userService.setUserDto(data);

          this.isLoading = false;
          this._showSuccessToast$.next(true);
          setTimeout(() => {
            this._showSuccessToast$.next(false);
          }, 2000)
        } else {
          this.isLoading = false;
          this.errorMsg = apiResponseDto.message ?? "Error while updating address";
          this._showErrorToast$.next(true);

        }
      },
      error: (err) => {
        this.isLoading = false;
        this.errorMsg = "Error while updating address";
        this._showErrorToast$.next(true);
      }
    })
  }

  onFilterChange() {
    let searchText = this.ctrlSearch.value;

    // if (searchText.trim() != '') {
    //   let tempMasterList = AuctionExtUtil.clone(this.masterCategories) as CategoryDto[];
    //   let filterCategoryList = tempMasterList.filter(item => item.subcategoryLevelOne!.trim().toLowerCase().includes(searchText.trim().toLowerCase())).map(item => item);

    //   let categories = filterCategoryList.map(item => item.category!);
    //   let subCategories = filterCategoryList.map(item => item.subcategory!);

    //   this.categories = [...new Set(categories)];
    //   this.subCategories = [...new Set(subCategories)];
    //   this.subCategoriesLevelOne = filterCategoryList;
    // } else {
    //   this.categories = this.defaultCategories
    //   this.subCategories = []
    //   this.subCategoriesLevelOne = []

    //   this.isCategoryOpened = false;
    //   this.isSubCategoryOpened = false;
    //   this.openedCategory = undefined
    //   this.openedSubCategory = undefined
    // }
  }

  ngOnDestroy(): void {
    if (this.userEntityDtoSubscription$) {
      this.userEntityDtoSubscription$.unsubscribe();
    }
  }
}
