
  <div class="row m-3 justify-content-center">
    <div class="col-md-6 mt-5">
      <div class="text-orange mb-1" >
        <img src="assets/icons/pending-icon.svg" class="svg-icon-class c-pointer me-2">
         In order to get Notifications subscribe to one or many UNSPC categories
       </div>
       <div class="text-success"  *ngIf="_showSuccessToast$ | async">
        <img src="assets/icons/filter-check.svg" class="svg-icon-class-md c-pointer me-2"> You have give preference for 5 level-3 Categories
       </div>
      <div class="border p-2 bg-white">
      <!-- <div class="d-grid gap-2 mb-3" *ngIf="isLoadingData">
        <div class="e-spinner"></div>
      </div> -->

      <div class="row">
        <div class="col-12 my-3 div-class">
          Three level UNSPC Categorisation
        </div>
        <!-- <div class="col-6">
          <div class="mb-2  input-group ">
            <input class="form-control border-end-0 rounded-0 border bg-white" placeholder="Search Category"
              [formControl]="ctrlSearch" id="ctrlSearch">
            <span class="input-group-append">
              <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
                <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
              </button>
            </span>
          </div>
        </div>
        <div class="col-6">
          <div class="primary-text">
            <div >
              Level-1: {{countCategories}}
            </div>
            <div>
              Level-2: {{countSubCategories}}
            </div>
            <div>
              Level-3: {{countSubCategoriesLevelOne}}
            </div>
          </div>
        </div> -->
      </div>

      <div class="scroll-container">
        <div *ngIf="!selectedPreferenceCategory">
          <label for="description">Select Category</label>
          <div class="col-9 mx-auto mb-3">
            <app-categories-tree-view [selectionMode]="TreeSelectionMode.single"
              (onSelectedCategory)="onSelectedCategory($event)"></app-categories-tree-view>
          </div>
        </div>

        <div *ngIf="selectedPreferenceCategory">
          <label for="description">Selected Material</label>
          <div class="table-responsive">
            <table class="table table-bordered medium-font mb-0 align-middle">
              <thead class="table-dark">
                <tr class="text-center">
                  <th class=""><span>Label One</span></th>
                  <th class=""><span>Label Two</span></th>
                  <th class=""><span>Label Three</span></th>
                  <th class=""><span>Label Four</span></th>
                  <th class=""><span>Label Five</span></th>
                  <th class=""><span>Action</span></th>
                </tr>
              </thead>

              <tbody class="bg-white">
                <tr>
                  <td>{{selectedPreferenceCategory.categoryName}}</td>
                  <td>{{selectedPreferenceCategory.categoryLevelOneName}}</td>
                  <td>{{selectedPreferenceCategory.categoryLevelTwoName}}</td>
                  <td>{{selectedPreferenceCategory.categoryLevelThreeName}}</td>
                  <td>{{selectedPreferenceCategory.categoryLevelFourName}}</td>
                  <td><a class="link-class" (click)="toggleEditCategory()">Edit</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- <div class="scroll-container">
        <nav id="navbar-example3" class="flex-column align-items-stretch pe-4 bg-white">
          <nav class="nav nav-pills flex-column">
            <div *ngFor="let category of categories;">
              <a class="nav-link c-pointer position-relative" [ngClass]="{'font-weight-bold': isCategorySelected(category) }" (click)="onCategorySelectedChange(category)">
                <span *ngIf="isCategoryOpened && openedCategory == category">
                  <img src="assets/icons/angle-left.svg" class="svg-icon-class-small c-pointer me-2 margin-top-neg">
                 </span>
                <span *ngIf="!isCategoryOpened || (isCategoryOpened && openedCategory != category)">
                  <img src="assets/icons/angle-right.svg" class="svg-icon-class-small c-pointer me-2 margin-top-neg">
                  <input type="checkbox" name="" id="check1" class="checkbox-class">
                </span>
               <span class="ms-2"> {{category}}</span>
              </a>
              <nav class="nav nav-pills flex-column" *ngIf="isCategoryOpened && openedCategory == category">
                <div *ngFor="let subcategory of subCategories;">
                  <a class="nav-link c-pointer ms-3 my-1" [ngClass]="{'font-weight-bold': isSubCategorySelected(subcategory) }" (click)="onSubCategorySelectedChange(subcategory)">
                    <span *ngIf="isSubCategoryOpened && openedSubCategory == subcategory">
                      <img src="assets/icons/angle-left.svg" class="svg-icon-class-small c-pointer me-2 margin-top-neg">
                    </span>
                    <span *ngIf="!isSubCategoryOpened || (isSubCategoryOpened && openedSubCategory != subcategory)">
                      <img src="assets/icons/angle-right.svg" class="svg-icon-class-small c-pointer me-2 margin-top-neg">
                      <input type="checkbox" name=""id="check2" class="checkbox-class">
                    </span>
                    <span class="ms-2"> {{subcategory}}</span>
                  </a>
                  <nav class="nav nav-pills flex-column"
                    *ngIf="isCategoryOpened && isSubCategoryOpened && openedCategory == category && openedSubCategory == subcategory">
                    <div class="ms-5 my-1" *ngFor="let subcategoryLevelOne of subCategoriesLevelOne;">
                      <input class="me-3" [id]="subcategoryLevelOne.id" type="checkbox" [value]="subcategoryLevelOne.id"
                        (change)="onSelectSubCategoryLevelOne(subcategoryLevelOne.id!)"
                        [checked]="isSubCategoryLevelOneChecked(subcategoryLevelOne.id!)">
                      <label [for]="subcategoryLevelOne.id">{{subcategoryLevelOne.subcategoryLevelOne}}</label>
                    </div>
                  </nav>
                </div>
              </nav>
            </div>
          </nav>
        </nav>
      </div> -->

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ | async" [isError]="false"
          message="Category saved successfully."></app-message-toast>
      </div>

      <div class="d-grid gap-2 my-3" *ngIf="isLoading">
        <div class="e-spinner center-spinner" style="margin-top: -20px !important"></div>
      </div>

      <div class="col-12 text-center" *ngIf="(!isLoading && !(_showSuccessToast$ | async))">
        <button class="btn btn-primary-clr-class btn-sm" (click)="handleValidSubmit()">Save</button>
      </div>

    </div>
  </div>
</div>

