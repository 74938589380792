<div class="position-relative" [ngClass]="{'e-new-spinner c-progress': isLoading}">
  <div class="card card-ripple border-0 mb-3 shadow zoom rounded-20 bg-white h-358 c-pointer" (click)="navigateAuctionView()">
    <div>
      <img *ngIf="auctionEntityDto?.auctionImage"
        src="/downloadLandingBlob?fileId={{auctionEntityDto?.auctionImage?.fileId}}"
        [alt]="auctionEntityDto?.auctionName" class="image-premium-auction">
      <img *ngIf="!auctionEntityDto?.auctionImage" src="assets/images/quote01.png" alt="" class="image-premium-auction">
    </div>
    <div class="dark-btn-position-all-auction">
      <div class="" id="countdown{{counterFunction}}{{auctionEntityDto?.auctionId}}"
        *ngIf="auctionEntityDto?.status != 'CLOSE'">
        <span class="cust-text danger-clrr" id="headline{{counterFunction}}{{auctionEntityDto?.auctionId}}"></span>
        <span class="badge p-2 dark-btn-position-all-auction"
          id="lotcountdown{{counterFunction}}{{auctionEntityDto?.auctionId}}">
          <span class="cust-text danger-clrr span-class-live"
            id="days{{counterFunction}}{{auctionEntityDto?.auctionId}}"></span>
          <span class="cust-text danger-clrr span-class-live"
            id="hours{{counterFunction}}{{auctionEntityDto?.auctionId}}"></span>
          <span class="cust-text danger-clrr span-class-live"
            id="minutes{{counterFunction}}{{auctionEntityDto?.auctionId}}"></span>
          <span class="cust-text danger-clrr span-class-live"
            id="second{{counterFunction}}{{auctionEntityDto?.auctionId}}"></span>
        </span>
      </div>
    </div>
    <div class="premium-card-bids-position p-2 badge text-white rounded-0" (click)="navigateAuctionView()">
      <img src="assets/icons/hoticon.svg" class="svg-icon-class-md c-pointer" *ngIf="auctionCardWrapperDto?.totalBids && auctionCardWrapperDto?.totalBids! > 10">
       {{auctionCardWrapperDto?.totalBids}} Bids
    </div>
    <div class="premium-lots-position p-2 badge text-white rounded-0" *ngIf="auctionEntityDto!.noOfActiveLots! > 1 ">
      {{ auctionEntityDto?.noOfActiveLots+" Lots"}}
    </div>

    <!-- <div class="badge bg-dark p-2 rounded dark-btn-position-premium-auction">
      <span class="span-class-live">Closed</span>
    </div> -->

    <div class="p-2">
      <div class="mt-2 row">
        <div class="col-xl-4 text-start ">
          <button class="button-class"
            [ngClass]="{'badge-warning-class' : auctionEntityDto?.status == 'LIVE_WAIT', 'badge-primary-class': auctionEntityDto?.status == 'LIVE'}">
            {{auctionEntityDto?.status == 'LIVE_WAIT' ? 'UPCOMING' : auctionEntityDto?.status == 'LIVE' ? 'RUNNING' :
            auctionEntityDto?.status}}
          </button>
        </div>
      <div class="col-xl-8 text-start text-xl-end">
        <div class="row">
          <!-- <div class="col-4 text-end">
            <span>
              <small class="small-class">
                {{(auctionEntityDto?.notOfLots! > 1) ? auctionEntityDto?.notOfLots+" Lots" : ''}}
              </small>
            </span>
          </div> -->
          <div class="col-12 text-md-end">
            <span>
              <small class="small-class" *ngIf="auctionEntityDto?.addresses && auctionEntityDto?.addresses!.length > 0">
                {{ auctionEntityDto?.addresses![0].city}}, {{auctionEntityDto?.addresses![0].countryShortName}}
              </small>
            </span>

          </div>
        </div>
      </div>

      </div>
      <div class="mt-2">
        <div class="bold-font text-start overflow-class" ngbTooltip="{{auctionEntityDto?.auctionName}}">
          {{auctionEntityDto?.sequenceNo}} # {{auctionEntityDto?.auctionName}}
        </div>
        <div class="row">
          <div class="col-12 mt-1" *ngIf="isUserLogin()">
            <div class="bold-font text-start overflow-class" *ngIf="userAuctionRegistrationDto?.paddleNo">
              Registered <b class="b-class">#{{userAuctionRegistrationDto?.paddleNo}}</b>
            </div>
            <div class="bold-font text-start my-2" *ngIf="isShowRegisterToBid()">
              <button class="btn btn-sm btn-outline-primary"
                (click)="openRegisterToBidModal($event)">{{getTextForShowRegisterToBid()}}</button>
            </div>
          </div>
          <!-- <div class="col-6 text-start mt-3" *ngIf="!isUserLogin()">
            <h6 class="heading-h6 text-capitalize">
              {{getCompanyDto(auctionEntityDto?.companyId)!.companyName}}
            </h6>
          </div>
          <div class="col-6 text-end">
            <img src="/downloadLandingBlob?fileId={{getCompanyDto(auctionEntityDto?.companyId)!.companyLogo!.fileId}}"
            alt="{{auctionEntityDto?.listingContactDtos![0].name}}"
            class="profile-image-sm">
          </div> -->
        </div>

        <div class="row" [ngClass]="isShowRegisterToBid() ? 'mt-2' : 'mt-4'">
          <div class="col-3 ps-2  " >
            <div [ngClass]="screenWidth > 1199 ? 'rounded-border-end' : 'border-0'">
              <img src="/downloadLandingBlob?fileId={{auctionEntityDto?.listingContactDtos![0].profile?.fileId}}"
              alt="{{auctionEntityDto?.listingContactDtos![0].name}}"  class="me-0 me-md-2"
              [ngClass]="screenWidth > 768 ? 'profile-image-xl' : 'profile-image-large'">
            </div>
          </div>
          <div class=" col-6  mt-2 text-start ps-sm-4 ps-lg-2">
            <div class="overflow-class-small" >
              <h5 class="heading-h6 text-capitalize mb-0" ngbTooltip="{{auctionEntityDto?.listingContactDtos![0].name}}">{{auctionEntityDto?.listingContactDtos![0].name}}</h5>
            </div>
            <div class="div-class-small overflow-class-small"  ngbTooltip="{{auctionEntityDto?.listingContactDtos![0].emailId}}">
              <img src="assets/icons/mail-icon.svg" class="svg-icon-class-small c-pointer mt-0 me-1 left-padding-icon">
               {{auctionEntityDto?.listingContactDtos![0].emailId}}
            </div>
            <div class="div-class-small overflow-class-small" ngbTooltip="{{getCountryCode(auctionEntityDto?.listingContactDtos![0].countryCode)}} {{auctionEntityDto?.listingContactDtos![0].mobileNo}}">
              <img src="assets/icons/mobile-icon.svg" class="svg-icon-class-small c-pointer mt-0 me-1">
              {{getCountryCode(auctionEntityDto?.listingContactDtos![0].countryCode)}} {{auctionEntityDto?.listingContactDtos![0].mobileNo}}
            </div>

        </div>
        </div>
      </div>
    </div>
  </div>

</div>
