import { Component } from '@angular/core';

@Component({
  selector: 'app-new-footer',
  templateUrl: './new-footer.component.html',
  styleUrls: ['./new-footer.component.sass']
})
export class NewFooterComponent {

}
