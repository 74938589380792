<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>QUESTIONNAIRE</h2>
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3">
      <div class="d-grid gap-2 mb-3" *ngIf="isDataLoading">
        <div class="e-spinner"></div>
      </div>
      <div class="row">
        <div class="col-md-4 border-end mb-3">
          <div class="p-2">
            <div class=" row">
              <div class="medium-font col-6 ">
                Name
              </div>
              <div class="text-center col-4" *ngIf="this.questionnaireTemplate?.scoringTemplate">
                Score
              </div>
            </div>
            <div class="col-12 border mb-2 c-pointer" *ngFor="let item of sectionTemplateList"
              [ngClass]="item.sectionId == selectedSectionTemplate?.sectionId ? 'active-border': ''"
              (click)="changeSection(item)">
              <div class="row p-2 align-items-center">
                <div class="medium-font" [ngClass]="this.questionnaireTemplate?.scoringTemplate ? 'col-6' : 'col-10'">
                  {{item.preSequenceText}}. {{item.sectionName}}
                </div>
                <div class="text-center col-4 border-end" *ngIf="this.questionnaireTemplate?.scoringTemplate">
                  {{item.sectionScore}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-8 mb-3">

          <div class="col-12 table-responsive mt-3">
            <table class="table table-bordered medium-font">
              <thead class="table-secondary">
                <tr>
                  <th scope="col">Q No</th>
                  <th scope="col">Q Criteria</th>
                  <th scope="col">Response Type</th>
                  <th scope="col" *ngIf="questionnaireTemplate?.scoringTemplate">Score</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center mb-1" *ngIf="technicalQuestionsList.length == 0">
                  <td colspan="6">
                    <div class="small-font text-danger">
                      No Question is Available
                    </div>
                  </td>
                </tr>
                <tr *ngFor="let item of technicalQuestionsList;">
                  <th scope="row">{{item?.preSequenceText}}.{{item.sequenceNo}}</th>
                  <td>{{item.questionText}}</td>
                  <td>{{getTitleResponseType(item.responseType!)}}</td>
                  <td *ngIf="questionnaireTemplate?.scoringTemplate">{{item.score}}</td>
                  <td>{{getQuestionStatus(item.questionId!)}}</td>
                  <td>
                    <a class="link-class" (click)="openTechnicalQuestion(item)">View</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div *ngIf="isEnableTermsConditions && !isReadOnly && !isDataLoading">
            <div class="form-check" [ngClass]="{'disabled': isLoading}">
              <input class="form-check-input" type="checkbox" [formControl]="ctrlTermsCondition" id="flexCheckDefault1">
              <label class="form-check-label" for="flexCheckDefault1">
                I accept and agree the
                <span class="c-pointer text-decoration-underline" >Terms &
                  condition</span>
                and <span class="c-pointer text-decoration-underline" >Privacy
                  Policy</span>
                to participate on {{selectedAuction?.sequenceNo}} # {{selectedAuction?.auctionName}}
              </label>
            </div>
            <div *ngIf="ctrlTermsCondition.invalid && (ctrlTermsCondition.dirty || ctrlTermsCondition.touched)">
              <p class="text-danger" *ngIf="ctrlTermsCondition.errors?.['required']">Required</p>
            </div>

            <div class="my-3">
              <app-message-toast *ngIf="_showSuccessToast$ | async"
                [message]="'Auction Registration saved successfully.'"></app-message-toast>
              <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg"></app-message-toast>
            </div>

            <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
              <div class="e-spinner"></div>
            </div>

            <div class="d-grid gap-2 mb-3" *ngIf="!(_showSuccessToast$ | async) && !isLoading">
              <button type="button" class="btn btn-new-class c-pointer" [disabled]="!isEnableTermsConditions || ctrlTermsCondition.invalid" (click)="saveAuctionRegistration()">Save</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
