import { Component, OnInit } from '@angular/core';
import { UserRatingDto } from '../../models/user/UserRatingDto';
import { UserService } from '../../services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LandingAndBidderService } from '../../services/LandingAndBidder.service';
import { UUID } from 'angular2-uuid';
import { FirestoreListenerService } from '../../services/firestore-listener.service';
import { AuctionLotEntityDto } from '../../models/user/AuctionLotEntityDto';
import { BehaviorSubject } from 'rxjs';
import { AuctionExtUtil } from '../../util/AuctionExtUtil';
import { UserLoginComponent } from '../user/user-login/user-login.component';
import { Timestamp } from '@angular/fire/firestore';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-user-rating',
  templateUrl: './user-rating.component.html',
  styleUrls: ['./user-rating.component.sass']
})
export class UserRatingComponent implements OnInit {
  selectedUserRatingDto?: UserRatingDto;
  selectedLotEntityDto?: AuctionLotEntityDto;

  userRatingDtoList: UserRatingDto[] = []

  isLoading: boolean = false;
  selectedRating: number = 0;
  averageRating: number = 0
  totalRating: number = 0

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  constructor(
    private ngbModal: NgbModal,
    private userService: UserService,
    private bidderService: LandingAndBidderService,
    private firestoreService: FirestoreListenerService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    let userEntity = this.userService.getUserEntity();

    this.bidderService.getSelectedAuctionLot$.subscribe((data) => {
      if (data) {
        this.selectedLotEntityDto = data;
      }
    })

    this.bidderService.userRatingDtoList$.subscribe(data => {
      this.userRatingDtoList = [];
      if (data && data.length > 0) {
        this.userRatingDtoList = data;

        if (this.userRatingDtoList && this.userRatingDtoList.length > 0) {

          if (userEntity) {
            this.selectedUserRatingDto = this.userRatingDtoList.find(item => item.userId == userEntity.userId);
            this.selectedRating = this.selectedUserRatingDto?.rating ?? 0;
          }

          this.totalRating = this.userRatingDtoList.reduce((sum, current) => sum + current.rating!, 0);

          let totalUsers = this.userRatingDtoList.length;
          this.averageRating = this.totalRating / totalUsers;
        }
      }else{
        this.selectedUserRatingDto = undefined;
        this.userRatingDtoList = [];
        this.selectedRating = 0;
        this.totalRating = 0;
        this.averageRating = 0;
      }
    })
  }


  openLoginComponent() {
    this.ngbModal.open(UserLoginComponent, {
      size: 'sm', backdrop: 'static', keyboard: false , centered: true
    });
  }

  openLotRatingModal(content: any) {
    this.closeModal()

    let userEntity = this.userService.getUserEntity();
    if (userEntity) {
      this.selectedUserRatingDto = this.userRatingDtoList.find(item => item.userId == userEntity.userId);
      this.selectedRating = this.selectedUserRatingDto?.rating ?? 0;

      this.ngbModal.open(content, {
        size: 'sm', backdrop: 'static', keyboard: false , centered: true
      });
    } else {
      this.openLoginComponent()
    }
  }

  viewRatingListModal(content: any, rating: number) {
    this.closeModal()
    this.ngbModal.open(content, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
    });
  }

  getNoOfRatingCount(noOfStar: number) {
    if (this.userRatingDtoList && this.userRatingDtoList.length > 0) {
      return this.userRatingDtoList.filter(item => item.rating == noOfStar).length;
    }
    return 0;
  }

  getPercentOfRating(noOfStar: number) {
    if (this.userRatingDtoList && this.userRatingDtoList.length > 0) {
      let count = this.userRatingDtoList.filter(item => item.rating == noOfStar).length;
      return (count / this.userRatingDtoList.length) * 100;
    }
    return 0;
  }

  selectRating(rating: number) {
    this.selectedRating = rating;
  }

  saveRating() {
    if (this.selectedRating == 0) {
      return;
    }

    let user = this.userService.getUserEntity();
    let userRatingDto = new UserRatingDto();

    this.isLoading = true;

    //populate user rating dto
    if (this.selectedUserRatingDto) {
      userRatingDto = AuctionExtUtil.clone(this.selectedUserRatingDto);
    } else {
      userRatingDto.id = UUID.UUID().toString();
    }

    userRatingDto.auctionHouseId = this.selectedLotEntityDto?.auctionHouseId;
    userRatingDto.auctionId = this.selectedLotEntityDto?.auctionId;
    userRatingDto.lotId = this.selectedLotEntityDto?.lotId;
    userRatingDto.userId = user.userId;
    userRatingDto.emailId = user.primaryEmailId;
    userRatingDto.name = user.firstName + ' ' + user.lastName;
    userRatingDto.profileImage = user.profileImage;
    userRatingDto.rating = this.selectedRating;
    userRatingDto.timestamp = Timestamp.now();

    //save rating in firestore
    this.firestoreService.saveUserRating(userRatingDto)
    .then(() => {
      this._showSuccessToast$.next(true);
      this.isLoading = false;

      setTimeout(() => {
        this._showSuccessToast$.next(false);
        this.closeModal();
      }, 2000);
    })
    .catch((err) => {
      console.error(err);
      this.isLoading = false;
      this._showErrorToast$.next(true);
    });
  }

  getDisplayDateOfRating(timestamp?: Timestamp) {
    if (timestamp) {
      return this.datePipe.transform(timestamp.toDate(), 'dd MMM YY hh:mma')
    }
    return '';
  }

  closeModal() {
    this.selectedRating = 0;
    this.ngbModal.dismissAll();
  }
}
