import { DatePipe } from '@angular/common';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { DocumentArchiveEntityDto } from 'src/app/shared/models/user/DocumentArchiveEntityDto';
import { DocumentArchiveWrapper } from 'src/app/shared/models/user/DocumentArchiveWrapper';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';

@Component({
  selector: 'app-user-documents',
  templateUrl: './user-documents.component.html',
  styleUrls: ['./user-documents.component.sass']
})
export class UserDocumentsComponent implements OnInit{

  formGroup: FormGroup;
  isExpiry$ = new BehaviorSubject<Boolean>(false);
  isLoading$ = new BehaviorSubject<Boolean>(false);
  listLoading$ = new BehaviorSubject<Boolean>(false);
  _showErrorToast$ = new BehaviorSubject<Boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<Boolean>(false);

  _showListSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showListErrorToast$ = new BehaviorSubject<boolean>(false);
  errorListMsg: string | undefined;

  documentTypes = ['application/pdf'];
  errorMsg: string | undefined;
  currentFile?: File | null;
  fileUploadError: boolean = false;
  _fileDisplayName$ = new BehaviorSubject<string | null>("");
  _fileSize$ = new BehaviorSubject<string | null>("");


  selectedDocumentArchive?: DocumentArchiveEntityDto;
  documentArchiveList?: DocumentArchiveEntityDto[];
  countExpiredDocument: number = 0;

  constructor(
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    public userService: UserService) {
    this.formGroup = this.formBuilder.group({
      documentDisplayName: new FormControl('', [Validators.required]),
      expiryDate: new FormControl('')
    });
  }
  ngOnInit(): void {
    this.loadDocumentArchive();
  }

  get fc(): any { return this.formGroup.controls; }

  loadDocumentArchive() {
    this.listLoading$.next(true)
    this.userService.getDocumentArchives().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.listLoading$.next(false)
        this.documentArchiveList = apiResponseDto.data as DocumentArchiveEntityDto[];
        this.getCountExpiredDocument();
      },
      error: (error) => {
        this.listLoading$.next(false)
      }
    })
  }

  populateInitialDocumentDetails(){
    this.formGroup.controls['documentDisplayName'].setValue(this.selectedDocumentArchive?.documentDisplayName);

    if(this.selectedDocumentArchive?.expiryDate){
      this.isExpiry$.next(true);
      this.formGroup.controls['expiryDate'].setValidators([Validators.required]);
      this.formGroup.controls['expiryDate'].setValue(AuctionExtUtil.getNgbPickerDate(this.selectedDocumentArchive?.expiryDate));
    }else{
      this.formGroup.controls['expiryDate'].clearValidators();
    }
    this.formGroup.controls['expiryDate'].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();

    this._fileDisplayName$.next(this.selectedDocumentArchive?.documentName!)
    this._fileSize$.next(this.selectedDocumentArchive?.size!)
  }

  clear(){
    this.selectedDocumentArchive = undefined;
    this.formGroup.reset();
    this.deleteFile();
    this.isLoading$.next(false);
    this.isExpiry$.next(false);
  }

  deleteFile(){
    this._fileDisplayName$.next('');
    this._fileSize$.next('');
    this.currentFile = null;

  }

  formatBytes(size: any){
    return AuctionExtUtil.formatBytes(size);
  }


  chooseFile(event: any) {
    this._showErrorToast$.next(false);
    this.currentFile = event.target.files[0];

    if (!this.documentTypes.includes(this.currentFile!.type)) {
      this.fileUploadError = true;
      this.errorMsg = "Extension not supported";
      this._showErrorToast$.next(true);
      this.currentFile = null;
      this._fileDisplayName$.next(null);
      return;
    }

    this._fileDisplayName$.next(this.getFileName(this.currentFile?.name!) as string);
    this._fileSize$.next(this.currentFile?.size+"");
  }


  getFileName(fileName: string){
    let extensionName = fileName?.slice(fileName.lastIndexOf('.'), fileName.length)
    let name = fileName?.slice(0 , fileName.lastIndexOf('.'));
    let finalFileName = AuctionExtUtil.removeSpecialCharacters(name!) as string
    return finalFileName+""+extensionName;
  }

  enableAndDisableExpiry(){
    this.isExpiry$.next(!this.isExpiry$.value);

    if(this.isExpiry$.value){
      this.formGroup.controls['expiryDate'].setValidators([Validators.required]);
    }else{
      this.formGroup.controls['expiryDate'].clearValidators();
    }
    this.formGroup.controls['expiryDate'].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
  }

  selectDocumentArchive(documentArchive: DocumentArchiveEntityDto){
    this.clear()
    this.selectedDocumentArchive = documentArchive;
    this.populateInitialDocumentDetails()
  }


  populateDocumentDetails(){
    let formData = new FormData();
    let documentArchiveWrapper = new DocumentArchiveWrapper() ;

    if(this.selectedDocumentArchive){
      documentArchiveWrapper.documentArchiveId = this.selectedDocumentArchive.documentArchiveId;
      documentArchiveWrapper.expiryDate = this.selectedDocumentArchive.expiryDate;
    }

    documentArchiveWrapper.documentDisplayName = this.formGroup.controls['documentDisplayName'].value;

    if(this.isExpiry$.value){
      documentArchiveWrapper.expiryDate = AuctionExtUtil.getDateFromNgDatePicker(this.datePipe, this.formGroup.controls['expiryDate'].value) as string;
    }


    //document data populate
    if(this.currentFile){

      let metaData = {
        'mimeType': this.currentFile.type,
        'version': 0,
        'publicApi': true,
        'dataType': 'Square'
      };

      documentArchiveWrapper.metaData = JSON.stringify(metaData)
      formData.append("file", this.currentFile);
    }
    formData.append("documentArchiveWrapper", JSON.stringify(documentArchiveWrapper));
    return formData;
  }


  saveUserDetails() {
    this.isLoading$.next(false);
    this._showErrorToast$.next(false);
    this.formGroup.markAllAsTouched();

    if(this.formGroup.invalid){
      return;
    }else{
      //document validation
      if(!this._fileDisplayName$.value){
        this._showErrorToast$.next(true);
        this.errorMsg = 'Please upload document';
        return;
      }
    }

    let formData = this.populateDocumentDetails();

    this.isLoading$.next(true);
    this.userService.saveDocumentArchive(formData).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.isLoading$.next(false);
          this._showSuccessToast$.next(true);
          this.clear()
          this.loadDocumentArchive();
          setTimeout(() => {
            this._showSuccessToast$.next(false);
          }, 2000);
        } else {
          this.isLoading$.next(false);
          this._showErrorToast$.next(true);
          this.errorMsg = apiResponseDto.message;
        }
      },
      error: () => {
        this.isLoading$.next(false);
        this._showErrorToast$.next(true);
        this.errorMsg = "Error while saving document archive";
      }
    })
  }

  getCountExpiredDocument(){
    let errorDataList = this.documentArchiveList?.filter(item => item.expiryDate && AuctionExtUtil.convertedDate(item.expiryDate!, "23:59")! < new Date());
    if(errorDataList){
      this.countExpiredDocument = errorDataList.length;
      return;
    }

   this.countExpiredDocument = 0;
  }

  deleteDocumentArchive(id: string) {
    this.listLoading$.next(false);
    this._showListErrorToast$.next(false);
    this.listLoading$.next(true);
    this.userService.deleteDocumentArchive(id).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.listLoading$.next(false);
          this._showListSuccessToast$.next(true);
          this.loadDocumentArchive();
          setTimeout(() => {
            this._showListSuccessToast$.next(false);
          }, 2000);
        } else {
          this.listLoading$.next(false);
          this._showListErrorToast$.next(true);
          this.errorListMsg = apiResponseDto.message;
        }
      },
      error: () => {
        this.isLoading$.next(false);
        this._showListErrorToast$.next(true);
        this.errorListMsg = "Error while saving document archive";
      }
    })
  }
}
