import { ErrorService } from 'src/app/shared/services/error.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, tap } from 'rxjs';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { HttpClient } from '@angular/common/http';
import { SourcingCalenderEventDto } from '../models/user/SourcingCalenderEventDto';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {



  allSourcingEventDtos$ = new BehaviorSubject<SourcingCalenderEventDto[]>([]);
  selectedSourcingEventDto$ = new BehaviorSubject<SourcingCalenderEventDto|null>(null);

  get getAllSourcingEventDtos$() { return this.allSourcingEventDtos$.asObservable(); }
  get getSelectedSourcingEventDtos$() { return this.selectedSourcingEventDto$.asObservable(); }

  constructor(private httpClient: HttpClient, private errorService: ErrorService){}

  setAllSourcingEventDtos(allAuctions: SourcingCalenderEventDto[]) {
    this.allSourcingEventDtos$.next(allAuctions);
  }

  updateSelectedSourcingEventDto(auction: SourcingCalenderEventDto| null){
    this.selectedSourcingEventDto$.next(auction);
  }


  fetchSourcingCalenderData() {
    return this.httpClient.get<ServerAPIResponseDto>('sourcingCalender').pipe(
      tap(_ => console.log("fetch SourcingCalenderData")),
      catchError(this.errorService.handleError<any>("Error while fetching SourcingCalenderData" )));
  }
}
