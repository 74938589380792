<div class="margin-class" *ngIf="isLoggedIn">
  <!-- <div>
    <footer class="position-relative bg-dark">

      <div class="row justify-content-center align-items-center">
        <div class="col-md-4 border-md-end text-center h-130">
          <div class="my-5  text-white row ps-4">
            <div class="col-md-1 col-2">
              <img src="assets/icons/clock.svg" class="svg-icon-large me-2 c-pointer">
            </div>
            <div class="col-auto ps-5 ps-md-2 text-white">
              <span class="span-class-live ps-md-3">Availability</span> <br>
              <span class="span-class-live">24 x 7</span>
            </div>
          </div>
        </div>
        <div class="col-md-3 text-center border-md-end h-130">
          <div class="my-5 text-white row ps-4">
            <div class="col-md-1 col-2">
              <img src="assets/icons/mail_primary.svg" class="svg-icon-large me-2 c-pointer">
            </div>
            <div class="col-auto ps-md-4">
              <span class="span-class-live text-start">Support Mail</span> <br>
              <span class="span-class-live text-start ps-3">hello@auctionext.com</span>
            </div>
          </div>
        </div>
        <hr class="hr my-2 d-block d-md-none text-white" />
        <div class="col-md-5 text-center h-130">
          <div class="my-xl-5 my-2 text-white row ps-4">
            <div class="col-md-1 col-2">
              <img src="assets/icons/location.svg" class="svg-icon-large me-2 c-pointer">
            </div>
            <div class="col-md-auto col-9">
              <span class="span-class-live">2055 Limestone Road STE 200-C,
              </span> <br>
              <span class="span-class-live">Wilmington, Delaware, 19808
                United States
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-md-5 border-md-end text-center p-0">
          <div class="height-100-2 bg-color">
            <div class="pt-md-5 py-3 text-white">
              <div class=" justify-content-center">
                <div class="">
                  <img src="assets/icons/phone-icon.svg" class="svg-icon-large me-2 c-pointer">
                </div>
                <div class="mt-2">
                  <span class="span-class-live ">
                    Call Us:
                  </span> <br>
                  <span class="span-class-live">
                    <b class="landing-date-font-live">+1 302 415 31845</b></span>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="col-md-7 ">

          <div class="text-white p-3 p-md-2">
            AuctionExt is an online auction platform that offers a secure way of getting the best bid to match the
            requirement of sellers and bidders globally.
            With our user-friendly interface and advanced technology backed architecture, we aim to provide a
            seamless and
            enjoyable auction experience. Whether you're a seasoned bidder or a first-time seller, AuctionExt has
            all the
            tools and resources you need to
            succeed in online auctioneering. Our mission is to create a fair and transparent platform that connects
            buyers
            and sellers from all corners of the globe.
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-md-4 border-md-end text-center border-height">
          <div class="pt-3">
            <a href="#">
              <img *ngIf="!auctionHouse?.logo" src="assets/logo/auctionext-2.png" alt="REC Auctions"
                class="application-logo | img-fluid my-1" />
              <img *ngIf="auctionHouse?.logo" src="/downloadLandingBlob?fileId={{auctionHouse?.logo?.fileId}}"
                [alt]="auctionHouse?.name" class="application-logo | img-fluid my-1 bg-white p-1 rounded" />
            </a>
          </div>
        </div>
        <div class="col-md-3 text-center border-md-end  border-height">
          <div class="pt-4">
            <a [ngbPopover]="popContentShare" popoverTitle="Share">
              <img src="assets/icons/share-icon.svg" class="close-icon-class me-2 c-pointer">
            </a>
            <a href="https://www.linkedin.com/company/auctionext/" target="_blank">
              <img src="assets/icons/linkedin.svg" class="close-icon-class me-2 c-pointer">
            </a>
            <a href="https://www.facebook.com/people/AuctionExt/100087424403714/" target="_blank">
              <img src="assets/icons/facebook.svg" class="close-icon-class  me-2 c-pointer">
            </a>
            <a href="https://wa.me/13024153184?text=Greetings%20from%20AuctionExt!%20Please%20wait%20Our%20Executive%20will%20connect%20as%20soon%20as%20possible."
              target="_blank">
              <img src="assets/icons/whatsapp-icon.svg" class="close-icon-class c-pointer">
            </a>
          </div>
        </div>
        <hr class="hr my-2 d-block d-md-none text-white" />
        <div class="col-md-5">
          <div class="row mb-3">
            <div class="col-md-6 text-center text-md-start">
              <div class="mt-1 div-class-live">
                Copyright © {{year}}<a href="https://auctionext.com"> <span class="  text-white pt-1 ps-2 btn-link">AuctionExt</span></a>
              </div>
            </div>
            <div class="col-md-4 mt-3 mt-md-0 text-md-end text-center pe-md-4">
              <button class="btn btn-sm btn-outline-primary p-3" (click)="moveToTop()">
                <img src="assets/icons/arrowupward.svg" class="svg-icon-class c-pointer">
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div> -->
  <div class="col-12">
    <!-- <div class="row ">
      <div class="col-md-4 mb-2" (mouseout)="footerCardOneAnimationMouseOut()">
        <div id="footer-card-1" class="card zoom card-hover-effect-mail  shadow border-0 p-3 "
          (mousemove)="footerCardOneAnimationMouseOver($event)">
          <div class="border-dashed footer-card-height ">
            <div id="footer-card-1-text" class=" text-center text-move   my-3">
              <img src="assets/icons/mail-footer.svg" class="svg-icon-xl me-2 c-pointer">

              <div class="mt-md-3 mt-1">
                <span class=" text-start">Support Email</span> <br>
                <span class=" text-start">{{auctionHouse?.supportMail}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-2" (mouseout)="footerCardTwoAnimationMouseOut()">
        <div id="footer-card-2" class="card zoom card-hover-effect-location  shadow border-0 p-3 "
          (mousemove)="footerCardTwoAnimationMouseOver($event)">
          <div class="border-dashed footer-card-height">
            <div id="footer-card-2-text" class=" text-center text-move my-2">
              <img src="assets/icons/location-icon.svg" class="svg-icon-xl me-2 c-pointer">

              <div class="mt-md-1">

                <span class=" text-start"
                  *ngIf="auctionHouse?.address?.addressLine2">{{auctionHouse?.address?.addressLine1}},
                  {{auctionHouse?.address?.addressLine2}}</span> <br>
                <span class=" text-start"
                  *ngIf="!auctionHouse?.address?.addressLine2">{{auctionHouse?.address?.addressLine1}}</span> <br>
                <span class=" text-start">{{auctionHouse?.address?.city}}, {{auctionHouse?.address?.zipCode}},
                  {{auctionHouse?.address?.state}} {{auctionHouse?.address?.country}}


                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-2" (mouseout)="footerCardThreeAnimationMouseOut()">
        <div id="footer-card-3" class="card zoom card-hover-effect-call position-relative shadow border-0 p-3 "
          (mousemove)="footerCardThreeAnimationMouseOver($event)">
          <div class="border-dashed footer-card-height  card-and-image">

            <div id="footer-card-3-text" class="text-center text-move my-3">
              <img src="assets/icons/phone-icon.svg" class="svg-icon-xl me-2 c-pointer">
              <div class="mt-md-3 mt-1">
                <span class=" text-start">Call Us:</span> <br>
                <span class=" text-start">{{getCountryCode(auctionHouse?.countryCode)}} {{auctionHouse?.phoneNo}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
    <footer class="  mt-2">
      <div class="footer">
        <div class="row justify-content-center ">
          <div class="col-6 margin-bootom">
            <div>
              <div class="text-center my-2">
                <div class="pb-2">
                  <a *ngIf="twitterUsername" href="https://twitter.com/PKCAdvisory/" target="_blank">
                    <img src="assets/icons/twitter_icon.svg" class="svg-icon-class-md me-2 c-pointer">
                  </a>
                  <a *ngIf="linkedInUsername"
                    href="https://www.linkedin.com/company/30802?trk=tyah&trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A30802%2Cidx%3A2-1-2%2CtarId%3A1485343370926%2Ctas%3Apkc%20advi"
                    target="_blank">
                    <img src="assets/icons/linkedin.svg" class="close-icon-class-fb me-2 c-pointer">
                  </a>
                  <a *ngIf="fbUsername" href="https://www.facebook.com/PKCAdvisory/" target="_blank">
                    <img src="assets/icons/facebook.svg" class="close-icon-class-fb me-2 c-pointer">
                  </a>
                  <!-- <a *ngIf="whatsAppNumber"
                  href="https://wa.me/{{whatsAppNumber}}?text=Greetings%20from%20AuctionExt!%20Please%20wait%20Our%20Executive%20will%20connect%20as%20soon%20as%20possible."
                  target="_blank">
                  <img src="assets/icons/whatsapp-icon.svg" class="close-icon-class c-pointer">
                </a> -->
                </div>
                <div>

                </div>
                <div class="text-dark">
                  Copyright © {{year}}
                  <a target="_blank" href="https://trufetech.com/">
                    <span class="  text-dark ps-1 pt-1 btn-link text-capitalize pe-2">Trufe</span></a>
                  <span class="mx-1">|</span>
                  <span class="btn-link c-pointer text-capitalize" (click)="openPrivacyModal(privacyModal)">Privacy
                    Policy</span>
                  | <span class="btn-link c-pointer text-capitalize" (click)="openTCModal(tcModal)">Terms of Use</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </footer>
  </div>
</div>

<ng-template #privacyModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>PRIVACY POLICY</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="scroll-container">
      <div #privacyHeader></div>
      <div class="m-3" style="white-space: normal;">
        <p [innerHTML]="privacyAndTermsOfUseDto?.privacyPolicy ?? '' | safe: 'html'"></p>
      </div>
    </div>
    <button class="btn btn-sm btn-outline-primary p-3 floating-button" (click)="moveToTop(privacyHeader)">
      <img src="assets/icons/arrowupward.svg" class="svg-icon-class c-pointer">
    </button>
  </div>
</ng-template>

<ng-template #tcModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>TERMS OF USE</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="scroll-container">
      <div #termsHeader></div>
      <div class="m-3" style="white-space: normal;">
        <p [innerHTML]="privacyAndTermsOfUseDto?.termsOfUse ?? '' | safe: 'html'"></p>
      </div>
      <button class="btn btn-sm btn-outline-primary p-3 floating-button" (click)="moveToTop(termsHeader)">
        <img src="assets/icons/arrowupward.svg" class="svg-icon-class c-pointer">
      </button>
    </div>
  </div>
</ng-template>



<!-- <div class="col-md-3 col-4 text-center mb-2 mt-5">
      <div class="">
        <a href="#">
          <img *ngIf="!auctionHouse?.logo" src="assets/logo/auctionext-2.png" alt="REC Auctions"
            class="application-logo | img-fluid my-1" />
          <img *ngIf="auctionHouse?.logo" src="/downloadLandingBlob?fileId={{auctionHouse?.logo?.fileId}}"
            [alt]="auctionHouse?.name" class="application-logo | img-fluid my-1 bg-white p-1 rounded" />
        </a>
      </div>
    </div>
    <div class="col-md-6   mb-2">
      <h3 class="text-center">About Us</h3>
      <div class="text-start">
        AuctionExt is an online auction platform that offers a secure way of getting the best bid to match the
        requirement of sellers and bidders globally.
        With our user-friendly interface and advanced technology backed architecture, we aim to provide a seamless and
        enjoyable auction experience. Whether you're a seasoned bidder or a first-time seller, AuctionExt has all the
        tools and resources you need to
        succeed in online auctioneering. Our mission is to create a fair and transparent platform that connects buyers
        and sellers from all corners of the globe.
      </div>

    </div>

    <div class="col-md-2   mb-2">
      <h3 class="text-center">Location</h3>
      <div class="text-start">2055 Limestone Road STE 200-C,
        Wilmington, Delaware, 19808</div>
      <div>Newport Beach, CA 92660</div>
      <div>United States</div>
      <div>Phone: +1 302 415 31845</div>
      <div>Email: hello@auctionext.com</div>
    </div> -->