<div class="card">
  <p-steps [model]="stepItems" [readonly]="false" [activeIndex]="activeStepIndex"
    (activeIndexChange)="onActiveIndexChange($event)"></p-steps>


  <div class="d-grid gap-2 my-3" *ngIf="isDataLoading">
    <div class="e-spinner"></div>
  </div>

  <div class="row mt-4" *ngIf="!isDataLoading">
    <!-- <div class="col-12 text-start">
      <div class="row">
        <div class="col-md-8">
          <span class="span-class me-3">Event:</span>
          <span> {{selectedSubcategory?.title}}</span>
        </div>
        <div class="col-md-4 mb-2 text-md-end" *ngIf="questionnaireTemplate?.guideLines && guidelinesList.length > 0">
          <a class="link-class" (click)="openViewGuidelinesModal(guidelinesModalTemplate)">View Guidelines</a>
        </div>
      </div>
    </div> -->

    <div class="col-12 container p-3">
      <div class="row mt-2" *ngIf="this.isShowTechnicalTab || this.isShowFinancialTab">
        <div class="col-md-6 text-start">
          <!-- <span class="badge badge-draft-class me-3">Incomplete-{{totalIncompleteQuestions}}</span>
          <span class="badge-blue-class badge">Complete-{{totalCompleteQuestions}}</span> -->
          <span class="badge badge-draft-class" *ngIf="totalCompleteQuestions > 0 && !isSectionCompleted">Draft
            Saved</span>
          <span class="badge badge-info-class" *ngIf="isSectionCompleted">Submitted</span>
        </div>
        <div class="col-md-6 text-end"
          *ngIf="selectedSectionTemplate?.envelopeType == 'FINANCIAL' && isShowFinancialTab">
          <div class="row justify-content-end">
            <div class="col-auto">
              <div class="dropdown me-3" ngbDropdown>
                <a ngbDropdownToggle class="link-class" container="body" [ngClass]="{'disabled' : isDownloading}">
                  Bulk Upload
                </a>
                <ul class="scroll-area-lg mt-4" ngbDropdownMenu>
                  <li class="c-pointer" (click)="downloadBulkQuestionsExcel(bulkQuestionsExcelModal)">
                    <a class="dropdown-item" ngbDropdownItem>
                      Download Questions Excel
                    </a>
                  </li>
                  <hr class="hr">
                  <li class="c-pointer" (click)="openUploadQuestionsExcelModal(uploadQuestionsExcelModal)">
                    <a class="dropdown-item" ngbDropdownItem>
                      Upload Questions Excel
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-auto fs-6">
              {{selectedRfxDto?.currency?.symbol}}{{totalBidAmount}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TECHNICAL QUESTIONS -->
    <div class="col-12 table-responsive"
      *ngIf="selectedSectionTemplate?.envelopeType == 'TECHNICAL' && isShowTechnicalTab">
      <table class="table table-bordered text-center medium-font mb-0 align-middle">
        <thead class="table-dark">
          <tr>
            <th scope="col">Q No</th>
            <th scope="col">Q Criteria</th>
            <th scope="col">Response Type</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center mb-1" *ngIf="technicalQuestionsList.length == 0">
            <td colspan="6">
              <div class="small-font text-danger">
                No Question is Available
              </div>
            </td>
          </tr>
          <tr *ngFor="let item of technicalQuestionsList;">
            <th scope="row">{{item?.preSequenceText}}.{{item.sequenceNo}}</th>
            <td>{{item.questionText}}</td>
            <td>{{getTitleResponseType(item.responseType!)}}</td>
            <td>
              <div class=""
                [ngClass]="{'text-warning' : getQuestionStatus(item.questionId!) == 'Incomplete', 'text-success' : getQuestionStatus(item.questionId!) == 'Complete'}">
                <span class="ms-2">{{getQuestionStatus(item.questionId!)}}</span>
              </div>

            <td>
              <a class="link-class" (click)="openTechnicalQuestionModal(item)">View</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- FINANCIAL QUESTIONS -->
    <div class="col-12 table-responsive"
      *ngIf="selectedSectionTemplate?.envelopeType == 'FINANCIAL' && isShowFinancialTab">
      <table class="table table-bordered text-center medium-font mb-0 align-middle">
        <thead class="table-dark">
          <tr class="text-center">
            <th><span>Item No</span></th>
            <th><span>Item Name</span></th>
            <th><span>Qty</span></th>
            <th><span>Uom</span></th>
            <th><span>Tax Rate</span></th>
            <th><span>Bid Value</span></th>
            <th><span>Status</span></th>
            <th><span>Action</span></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr class="text-center mb-1" *ngIf="financialQuestionsList.length == 0">
            <td colspan="6">
              <div class="small-font text-danger">
                No Question is Available
              </div>
            </td>
          </tr>
          <tr class="text-center align-middle" *ngFor="let item of financialQuestionsList;">
            <td class="">{{item?.preSequenceText}}.{{item.sequenceNo}}</td>
            <td class="">{{item.questionText}}</td>
            <td class="">{{item.quantity}}</td>
            <td class="">{{item.uom}}</td>
            <td class="">{{item.tax}}</td>
            <td class="">{{getQuestionResponse(item.questionId!)}}</td>
            <td class="">
              <div class=""
                [ngClass]="{'text-warning' : getQuestionStatus(item.questionId!) == 'Incomplete', 'text-success' : getQuestionStatus(item.questionId!) == 'Complete'}">
                <span class="ms-2">{{getQuestionStatus(item.questionId!)}}</span>
              </div>
            </td>
            <td class="">
              <a class="link-class" (click)=" openFinancialQuestionModal(item)">Edit</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mt-3" *ngIf="isEnableTermsConditions && !isReadOnly && !isDataLoading">
      <div class="my-3 text-start">
        <app-message-toast *ngIf="_showSuccessToast$ | async"
          [message]="'Event Registration saved successfully.'"></app-message-toast>
        <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg"></app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>

      <div class="col-12 text-end mt-2" *ngIf="!(_showSuccessToast$ | async) && !isLoading">
        <button type="button" class="btn btn-blue-clr-class c-pointer" [disabled]="!isEnableTermsConditions"
          (click)="saveUserRfxSubcategory()">Submit</button>
      </div>
    </div>

  </div>
</div>

<ng-template #guidelinesModalTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>GUIDELINES</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <ul class="animate-show-more ps-0">
        <li class="c-default" *ngFor="let item of guidelinesList; index as i;">
          {{item}}
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #bulkQuestionsExcelModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Download Excel</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div *ngIf="isDownloading">
          <div class="d-grid gap-2 mb-3">
            <div class="e-spinner"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #uploadQuestionsExcelModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>UPLOAD QUESTIONS EXCEL</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <div class="form-floating mb-3">
        <input type="file" class="form-control" id="bulkExcelFormControl" formControlName="bulkExcelFormControl"
          accept=".xlsx, .xls" (change)="onBulkExcelChange($event)"
          [ngClass]="{'is-invalid': bulkExcelFormControl.invalid && (bulkExcelFormControl.dirty || bulkExcelFormControl.touched)}">
        <label for="rfxTitle">Choose Excel File</label>
        <div *ngIf="bulkExcelFormControl.invalid && (bulkExcelFormControl.dirty || bulkExcelFormControl.touched)">
          <p class="text-danger" *ngIf="bulkExcelFormControl.errors?.['required']">Required</p>
        </div>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="errorMsg"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
          message="Excel uploaded successfully."></app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>

      <div class="text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="btn btn-new-class btn-sm me-3" (click)="closeModal()">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="uploadBulkQuestionsExcel()">OK</button>
      </div>
    </div>
  </div>
</ng-template>