
<div ngbDropdown class="dropdown " #statusNgbDropdown="ngbDropdown" [autoClose]="false">
  <input class="btn select-box-btn-class text-start dropdown-toggle mb-2 rounded-0" [formControl]="ctrlSearchAuction" type="text" data-bs-toggle="dropdown" ngbDropdownToggle (click)="loadAuctionSearchData()"
    aria-expanded="false" placeholder="Search by keywords" id="ctrlSearchAuction">
<!-- <div class="row">
  <div class="col-9">
    <div class="overflow-class-sort">
    test
    </div>
  </div>
</div> -->

  <ul class="dropdown-menu text-start shadow mt-5 dropdown-width1 scroll-search" ngbDropdownMenu>
    <li>
      <div class="e-spinner" *ngIf="isLoading"></div>
      <div class="col-12 text-end px-2">
        <img src="assets/icons/close_light.svg" class="svg-icon-class c-pointer" (click)="statusNgbDropdown.close()" >
      </div>
    </li>

    <p> Filter Result: {{(auctionSearchDataList$ | async)?.length}}</p>

    <li *ngFor="let item of auctionSearchDataList$ | async">
      <div class="col-12   card bg-white shadow border-0 rounded-0 p-2">
        <h5>#{{item.sequenceNo}} {{item.auctionName}}</h5>
        <div class="row">

          <div class="col-12 ">
            {{item?.addresses}}
          </div>

          <div class="col-9 mt-2 text-start ps-sm-4">
            <div class="overflow-class-small">
              <h4 class="heading-h6 text-capitalize mb-0" >
                {{item?.listingContactDto?.name}}</h4>
            </div>
            <div class="div-class-small overflow-class-small">
             {{item.companyName}}
            </div>
            <div class="div-class-small overflow-class-small" >
              <img src="assets/icons/mail-icon.svg" class="svg-icon-class-small c-pointer mt-0 me-1 left-padding-icon">
              {{item?.listingContactDto?.emailId}}
            </div>
            <div class="div-class-small overflow-class-small" >
              <img src="assets/icons/mobile-icon.svg" class="svg-icon-class-small c-pointer mt-0 me-1">
              {{item?.listingContactDto?.mobileNo}}
            </div>
              </div>
              <div class="col-3">
            <img src="/downloadLandingBlob?fileId={{item?.listingContactDto?.profile?.fileId}}" alt="" class="profile-image-md">
          </div>
        </div>
      </div>
      <hr class="hr">
    </li>

  </ul>
</div>


