<div class="container" #scrollableElement>
  <div class="my-3">
    <div class="col-12 mb-3">
      <div class="border p-2 col mb-3 bg-white">
        <div class="row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-auto mt-1">
                <img src="assets/icons/home_light.svg" class="svg-icon-class c-pointer" (click)="navigateToHomePage()">
                <span>
                  <img src="assets/icons/forward-icon.svg"
                    class="svg-icon-class-small opacity-svg-icon c-pointer mt-1  ps-2"></span>
              </div>
              <div class="col-auto mt-2 ps-0">
                <span>
                  <span class=" span-class text-secondary text-capitalize">
                    All Auctions
                  </span>
                </span>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-4 text-md-end mt-2 mt-md-0">
            <button class="btn btn-new-class font-weight-bold" (click)="navigateToClosedAuction()"> CLOSED
              AUCTIONS</button>
          </div> -->
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 border-end mb-3">

          <div class="mb-2">
            <b>Auction Type</b>
          </div>
          <div class="col-auto align-items-center">
            <div class="form-check  mt-3 me-3">
              <input class="form-check-input checkbox-class" type="checkbox" id="flexCheckDefault1"
                [formControl]="ctrlUpcoming">
              <label class="form-check-label ms-2 span-class" for="flexCheckDefault">
                <span class="span-class"> Upcoming</span>
              </label>
            </div>
            <div class="form-check  mt-3 me-3">
              <input class="form-check-input checkbox-class" type="checkbox" id="flexCheckDefault2"
                [formControl]="ctrlRunning">
              <label class="form-check-label  ms-2 " for="flexCheckDefault">
                <span class="span-class"> Running</span>
              </label>
            </div>
            <div class="form-check  mt-3 me-3">
              <input class="form-check-input checkbox-class" type="checkbox" id="flexCheckDefault3"
                [formControl]="ctrlClosed">
              <label class="form-check-label  ms-2 " for="flexCheckDefault">
                <span class="span-class"> Closed</span>
              </label>
            </div>
            <!-- <div class="form-check  mt-3 me-3" hidden>
              <input class="form-check-input checkbox-class" type="checkbox" id="flexCheckDefault"
                [formControl]="ctrlPremium">
              <label class="form-check-label  ms-2 " for="flexCheckDefault">
                <span class="span-class"> Premium</span>
              </label>
            </div> -->
          </div>

          <hr class="hr my-3" />

          <div class="">
            <b >Search</b>
          </div>
          <div class="col-12 mt-1 text-end">
            <div class="mb-2  input-group">
              <input placeholder="Filter by Keywords" type="text" id="ctrlSearchAuction"
                class="form-control border-end-0 rounded-0 border bg-white" [formControl]="ctrlSearchAuction" />
              <span class="input-group-append">
                <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button"
                  (click)="searchAuctionCardWrapperDtos()">
                  <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
                </button>
              </span>
            </div>
            <!-- <app-search-card [selectedStatusList$]="this.selectedStatusList$"></app-search-card> -->
          </div>

          <hr class="hr my-3" />

          <div class="mb-2">
            <div class="">
              <b>Sort by</b>
            </div>
            <div class="col-12 ps-0">
              <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
                <span>
                  <button class="btn btn-sm bg-white w-100 border text-start mb-2  py-2 rounded-0 ps-3 text-capitalize" type="button"
                    ngbDropdownToggle data-bs-toggle="dropdown" aria-expanded="false">
                    <span class="select-button-font"> {{getSortByTitle(currentSortByTitle)}} Lots</span>
                  </button>
                </span>

                <ul ngbDropdownMenu class="text-start ps-2 dropdown-width">
                  <li ngbDropdownItem class="c-pointer" (click)="sortAuctionList('START_DATE_ASC')">Start Date - Ascending</li>
                  <hr class="dropdown-divider ">
                  <li ngbDropdownItem class="c-pointer" (click)="sortAuctionList('START_DATE_DESC')">Start Date - Descending</li>
                  <hr class="dropdown-divider ">
                  <li ngbDropdownItem class="c-pointer" (click)="sortAuctionList('END_DATE_ASC')">End Date - Ascending</li>
                  <hr class="dropdown-divider ">
                  <li ngbDropdownItem class="c-pointer" (click)="sortAuctionList('END_DATE_DESC')">End Date - Descending</li>
                </ul>
              </div>
            </div>
            
          </div>
        </div>

        <div class="col-lg-8 scroll-card-multilot" >
          <div class="row justify-content-center">
            <div class="col-lg-6 mb-2" *ngFor="let item of filteredAuctionCardWrapperDtos$ | async; index as i">
              <app-all-auction-card [auctionCardWrapperDto]="item" *ngIf="!isAuctionClosed(item.auctionEntityDto!)"></app-all-auction-card>
              <app-closed-auction-card [auctionCardWrapperDto]="item" *ngIf="isAuctionClosed(item.auctionEntityDto!)"></app-closed-auction-card>
            </div>
            <div class="text-center" *ngIf="(filteredAuctionCardWrapperDtos$ | async) && (filteredAuctionCardWrapperDtos$ | async)!.length == 0">
              <h5 class="heading-new text-dark">NO AUCTION AVAILABLE</h5>
            </div>
          </div>
        </div>
        <div class="mt-5 text-center" style="margin-left: 6rem !important" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>
        <div class="mt-5 text-center" style="margin-left: 6rem !important" *ngIf="((filteredAuctionCardWrapperDtos$ | async)?.length == totalAuctionPageSize) && !isLoading">
        <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5 " type="button"
                  (click)="showMore()">
                  Show More
                </button>
          </div>
      </div>
    </div>
  </div>
