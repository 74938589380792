import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserPrivilege } from 'src/app/shared/enums/UserPrivilege';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { UserEntityInfoDto } from 'src/app/shared/models/user/UserEntityInfoDto';
import { UserEntityDto } from 'src/app/shared/models/UserEntityDto';
import { FileService } from 'src/app/shared/services/file.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MobileWrapper } from 'src/app/shared/models/user/MobileWrapper';
import { EmailWrapper } from 'src/app/shared/models/user/EmailWrapper';
import { CategoryDto } from 'src/app/shared/models/CategoryDto';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { PreferenceCategoryDto } from 'src/app/shared/models/user/PreferenceCategoryDto';

@Component({
  selector: 'app-admin-bidder-details',
  templateUrl: './admin-bidder-details.component.html',
  styleUrls: ['./admin-bidder-details.component.sass']
})
export class AdminBidderDetailsComponent implements OnInit, OnDestroy {

  @Input() userId?: string;

  preferenceCategories: PreferenceCategoryDto[] = []
  userDto?: UserEntityDto | UserEntityInfoDto;

  profileImage?: FileInfoDto;

  isLoading: boolean = false;
  isUserProfileLoading: boolean = false;
  isSubmitted: boolean = false;


  selectedWrapper?: MobileWrapper | EmailWrapper;
  selectedWrapperType?: string;

  latitude?: number;
  longitude?: number;
  countryShortName?: string

  currentFileInfoDto?: FileInfoDto
  imageUrl?: string | ArrayBuffer | SafeUrl | null;




  userEntitySubscription$?: Subscription;
  userByUserIdSubscription$?: Subscription;



  zoom = 12;
  infoContent = ''
  position?: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    minZoom: 8,
  };
  marker = {
    label: { color: 'black', text: '' },
    title: '',
    options: { animation: google.maps.Animation.DROP },
    info: ''
  }

  @ViewChild(GoogleMap) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;

  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private landingAndBidderService: LandingAndBidderService
  ) {

  }

  ngOnInit(): void {

    this.userByUserIdSubscription$ = this.userService.getUserByUserId(this.userId!).subscribe(data => {
      if (data) {
        this.userDto = data.data as UserEntityInfoDto;
        this.populateUserDetails(this.userDto);
      }
    })

  }


  populateUserDetails(userDto: UserEntityDto) {

    this.profileImage = this.userDto?.profileImage;

    if (userDto.address?.latitude && userDto.address?.longitude) {
      this.latitude = Number(userDto.address?.latitude);
      this.longitude = Number(userDto.address?.longitude);

      this.position = { lat: this.latitude!, lng: this.longitude! }
      this.marker.label.text = userDto?.address?.searchAddress!;
      this.marker.title = userDto?.address?.searchAddress!;
      this.marker.info = userDto?.address?.searchAddress!;
    }

    if (this.profileImage) {
      this.loadImage();
    }


    if (userDto.preferenceCategories) {
      this.preferenceCategories = userDto.preferenceCategories;
    }
  }

  loadImage() {
    this.imageUrl = `/downloadLandingBlob?fileId=${this.profileImage?.fileId}`;
  }


  getUserRole() {
    let toReturn = '';

   if (AuctionExtUtil.checkIfRoleIsAssigned(this.userDto?.userPrivileges!, UserPrivilege.BIDDER)) {
      toReturn = 'BIDDER';
    }

    return toReturn;
  }

  closeUserDetailsModal() {
    this.userDto = null!;
    this.imageUrl = null;
    this.profileImage = null!;
    this.modalService.dismissAll();
  }

  closeModal(modalRef?: NgbModalRef) {
    modalRef?.close();
    this.isLoading = false;
  }



  openInfo(marker: MapMarker, content: string) {
    this.infoContent = content;
    this.info.open(marker)
  }

  getCountryCode(countryCode: CountryCodeDto) {
    return AuctionExtUtil.getCountryCode(countryCode);
  }



  ngOnDestroy(): void {
    if (this.userEntitySubscription$) {
      this.userEntitySubscription$.unsubscribe()
    }
    if (this.userByUserIdSubscription$) {
      this.userByUserIdSubscription$.unsubscribe()
    }
  }

}
