<div class="mt-6rem">
  <div id="stepProgressBar">
    <div class="step" [ngClass]="{'step-active' : activeStage == registrationStages.VERIFICATION}">
      <!-- (click)="toggleCurrentStage(registrationStages.VERIFICATION)"> -->
      <p class="step-text">01 Verification</p>
      <div class="bullet step-progress" [ngClass]="{'completed' : currentStageIndex > 1}">
        <div class="bullet-child" [ngClass]="{'half-filled' : currentStageIndex == 1}"></div>
      </div>
    </div>
    <div class="step" [ngClass]="{'step-active' : activeStage == registrationStages.BASIC_DETAILS}">
      <!-- (click)="toggleCurrentStage(registrationStages.BASIC_DETAILS)"> -->
      <p class="step-text">02 Basic Details</p>
      <div class="bullet step-progress" [ngClass]="{'completed' : currentStageIndex > 2}">
        <div class="bullet-child" [ngClass]="{'half-filled' : currentStageIndex == 2}"></div>
      </div>
    </div>
    <div class="step" [ngClass]="{'step-active' : activeStage == registrationStages.ADDRESS}">
      <!-- (click)="toggleCurrentStage(registrationStages.ADDRESS)"> -->
      <p class="step-text">03 Address</p>
      <div class="bullet step-progress" [ngClass]="{'completed' : currentStageIndex > 3}">
        <div class="bullet-child" [ngClass]="{'half-filled' : currentStageIndex == 3}"></div>
      </div>
    </div>
    <div class="step" [ngClass]="{'step-active' : activeStage == registrationStages.DOCUMENTS}">
      <!-- (click)="toggleCurrentStage(registrationStages.DOCUMENTS)"> -->
      <p class="step-text">04 Documents</p>
      <div class="bullet step-progress" [ngClass]="{'completed' : currentStageIndex > 4}">
        <div class="bullet-child" [ngClass]="{'half-filled' : currentStageIndex == 4}"></div>
      </div>
    </div>
    <div class="step" [ngClass]="{'step-active' : activeStage == registrationStages.PREFERENCES}">
      <!-- (click)="toggleCurrentStage(registrationStages.PREFERENCES)"> -->
      <p class="step-text">05 Preferences</p>
      <div class="bullet step-progress" [ngClass]="{'completed' : currentStageIndex > 5}">
        <div class="bullet-child" [ngClass]="{'half-filled' : currentStageIndex == 5}"></div>
      </div>
    </div>
  </div>
</div>

<ng-container [ngTemplateOutlet]="activeStage == registrationStages.VERIFICATION ? verificationPage
  : activeStage == registrationStages.BASIC_DETAILS ? basicDetailsPage
  : activeStage == registrationStages.ADDRESS ? addressPage
  : activeStage == registrationStages.DOCUMENTS ? documentsPage
  : activeStage == registrationStages.PREFERENCES ? preferencesPage
  : emptyTemplate">
</ng-container>

<ng-template #verificationPage>
  <app-user-verification></app-user-verification>
</ng-template>

<ng-template #basicDetailsPage>
  <app-user-basic-details></app-user-basic-details>
</ng-template>

<ng-template #addressPage>
  <app-user-address></app-user-address>
</ng-template>

<ng-template #documentsPage>
  <app-user-documents></app-user-documents>
</ng-template>

<ng-template #preferencesPage>
  <app-user-preferences></app-user-preferences>
</ng-template>

<ng-template #emptyTemplate></ng-template>

<div class="position-relative mt-5">
  <div class=" text-center col-12">
    <a class="link-class me-3" *ngIf="isShowSkipButton" (click)="nextStep()">Skip</a>
    <button class="btn btn-primary-clr-class btn-sm me-3" *ngIf="activeStage != registrationStages.VERIFICATION"
      [disabled]="isDisabledPrevButton" (click)="prevStep()">Prev</button>
    <button class="btn btn-primary-clr-class btn-sm me-3" *ngIf="activeStage != registrationStages.PREFERENCES"
      [disabled]="isDisabledNextButton" (click)="nextStep()">Next</button>

      <button class="btn btn-grey-clr-class btn-sm me-3 mt-3 mt-md-0" *ngIf="activeStage == registrationStages.PREFERENCES" (click)="redirectToHomePage()">Go to Dashboard</button>
  </div>
</div>
