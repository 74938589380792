<div class="p-2">
  <div class="col-12">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>Privacy Policy</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
    </div>
  </div>
  <div class="page-divider"></div>
</div>
<div class="modal-body">
  <div class="scroll-container">
    <div #privacyHeader></div>
    <div class="m-3" style="white-space: normal;">
      At AuctionExt, we respect your privacy and are committed to protecting your personal information. This Privacy
      Policy explains how we collect, use, and disclose the information we receive from you when you use our online
      auction platform.
      <br><br>
      <b> Information we collect:</b>
      <br><br>

      We collect personal information from you when you register an account, participate in an auction, make a
      purchase,
      or communicate with us. This information may include your name, email address, phone number, mailing address,
      payment information, and other relevant details.
      <br><br>
      <b>How we use your information:</b>
      <br><br>
      We use your personal information to provide and improve our services, process payments, and communicate with you
      about auctions, products, and services that may be of interest to you. We may also use your information for
      legal
      and regulatory compliance purposes, such as fraud prevention, tax reporting, and record-keeping.
      <br><br>
      <b> Sharing your information:</b>
      <br><br>
      We do not sell or rent your personal information to third parties for their marketing purposes. However, we may
      share your information with our trusted service providers, such as payment processors, shipping providers, and
      marketing partners, to provide you with our services. We may also disclose your information in response to legal
      requests or if required by law.
      <br><br>
      <b>Security:</b>
      <br><br>
      We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure.
      We
      use industry-standard security technologies, such as SSL encryption, to protect your information during
      transmission and storage. However, no method of transmission over the Internet or electronic storage is 100%
      secure, so we cannot guarantee absolute security.
      <br><br>
      <b> Your choices:</b>
      <br><br>
      You can choose to opt-out of receiving marketing communications from us by following the instructions provided
      in
      our emails. You can also update your account information by logging into your account or delete by contacting us
      at
      <span class="text-primary"><b class="b-class btn-link"><a
            href="mailto:support@auctionext.com">support@auctionext.com</a></b></span>.
      <br><br>
      <b>Changes to this policy:</b>
      <br><br>
      We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We
      will post the revised policy on our website, and the changes will be effective immediately upon posting.
      <br><br>
      <b> Contact us:</b>
      <br><br>
      If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at

      <span class="text-primary"><b class="b-class btn-link"><a
            href="mailto:support@auctionext.com">support@auctionext.com</a></b></span>.
    </div>
    <button class="btn btn-sm btn-outline-primary p-3 floating-button me-3" (click)="moveToTop(privacyHeader)">
      <img src="assets/icons/arrowupward.svg" class="svg-icon-class c-pointer">
    </button>
  </div>
</div>