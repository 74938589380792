<div>
  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'DASHBOARD_PAGE'">
    <span class="c-pointer">Home</span>
    <span class="mx-2">|</span>
    <span>Dashboard</span>
  </div>
  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'LIVE_PROJECTS_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>Live Projects</span>
  </div>
  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'PAST_PROJECTS_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>Past Projects</span>
  </div>
  <div class=" p-2 my-2 medium-font" *ngIf="pageName == 'PO_AND_INVOICE_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>PO and Invoice</span>
  </div>
  <div class=" p-2 my-2 medium-font" *ngIf="pageName == 'ANALYTICS_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>Analytics</span>
  </div>
  <div class=" p-2 my-2 medium-font" *ngIf="pageName == 'PURCHASE_ORDER_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>Purchase Order</span>
  </div>
  <div class=" p-2 my-2 medium-font" *ngIf="pageName == 'INVOICE_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>Invoice</span>
  </div>
  <div class=" p-2 my-2 medium-font" *ngIf="pageName == 'REPORTING_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>Reporting</span>
  </div>
  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'SETTING_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>Settings</span>
  </div>
  
  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'MY_REGISTRATIONS_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>My Registrations</span>
  </div>
  
  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'MY_BIDS_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>My Bids</span>
  </div>
  
  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'MY_FAVOURITES_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>My Favourites</span>
  </div>

  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'MY_TEAM_MEMBERS'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>My Teams</span>
  </div>

  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'QUESTIONNAIRE_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="redirectToHomePage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span class="c-pointer enable-breadcrumb" (click)="redirectToHomePage('LIVE_PROJECTS_PAGE')">Sourcing</span>
    <span class="mx-2">|</span>
    <span>Auction</span>
    <span class="mx-2">|</span>
    <span>#{{selectedAuction?.sequenceNo}} {{selectedAuction?.auctionName}}</span>
  </div>

  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'RFX_SUBCATEGORY_LIST'">
    <span class="c-pointer enable-breadcrumb" (click)="redirectToHomePage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span class="c-pointer enable-breadcrumb" (click)="redirectToHomePage('LIVE_PROJECTS_PAGE')">Sourcing</span>
    <span class="mx-2">|</span>
    <span>Rfx</span>
    <span class="mx-2">|</span>
    <span>#{{selectedRfx?.sequenceNo}} {{selectedRfx?.rfxTitle}}</span>
  </div>

  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'RFX_SUBCATEGORY_DETAILS' && isSingleActivity">
    <span class="c-pointer enable-breadcrumb" (click)="redirectToHomePage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span class="c-pointer enable-breadcrumb" (click)="redirectToHomePage('LIVE_PROJECTS_PAGE')">Sourcing</span>
    <span class="mx-2">|</span>
    <span>Rfx</span>
    <span class="mx-2">|</span>
    <span>#{{selectedRfx?.sequenceNo}} {{selectedRfx?.rfxTitle}}</span>
    <span class="mx-2">|</span>
    <span>#{{selectedRfxSubcategory?.subcategorySequence}} {{selectedRfxSubcategory?.title}}</span>
  </div>

  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'RFX_SUBCATEGORY_DETAILS' && !isSingleActivity">
    <span class="c-pointer enable-breadcrumb" (click)="redirectToHomePage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span class="c-pointer enable-breadcrumb" (click)="redirectToHomePage('LIVE_PROJECTS_PAGE')">Sourcing</span>
    <span class="mx-2">|</span>
    <span>Rfx</span>
    <span class="mx-2">|</span>
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('RFX_SUBCATEGORY_LIST')">
      #{{selectedRfx?.sequenceNo}} {{selectedRfx?.rfxTitle}}
    </span>
    <span class="mx-2">|</span>
    <span>#{{selectedRfxSubcategory?.subcategorySequence}} {{selectedRfxSubcategory?.title}}</span>
  </div>

  <div class="breadcrum-divider my-1"></div>
</div>