<div class="position-relative">
  <button class=" d-none d-md-block" *ngIf="isShowCarouselNavigation()"
    [ngClass]="isMultiLot? 'scroll-left-icon-small carousel-left-button-small' : 'scroll-left-icon carousel-left-button'"
    (click)="onNavigationPrev($event)">
    <img src="assets/icons/leftarrow_dark.svg" class="svg-icon-class c-pointer">
  </button>


  <swiper #swiperCarousel [virtual]="true" [config]="swiperConfig" (slideChange)="onSlideChange()"
    *ngIf="isShowSwiper && (!auctionLotEntityDto?.lotImages || !(auctionLotEntityDto?.lotImages && auctionLotEntityDto?.lotImages!.length == 0))">

    <ng-template swiperSlide *ngFor="let item of auctionLotEntityDto?.lotImages; index as i">
      <div class="card border-0 image-carousal-color rounded-0">
        <img src="assets/images/sold.png" class="lot-status" *ngIf="auctionLotEntityDto?.lotAllocated">
        <!-- <img src="assets/images/unsold.svg" class="lot-status"> -->
        <img src="/downloadLandingBlob?fileId={{item.fileId}}" [alt]="auctionLotEntityDto?.title" [ngClass]="{'img-portrait': item.dataType == 'Portrait', 'image-who-we-are' : isMultiLot,
            'image-for-single-lot' : !isMultiLot && !isGalleryView, 'image-for-gallery' : isGalleryView}"
          *ngIf="!isValidUrl(item.fileId!)">
        <video #videoCarousel [src]="item.fileId" *ngIf="isValidUrl(item.fileId!) && item.fileType! != 'embed'"
          [muted]="true" controls></video>
        <iframe #iframeCarousel [src]="item.fileId! | safe: 'url'"
          [ngStyle]="{'height':  isGalleryView ? '92vh' : !isMultiLot && !isGalleryView ? '608px' : '170px'}"
          *ngIf="isValidUrl(item.fileId!) && item.fileType! == 'embed'"></iframe>
      </div>
    </ng-template>

  </swiper>

  <button class=" d-none d-md-block " *ngIf="isShowCarouselNavigation()"
    [ngClass]="isMultiLot? 'scroll-right-icon-small carousel-right-button-small' : 'scroll-right-icon carousel-right-button'"
    (click)="onNavigationNext($event)">
    <img src="assets/icons/rightarrow_dark.svg" class="svg-icon-class c-pointer">
  </button>
</div>