import { BehaviorSubject } from 'rxjs';
import { Component, OnInit, Input, AfterViewInit, AfterContentInit } from '@angular/core';

import { FormControl } from '@angular/forms';
import { InvoiceWrapper } from 'src/app/shared/models/user/InvoiceWrapper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from 'src/app/shared/services/file.service';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { LotInfoDto } from 'src/app/shared/models/user/LotInfoDto';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';

@Component({
  selector: 'app-my-generated-invoice',
  templateUrl: './my-generated-invoice.component.html',
  styleUrls: ['./my-generated-invoice.component.sass']
})
export class MyGeneratedInvoiceComponent implements  OnInit, AfterViewInit {

  @Input() selectedAuctionHouseId?: string;
  @Input() selectedUserId?: string;

  invoiceList$ = new BehaviorSubject<InvoiceWrapper[]>([]);
  invoiceList: InvoiceWrapper[] =  [];
  currentIndex?: number;
  page: number = 1
  pageSize: number = 50
  ctrlSearch: FormControl = new FormControl('');

  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _errorMsg$  = new BehaviorSubject<string>("");
  isLoading: boolean = true;
  isPaymentLoading: boolean = false;
  selectedStatus?: string;
  lotIds: (string| undefined)[] = [];
  constructor(
    private ngbModal: NgbModal,
    private fileService: FileService,
    private bidderService: LandingAndBidderService,
  ) { }


  ngOnInit(): void {
    this.ctrlSearch.valueChanges.subscribe(() => {
      this.search()
    })
  }


  ngAfterViewInit(): void {
    this.loadInvoiceData();
  }


  loadInvoiceData(){
    this.isLoading = true;
    this.invoiceList = [];
    this.paginateList();

    this.bidderService.getGeneratedInvoiceList(this.selectedAuctionHouseId!, this.selectedUserId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.invoiceList = apiResponseDto.data! as Array<InvoiceWrapper>;
        this.isLoading = false;
        this.paginateList()
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
      }
    })
  }

  search(){
    let searchText = this.ctrlSearch.value;
    if(searchText.trim() != ''){
      let tempInvoiceList = [...this.invoiceList];
      tempInvoiceList = tempInvoiceList.filter(item => item.auctionName?.toLowerCase().includes(searchText.toLowerCase()) || this.getStatus(item.status!)?.toLowerCase().includes(searchText.toLowerCase())
      || item.lotInfoDtos?.some(item1 => item1.lotName?.toLocaleLowerCase().includes(searchText.toLowerCase())) || item.lotInfoDtos?.some(item1 => item1.lotSequenceNo == (searchText))
      )

      this.invoiceList$.next(tempInvoiceList);
    }else{
      this.invoiceList$.next(this.invoiceList);
    }
  }

  changePageSize(size: number) {
      this.pageSize = size;
  }

  paginateList() {
    if (this.invoiceList && this.invoiceList.length > 0) {
      let temp = [...this.invoiceList];

      this.invoiceList$.next(temp.map((history, i) => ({ id: i + 1, ...history })).slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize,
      ));
    } else {
      this.invoiceList$.next([]);
    }
  }

  downloadInvoice(fileId: string) {
    this.fileService.downloadFile(fileId!).subscribe(data => {
      if (data) {
        let file = new Blob([data], { type: 'application/pdf'});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(file);;
        link.download = "invoice.pdf"; // Set the desired filename
        link.click();
      }
    })
  }

  showLotsMore(index: number) {
    this.currentIndex = index;
  }
  openConfirmationModel(modelRef: any, status: string, lotInfoDtos: Array<LotInfoDto>){
    this.selectedStatus = status;
    this.lotIds = lotInfoDtos.map(item => item.lotId);
    this.ngbModal.open(modelRef, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    })
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }


  getStatus(status: string){
    if(status == "PENDING"){
      return "PENDING"
    }else if(status == "PAID"){
      return "PAID"
    }else if(status == "PARTIALLY_PAID"){
      return "PARTIALLY PAID"
    }else if(status == "NO_SHOW"){
      return "NO SHOW"
    }
    return null;
  }


}
