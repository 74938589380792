import { UserAuctionEligibleStatus } from "../../enums/UserAuctionEligibleStatus";

export class UserAuctionRegistrationDto {
  auctionId?: string;
  userId?: string;
  companyId?: string;
  emailId?: string;
  name?: string;
  paddleNo?: string;
  signatureName?: string;
  userAuctionRegistrationStatus?: UserAuctionEligibleStatus;
  registrationTimeInternal?: number;
  registrationTimeStr?: string;
  auctionHouseId?: string;
  updateTimeUtc?: string
  updateTimeUtcMillis?: number;
}
