import { AdminLotsDataHolderService } from 'src/app/shared/services/adminLotsDataHolderService.service';
import { AdminAuctionDataHolderService } from 'src/app/shared/services/AdminAuctionDataHolder.service';
import { AuctionEntityDto } from '../models/user/AuctionEntityDto';
import { Injectable } from "@angular/core";
import { AuctionLotEntityDto } from '../models/user/AuctionLotEntityDto';
import { AuctionValidationErrorCodeDto } from '../models/user/AuctionValidationErrorCodeDto';
import { AuctionExtUtil } from '../util/AuctionExtUtil';
import { QuestionnaireService } from './questionnaire.service';

@Injectable({
  providedIn: 'root'
})
export class AuctionValidationService {


  constructor(
    private auctionService: AdminAuctionDataHolderService,
    private auctionLotService: AdminLotsDataHolderService,
    private questionnaireService: QuestionnaireService
  ) { }

  /**
   * check Auction And Lot validations
   */
  doAuctionAndLotValidation() {
    let errorDtoList: AuctionValidationErrorCodeDto[] = [];
    let auction = this.auctionService.getSelectedAuction();
    let allLots = this.auctionLotService._allLots$.value;

    // run validation for auction
    this.doAuctionValidation(errorDtoList!, auction!);

    //run validation for all lots
    if (allLots && allLots.length > 0) {
      allLots.forEach(lot => {
        if(!lot.active) {
          this.doAuctionLotValidation(errorDtoList!, auction!, lot);
        }
      });
    }

    // run validation for questionnaire
    let haveQuestionnaireValidationIssues = this.questionnaireService.haveQuestionnaireValidationIssues();

    if (haveQuestionnaireValidationIssues) {
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "QUESTIONNAIRE-ERROR-101";
      codeDto.type = "QUESTIONNAIRE";
      codeDto.message = "The Questionnaire Section requires your attention. Please resolve the issues to continue.";
      errorDtoList.push(codeDto);
    }

    return errorDtoList!;
  }

  doValidationForActiveAuctionAndLots(auction: AuctionEntityDto) {
    let errorDtoList: AuctionValidationErrorCodeDto[] = [];
    let allLots = this.auctionLotService._allLots$.value;

    // run validation for auction
    this.doAuctionValidation(errorDtoList!, auction!);

    //run validation for all lots
    if (allLots && allLots.length > 0) {
      allLots.forEach(lot => {
        let tempLotEntityDto = AuctionExtUtil.clone(lot);
        if(tempLotEntityDto.active) {
          tempLotEntityDto.lotType = auction.auctionType;
          this.doAuctionLotValidation(errorDtoList!, auction!, tempLotEntityDto);
        }
      });
    }
    return errorDtoList!;
  }

  /**
   * Auction Validation
   * @param errorDtoList
   * @param auction
   */
  doAuctionValidation(errorDtoList: AuctionValidationErrorCodeDto[], auction: AuctionEntityDto,) {

    // check auction name empty
    if (!auction.auctionName) {
      //AUCTION_NAME_NOT_FOUND_EXCEPTION
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-101";
      codeDto.type = "AUCTION";
      codeDto.message = "Auction Name is missing";
      errorDtoList.push(codeDto);
    }

    // check timezone empty
    if (!auction.timeZone) {
      //TIMEZONE_NOT_FOUND_EXCEPTION
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-102";
      codeDto.type = "AUCTION";
      codeDto.message = "Timezone is missing"
      errorDtoList.push(codeDto);
    }

    // check timezone empty
    if (!auction.auctionBiddingMethod) {
      //TIMEZONE_NOT_FOUND_EXCEPTION
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-146";
      codeDto.type = "AUCTION";
      codeDto.message = "AuctionBiddingMethod is missing"
      errorDtoList.push(codeDto);
    }

    // check start is less than end date
    if (!auction.startDate) {
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-103";
      codeDto.type = "AUCTION";
      codeDto.message = "Auction Start Date is Empty";
      errorDtoList.push(codeDto);
      errorDtoList.push(codeDto);
    }

    // check currency empty
    if (!auction.currency) {
      //ErrorCode.CURRENCY_NOT_FOUND_EXCEPTION
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-104";
      codeDto.type = "AUCTION";
      codeDto.message = "Currency is missing.";
      errorDtoList.push(codeDto);
    }

    // check bidder approval is empty
    if (!auction.bidderApproval) {
      //ErrorCode.BIDDER_APPROVAL_NOT_FOUND_EXCEPTION
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-105";
      codeDto.type = "AUCTION";
      codeDto.message = "Bidder approval is missing.";
      errorDtoList.push(codeDto);
    }

    // check listing contact empty
    if (!auction.listingContactDtos || !(auction.listingContactDtos.length > 0)) {
      //ErrorCode.LISTING_CONTACT_NOT_FOUND_EXCEPTION
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-106";
      codeDto.type = "AUCTION";
      codeDto.message = "There must be a listing contact for an active auction.";
      errorDtoList.push(codeDto);
    }

    // check listing contact empty
    if (auction.notOfLots! < 1) {
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-108";
      codeDto.type = "AUCTION";
      codeDto.message = "No Lot found.";
      errorDtoList.push(codeDto);
    }

    // check listing contact empty
    if (!auction.auctionImage) {
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-109";
      codeDto.type = "AUCTION";
      codeDto.message = "Auction Image is missing.";
      errorDtoList.push(codeDto);
    }

  }

  /**
   * Auction Lot Validation
   * @param errorDtoList
   * @param auction
   * @param auctionLot
   */
  doAuctionLotValidation(errorDtoList: AuctionValidationErrorCodeDto[], auction: AuctionEntityDto, auctionLot: AuctionLotEntityDto) {

    // check lot name empty
    if (!auctionLot.title) {
      //ErrorCode.LOT_NAME_NOT_FOUND_EXCEPTION);
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-122";
      codeDto.type = "LOT";
      codeDto.lotId = auctionLot.lotId;
      codeDto.lotName = auctionLot.title;
      codeDto.lotSequence = auctionLot.lotSequence?.toString();
      codeDto.message = "Lot Name is missing.";
      errorDtoList.push(codeDto);
    }

    // check end date
    if (!auctionLot.endDate) {
      //ErrorCode.END_DATE_NOT_FOUND_EXCEPTION);
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-123";
      codeDto.lotId = auctionLot.lotId;
      codeDto.lotName = auctionLot.title;
      codeDto.lotSequence = auctionLot.lotSequence?.toString();
      codeDto.type = "LOT";
      codeDto.message = "End Date is missing.";
      errorDtoList.push(codeDto);
    }

    // check end time
    if (!auctionLot.endTime) {
      //ErrorCode.END_TIME_NOT_FOUND_EXCEPTION);
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-124";
      codeDto.lotId = auctionLot.lotId;
      codeDto.lotName = auctionLot.title;
      codeDto.lotSequence = auctionLot.lotSequence?.toString();
      codeDto.type = "LOT";
      codeDto.message = "End Time is missing.";
      errorDtoList.push(codeDto);
    }

    if (auctionLot.endDate && auctionLot.endTime && this.haveAnyErrorInEndDate(auction, auctionLot)) {
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-142";
      codeDto.lotId = auctionLot.lotId;
      codeDto.lotName = auctionLot.title;
      codeDto.lotSequence = auctionLot.lotSequence?.toString();
      codeDto.type = "LOT";
      codeDto.message = "The lot end date/time must be after the current date/time or start date/time";
      errorDtoList.push(codeDto);
    }

    // check category empty
    if (!auctionLot.categorySubcategoryId) {
      //ErrorCode.CATEGORY_NOT_FOUND_EXCEPTION);
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-125";
      codeDto.lotId = auctionLot.lotId;
      codeDto.lotName = auctionLot.title;
      codeDto.lotSequence = auctionLot.lotSequence?.toString();
      codeDto.type = "LOT";
      codeDto.message = "Category is missing.";
      errorDtoList.push(codeDto);
    }



    // check starting bid empty
    if (!auctionLot.startingBid) {
      //ErrorCode.STARTING_BID_NOT_FOUND_EXCEPTION);
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-126";
      codeDto.lotId = auctionLot.lotId;
      codeDto.lotName = auctionLot.title;
      codeDto.lotSequence = auctionLot.lotSequence?.toString();
      codeDto.type = "LOT";
      codeDto.message = "Starting Price is missing.";
      errorDtoList.push(codeDto);
    }

    // check reserve price empty
    if ((auctionLot.lotType == "Reserve") &&
      (!auctionLot.reservePrice)) {
      //ErrorCode.RESERVE_BID_NOT_FOUND_EXCEPTION);
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-127";
      codeDto.lotId = auctionLot.lotId;
      codeDto.lotName = auctionLot.title;
      codeDto.lotSequence = auctionLot.lotSequence?.toString();
      codeDto.type = "LOT";
      codeDto.message = "Reserve Price is missing.";
      errorDtoList.push(codeDto);
    }

    // check reserve bid value is greater than starting bid value
    if (auctionLot.lotType == "Reserve") {
      if (Number(auctionLot.reservePrice!) <= Number(auctionLot.startingBid!)) {
        //"Reserve bid price: {} is greater than starting bid value: {}", auction.getReserveBidValue(), auction.getStartingBidValue());
        let codeDto = new AuctionValidationErrorCodeDto();
        codeDto.code = "AUCTION-ERROR-128";
        codeDto.lotId = auctionLot.lotId;
        codeDto.lotName = auctionLot.title;
        codeDto.lotSequence = auctionLot.lotSequence?.toString();
        codeDto.type = "LOT";
        codeDto.message = "Reserve Price should be more than the Starting Price.";
        codeDto.param1 = auctionLot.reservePrice?.toString();
        codeDto.param2 = auctionLot.startingBid?.toString();
        errorDtoList.push(codeDto);
      }
    }

    // single bid increment
    if ((auctionLot?.incrementalWrapperDto?.incrementalBidType, "SINGLE") && (!auctionLot?.incrementalWrapperDto?.constantIncrement == null)) {
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-129";
      codeDto.lotId = auctionLot.lotId;
      codeDto.lotName = auctionLot.title;
      codeDto.lotSequence = auctionLot.lotSequence?.toString();
      codeDto.type = "LOT";
      codeDto.message = "Increment amount is missing.";
      errorDtoList.push(codeDto);
    }

    // multiple bid increment
    if ((auctionLot?.incrementalWrapperDto?.incrementalBidType == "MULTIPLE") &&
      (!auctionLot.incrementalWrapperDto.bidIncrementalDtoList)) {
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-130";
      codeDto.lotId = auctionLot.lotId;
      codeDto.lotName = auctionLot.title;
      codeDto.lotSequence = auctionLot.lotSequence?.toString();
      codeDto.type = "LOT";
      codeDto.message = "Increment amount is missing.";
      errorDtoList.push(codeDto);
    }

    // check auction images empty
    if (!auctionLot.lotImages || auctionLot.lotImages.length == 0) {
      //ErrorCode.AUCTION_IMAGES_NOT_FOUND_EXCEPTION);
      let codeDto = new AuctionValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-135";
      codeDto.lotId = auctionLot.lotId;
      codeDto.lotName = auctionLot.title;
      codeDto.lotSequence = auctionLot.lotSequence?.toString();
      codeDto.type = "LOT";
      codeDto.message = "Lot images is missing";
      errorDtoList.push(codeDto);
    }


  }

  haveAuctionDetailsErrors(auction: AuctionEntityDto) {
    if (!auction?.auctionName || !auction?.timeZone
      || !auction?.startDate || !auction?.startTime
      || !auction?.currency || !auction?.bidderApproval
    ) {
      return true;
    }
    return false;
  }

  haveLotDetailsErrors(auctionEntityDto: AuctionEntityDto, auctionLot: AuctionLotEntityDto) {
    if (!auctionLot?.title || !auctionLot?.endDate || !auctionLot?.endTime || this.haveAnyErrorInEndDate(auctionEntityDto, auctionLot)) {
      return true;
    }
    return false;
  }

  haveBidSettingErrors(auction: AuctionEntityDto, auctionLot: AuctionLotEntityDto) {
    if (!auctionLot?.startingBid) {
      return true;
    }
    if (auctionLot.lotType == 'Reserve' && (!auctionLot.reservePrice || auctionLot.reservePrice  == 0)) {
      return true;
    }
    return false;
  }

  haveLotCategoryErrors(auctionLot: AuctionLotEntityDto) {
    if (!auctionLot?.categorySubcategoryId) {
      return true;
    }
    return false;
  }

  haveAnyErrorInEndDate(auctionEntityDto: AuctionEntityDto, auctionLot: AuctionLotEntityDto) {
    if(auctionLot.active){
      return false;
    }

    if (auctionLot.endDate && auctionLot.endTime) {
      let endDate = auctionLot.endDate;
      let endTime = auctionLot.endTime
      if (endDate && endTime) {
        let _sDate = AuctionExtUtil.convertedDate(auctionEntityDto?.startDate!, auctionEntityDto?.startTime!);
        let _eDate = AuctionExtUtil.convertedDate(endDate, endTime);

        let currentDate = new Date();
        if (_sDate && _eDate && (_sDate >= _eDate || currentDate >= _eDate)) {
          return true;
        }
      }
      return false;
    }
    return true;
  }


  haveAnyLotErrors(auction: AuctionEntityDto, auctionLot: AuctionLotEntityDto) {
    if (this.haveLotDetailsErrors(auction, auctionLot) || this.haveBidSettingErrors(auction, auctionLot)
    || this.haveLotCategoryErrors(auctionLot) || !auctionLot.lotImages) {

      return true;
    }
    return false;
  }

  /**
   *  Check Lots Errors
   */

}
