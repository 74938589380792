<div class="">
  <div class="border rounded bg-white" [ngClass]="{'border-danger' : haveQuestionnaireValidationIssues()}">
    <header class="p-2">
      <div class="row ">
        <div class="col-md-6">
          <h5 class="heading-h5">QUESTIONNAIRE</h5>
        </div>
        <div class="col-md-6 text-md-end">
          <button class="btn btn-new-class btn-sm px-4 me-2" (click)="openPublishConfirmModal(publishConfirmModal)" *ngIf="isShowPublishButton">
            PUBLISH
          </button>
          <button class="btn btn-new-class btn-sm px-4 " (click)="openAddQuestionnaireModal(addQuestionnaireModal)">
            <!-- <img src="assets/icons/edit_dark.svg" class="svg-icon-class c-pointer"> -->
            {{questionnaireTemplate ? 'EDIT' : 'ADD'}}
          </button>
        </div>
      </div>
    </header>
    <hr class="hr" />
    <div class="col-12">
      <div class="row mx-0">
        <div class="col-12 div-class py-2">
          Create bidder criteria for the opportunity
        </div>
        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2">
          Scoring
        </div>
        <div class="col-8 div-class py-2">
          {{questionnaireTemplate?.scoringTemplate? 'Yes' : 'No'}}
        </div>
        <hr class="hr" *ngIf="questionnaireTemplate?.scoringTemplate" />
        <div class="col-4 font-weight-normal py-2" *ngIf="questionnaireTemplate?.scoringTemplate">
          Maximum Score
        </div>
        <div class="col-8 div-class py-2" *ngIf="questionnaireTemplate?.scoringTemplate">
          {{questionnaireTemplate?.maximumScore}}
        </div>
        <hr class="hr" *ngIf="questionnaireTemplate?.scoringTemplate" />
        <div class="col-4 font-weight-normal py-2" *ngIf="questionnaireTemplate?.scoringTemplate">
          Qualifying Score
        </div>
        <div class="col-8 div-class py-2" *ngIf="questionnaireTemplate?.scoringTemplate">
          {{questionnaireTemplate?.qualifyingScore}}
        </div> 
        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2">
         Guidelines for bidders
        </div>
        <div class="col-8 div-class py-2">
          <ul class="animate-show-more ps-0" #guidelineRef
            [ngClass]="{'hide-guideline': !isShowMoreGuideline && guidelineRef.scrollHeight > 120 && questionnaireTemplate?.guideLines}">
            <li class="c-default" *ngFor="let item of guidelinesList; index as i;">
              {{item}}
            </li>
          </ul>
          <hr class="hr" *ngIf="isShowMoreGuideline" />
          <div class="col-12 text-end btn-link pb-2" (click)="showMoreGuidelineModal()" *ngIf="guidelineRef.scrollHeight > 90">
            {{isShowMoreGuideline ? 'Hide More' : 'See More'}}
          </div>
        </div> 
        <hr class="hr mb-3" />  
        <div class="col-12 table-responsive">
          <table class="table table-bordered medium-font">
            <thead class="table-secondary">
              <tr>
                <th scope="col">S No</th>
                <th scope="col">Section</th>
                <th scope="col">Total Questions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="!sectionTemplateList || sectionTemplateList.length == 0">
                <td colspan="3" class="text-center">
                  <span>No questions are available.</span>
                </td>
              </tr>
              <tr *ngFor="let item of sectionTemplateList">
                <td>{{item.sequenceNo}}</td>
                <td>{{item?.sectionName}}</td>
                <td>{{getQuestionsCountOfSection(item?.sectionId!)}}</td>
                
              </tr>
            </tbody>
          </table>
        </div>     
      </div>
    </div>
  </div>
</div>


<ng-template #addQuestionnaireModal>
<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>Questionnaire</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3">
    <form  [formGroup]="formGroupQuestionnaire">
      <p class="medium-font">
        Create bidder criteria for the opportunity
      </p>
      <hr class="hr my-1" />
      <div class="row my-2">
        <div class="col-8 col-md-10">
          <span class="me-3 medium-font">Scoring</span>
          <span class="form-switch c-pointer">
            <input class="form-check-input " type="checkbox" id="flexSwitchCheckChecked" formControlName="scoringTemplate"/>
          </span>
        </div>
        <div class="col-2 col-md-1 text-end pe-0">
          <a class="link-class c-pointer" (click)="saveQuestionnaire()" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            Save
          </a>
 
        </div>
        <div class="col-2 col-md-1 text-end" *ngIf="questionnaireTemplate?.templateId">
          <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer"
            (click)="openDeleteConfirmModal(deleteConfirmModal)">
        </div>
      </div>
      <div class="row">
        <span class="small-font" *ngIf="!fc.scoringTemplate.value">
          There is no scoring to this sourcing event
        </span>
        <div class="col-md-6" *ngIf="fc.scoringTemplate.value">
          <div class="input-group">
            <div class="form-floating">
              <input type="email" class="form-control" id="maximumScore" formControlName="maximumScore"
               />
              <label for="maximumScore">Maximum Score</label>
            </div>
            <!-- <span class="input-group-text bg-white border-start-0">
              <a class="link-class-small" (click)="saveQuestionnaire()">Save</a>
            </span> -->
          </div>
          <div *ngIf="fc.maximumScore.invalid && (fc.maximumScore.dirty || fc.maximumScore.touched)">
            <p class="text-danger" *ngIf="fc.maximumScore.errors?.['required']">Required</p>
            <p class="text-danger" *ngIf="fc.maximumScore.errors?.['pattern']">Invalid</p>
          </div>
        </div>
        <div class="col-md-6" *ngIf="fc.scoringTemplate.value">
          <div class="input-group">
            <div class="form-floating">
              <input type="email" class="form-control" id="qualifyingScore" formControlName="qualifyingScore"
               />
              <label for="qualifyingScore">Qualifying Score</label>
            </div>
            <!-- <span class="input-group-text bg-white border-start-0">
              <a class="link-class-small" (click)="saveQuestionnaire()">Save</a>
            </span> -->
          </div>
          <div *ngIf="fc.qualifyingScore.invalid && (fc.qualifyingScore.dirty || fc.qualifyingScore.touched)">
            <p class="text-danger" *ngIf="fc.qualifyingScore.errors?.['required']">Required</p>
            <p class="text-danger" *ngIf="fc.qualifyingScore.errors?.['pattern']">Invalid</p>
          </div>
        </div>
      </div>

      <hr class="hr mt-2" />
      <!-- <div class="my-2">
        <div class="mb-2 medium-font">Select Type of envelop</div>
        <div class="mb-2">
          <button class="btn-selection btn me-3 btn-sm" (click)="onSelectEnvelope(item)"
            [ngClass]="selectedEnvelopesList.includes(item) ? 'active': ''" *ngFor="let item of defaultEnvelopesList;">
            {{item | titlecase}}
            <img src="assets/icons/close_dark.svg" class="svg-icon-class-small"
              *ngIf="selectedEnvelopesList.includes(item)">
          </button>
        </div>
      </div>
      <hr class="hr my-1" /> -->
      <div class="row mt-2">
        <div class="col-8">
          <h6 class="heading-small">
            Guidelines for bidders
          </h6>
        </div>
        <div class="col-4 text-end">
          <a class="link-class" (click)="openEditGuidelineModal(guidelineModal)">
            Edit
          </a>
        </div>
      </div>
      <div>
        <ol class="mb-1" #guidelineRef
        [ngClass]="{'hide-guideline': !isShowMoreGuideline && guidelineRef.scrollHeight > 120 && fc['guideLines'].value}">
          <li class="medium-font c-default" *ngFor="let item of guidelinesList; index as i;">
            {{item}}
          </li>
        </ol>
        <hr class="hr" *ngIf="isShowMoreGuideline" />
        <span class="float-end" *ngIf="guidelineRef.scrollHeight > 90">
          <a class="link-class" (click)="showMoreGuidelineModal()">
            {{isShowMoreGuideline ? 'Hide More' : 'See More'}}
          </a>
        </span> 
      <div class="my-1">
        <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="errorMsg"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false" [message]="'Questionnaire saved successfully.'"></app-message-toast>
      </div>

      <div class="d-grid gap-2 my-1" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>
      </div>
      <hr class="hr my-1" />
      <div class="row ">
        <div class="col-md-8 mb-2">
          <!-- <h6 class="heading-small">
            Envelope
            <span class="ms-3 badge small-badge-grey">
              Draft
            </span>
          </h6> -->
        </div>
        <div class="col-md-4 text-md-end mb-2">
          <a class="link-class" (click)="openAddTechSectionModal()">
            Add Question
          </a>
        </div>
      </div>
      <div>
        <div class="col-12 table-responsive">
          <table class="table table-bordered medium-font">
            <thead class="table-secondary">
              <tr>
                <th scope="col">S No</th>
                <th scope="col">Section</th>
                <th scope="col">Total Questions</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of sectionTemplateList">
                <td>{{item?.sequenceNo}}</td>
                <td>{{item?.sectionName}}</td>
                <td>{{getQuestionsCountOfSection(item?.sectionId!)}}</td>
                <td>
                  <a class="link-class" (click)="openEditTechSectionModal(item?.sectionId!)">Edit</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <div class="medium-font text-center" *ngIf="!sectionTemplateList || sectionTemplateList!.length == 0">
          No question is available.
        </div>
      </div>
      <!-- <hr class="hr my-1" /> -->
      <!-- <div class="row">
        <div class="col-md-8 mb-2">
          <h6 class="heading-small">
            Financial Envelope
            <span class="ms-3 badge small-badge-grey">
              Draft
            </span>
          </h6>
        </div>
        <div class="col-md-4 text-md-end mb-2">
          <a class="link-class" (click)="openAddFinSectionModal()">
            Add Items
          </a>
        </div>
      </div> -->
      <!-- <div>
        <div class="col-12 table-responsive">
          <table class="table table-bordered medium-font">
            <thead class="table-dark">
              <tr>
                <th scope="col">Section</th>
                <th scope="col">Total Questions</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Certificate</td>
                <td>12</td>
                <td>
                  <a class="link-class">Edit</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->
      <!-- <div>
        <ul *ngIf="!isQuestionnaireAvailable">
          <li class="medium-font">
            First Question
          </li>
          <li class="medium-font">
            Second Question
          </li>
          <li class="medium-font">
            Third Question
          </li>
        </ul>
        <div class="medium-font text-center" *ngIf="isQuestionnaireAvailable">
          No items is available.
        </div>
      </div> -->
    </form>
  </div>
  </div>
</div>
</ng-template>

<ng-template #guidelineModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>
            Guidelines for Bidders
          </h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(guidelineModalRef)">
        </div>
      </div>
    </header>
    <div class="header-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <label class="small-font">
          Enter a new line for next guideline
        </label>
        <textarea class=" medium-font form-control" rows="6" [formControl]="ctrlGuidelineBidders">
        </textarea>
        <div *ngIf="ctrlGuidelineBidders.invalid && (ctrlGuidelineBidders.dirty || ctrlGuidelineBidders.touched)">
          <p class="text-danger" *ngIf="ctrlGuidelineBidders.errors?.['required']">Required</p>
        </div>
        <div class="d-grid gap-2 my-3">
          <button class="btn btn-sm btn-new-class "
          (click)="saveGuidelinesForBidder()">Save</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteConfirmModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>DELETE QUESTIONNAIRE</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoadingForModal}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal(deleteConfirmModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <div class="">
        <p>Are you sure you want to delete this questionnaire?</p>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToastForModal$ |async" [isError]="true" [message]="errorMsgForModal"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToastForModal$ |async" [isError]="false" message="Questionnaire deleted successfully."></app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoadingForModal">
        <div class="e-spinner"></div>
      </div>

      <div class="text-end mb-3 mt-2" *ngIf="!isLoadingForModal && !(_showSuccessToastForModal$ | async)">
        <button type="button" class="btn btn-new-class btn-sm me-3" (click)="closeModal(deleteConfirmModalRef)">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="deleteQuestionnaireTemplate()">OK</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #publishConfirmModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>QUESTIONNAIRE PUBLISH</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoadingForModal}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal(publishConfirmModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <div class="">
        <p>Are you sure you want to publish this questionnaire?</p>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToastForModal$ |async" [isError]="true" [message]="errorMsg"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToastForModal$ |async" [isError]="false" message="Questionnaire published successfully."></app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoadingForModal">
        <div class="e-spinner"></div>
      </div>

      <div class="text-end mb-3" *ngIf="!isLoadingForModal && !(_showSuccessToastForModal$ | async)">
        <button type="button" class="btn btn-new-class btn-sm me-3" (click)="closeModal(publishConfirmModalRef)">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="markQuestionnairePublish()">OK</button>
      </div>
    </div>
  </div>
</ng-template>