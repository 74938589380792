<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>Ask Question</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()" />
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3">
      <form class="needs-validation" novalidate>
        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="ctrlAskQuestion" [formControl]="ctrlAskQuestion"
            [ngClass]="{ 'is-invalid': ctrlAskQuestion.invalid && (ctrlAskQuestion.dirty || ctrlAskQuestion.touched) }" />
          <label for="ctrlAskQuestion">Ask Question</label>
          <div *ngIf="ctrlAskQuestion.invalid && (ctrlAskQuestion.dirty || ctrlAskQuestion.touched)">
            <p class="text-danger" *ngIf="ctrlAskQuestion.errors?.['required']">
              Required
            </p>
          </div>
        </div>
        <div class="my-3 div-class">
          Your question is under review. You can edit till it is accepted.
        </div>
  
        <div class="mb-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Question submitted successfully'"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="'Error while submitting question!'"></app-message-toast>
        </div>
        
        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>
  
        <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-new-class btn-sm" (click)="saveAskQuestion()">
            SAVE
          </button>
        </div>
      </form>
    </div>
  </div>

</div>