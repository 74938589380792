import { UserPrivilege } from "../../enums/UserPrivilege";
import { CountryCodeDto } from "../CountryCodeDto";

export class RegistrationUserModalDto {
  // User
  firstName?: string;
  lastName?: string;
  designation?: string;
  companyName?: string;
  companyUrl?: string;

  emailId?: string;
  mobileNo?: string;
  countryCode?: CountryCodeDto

  faxNo?: string;
  faxNoCountryCode?: CountryCodeDto

  password?: string;

  agreeTermsAndCondition?: boolean;

  // OTP
  otpId?: string
  userEnteredOtp?: string

  // Address
  addressId?: string;
  addressType?: string;
  searchAddress?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  landmark?: string;
  latitude?: string;
  longitude?: string;
  countryShortName?: string;
  privilege?: UserPrivilege;
}
