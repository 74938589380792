import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-html-view',
  templateUrl: './html-view.component.html',
  styleUrls: ['./html-view.component.sass']
})
export class HtmlViewComponent {
  @Input() title?: string;
  @Input() content?: string;

  @ViewChild('viewContent') viewContent?: ElementRef

  constructor(
    private activeModal: NgbActiveModal,
    private ngbModal: NgbModal
  ) {}

  moveToTop(ref: HTMLElement) {
    ref.scrollIntoView()
  }

  ngAfterViewInit(): void {
    this.viewContent!.nativeElement.innerHTML = this.content!;
  }

  getContent(content: string) {
    return content.split('\n');
  }

  closeModal() {
    this.activeModal.close()
  }

  openEditModal(content: any) {
    this.ngbModal.open(content, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
    })
  }
}
