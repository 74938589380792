import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { BehaviorSubject, catchError, firstValueFrom, tap } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { NavigationStart, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserPrivilege } from '../enums/UserPrivilege';
import { ServerDataFetchService } from './server-data-fetch.service';
import { AuctionExtUtil } from '../util/AuctionExtUtil';
import { DatePipe } from '@angular/common';
import { AdminAuctionDataHolderService } from './AdminAuctionDataHolder.service';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { AuthenticationResponseDto } from '../models/AuthenticationResponseDto';
import { SessionInfoDto } from '../models/SessionInfoDto';
import { ApplicationListenerService } from './application-listener.service';
import { NavigationEventsDto } from '../models/NavigationEventsDto';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';



@Injectable({
  providedIn: 'root'
})
export class DataRedirectionService {

  // isRedirection?: boolean = false;
  // _isRedirection$ = new BehaviorSubject<boolean>(false);

  _subNavigation$ = new BehaviorSubject<string>("");

  selectedAuctionHouseId?: string | null;
  selectedAuctionId?: string | null;
  selectedLotId?: string | null;

  refreshCalled: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private errorService: ErrorService,
    private router: Router,
    private landingAndBidderService: LandingAndBidderService,
    private userService: UserService,
    private serverDataFetchService: ServerDataFetchService,
    private adminAuctionDataHolderService: AdminAuctionDataHolderService,
    private applicationListenerService: ApplicationListenerService,
    private datePipe: DatePipe,

  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const currentRoute = event.url;
        const hashString = AuctionExtUtil.getSubDomainName() + ':' + this.datePipe.transform(new Date(), 'ddMMyyyy')
        let encryptHash = AuctionExtUtil.encryptSHA256String(hashString);
        console.log(currentRoute + ":" + encryptHash);
        if (currentRoute === `/SuperAdmin/debugConsole?p=${encryptHash}`) {
          sessionStorage.setItem("NAVIGATION_NAME", AuctionExtConstant.DEBUG_CONSOLE);
        } else if (currentRoute === '/SuperAdmin/logConsole') {
          sessionStorage.setItem("NAVIGATION_NAME", AuctionExtConstant.LOG_CONSOLE);
        } else if (currentRoute === `/SuperAdmin/configConsole?p=${encryptHash}`) {
          sessionStorage.setItem("NAVIGATION_NAME", AuctionExtConstant.CONFIG_CONSOLE);
        }
      }
    });

  }

  get getSubNavigation() { return this._subNavigation$.asObservable() }



  setResourceIds(houseId: string, auctionId: string, lotId?: string) {
    sessionStorage.setItem("AUCTION_HOUSE_ID", houseId)
    sessionStorage.setItem("AUCTION_ID", auctionId)
    if (lotId) {
      sessionStorage.setItem("LOT_ID", lotId)
    }

  }


  navigationToHomePage() {
    ////clear old session cookies
    this.clearData();

    if (this.userService.getUserEntity() && this.userService.getUserEntity().userId) {
      let userPrivilege = this.userService.getUserEntity().userPrivileges;
      if (AuctionExtUtil.checkIfRoleIsAssigned(userPrivilege!, UserPrivilege.BIDDER)) {
        // If User Is Bidder then Navigate to Bidder Dashboard
        this.router.navigate(['Bidder/Dashboard'], { skipLocationChange: true })
      } else {
        this.router.navigate(['landing'], { skipLocationChange: true })
      }
    } else {
      this.router.navigate(['landing'], { skipLocationChange: true });
    }
  }

  setSubNavigation(value: string) {
    this._subNavigation$.next(value);
  }

  setCurrentPageInSession(navigationName: string, auctionId?: string) {
    sessionStorage.setItem("NAVIGATION_NAME", navigationName)
    if (auctionId) {
      sessionStorage.setItem("RESOURCE_ID", auctionId);
    }
  }

  async storeNavigationContext(contextName: string, subContextName:string, pageName: string, tabName: string, resourceNo: string, subResourceNo?: string) {
   this.clearData();

    sessionStorage.setItem("CONTEXT_NAME", contextName);
    sessionStorage.setItem("SUB_CONTEXT_NAME", subContextName);
    sessionStorage.setItem("PAGE_NAME", pageName);

    if(tabName){
      sessionStorage.setItem("TAB_NAME", tabName);
    }
    if(resourceNo){
      sessionStorage.setItem("RESOURCE_NO", resourceNo);
    }
    if (subResourceNo) {
      sessionStorage.setItem("SUB_RESOURCE_NO", subResourceNo);
    }
  }

  async navigateToPage() {
    let contextPage = sessionStorage.getItem("CONTEXT_NAME");
    let subContextPage = sessionStorage.getItem("SUB_CONTEXT_NAME");
    let pageName = sessionStorage.getItem("PAGE_NAME");
    let sequenceNumber = sessionStorage.getItem('RESOURCE_NO');

    contextPage = this.getCorrectContextName();


    if (sequenceNumber && contextPage && pageName) {
      this.clearSpecificParams();

      // LANDING
      if (contextPage == AuctionExtConstant.LANDING && subContextPage == AuctionExtConstant.AUCTION) {
        await this.landingAndBidderService.loadAuctionBasedOnSequenceNo(sequenceNumber);
        let auction = this.landingAndBidderService.getSelectedAuction;

        if (auction) {
          await this.serverDataFetchService.loadAllLotsOfAuctionForBidderSync(auction.auctionId!);
          let allLots = this.landingAndBidderService.getAllLots();

          // LANDING_SINGLE_LOT
          if (pageName == AuctionExtConstant.SINGLE_LOT) {
            if (allLots && allLots.length > 0) {
              let lot = allLots[0];
              this.landingAndBidderService.setSelectedAuctionLot(lot!);
            }

            this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['lotDetails'], { skipLocationChange: true, queryParamsHandling: 'merge' }));
          }

          // LANDING_MULTI_LOT
          if (pageName == AuctionExtConstant.MULTI_LOT) {
            this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['multiLotsList'], { skipLocationChange: true, queryParamsHandling: 'merge' }));
          }

          if (pageName == AuctionExtConstant.ANY_PAGE) {

            if (auction.singleLot) {
              if (allLots && allLots.length > 0) {
                let lot = allLots[0];
                this.landingAndBidderService.setSelectedAuctionLot(lot!);
              }
              this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['lotDetails'], { skipLocationChange: true, queryParamsHandling: 'merge' }));

            } else {
              this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['multiLotsList'], { skipLocationChange: true, queryParamsHandling: 'merge' }));
            }

          }

        } else {
          this.router.navigate(['not-found'], { skipLocationChange: true });
        }
      }

      if (contextPage == AuctionExtConstant.LANDING && subContextPage == AuctionExtConstant.RFX) {
        // await this.landingAndBidderService.loadRfxDtoByRfxSequenceNumber(sequenceNumber);
        // let selectedRfx = this.landingAndBidderService.getSelectedRfx;

        // if (selectedRfx) {
          // if (pageName == AuctionExtConstant.RFX_SUBCATEGORY_LIST) {
          //   this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['/'], { skipLocationChange: true, queryParamsHandling: 'merge' }));
          // }
          // if (pageName == AuctionExtConstant.RFX_SUBCATEGORY_DETAILS) {

          // }
        // }
        this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['/landing'], { skipLocationChange: true, queryParamsHandling: 'merge' }));
      }

      // SUPPLIER
      if (contextPage == AuctionExtConstant.SUPPLIER && subContextPage == AuctionExtConstant.AUCTION) {
        await this.landingAndBidderService.loadAuctionBasedOnSequenceNo(sequenceNumber);
        let auction = this.landingAndBidderService.getSelectedAuction;

        if (auction) {
          await this.serverDataFetchService.loadAllLotsOfAuctionForBidderSync(auction.auctionId!);
          let allLots = this.landingAndBidderService.getAllLots();

          // SUPPLIER_SINGLE_LOT
          if (pageName == AuctionExtConstant.SINGLE_LOT) {
            if (allLots && allLots.length > 0) {
              let lot = allLots[0];
              this.landingAndBidderService.setSelectedAuctionLot(lot!);
            }

            this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['Bidder/lotDetails'], { skipLocationChange: true, queryParamsHandling: 'merge' }));
          }

          // SUPPLIER_MULTI_LOT
          if (pageName == AuctionExtConstant.MULTI_LOT) {
            this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['Bidder/multiLotsList'], { skipLocationChange: true, queryParamsHandling: 'merge' }));
          }

          // AUCTION_CATALOGUE
          if (pageName == AuctionExtConstant.AUCTION_CATALOGUE) {
            this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['Bidder/auction-catalogue'], { skipLocationChange: true, queryParamsHandling: 'merge' }));
          }

          if (pageName == AuctionExtConstant.ANY_PAGE) {

            if (auction.singleLot) {
              if (allLots && allLots.length > 0) {
                let lot = allLots[0];
                this.landingAndBidderService.setSelectedAuctionLot(lot!);
              }
              this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['Bidder/lotDetails'], { skipLocationChange: true, queryParamsHandling: 'merge' }));

            } else {
              this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['Bidder/multiLotsList'], { skipLocationChange: true, queryParamsHandling: 'merge' }));
            }
          }

        } else {
          this.router.navigate(['not-found'], { skipLocationChange: true });
        }
      }

      if (contextPage == AuctionExtConstant.SUPPLIER && subContextPage == AuctionExtConstant.RFX) {
        await this.landingAndBidderService.loadRfxDtoByRfxSequenceNumber(sequenceNumber);
        let selectedRfx = this.landingAndBidderService.getSelectedRfx;

        if (selectedRfx) {
          await this.serverDataFetchService.loadAllSubcategoriesForBidderSync(selectedRfx?.rfxId!);

          if (pageName == AuctionExtConstant.RFX_SUBCATEGORY_LIST) {
            this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['Bidder/Dashboard'], { skipLocationChange: true, queryParamsHandling: 'merge' }));
          }
          if (pageName == AuctionExtConstant.RFX_SUBCATEGORY_DETAILS) {
            this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['Bidder/Dashboard'], { skipLocationChange: true, queryParamsHandling: 'merge' }));
          }
        } else {
          this.router.navigate(['not-found'], { skipLocationChange: true });
        }
      }

    } else {
      // AUCTION_CALENDAR
      if (pageName == AuctionExtConstant.AUCTION_CALENDAR) {
        this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['calendar'], { skipLocationChange: true, queryParamsHandling: 'merge' }));
      }else{
          let user = this.userService.getUserEntity();
          if(user){
            this.router.navigate(['Bidder/Dashboard'], { skipLocationChange: true })
          }else{
            this.router.navigate(['landing'], { skipLocationChange: true })
          }
      }
    }
  }


  async initializeUserListeners() {
    let currentUser = this.userService.getUserEntity();
    if (currentUser) {
      this.applicationListenerService.loadAndSetMyUserAuctionRegistrationWrappersSync()
      this.applicationListenerService.listenMyAuctionRegistrations();
    }
  }


  async getUserSessionInfo(): Promise<SessionInfoDto | undefined> {
    let sessionId = localStorage.getItem("AUC_SESSION_ID");
    let user = this.userService.getUserEntity();

    if (sessionId && !user) {
      try {
        let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.userService.checkIfValidSession(sessionId));

        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let data = apiResponseDto.data as AuthenticationResponseDto;
          let sessionInfo = data.sessionInfoDto as SessionInfoDto;
          return sessionInfo;
        } else {
          return undefined;
        }
      } catch (error) {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  clearSpecificParams() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    // Remove specific parameters by name
    params.delete('p');

    // Update the URL search string
    url.search = params.toString();

    // Update browser history (optional)
    window.history.replaceState({}, '', url.href);
  }


  disableAngularLoader(){
    let element = document.getElementById('idForDataLoader' );
    if(element){
      element.style.display = 'none';
      console.log("Loader disable successfully.")
    }
  }
  disableApplicationDataLoader(){
    let element = document.getElementById('idForDataLoaderAPP' );
    if(element){
      element.style.display = 'none';
      console.log("Loader disable successfully.")
    }
  }

  clearData() {
    sessionStorage.removeItem("CONTEXT_NAME");
    sessionStorage.removeItem("SUB_CONTEXT_NAME");
    sessionStorage.removeItem("PAGE_NAME");
    sessionStorage.removeItem("TAB_NAME");
    sessionStorage.removeItem("RESOURCE_NO");
    sessionStorage.removeItem("SUB_RESOURCE_NO");

  }


  getCorrectContextName() {
    let toReturn = '';
    let user = this.userService.getUserEntity();

    if (user && AuctionExtUtil.checkIfRoleIsAssigned(user.userPrivileges!, UserPrivilege.BIDDER)) {
      toReturn = AuctionExtConstant.SUPPLIER;
    } else {
      toReturn = AuctionExtConstant.LANDING;
    }

    return toReturn;

  }

}


