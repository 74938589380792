import { MessageToastComponent } from './components/message-toast/message-toast.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { NgbAccordionModule, NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './components/footer/footer.component';
import { LoaderComponent } from './components/loader/loader.component';
import { UserLoginComponent } from './components/user/user-login/user-login.component';
import { UserRegistrationComponent } from './components/user/user-registration/user-registration.component';
import { UserPasswordChangeComponent } from './components/user/user-password-change/user-password-change.component';
import { MessageDialogComponent } from '../common/message-dialog/message-dialog.component';
import { UserPasswordResetComponent } from './components/user/user-password-reset/user-password-reset.component';
import { UserLogoutComponent } from './components/user/user-logout/user-logout.component';
import { UserVerifyOtpComponent } from './components/user/user-verify-otp/user-verify-otp.component';
import { UserRegisterTobidComponent } from './components/user/user-register-tobid/user-register-tobid.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularEditorModule } from 'src/assets/plugins/angular-editor';
import { UserDetailsComponent } from './components/user/user-details/user-details.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { HtmlViewComponent } from './components/html-view/html-view.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ErrorsListModalComponent } from './components/errors-list-modal/errors-list-modal.component';
import { PremiumAuctionCardComponent } from '../layouts/bidder/bidder-dashboard/premium-auction-card/premium-auction-card.component';
import { MapViewComponent } from './components/map-view/map-view.component';
import { LotBidHistoryComponent } from './components/lot-bid-history/lot-bid-history.component';

import { RouterModule } from '@angular/router';
import { GalleryViewComponent } from './components/gallery-view/gallery-view.component';
import { AllAuctionsComponent } from './components/user/all-auctions/all-auctions.component';
import { ShareButtonsComponent } from './components/share-buttons/share-buttons.component';
import { AllAuctionCardComponent } from './components/all-auction-card/all-auction-card.component';
import { CompanyTermsConditionsComponent } from './components/company-terms-conditions/company-terms-conditions.component';
import { CompanyPrivacyPolicyComponent } from './components/company-privacy-policy/company-privacy-policy.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { LotsErrorValidationComponent } from './components/lots-error-validation/lots-error-validation.component';
import { SwiperModule } from 'swiper/angular';
import { EmailVerifyModalComponent } from './components/email-verify-modal/email-verify-modal.component';
import { NewFooterComponent } from './new-footer/new-footer.component';
import { SafePipe } from './pipes/safe.pipe';
import { AskAuctionQuestionComponent } from './components/ask-auction-question/ask-auction-question.component';
import { UserRatingComponent } from './components/user-rating/user-rating.component';
import { LotCarouselComponent } from './components/lot-carousel/lot-carousel.component';
import { LongPressDirective } from './directives/long-press.directive';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { CountryCodeSelectComponent } from './components/country-code-select/country-code-select.component';
import { CookiesAlertComponent } from './components/cookies-alert/cookies-alert.component';
import { UserAuctionRegistrationsNotificationComponent } from './components/user-auction-registrations-notification/user-auction-registrations-notification.component';
import { AuctionHouseDataChartsComponent } from './components/auction-house-data-charts/auction-house-data-charts.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { AiDescriptionGeneratorComponent } from './components/ai-description-generator/ai-description-generator.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ClosedAuctionCardComponent } from './components/closed-auction-card/closed-auction-card.component';
import { RedirectLoaderComponent } from './components/redirect-loader/redirect-loader.component';
import { PinchZoomModule } from 'src/assets/plugins/ngx-pinch-zoom';
import { SimilarAuctionCardComponent } from '../layouts/bidder/bidder-dashboard/similar-auction-card/similar-auction-card.component';
import { AuctionCalendarComponent } from './components/auction-calendar/auction-calendar.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule } from 'angular-calendar';
import { CalendarListComponent } from './components/calendar-list/calendar-list.component';
import { AuctionCalendarDetailsComponent } from './components/auction-calendar-details/auction-calendar-details.component';
import { AuctionCalendarViewComponent } from './components/auction-calendar-view/auction-calendar-view.component';
import { SearchCardComponent } from './components/search-card/search-card.component';
import { LotSequenceCarouselComponent } from './components/lot-sequence-carousel/lot-sequence-carousel.component';
import { ListingContactDetailComponent } from './components/listing-contact-detail/listing-contact-detail.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { UserCategorySelectionComponent } from './components/user-category-selection/user-category-selection.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { AdminBidderDetailsComponent } from './components/user/admin-bidder-details/admin-bidder-details.component';
import { GlobalBidderDetailsComponent } from './components/user/global-bidder-details/global-bidder-details.component';
import { NewHeaderComponent } from '../app/shared/components/new-header/new-header.component';
import { UnsubscribeNotificationComponent } from '../app/shared/components/unsubscribe-notification/unsubscribe-notification.component';
import { TechnicalSectionComponent } from '../layouts/questionnaire/technical-section/technical-section.component';
import { FinancialQuestionComponent } from '../layouts/questionnaire/financial-question/financial-question.component';
import { FinancialSectionComponent } from '../layouts/questionnaire/financial-section/financial-section.component';
import { TechnicalQuestionComponent } from '../layouts/questionnaire/technical-question/technical-question.component';
import { QuestionnaireProjectComponent } from '../layouts/questionnaire/questionare-project/questionnaire-project.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { StepsModule } from 'primeng/steps';
import { TreeModule } from 'primeng/tree';
import { InputTextModule } from 'primeng/inputtext';
import { CategoriesTreeViewComponent } from './components/categories-tree-view/categories-tree-view.component';

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  LoaderComponent,
  UserLoginComponent,
  UserRegistrationComponent,
  UserPasswordChangeComponent,
  UserPasswordResetComponent,
  UserLogoutComponent,
  MessageDialogComponent,
  UserVerifyOtpComponent,
  UserRegisterTobidComponent,
  MessageToastComponent,
  UserDetailsComponent,
  HtmlViewComponent,
  ErrorsListModalComponent,
  PremiumAuctionCardComponent,
  SimilarAuctionCardComponent,
  MapViewComponent,
  LotBidHistoryComponent,
  GalleryViewComponent,
  ShareButtonsComponent,
  AllAuctionCardComponent,
  CompanyTermsConditionsComponent,
  CompanyPrivacyPolicyComponent,
  ComingSoonComponent,
  EmailVerifyModalComponent,
  SafePipe,
  AskAuctionQuestionComponent,
  UserRatingComponent,
  LotCarouselComponent,
  LongPressDirective,
  ImagePreviewComponent,
  CountryCodeSelectComponent,
  CookiesAlertComponent,
  UserAuctionRegistrationsNotificationComponent,
  AuctionHouseDataChartsComponent,
  ClosedAuctionCardComponent,
  RedirectLoaderComponent,
  AuctionCalendarComponent,
  SearchCardComponent,
  LotSequenceCarouselComponent,
  ListingContactDetailComponent,
  NewHeaderComponent,
  UnsubscribeNotificationComponent,
  QuestionnaireProjectComponent,
  TechnicalSectionComponent,
  TechnicalQuestionComponent,
  FinancialSectionComponent,
  FinancialQuestionComponent,
  BreadcrumbComponent,
  NotificationsComponent,
  CategoriesTreeViewComponent
]

const MODULES = [
  FormsModule,
  ReactiveFormsModule,
  NgbModule,
  FontAwesomeModule,
  AngularEditorModule,
  GoogleMapsModule,
  GooglePlaceModule,
  DragDropModule,
  RouterModule,
  NgbCollapseModule,
  NgbAccordionModule,
  SwiperModule,
  NgxEchartsModule,
  ColorPickerModule,
  PinchZoomModule,
  FlatpickrModule,
  CalendarModule,
  NgxJsonViewerModule,
  StepsModule,
  TreeModule,
  InputTextModule
]

@NgModule({
  declarations: [
    ...COMPONENTS,
    AllAuctionsComponent,
    LotsErrorValidationComponent,
    NewFooterComponent,
    AiDescriptionGeneratorComponent,
    CalendarListComponent,
    AuctionCalendarDetailsComponent,
    AuctionCalendarViewComponent,
    SearchCardComponent,
    UserCategorySelectionComponent,
    UserProfileComponent,
    AdminBidderDetailsComponent,
    GlobalBidderDetailsComponent,


  ],
  imports: [
    CommonModule,
    ...MODULES
  ],
  exports: [
    ...COMPONENTS,
    ...MODULES
  ]
})
export class SharedModule { }
