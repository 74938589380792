<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>AI Text Generator</h2>
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
 <div class="model-body-top">
  <div class="m-3">
    <form class="needs-validation" novalidate>
      <label for="write" class="text-area-label mb-3">What do you want to write?</label>
      <div class="ai-types-wrapper">
        <button class="btn btn-sm d-flex mb-2 me-2 rounded-0"
          [ngClass]="selectedAi == item?.id ? 'btn-new-class' : 'btn-outline-secondary'"
          *ngFor="let item of aiGeneratorTypes;" (click)="changeAiGenerator(item.id)">
          {{item?.title}}
        </button>
      </div>
      <!-- <label for="floatingInput" class="text-area-label">Description</label>
      <textarea name="" id="" class="form-control" rows="3" [formControl]="ctrlAiDescription">
      </textarea>
      <div *ngIf="ctrlAiDescription.invalid && (ctrlAiDescription.dirty || ctrlAiDescription.touched)">
        <p class="text-danger" *ngIf="ctrlAiDescription.errors?.['required']">Required</p>
      </div> -->



      <!-- Auction House Description -->
      <div [formGroup]="fgAuctionHouse" *ngIf="selectedAi == 'AUCTION_HOUSE_DESCRIPTION'">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" placeholder="Company Name" id="companyName" formControlName="companyName" />
          <label for="companyName">Company Name</label>
          <div *ngIf="fcAuctionHouse['companyName'].invalid && (fcAuctionHouse['companyName'].dirty || fcAuctionHouse['companyName'].touched)">
            <p class="text-danger" *ngIf="fcAuctionHouse['companyName'].errors?.['required']">Required</p>
          </div>
        </div>
        <div class="form-floating mb-3">
          <input type="email" class="form-control" id="email" placeholder="Email address" formControlName="email" />
          <label for="email">Email address</label>
          <div *ngIf="fcAuctionHouse['email'].invalid && (fcAuctionHouse['email'].dirty || fcAuctionHouse['email'].touched)">
            <p class="text-danger" *ngIf="fcAuctionHouse['email'].errors?.['required']">Required</p>
          </div>
        </div>
        <div class="form-floating mb-3">
          <input type="number" class="form-control" id="mobileNo" placeholder="Phone" formControlName="mobileNo" />
          <label for="mobileNo">Phone</label>
          <div *ngIf="fcAuctionHouse['mobileNo'].invalid && (fcAuctionHouse['mobileNo'].dirty || fcAuctionHouse['mobileNo'].touched)">
            <p class="text-danger" *ngIf="fcAuctionHouse['mobileNo'].errors?.['required']">Required</p>
          </div>
        </div>
        <div class="form-floating mb-3">
          <input type="text" class="form-control" placeholder="Website" formControlName="website" id="website" />
          <label for="website">Website</label>
          <div *ngIf="fcAuctionHouse['website'].invalid && (fcAuctionHouse['website'].dirty || fcAuctionHouse['website'].touched)">
            <p class="text-danger" *ngIf="fcAuctionHouse['website'].errors?.['required']">Required</p>
          </div>
        </div>
      </div>

      <!-- Auction Title -->
      <div *ngIf="selectedAi == 'AUCTION_TITLE'">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" placeholder="Auction Detail" id="ctrlAuctionTitle" [formControl]="ctrlAuctionTitle" />
          <label for="ctrlAuctionTitle">Auction Detail</label>
          <div *ngIf="ctrlAuctionTitle.invalid && (ctrlAuctionTitle.dirty || ctrlAuctionTitle.touched)">
            <p class="text-danger" *ngIf="ctrlAuctionTitle.errors?.['required']">Required</p>
          </div>
        </div>
      </div>

      <!-- Privacy Policy  -->
      <div [formGroup]="fgPrivacyPolicy" *ngIf="selectedAi == 'PRIVACY_POLICY'">
        <div class="form-floating mb-3">
          <input type="email" class="form-control" id="headings" placeholder="Email" formControlName="headings" />
          <label for="headings">Email</label>
          <div *ngIf="fcPrivacyPolicy['headings'].invalid && (fcPrivacyPolicy['headings'].dirty || fcPrivacyPolicy['headings'].touched)">
            <p class="text-danger" *ngIf="fcPrivacyPolicy['headings'].errors?.['required']">Required</p>
          </div>
        </div>
        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="companyName" placeholder="Company Name" formControlName="companyName" />
          <label for="companyName">Company Name</label>
          <div *ngIf="fcPrivacyPolicy['companyName'].invalid && (fcPrivacyPolicy['companyName'].dirty || fcPrivacyPolicy['companyName'].touched)">
            <p class="text-danger" *ngIf="fcPrivacyPolicy['companyName'].errors?.['required']">Required</p>
          </div>
        </div>
        <div class="form-floating mb-3">
          <input type="email" class="form-control" placeholder="Email" id="email" formControlName="email" />
          <label for="email">Email</label>
          <div *ngIf="fcPrivacyPolicy['email'].invalid && (fcPrivacyPolicy['email'].dirty || fcPrivacyPolicy['email'].touched)">
            <p class="text-danger" *ngIf="fcPrivacyPolicy['email'].errors?.['required']">Required</p>
          </div>
        </div>
        <div class="form-floating mb-3">
          <input type="text" class="form-control" placeholder="Phone" id="mobileNo" formControlName="mobileNo" />
          <label for="mobileNo">Phone</label>
          <div *ngIf="fcPrivacyPolicy['mobileNo'].invalid && (fcPrivacyPolicy['mobileNo'].dirty || fcPrivacyPolicy['mobileNo'].touched)">
            <p class="text-danger" *ngIf="fcPrivacyPolicy['mobileNo'].errors?.['required']">Required</p>
          </div>
        </div>
      </div>

      <!-- Terms and Conditions  -->
      <div [formGroup]="fgTermCondition" *ngIf="selectedAi == 'TERM_CONDITION'">
        <div class="form-floating mb-3">
          <input type="email" class="form-control" placeholder="Email" id="heading1" formControlName="headings" />
          <label for="heading1">Email</label>
          <div *ngIf="fcTermCondition['headings'].invalid && (fcTermCondition['headings'].dirty || fcTermCondition['headings'].touched)">
            <p class="text-danger" *ngIf="fcTermCondition['headings'].errors?.['required']">Required</p>
          </div>
        </div>
        <div class="form-floating mb-3">
          <input type="text" class="form-control" placeholder="Company Name" id="companyName1" formControlName="companyName" />
          <label for="companyName1">Company Name</label>
          <div *ngIf="fcTermCondition['companyName'].invalid && (fcTermCondition['companyName'].dirty || fcTermCondition['companyName'].touched)">
            <p class="text-danger" *ngIf="fcTermCondition['companyName'].errors?.['required']">Required</p>
          </div>
        </div>
        <div class="form-floating mb-3">
          <input type="email" class="form-control" placeholder="Email" formControlName="email" id="email1" />
          <label for="email1">Email</label>
          <div *ngIf="fcTermCondition['email'].invalid && (fcTermCondition['email'].dirty || fcTermCondition['email'].touched)">
            <p class="text-danger" *ngIf="fcTermCondition['email'].errors?.['required']">Required</p>
          </div>
        </div>
        <div class="form-floating mb-3">
          <input type="text" class="form-control" placeholder="Phone" formControlName="mobileNo" id="mobileNo1" />
          <label for="mobileNo1">Phone</label>
          <div *ngIf="fcTermCondition['mobileNo'].invalid && (fcTermCondition['mobileNo'].dirty || fcTermCondition['mobileNo'].touched)">
            <p class="text-danger" *ngIf="fcTermCondition['mobileNo'].errors?.['required']">Required</p>
          </div>
        </div>
      </div>

      <!-- Lot Title -->
      <div *ngIf="selectedAi == 'LOT_TITLE'">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" placeholder="Lot Detail" id="ctrlLotTitle1" [formControl]="ctrlLotTitle" />
          <label for="ctrlLotTitle">Lot Detail</label>
          <div *ngIf="ctrlLotTitle.invalid && (ctrlLotTitle.dirty || ctrlLotTitle.touched)">
            <p class="text-danger" *ngIf="ctrlLotTitle.errors?.['required']">Required</p>
          </div>
        </div>
      </div>

      <!--Lot Description -->
      <div *ngIf="selectedAi == 'LOT_DESCRIPTION'">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" placeholder="Lot Name or Other Details" id="ctrlLotDescription1" [formControl]="ctrlLotDescription" />
          <label for="ctrlLotDescription1">Lot Name or Other Details</label>
          <div *ngIf="ctrlLotDescription.invalid && (ctrlLotDescription.dirty || ctrlLotDescription.touched)">
            <p class="text-danger" *ngIf="ctrlLotDescription.errors?.['required']">Required</p>
          </div>
        </div>
      </div>

      <!--Lot Hashtags -->
      <div *ngIf="selectedAi == 'HASHTAGS'">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" placeholder="Lot Name or Other Details" id="ctrlHashtags" [formControl]="ctrlHashtags" />
          <label for="ctrlHashtags">Lot Name or Other Details</label>
          <div *ngIf="ctrlHashtags.invalid && (ctrlHashtags.dirty || ctrlHashtags.touched)">
            <p class="text-danger" *ngIf="ctrlHashtags.errors?.['required']">Required</p>
          </div>
        </div>
      </div>

      <!--Lot Highlights -->
      <div *ngIf="selectedAi == 'HIGHLIGHTS'">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" placeholder="Lot Name or Other Details" id="ctrlHighlights" [formControl]="ctrlHighlights" />
          <label for="ctrlHighlights">Lot Name or Other Details</label>
          <div *ngIf="ctrlHighlights.invalid && (ctrlHighlights.dirty || ctrlHighlights.touched)">
            <p class="text-danger" *ngIf="ctrlHighlights.errors?.['required']">Required</p>
          </div>
        </div>
      </div>

      <!--Lot Features -->
      <div *ngIf="selectedAi == 'FEATURES'">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" placeholder="Lot Name or Other Details" id="ctrlFeatures" [formControl]="ctrlFeatures" />
          <label for="ctrlFeatures">Lot Name or Other Details</label>
          <div *ngIf="ctrlFeatures.invalid && (ctrlFeatures.dirty || ctrlFeatures.touched)">
            <p class="text-danger" *ngIf="ctrlFeatures.errors?.['required']">Required</p>
          </div>
        </div>
      </div>


      <!-- <label for="floatingInput" class="text-area-label">Description</label>
        <textarea name="" id="" class="form-control" rows="3" [formControl]="ctrlAiDescription">
        </textarea>
        <div *ngIf="ctrlAiDescription.invalid && (ctrlAiDescription.dirty || ctrlAiDescription.touched)">
          <p class="text-danger" *ngIf="ctrlAiDescription.errors?.['required']">Required</p>
        </div> -->
      <div class="col-12 text-end my-3">
        <div class="row justify-content-between align-items-center">
          <!-- <div class="col-auto">
            <span>Select Description Type: </span>
            <div ngbDropdown class="d-inline-block">
              <button type="button" class="btn btn-sm btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle>
                {{getMaxWordsTitle()}}
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="changeMaxWordsType('SMALL')">Short (Upto 200 words)</button>
                <button ngbDropdownItem (click)="changeMaxWordsType('FAIRLY_LARGE')">Brief (Upto 500 words)</button>
                <button ngbDropdownItem (click)="changeMaxWordsType('LARGE')">Detailed  (Upto 1000 words)</button>
              </div>
            </div>
          </div> -->
          <div class="col-auto">
            <button type="button" class="btn btn-new-class btn-sm" [disabled]="isGenerateButtonDisabled()"
              (click)="generateDescription()">Generate</button>
          </div>
        </div>
      </div>

      <hr>

      <div class="d-grid gap-2 my-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>

      <div class="row mt-2" *ngIf="aiDescription">
        <div class="col-8 mt-2">
          <h6 class="heading-h6">AI Generated Description</h6>
        </div>
        <div class="col-4 text-end">
          <button type="button" class="btn btn-new-class btn-sm" (click)="copyDescription()">Copy</button>
        </div>
      </div>

      <div class="mb-3">
        <app-message-toast *ngIf="_showSuccessToast$ | async"
          [message]="'Description copied successfully.'"></app-message-toast>
      </div>

      <p class="p-class scroll-description" *ngIf="aiDescription">
        {{aiDescription}}
      </p>

    </form>
  </div>
 </div>

</div>
