<div class="row mx-0 mt-2 align-items-start justify-content-center" *ngIf="
    this.deploymentConfigurationDto?.multiLotAllowed &&
    !auctionEntityDto?.singleLot
  ">
  <div class="col-md-1 col-2 p-0 text-md-end">
    <button class="scroll-left-icon-bidder" (click)="previousLot()">
      <img src="assets/icons/leftarrow2_dark.svg" class="svg-icon-class c-pointer" />
    </button>
  </div>

  <div class="col-8 col-md-9 mt-1 ms-md-5">
    <swiper #swiperLots class="lot-swiper" [virtual]="true" [config]="swiperConfig" (slideChange)="onSlideChange()" *ngIf="isLotsLoaded$ | async">
      <ng-template swiperSlide *ngFor="let item of auctionLotEntityDtos; index as i">
        <button class="btn px-3 py-2 btn-sm me-2" [ngClass]="{
            selected: auctionLotEntityDto?.lotId == item?.lotId,
            'danger-border': haveAnyLotErrors(item.lotId!),
            'secondary-border': !haveAnyLotErrors(item.lotId!)
          }" (click)="changeCurrentLot(item.lotId!, i)">
          <span [ngClass]="haveAnyLotErrors(item.lotId!) ? '' : ''">
            {{ item?.lotSequence }}</span>
        </button>
      </ng-template>
    </swiper>
  </div>

  <div class="col-md-1 col-2 p-0 text-md-end">
    <button class="scroll-right-icon-bidder" (click)="nextLot()">
      <img src="assets/icons/rightarrow2_dark.svg" class="svg-icon-class c-pointer" />
    </button>
  </div>
</div>