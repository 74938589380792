<div class="mt-5">
  <div  class="col-md-9 m-md-auto">
    <div class="text-danger"  *ngIf="_showErrorToast$ | async">
      <img src="assets/icons/close_red.svg" class="svg-icon-class-md c-pointer me-2"> {{errorMsg}}
     </div>
    <div class="text-orange mb-1"  *ngIf="isLoading$ | async">
      <img src="assets/icons/pending-icon.svg" class="svg-icon-class c-pointer me-2"> Your document update is pending
     </div>
     <div class="text-success"  *ngIf="_showSuccessToast$ | async">
      <img src="assets/icons/filter-check.svg" class="svg-icon-class-md c-pointer me-2"> Your document is saved
     </div>
     <div >
      <form action="" class="needs-validation" novalidate [formGroup]="formGroup">
        <div class="row mt-3">
        <div class="col-md-4">
          <div class="div-class">
            Documents could be used for Bid Submission
          </div>
          <div class="border bg-white p-2">
            <h6>Add New Document</h6>
            <div >
              <div class="col-12  mb-3">
                <div class="form-floating">
                  <input class="form-control" id="documentDisplayName" formControlName="documentDisplayName"
                  [ngClass]="{'border-danger': fc.documentDisplayName.invalid && (fc.documentDisplayName.dirty || fc.documentDisplayName.touched)}"/>
                  <label for="documentDisplayName">Document display name</label>
                </div>
                <div class="text-start"
                *ngIf="fc.documentDisplayName.invalid && (fc.documentDisplayName.dirty || fc.documentDisplayName.touched)">
                <p class="text-danger" *ngIf="fc.documentDisplayName.errors?.required">Required
                </p>
              </div>
              </div>
              <div class=" col-12  text-start mb-3">
                <span class="span-class me-2">
                  Expiry
                </span>
                <span class="form-switch">
                  <input class="form-check-input c-pointer" type="checkbox" id="flexSwitchCheckChecked" (click)="enableAndDisableExpiry()" [checked]="isExpiry$ |async"/></span>
              </div>
              <div class="col-12  mb-3" *ngIf="isExpiry$ | async">
                <div class="input-group">
                  <div class="form-floating">
                    <input type="text" ngbDatepicker #date="ngbDatepicker" id="expiryDate" (click)="date.toggle()"
                    [ngClass]="{'border-danger': fc.expiryDate.invalid && (fc.expiryDate.dirty || fc.expiryDate.touched)}"
                    class="form-control" placeholder="End Date" formControlName="expiryDate">
                    <label for="expiryDate">Select expiry date</label>
                  </div>
                  <span class="border border-start-0 bg-white input-group-text ">
                    <img src="assets/icons/calendar.svg" class="svg-icon-class c-pointer">
                  </span>
                </div>
                <div class="text-start"
                *ngIf="fc.expiryDate.invalid && (fc.expiryDate.dirty || fc.expiryDate.touched)">
                <p class="text-danger" *ngIf="fc.expiryDate.errors?.required">Required
                </p>
              </div>
              </div>
              <div class="col-12 mb-3">
                <label for="file-upload" class="custom-file-upload" >
                  <div>
                   <img src="assets/icons/cloud-upload.svg" class="svg-icon-large c-pointer me-2">
                  </div>
                     Drag or drop here or browse file to upload
                 </label>
                   <input id="file-upload" type="file" onclick="this.value=null;" type="file" (change)="chooseFile($event)" />
              </div>
              <div class="col-12 mb-3" *ngIf="(_fileDisplayName$ | async) &&(_fileSize$ | async)">
                <div class="row">
                  <div class="col-8" >
                    <img src="assets/icons/file_light.svg" class="svg-icon-class c-pointer">
                    {{_fileDisplayName$ |async}}
                  </div>
                  <div class="col-3 text-end" >
                    {{formatBytes(_fileSize$ | async)}}
                  </div>
                  <div class="col-1 text-end ps-0">
                    <img src="assets/icons/delete_dark.svg" class="svg-icon-class-small c-pointer" (click)="deleteFile()">
                  </div>
                </div>
              </div>
              <div class="mb-3" *ngIf="isLoading$ | async">
                <div class="e-spinner"></div>
              </div>
              <div class="col-12 text-center">
                <button class="btn btn-white-clr-class me-4" [disabled]="isLoading$ | async" (click)="clear()" >
                  Reset
                </button>
                <button class="btn btn-sm btn-primary-clr-class" [disabled]="isLoading$ | async" (click)="saveUserDetails()">
                  Upload
                </button>
              </div>
            </div>
          </div>

        </div>
        <div class="col-md-1 d-md-inline-block d-none"></div>
          <div class="col-md-7  mb-3">
            <div class="row align-items-center">
              <div class="col-9">
                <div class="text-success"  *ngIf="_showListSuccessToast$ | async">
                  <img src="assets/icons/filter-check.svg" class="svg-icon-class-md c-pointer me-2"> document deleted.
                 </div>
                <div class="text-danger"  *ngIf="_showListErrorToast$ | async">
                  <img src="assets/icons/close_red.svg" class="svg-icon-class-md c-pointer me-2"> {{errorListMsg}}
                 </div>
                <div class="text-orange" *ngIf="!(listLoading$ | async) &&(!documentArchiveList || documentArchiveList.length) == 0 && !(_showListErrorToast$ | async) && !(_showListSuccessToast$ | async)">
                  <img src="assets/icons/pending-icon.svg" class="svg-icon-class c-pointer me-2"> You have not uploaded any document yet
                </div>
                <div class="text-success" *ngIf="(documentArchiveList?.length ?? 0 > 0) && !(_showListErrorToast$ | async) && !(_showListSuccessToast$ | async)">
                  <img src="assets/icons/filter-check.svg" class="svg-icon-class-md c-pointer me-2"> You have uploaded {{documentArchiveList?.length ?? 0 }} documents
                </div>
              </div>
              <div class="col-3 text-end">
                <span class="text-danger">
                  {{countExpiredDocument}} expired
                </span>
              </div>
            </div>


            <div class="over-flow-scroll">
              <div class="card bg-white p-2 mb-3" *ngFor="let documentArchive of documentArchiveList index as i;" (click)="selectDocumentArchive(documentArchive)">
                <h6>{{documentArchive.documentDisplayName}}</h6>
                <div class="col-12 mb-3">
                  <div class="row medium-grey-font">
                    <div class="col-6">
                      <img src="assets/icons/file_light.svg" class="svg-icon-class c-pointer">
                      {{documentArchive.documentName}}
                    </div>
                    <div class="col-2 text-end">
                      {{formatBytes(documentArchive.size)}}
                    </div>
                    <div class="col-3 text-end" >
                      <span class="me-3"> Expiry</span>
                      <span *ngIf="!documentArchive.expiryDate">NA</span>
                      <span *ngIf="documentArchive.expiryDate"> <span>{{documentArchive.expiryDate}}</span></span>
                    </div>
                    <div class="col-1 text-end">
                      <img src="assets/icons/delete_dark.svg" class="svg-icon-class-small c-pointer" (click)="deleteDocumentArchive(documentArchive.documentArchiveId!)">
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="mb-3" *ngIf="listLoading$ | async">
              <div class="e-spinner"></div>
            </div>
          </div>
        </div>

      </form>
     </div>
  </div>
</div>
