<div class="row mb-3 align-items-center">
  <div class="col-6 div-class text-start">
    Already a User?
  </div>
  <div class="col-4">
    <a class="link-class" (click)="navigateToLogin()">Login</a>
  </div>
</div>

<form class="needs-validation" novalidate [formGroup]="formGroup">
  <div class="row">
    <div class="col-12">
      <div class="form-floating mb-3">
        <input type="email" class="form-control" id="emailId" formControlName="emailId"
          placeholder="name@example.com"
          [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && formGroup.controls['emailId'].invalid}" />

        <label for="emailId">Email address</label>
        <div class="text-start"
          *ngIf="fc.emailId.invalid && (_validationErrorPresent$ |async)|| fc.emailId.invalid && (fc.emailId.dirty || fc.emailId.touched)">
          <p class="text-danger" *ngIf="fc.emailId.errors?.required">Required</p>
          <p class="text-danger" *ngIf="fc.emailId.errors?.pattern">Invalid email address
          </p>
          <p class="text-danger" *ngIf="fc.emailId.value && fc.emailId.value.length > 50">
            Maximum Length Exceed
          </p>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="input-group">
        <span class="input-group-addon">
          <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEvent($event)"
            [countryCode$]="countryCodeForMobileNo$"></app-country-code-select>
        </span>
        <div class="form-floating mb-3">
          <input type="text" class="form-control " id="mobileNo" placeholder="Phone Number"
            formControlName="mobileNo"
            [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.mobileNo.invalid}">
          <label for="mobileNo">Phone Number</label>
          <div class="text-start"
            *ngIf="fc.mobileNo.invalid && (_validationErrorPresent$ |async) || fc.mobileNo.invalid && (fc.mobileNo.dirty || fc.mobileNo.touched)">
            <p class="text-danger" *ngIf="fc.mobileNo.errors?.required">Required
            </p>
            <p class="text-danger" *ngIf="fc.mobileNo.errors?.pattern">Invalid</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 mb-3" *ngIf="!isShowOtpVerify">
      <div class="input-group">
        <div class="form-floating">
          <input #password [type]="isPasswordVisible ? 'text' : 'password'" class="form-control border-end-0"
            id="password" formControlName="password" placeholder="name@example.com" (keyup)="onChange()"
            (change)="onChange()" [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.password.invalid}">
          <label for="password">Password</label>
        </div>

        <span class="border border-start-0 bg-color-filed input-group-text "
          [ngClass]="{'focus-border': (_isPasswordFocused$|async)}" (click)="togglePasswordVisibility()">
          <img src="assets/icons/visible_light.svg" class="svg-icon-class c-pointer" *ngIf="!isPasswordVisible">
          <img src="assets/icons/invisible_light.svg" class="svg-icon-class c-pointer" *ngIf="isPasswordVisible">
        </span>
      </div>
      <div class="mb-2 text-start"
        *ngIf="(_validationErrorPresent$ |async) && fc.password.invalid || fc.password.invalid && (fc.password.dirty || fc.password.touched)">
        <p class="text-danger" *ngIf="fc.password.invalid && (!fc.password.value)">
          Required</p>
        <p class="text-danger" *ngIf="fc.password.invalid &&  (fc.password.value)">
          Password must be more than 8 characters, containing at least one capital letter, one lowercase letter,
          one number and one special character.</p>
      </div>
    </div>

    <div class="col-12 mb-3" *ngIf="!isShowOtpVerify">
      <div class="input-group">
        <div class="form-floating">
          <input #confirmPassword [type]="isRepeatPasswordVisible ? 'text' : 'password'" id="confirmPassword"
            class="form-control border-end-0" (keyup)="onChange()" (change)="onChange()"
            formControlName="confirmPassword"
            [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.confirmPassword.invalid}">
          <label for="confirmPassword">Repeat Password</label>
        </div>

        <span class="border border-start-0 bg-color-filed input-group-text "
          [ngClass]="{'focus-border': (_isConfirmPasswordFocused$|async)}" (click)="toggleRepeatPasswordVisibility()">
          <img src="assets/icons/visible_light.svg" class="svg-icon-class c-pointer" *ngIf="!isRepeatPasswordVisible">
          <img src="assets/icons/invisible_light.svg" class="svg-icon-class c-pointer" *ngIf="isRepeatPasswordVisible">
        </span>
      </div>
      <div class="text-start">
        <p class="text-danger"
          *ngIf="(_validationErrorPresent$ |async) && fc.confirmPassword.invalid || fc.confirmPassword.invalid && (fc.confirmPassword.dirty || fc.confirmPassword.touched)">
          Required
        </p>
        <p class="text-danger" *ngIf="!fc.confirmPassword.invalid && !(_isPasswordMatch$ | async)">
          Password Not Match
        </p>
      </div>
    </div>
  </div>

  <div class="my-3">
    <!-- Show REGISTER Screen -->
    <div class="row" *ngIf="!isShowOtpVerify">
      <div class="my-3">
        <app-message-toast *ngIf="_showSuccessMsg$ | async" [message]="_successMsg$ | async"></app-message-toast>
        <app-message-toast *ngIf="authService.errorMsg" [isError]="true" [message]="authService.errorMsg"></app-message-toast>
      </div>

      <div class="d-grid gap-2 my-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>
      <div class="col-12 text-end mt-3" *ngIf="!isLoading">
        <button class="btn btn-sm btn-primary-clr-class" (click)="generateOTP()">Generate Otp</button>
      </div>
    </div>
    
    <!-- Show OTP Screen -->
    <div *ngIf="isShowOtpVerify">
      <div class="form-floating mb-1">
        <input type="text" id="otp" class="form-control" formControlName="userOtp" id="userOtp"
          [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.userOtp.invalid}" />
        <label for="userOtp">Enter OTP</label>
        <div class="text-start"
          *ngIf="(_validationErrorPresent$ |async) && fc.userOtp.invalid || fc.userOtp.invalid && (fc.userOtp.dirty || fc.userOtp.touched)">
          <p class="e-fielderror e-fielderror-required text-danger" *ngIf="fc.userOtp.errors!['required']">Required</p>
        </div>
      </div>
      <div class="text-start small-grey-font">
        Enter the One Time Password (OTP) which was just sent the email address you registered with. Be sure
        to check your spam folder
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showSuccessMsg$ | async" [message]="_successMsg$ | async"></app-message-toast>
        <app-message-toast *ngIf="authService.errorMsg" [isError]="true" [message]="authService.errorMsg"></app-message-toast>
      </div>

      <div class="d-grid gap-2 my-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>

      <div class="row align-items-center mt-3" *ngIf="!isLoading">
        <div class="col-6 text-start">
          <a class="link-class" (click)="skipOTPVerification()">Verify Later</a>
        </div>
        <div class="col-6 text-end">
          <button class="btn btn-sm btn-primary-clr-class" (click)="handleValidSubmit('', false)">Register</button>
        </div>
      </div>
    </div>

  </div>
</form>







<!-- <div>
  <ng-container [ngTemplateOutlet]="registrationModalType == 'ADDRESS' ? companyRegistration
    : registrationModalType == 'OTP_VERIFY' ? otpVerifyModal
    : registrationModal">
  </ng-container>
</div> -->

<!-- Registration Modal -->
<!-- <ng-template #registrationModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Register</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="d-flex align-items-center my-2">
          <span>Already registered?&nbsp;</span><button type="button" class="btn btn-link p-0"
            (click)="openLoginModal()">Login</button>
        </div>
        <form class="needs-validation" novalidate [formGroup]="formGroup">
          <div class="row">
            <div class="col-12">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="floatingInput4" placeholder="Email" formControlName="emailId"
                  [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.emailId.invalid}">
                <label for="floatingInput4">Email (Username for Login)</label>
                <div
                  *ngIf="fc.emailId.invalid && (_validationErrorPresent$ |async)|| fc.emailId.invalid && (fc.emailId.dirty || fc.emailId.touched)">
                  <p class="text-danger" *ngIf="fc.emailId.errors?.required">Required</p>
                  <p class="text-danger" *ngIf="fc.emailId.errors?.pattern">Invalid email address
                  </p>
                  <p class="text-danger" *ngIf="fc.emailId.value && fc.emailId.value.length > 50">
                    Maximum Length
                    Exceed</p>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="floatingInput1" placeholder="First Name"
                  formControlName="firstName"
                  [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.firstName.invalid}">
                <label for="floatingInput1">First Name</label>
                <div
                  *ngIf="fc.firstName.invalid && (_validationErrorPresent$ |async) ||fc.firstName.invalid && (fc.firstName.dirty || fc.firstName.touched)">
                  <p class="text-danger" *ngIf="fc.firstName.errors?.required">Required
                  </p>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="floatingInput2" placeholder="Last Name"
                  formControlName="lastName"
                  [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.lastName.invalid}">
                <label for="floatingInput2">Last Name</label>
                <div
                  *ngIf="fc.lastName.invalid &&  (_validationErrorPresent$ |async) || fc.lastName.invalid && (fc.lastName.dirty || fc.lastName.touched)">
                  <p class="text-danger" *ngIf="fc.lastName.errors?.required">Required
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="floatingInput3" placeholder="Designation" formControlName="designation">
                <label for="floatingInput3">Designation  (Optional)</label>
               
              
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="floatingInput3" placeholder="Company Name" formControlName="companyName"
                  [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.companyName.invalid}">
                <label for="floatingInput3">Company Name</label>
                <div
                  *ngIf="fc.companyName.invalid && (_validationErrorPresent$ |async)|| fc.companyName.invalid && (fc.companyName.dirty || fc.companyName.touched)">
                  <p class="text-danger" *ngIf="fc.companyName.errors?.required">Required</p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="floatingInput3" placeholder="Company Url" formControlName="companyUrl">
                <label for="floatingInput3">Company Url (Optional)</label>
              </div>
            </div>

           

            <div class="col-12">
              <div class="input-group">
                <span class="input-group-addon">
                  <app-country-code-select #countryCode
                    (countryCodeEvent)="handleCountryCodeEvent($event)" [countryCode$]="countryCodeForMobileNo$"></app-country-code-select>
                </span>
                <div class="form-floating mb-3 ">
                  <input type="text" class="form-control " id="floatingInput5" placeholder="Phone Number" formControlName="mobileNo"
                    [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.mobileNo.invalid}">
                  <label for="floatingInput5">Phone Number</label>

                  <div
                    *ngIf="fc.mobileNo.invalid && (_validationErrorPresent$ |async) || fc.mobileNo.invalid && (fc.mobileNo.dirty || fc.mobileNo.touched)">
                    <p class="text-danger" *ngIf="fc.mobileNo.errors?.required">Required
                    </p>
                    <p class="text-danger" *ngIf="fc.mobileNo.errors?.pattern">Invalid</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="input-group">
                <span class="input-group-addon">
                  <app-country-code-select #countryCode
                    (countryCodeEvent)="handleCountryCodeEventForFaxNo($event)" [countryCode$]="countryCodeForFaxNo$"></app-country-code-select>
                </span>
                <div class="form-floating mb-3 ">
                  <input type="text" class="form-control " id="floatingInput5" placeholder="Fax No" formControlName="faxNo"
                    [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.faxNo.invalid}">
                  <label for="floatingInput5">Fax No (Optional)</label>

                  <div
                    *ngIf="fc.faxNo.invalid && (_validationErrorPresent$ |async) || fc.faxNo.invalid && (fc.faxNo.dirty || fc.faxNo.touched)">
                    <p class="text-danger" *ngIf="fc.faxNo.errors?.required">Required
                    </p>
                    <p class="text-danger" *ngIf="fc.faxNo.errors?.pattern">Invalid</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="input-group">
                <div class="form-floating">
                  <input  #password [type]="isPasswordVisible ? 'text' : 'password'" class="form-control border-end-0"
                    id="floatingInput6" placeholder="Password" formControlName="password" (keyup)="onChange()" (change)="onChange()"
                    [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.password.invalid}">
                  <label for="floatingInput6">Password</label>
                </div>
                <span class="input-group-text bg-white border-start-0" (click)="togglePasswordVisibility()"
                [ngClass]="{'focus-border': (_isPasswordFocused$|async)}">
                  <img src="assets/icons/visible_light.svg" class="svg-icon-class c-pointer" *ngIf="!isPasswordVisible">
                  <img src="assets/icons/invisible_light.svg" class="svg-icon-class c-pointer" *ngIf="isPasswordVisible">
                </span>
              </div>
              <div
                *ngIf="(_validationErrorPresent$ |async) && fc.password.invalid || fc.password.invalid && (fc.password.dirty || fc.password.touched)">
                <p class="text-danger" *ngIf="fc.password.invalid && (!fc.password.value)">
                  Required</p>
                <p class="text-danger" *ngIf="fc.password.invalid &&  (fc.password.value)">
                  Password must be more than 8 characters, containing at least one capital letter, one lowercase letter,
                  one number and one special character.</p>
              </div>
            </div>

            <div class="col-6">
              <div class="input-group">
                <div class="form-floating">
                  <input #confirmPassword [type]="isRepeatPasswordVisible ? 'text' : 'password'" class="form-control border-end-0"
                    id="floatingInput6" placeholder="Confirm Password" formControlName="confirmPassword"
                    (keyup)="onChange()" (change)="onChange()"
                    [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.confirmPassword.invalid}">
                  <label for="floatingInput6">Confirm Password</label>
                </div>
                <span class="input-group-text bg-white border-start-0"
                [ngClass]="{'focus-border': (_isConfirmPasswordFocused$|async)}"
                (click)="toggleRepeatPasswordVisibility()">
                  <img src="assets/icons/visible_light.svg" class="svg-icon-class c-pointer"
                    *ngIf="!isRepeatPasswordVisible">
                  <img src="assets/icons/invisible_light.svg" class="svg-icon-class c-pointer"
                    *ngIf="isRepeatPasswordVisible">
                </span>
              </div>
              <div class="mb-2">
                <p class="text-danger"
                  *ngIf="(_validationErrorPresent$ |async) && fc.confirmPassword.invalid || fc.confirmPassword.invalid && (fc.confirmPassword.dirty || fc.confirmPassword.touched)">
                  Required
                </p>
                <p class="text-danger" *ngIf="!fc.confirmPassword.invalid && !(_isPasswordMatch$ | async)">
                  Password Not Match
                </p>
              </div>
            </div>

          </div>
          <div class="col-12 text-end my-3">
            <button type="button" class="btn btn-new-class btn-width-small btn-sm"
              (click)="toggleRegistrationModal('ADDRESS')">NEXT</button>
          </div>
        </form>
      </div>
    </div>

  </div>
</ng-template> -->

<!-- Registration Modal 2 (Company Details) -->
<!-- <ng-template #companyRegistration>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Register</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate [formGroup]="formGroup">
          <div class="row">
            <div class="form-floating mb-3">
              <input ngx-google-places-autocomplete #placesRef="ngx-places" type="text" class="form-control"
                [ngClass]="{'is-invalid': fc.searchAddress.invalid && (fc.searchAddress.dirty || fc.searchAddress.touched)}"
                id="floatingInput" placeholder="Search" formControlName="searchAddress"
                (onAddressChange)="handleAddressChange($event)">
              <label for="floatingInput" class="ms-3">Search Address</label>
              <div
                *ngIf="(_validationErrorPresent$ |async) || fc.searchAddress.invalid && (fc.searchAddress.dirty || fc.searchAddress.touched)">
                <p class="text-danger" *ngIf="fc.searchAddress.errors?.required">Required</p>
                <p class="text-danger" *ngIf="fc.searchAddress.errors?.address">Invalid</p>
              </div>
            </div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="floatingInput" placeholder="Address Line 1"
                formControlName="addressLine1"
                [ngClass]="{'is-invalid': fc.addressLine1.invalid && (fc.addressLine1.dirty || fc.addressLine1.touched)}">
              <label for="floatingInput" class="ms-3"> Address Line 1</label>
              <div
                *ngIf="(_validationErrorPresent$ |async) || fc.addressLine1.invalid && (fc.addressLine1.dirty || fc.addressLine1.touched)">
                <p class="text-danger" *ngIf="fc.addressLine1.errors?.required">Required</p>
              </div>
            </div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="floatingInput" placeholder="Address Line 2"
                formControlName="addressLine2">
              <label for="floatingInput" class="ms-3">Address Line 2</label>
            </div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="floatingInput" placeholder="City" formControlName="city"
                [ngClass]="{'is-invalid': fc.city.invalid && (fc.city.dirty || fc.city.touched)}">
              <label for="floatingInput" class="ms-3">City</label>
              <div *ngIf="(_validationErrorPresent$ |async) || fc.city.invalid && (fc.city.dirty || fc.city.touched)">
                <p class="text-danger" *ngIf="fc.city.errors?.required">Required</p>
              </div>
            </div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="floatingInput" placeholder="State" formControlName="state"
                [ngClass]="{'is-invalid': fc.state.invalid && (fc.state.dirty || fc.state.touched)}">
              <label for="floatingInput" class="ms-3">State</label>
              <div *ngIf="(_validationErrorPresent$ |async) || fc.state.invalid && (fc.state.dirty || fc.state.touched)">
                <p class="text-danger" *ngIf="fc.state.errors?.required">Required</p>
              </div>
            </div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="floatingInput" placeholder="Country" formControlName="country"
                [ngClass]="{'is-invalid': fc.country.invalid && (fc.country.dirty || fc.country.touched)}">
              <label for="floatingInput" class="ms-3">Country</label>
              <div *ngIf="(_validationErrorPresent$ |async) || fc.country.invalid && (fc.country.dirty || fc.country.touched)">
                <p class="text-danger" *ngIf="fc.country.errors?.required">Required</p>
              </div>
            </div>

            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="floatingInput" placeholder="Country-zip" formControlName="zipCode"
                [ngClass]="{'is-invalid': fc.zipCode.invalid && (fc.zipCode.dirty || fc.zipCode.touched)}">
              <label for="floatingInput" class="ms-3">PIN Code</label>
              <div
                *ngIf="(_validationErrorPresent$ |async) || fc.zipCode.invalid && (fc.zipCode.dirty || fc.zipCode.touched)">
                <p class="text-danger" *ngIf="fc.zipCode.errors?.required">Required</p>
                <p class="text-danger" *ngIf="fc.zipCode.errors?.pattern">Invalid</p>
              </div>
            </div>

            <div class="bg-secondary text-center mb-3" [ngClass]="{'py-5': latitude == undefined}">
              <google-map height="20vh" width="100%" [options]="options" [center]="position!" [zoom]="zoom"
                *ngIf="latitude != undefined">
                <map-marker #markerElem="mapMarker" [position]="position!" [title]="marker.title" [options]="marker.options"
                  (mapClick)="openInfo(markerElem, marker.info)">
                </map-marker>
                <map-info-window>{{ infoContent }}</map-info-window>
              </google-map>
            </div>
          </div>

          <div class="col-12 mt-2">
            <input type="checkbox" class="me-1" formControlName="agreeTermsAndCondition"
              [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.agreeTermsAndCondition.invalid}" />
            <span>By clicking REGISTER you have agreed to
              <span class="btn-link c-pointer" (click)="openTermsConditionModal()">Terms and Conditions</span> and
              <span class="btn-link c-pointer" (click)="openPrivacyModal()">Privacy Statement</span>
            </span>
            <div>
              <p class="text-danger"
                *ngIf="(_validationErrorPresent$ |async) && fc.agreeTermsAndCondition.invalid || fc.agreeTermsAndCondition.invalid  && (fc.agreeTermsAndCondition.dirty || fc.agreeTermsAndCondition.touched)">
                Required
              </p>
            </div>
          </div>

          <div class="col-12 text-end my-3">
            <button type="button" class="btn btn-new-class btn-width-small btn-sm me-3"
              (click)="toggleRegistrationModal('USER_DETAILS')">BACK</button>
            <button type="button" class="btn btn-new-class btn-width-small btn-sm"
              (click)="startRegistrationProcess()">REGISTER</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</ng-template> -->

<!-- OTP Verify Modal -->
<!-- <ng-template #learnMoreModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>LEARN MORE</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        At AuctionExt, we understand that staying up-to-date with the latest auction listings and updates is important to
        our users. That's why we offer the option for users to receive updates via SMS and email. To ensure that our users
        have control over their communication preferences, we have established the following policy:
        <br><br>
        <b>Opt-In</b>: Users must opt-in to receive updates via SMS and email by providing their consent during the account
        registration process or through their account settings.<br><br>
        <b>Frequency</b>: AuctionExt will only send updates via SMS and email when there are new auction listings or updates to
        existing auctions. We will not send marketing messages or spam users with irrelevant information.<br><br>
        <b>Opt-Out</b>
        : Users can opt-out of receiving updates via SMS and email at any time by contacting us at
        <span class="text-primary">support@auctionext.com</span>.<br> <br>
        <b>Privacy</b>: We take user privacy seriously and will not share user information with third-party marketers or
        advertisers. The information we collect will only be used for the purpose of sending auction updates and improving
        the AuctionExt platform.
        <br> <br>
        By following this policy, we aim to provide a convenient and personalized auction experience for our users while
        respecting their privacy and communication preferences.
      </div>
    </div>
  </div>
</ng-template> -->

<!-- <ng-template #otpVerifyModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-class">
      <div class="m-3">
        <div class="text-center">
          <p>Enter the One Time Password (OTP) which was just sent to the email address you registered with. Be sure to check your spam folder.</p>
          <form autocomplete="off" class="needs-validation" novalidate
            (keyup.enter)="handleValidSubmit('Verifying OTP...', false)">
            <div class="col-12 text-start">
              <div class="form-floating mt-3">
                <input type="text" id="otp" [formControl]="ctrlOtp" class="form-control"
                  [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && ctrlOtp.invalid}" />
                <label for="floatingInput">OTP</label>
              </div>
              <div
                *ngIf="(_validationErrorPresent$ |async) && ctrlOtp.invalid || ctrlOtp.invalid && (ctrlOtp.dirty || ctrlOtp.touched)">
                <p class="e-fielderror e-fielderror-required text-danger" *ngIf="ctrlOtp.errors!['required']">Required</p>
              </div>
            </div>

            <div class="my-3">
              <app-message-toast *ngIf="_showSuccessMsg$ | async" [message]="_successMsg$ | async"></app-message-toast>
              <app-message-toast *ngIf="authService.errorMsg" [isError]="true"
                [message]="authService.errorMsg"></app-message-toast>
            </div>

            <div class="text-end my-3">
              <button type="button" class="btn btn-new-class btn-sm px-3 me-3" (click)="closeOtpVerifyModal()">CANCEL</button>
              <button type="button" class="btn btn-sm btn-outline-primary  px-3" [disabled]="ctrlOtp.invalid"
                (click)="handleValidSubmit('Verifying OTP...', false)">VERIFY</button>
            </div>
          </form>
          <div class="row justify-content-between">
            <div class="col-auto">
              <a class="btn-link col-6 text-start c-pointer" (click)="resendOTP()">Resend OTP ?</a>
            </div>
            <div class="col-auto">
              <a class="btn-link col-6 text-end c-pointer" (click)="skipOTPVerification()">Verify Later</a>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</ng-template> -->


<!-- <div class="my-3">
  <header class="border-bottom">
   <div class="col-12 px-3">
    <div class="row">
      <div class="col-9">
        <h6>REGISTER</h6>
      </div>
      <div class="col-3 text-end">
        <button class="btn btn-dark btn-sm border-0">
          <span class="material-symbols-outlined" (click)="closeModal()">
            close</span>
        </button>
      </div>
    </div>
   </div>
  </header>
  <div class="e-popupmain">
    <div class="e-maintext my-3">
      <p>Already registered? Please <a>Login
          </a>.</p>
        <div class="col-12">
          <div class="row">
            <div class="col-6">
              <div class="e-field"><label>First Name</label>
                <div class="e-fieldtext"><input name="" type="text" /></div>
              </div>
            </div>
            <div class="col-6">
              <div class="e-field"><label>Last Name</label>
                <div class="e-fieldtext"><input name="" type="text" /></div>
              </div>
            </div>
          </div>
        </div>
      <div class="e-field"><label>Email</label>
        <div class="e-fieldtext"><input name="" type="text" /></div>
      </div>
      <div class="row ">
        <div class="col-3">
          <select class="e-fieldtext mt-4 py-2" placeholder="US +1">
            <option value="Interested In">US +1</option>
            <option value="Buying"> UK +44</option>
            <option value="Selling">Australia +61</option>
            <option value="Selling">France +33</option>
          </select>
        </div>
        <div class="col-9">
          <div class="e-field input-group"><label>Phone Number</label>
            <div class="e-fieldtext">
              <input name="" type="text" /></div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <div class="e-field"><label>Password</label>
              <div class="e-fieldtext"><input name="" type="text" /></div>
            </div>
          </div>
          <div class="col-6">
            <div class="e-field"><label>Confirm Password</label>
              <div class="e-fieldtext"><input name="" type="text" /></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3">
        <input name="" type="checkbox" class="me-1" />
        <span>By clicking REGISTER you have agreed to Terms and Conditions and Privacy Statement </span>
      </div>
        <div class="col-12 mt-2">
        <input name="" type="checkbox" class="me-1" />
        <span>I would like to receives update. <a>Learn More</a> </span>
      </div>
      <div class=" w-100 text-end my-3">
        <button class="btn btn-sm btn-new-class me-2" (click)="closeModal()">CANCEL</button>
        <button class="btn btn-sm btn-outline-primary">Register</button>
      </div>
    </div>
  </div>
</div> -->

<app-loader #appLoader></app-loader>