import { Timestamp } from "@angular/fire/firestore";
import { AuctionQuestionStatus } from "../../enums/AuctionQuestionStatus";

export class AuctionQuestionDto {
  id?: string;
  auctionHouseId?: string;
  auctionId?: string;
  lotId?: string;
  bidderId?: string;
  bidderName?: string;
  adminId?: string;
  adminEmailId?: string;
  question?: string;
  answer?: string;
  status?: AuctionQuestionStatus;
  published?: boolean;
  createdTimestamp?: Timestamp;
  answeredTimestamp?: Timestamp;
}