import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, Subscription } from "rxjs";
import { SourcingEnvelopeType } from "src/app/shared/enums/questionnaire/SourcingEnvelopeType";
import { ServerAPIResponseDto } from "src/app/shared/models/ServerAPIResponseDto";
import { QuestionnaireTemplate } from "src/app/shared/models/questionnaire/QuestionnaireTemplate";
import { QuestionnaireWrapperDto } from "src/app/shared/models/questionnaire/QuestionnaireWrapperDto";
import { QuestionnaireService } from "src/app/shared/services/questionnaire.service";
import { AuctionExtConstant } from "src/app/shared/util/AuctionExtConstant";
import { AuctionExtUtil } from "src/app/shared/util/AuctionExtUtil";
import { FinancialSectionComponent } from "../financial-section/financial-section.component";
import { TechnicalSectionComponent } from "../technical-section/technical-section.component";
import { AuctionEntityDto } from "src/app/shared/models/user/AuctionEntityDto";
import { AdminAuctionDataHolderService } from "src/app/shared/services/AdminAuctionDataHolder.service";
import { SectionTemplate } from "src/app/shared/models/questionnaire/SectionTemplate";
import { Pattern } from "src/app/shared/util/Patterns";
import { AuctionStatus } from "src/app/shared/models/user/AuctionStatus";
import { QuestionnaireStatus } from "src/app/shared/enums/questionnaire/QuestionnaireStatus";


@Component({
  selector: 'app-questionnaire-project',
  templateUrl: './questionnaire-project.component.html',
  styleUrls: ['./questionnaire-project.component.sass']
})
export class QuestionnaireProjectComponent implements OnInit, OnDestroy {
  formGroupQuestionnaire: FormGroup
  ctrlGuidelineBidders: FormControl = new FormControl('', Validators.required)

  defaultEnvelopesList: string[] = [
    SourcingEnvelopeType.TECHNICAL,
    SourcingEnvelopeType.FINANCIAL
  ]

  questionnaireWrapperDto ?: QuestionnaireWrapperDto;
  questionnaireTemplate?: QuestionnaireTemplate;
  selectedAuction?: AuctionEntityDto;
  sectionTemplateList: SectionTemplate[] = []

  selectedEnvelopesList: string[] = []
  guidelinesList: string[] = []
  errorMsg: string | undefined;
  errorMsgForModal: string | undefined;
  isLoading: boolean = false;
  isLoadingForModal: boolean = false;
  isShowMoreGuideline: boolean = false;
  isShowPublishButton: boolean = false;

  guidelineModalRef?: NgbModalRef
  deleteConfirmModalRef?: NgbModalRef;
  publishConfirmModalRef?: NgbModalRef;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToastForModal$ = new BehaviorSubject<boolean>(false);
  _showErrorToastForModal$ = new BehaviorSubject<boolean>(false);

  questionnaireWrapperDtoSubscription$?: Subscription

  isTechnicalActive = true;
  isQuestionnaireAvailable = true;
  isSelectOption = true;

  @ViewChild('guidelineRef') guidelineRef?: ElementRef<HTMLParagraphElement>


  constructor(
    private ngbModal: NgbModal,
    private formBuilder: FormBuilder,
    private questionnaireService: QuestionnaireService,
    private auctionService: AdminAuctionDataHolderService
  ) {
    this.formGroupQuestionnaire = this.formBuilder.group({
      templateName: ['test'],
      templateDescription: ['test'],
      scoringTemplate: [false, Validators.minLength(3)],
      maximumScore: [''],
      qualifyingScore: [''],
      envelopesList: [null],
      guideLines: [''],
    })
  }

  ngOnInit(): void {
    this.selectedAuction = this.auctionService.selectedAuction;
    if (this.selectedAuction?.questionnaireId) {
      this.questionnaireService.loadQuestionnaireWrapperDto(this.selectedAuction?.questionnaireId);
    }

    this.formGroupQuestionnaire.controls['scoringTemplate'].valueChanges.subscribe(val => {
      if (val) {
        this.formGroupQuestionnaire.controls['maximumScore'].setValidators([Validators.required, Validators.pattern(Pattern.numberGreaterZero)]);
        this.formGroupQuestionnaire.controls['qualifyingScore'].setValidators([Validators.required, Validators.pattern(Pattern.numberGreaterZero)]);
      } else {
        this.formGroupQuestionnaire.controls['maximumScore'].clearValidators();
        this.formGroupQuestionnaire.controls['qualifyingScore'].clearValidators();
      }
      this.formGroupQuestionnaire.updateValueAndValidity();
    })

    this.questionnaireWrapperDtoSubscription$ = this.questionnaireService.getQuestionnaireWrapper$.subscribe(data => {
      if (data) {
        this.questionnaireWrapperDto = data;
        this.questionnaireTemplate = this.questionnaireWrapperDto.questionnaireTemplate;
        
        this.sectionTemplateList = this.questionnaireWrapperDto?.sectionTemplateList?.filter(item => item.envelopeType == SourcingEnvelopeType.TECHNICAL) ?? [];
        this.sectionTemplateList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));

        this.isShowPublishButton = this.selectedAuction?.status == AuctionStatus.LIVE && this.questionnaireTemplate?.status == QuestionnaireStatus.DRAFT;

        this.populateFormGroup();
      } else {
        this.questionnaireWrapperDto = undefined;
        this.questionnaireTemplate = undefined;
        this.sectionTemplateList = [];
        this.guidelinesList = [];
        this.formGroupQuestionnaire.reset();
        this.ctrlGuidelineBidders.reset();
      }
    })
  }

  get fc(): any { return this.formGroupQuestionnaire.controls; }

  populateFormGroup() {
    this.formGroupQuestionnaire.controls['templateName'].patchValue(this.questionnaireTemplate?.templateName);
    this.formGroupQuestionnaire.controls['templateDescription'].patchValue(this.questionnaireTemplate?.templateDescription);
    this.formGroupQuestionnaire.controls['scoringTemplate'].patchValue(this.questionnaireTemplate?.scoringTemplate);
    this.formGroupQuestionnaire.controls['maximumScore'].patchValue(this.questionnaireTemplate?.maximumScore);
    this.formGroupQuestionnaire.controls['qualifyingScore'].patchValue(this.questionnaireTemplate?.qualifyingScore);
    this.formGroupQuestionnaire.controls['envelopesList'].patchValue(this.questionnaireTemplate?.envelopesList);
    this.formGroupQuestionnaire.controls['guideLines'].patchValue(this.questionnaireTemplate?.guideLines);
    this.formGroupQuestionnaire.updateValueAndValidity();

    this.selectedEnvelopesList = this.questionnaireTemplate?.envelopesList ?? [];
    if (this.questionnaireTemplate?.guideLines) {
      this.guidelinesList = this.questionnaireTemplate?.guideLines?.split('\n');
      this.ctrlGuidelineBidders.patchValue(this.questionnaireTemplate?.guideLines);
      this.ctrlGuidelineBidders.updateValueAndValidity();
    }
  }

  onSelectEnvelope(val: string) {
    if (this.selectedEnvelopesList.includes(val)) {
      let index = this.selectedEnvelopesList.findIndex(m => m == val);
      this.selectedEnvelopesList.splice(index, 1);
    } else {
      this.selectedEnvelopesList.push(val);
    }

    this.formGroupQuestionnaire.controls['envelopesList'].patchValue(this.selectedEnvelopesList);
    this.formGroupQuestionnaire.updateValueAndValidity();

    // this.saveQuestionnaire();
  }

  getQuestionsCountOfSection(sectionId: string) {
    let technicalQuestionTemplates = this.questionnaireWrapperDto?.technicalQuestionTemplates ?? [];
    let financialQuestionTemplates = this.questionnaireWrapperDto?.financialQuestionTemplates ?? [];

    let technicalQuestionsCount = technicalQuestionTemplates.filter(item => item.sectionId == sectionId).length;
    let financialQuestionsCount = financialQuestionTemplates.filter(item => item.sectionId == sectionId).length;

    return technicalQuestionsCount + financialQuestionsCount;
  }

  getTechnicalQuestionsOfSection(sectionId: string) {
    let technicalQuestionTemplates = this.questionnaireWrapperDto?.technicalQuestionTemplates ?? [];
    return technicalQuestionTemplates.filter(item => item.sectionId == sectionId);
  }

  getFinancialQuestionsOfSection(sectionId: string) {
    let financialQuestionTemplates = this.questionnaireWrapperDto?.financialQuestionTemplates ?? [];
    return financialQuestionTemplates.filter(item => item.sectionId == sectionId);
  }

  openEditGuidelineModal(content: any) {
    this.guidelineModalRef = this.ngbModal.open(content, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      keyboard: false,
    });
  }

  openAddTechSectionModal() {
    let modalRef = this.ngbModal.open(TechnicalSectionComponent, {
      centered: true,
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.isNewQuestion = true;
  }

  openEditTechSectionModal(sectionId: string) {
    let modalRef = this.ngbModal.open(TechnicalSectionComponent, {
      centered: true,
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.selectedSectionId = sectionId;
    modalRef.componentInstance.isNewQuestion = false;
  }

  // openAddFinSectionModal() {
  //   this.ngbModal.open(FinancialSectionComponent, {
  //     centered: true,
  //     size: 'xl',
  //     backdrop: 'static',
  //     keyboard: false,
  //   });
  // }
  
  openAddQuestionnaireModal(content: any) {
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    this.errorMsg = '';
    this.isLoading = false;

    this.isShowMoreGuideline = false;

    this.ngbModal.open(content, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });

  }

  saveGuidelinesForBidder() {
    if (this.ctrlGuidelineBidders.invalid) {
      this.ctrlGuidelineBidders.markAllAsTouched();
      return;
    }

    this.formGroupQuestionnaire.controls['guideLines'].patchValue(this.ctrlGuidelineBidders.value.trim());
    this.formGroupQuestionnaire.updateValueAndValidity();

    this.guidelinesList = (this.ctrlGuidelineBidders.value.trim() as string).split('\n');

    this.closeModal(this.guidelineModalRef)
    this.saveQuestionnaire()
  }

  openDeleteConfirmModal(content: any) {
    this._showErrorToastForModal$.next(false);
    this._showSuccessToastForModal$.next(false);
    this.errorMsgForModal = '';
    this.isLoadingForModal = false;

    this.deleteConfirmModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false
    })
  }

  openPublishConfirmModal(content: any) {
    this._showErrorToastForModal$.next(false);
    this._showSuccessToastForModal$.next(false);
    this.errorMsgForModal = '';

    this.publishConfirmModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false
    })
  }

  showMoreGuidelineModal() {
    this.isShowMoreGuideline = !this.isShowMoreGuideline;
    if (!this.isShowMoreGuideline) {
      this.guidelineRef!.nativeElement.style.height = '120px'
    } else {
      this.guidelineRef!.nativeElement.style.height = this.guidelineRef!.nativeElement.scrollHeight + 'px'
    }
  }

  haveQuestionnaireValidationIssues() {
    return this.questionnaireService.haveQuestionnaireValidationIssues();
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.ngbModal.dismissAll();
    }

    this.isShowMoreGuideline = false;    
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    this.isLoading = false;
    this.errorMsg = '';
  }

  mergeQuestionnaireTemplate() {
    let questionnaireTemplate: QuestionnaireTemplate = AuctionExtUtil.clone(this.questionnaireTemplate ?? new QuestionnaireWrapperDto());

    let formValue = this.formGroupQuestionnaire.value;
    questionnaireTemplate.templateName = formValue.templateName ?? '';
    questionnaireTemplate.templateDescription = formValue.templateDescription ?? '';
    questionnaireTemplate.scoringTemplate = formValue.scoringTemplate;
    questionnaireTemplate.maximumScore = formValue.maximumScore;
    questionnaireTemplate.qualifyingScore = formValue.qualifyingScore;
    questionnaireTemplate.envelopesList = [SourcingEnvelopeType.TECHNICAL];
    questionnaireTemplate.guideLines = formValue.guideLines ? formValue.guideLines.trim() : '';

    return questionnaireTemplate;
  }

  saveQuestionnaire() {
    if (this.formGroupQuestionnaire.invalid) {
      return;
    }

    console.log(this.formGroupQuestionnaire.value);

    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = true;

    let questionnaireTemplate = this.mergeQuestionnaireTemplate();

    this.questionnaireService.saveQuestionnaires(this.selectedAuction?.auctionId!, questionnaireTemplate).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          setTimeout(() => {
            this._showSuccessToast$.next(false);
          }, 2000)

          let questionnaireWrapperDto = apiResponseDto.data as QuestionnaireWrapperDto;
          this.questionnaireService.updateQuestionnaireWrapperDto(questionnaireWrapperDto);

        } else {
          this.isLoading = false;
          this._showErrorToast$.next(true);
          this.errorMsg = apiResponseDto?.message;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this.errorMsg = "Error while saving questionnaire. Try again.";
      }
    })
  }

  deleteQuestionnaireTemplate() {
    this._showErrorToastForModal$.next(false);
    this.errorMsgForModal = "";
    this.isLoadingForModal = true;

    this.questionnaireService.deleteQuestionnaire(this.questionnaireTemplate?.templateId!, this.selectedAuction?.auctionId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this._showSuccessToastForModal$.next(true);
          this.isLoadingForModal = false;

          this.questionnaireService.updateQuestionnaireWrapperDto(undefined);

          setTimeout(() => {
            this._showSuccessToastForModal$.next(false);
            this.closeModal();
          }, 2000)

        } else {
          this.isLoadingForModal = false;
          this._showErrorToastForModal$.next(true);
          this.errorMsgForModal = apiResponseDto?.message;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoadingForModal = false;
        this._showErrorToastForModal$.next(true);
        this.errorMsgForModal = "Error while deleting question. Try again.";
      }
    })
  }

  markQuestionnairePublish() {
    this._showErrorToastForModal$.next(false);
    this.errorMsgForModal = "";
    this.isLoadingForModal = true;

    this._showErrorToastForModal$.next(false);
    this.errorMsgForModal = "";
    this.isLoadingForModal = true;

    this.questionnaireService.markQuestionnairePublish(this.questionnaireTemplate?.templateId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this._showSuccessToastForModal$.next(true);
          this.isLoadingForModal = false;

          let questionnaireWrapperDto = apiResponseDto.data as QuestionnaireWrapperDto;
          this.questionnaireService.updateQuestionnaireWrapperDto(questionnaireWrapperDto);

          setTimeout(() => {
            this._showSuccessToastForModal$.next(false);
            this.closeModal();
          }, 2000)

        } else {
          this.isLoadingForModal = false;
          this._showErrorToastForModal$.next(true);
          this.errorMsgForModal = apiResponseDto?.message;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoadingForModal = false;
        this._showErrorToastForModal$.next(true);
        this.errorMsgForModal = "Error while deleting question. Try again.";
      }
    })
  }

  ngOnDestroy(): void {
    if (this.questionnaireWrapperDtoSubscription$) {
      this.questionnaireWrapperDtoSubscription$.unsubscribe();
    }
  }

}
