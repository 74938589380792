import { UserEntityDto } from './../../../models/UserEntityDto';
import { FormControl } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { DataRedirectionService } from 'src/app/shared/services/data-redirection.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ServerDataFetchService } from 'src/app/shared/services/server-data-fetch.service';
import { UserLotFavouriteWrapperDto } from 'src/app/shared/models/user/UserLotFavouriteWrapperDto';
import { CategoryDto } from 'src/app/shared/models/CategoryDto';
import { Firestore, Timestamp, collection, onSnapshot, query, where } from '@angular/fire/firestore';
import { Unsubscribe } from '@angular/fire/firestore';
import { logEvent, getAnalytics } from '@angular/fire/analytics';
import { AuctionBidsHighestData } from 'src/app/shared/models/user/AuctionBidsHighestData';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';
import { UserLotFavouriteDto } from 'src/app/shared/models/user/UserLotFavouriteDto';
import { MyFavouriteLotCardComponent } from 'src/app/layouts/bidder/bidder-dashboard/my-favourite-lot-card/my-favourite-lot-card.component';

@Component({
  selector: 'app-my-dashboard',
  templateUrl: './my-dashboard.component.html',
  styleUrls: ['./my-dashboard.component.sass']
})
export class MyDashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  currentStatus: string = AuctionExtConstant.MY_DASHBOARD;

  ctrlSearch: FormControl = new FormControl('')

  userLotFavouriteWrapperDtos: UserLotFavouriteWrapperDto[] = [];
  user?: UserEntityDto
  auctionHouseId?: string

  filterLotFavouriteWrapperDtos$ = new BehaviorSubject<UserLotFavouriteWrapperDto[]>([]);
  favouriteDataLoaded$ = new BehaviorSubject<boolean>(false);

  allAuctionLotFavSubscription$?: Subscription;
  allLotListSubscription$?: Subscription;


  bidsHighestDataUnsubscribe: Unsubscribe | undefined;
  userLotFavouriteUnsubscribe: Unsubscribe | undefined;




  collectionBidsHighestData: string = "BIDS_HIGHEST_DATA";
  collectionUserLotFavourite: string = "USER_LOT_FAVOURITE";

  @ViewChild("myFavLotCardRef")
  myFavLotCard?: MyFavouriteLotCardComponent;


  constructor(
    private changeDetector: ChangeDetectorRef,
    private firestore: Firestore,
    private userService: UserService,
    private bidderService: LandingAndBidderService,
    private dataRedirectionService: DataRedirectionService,
    private serverDataFetchService: ServerDataFetchService
  ) { }


 async ngOnInit() {
    this.auctionHouseId = this.bidderService.getCurrentOrganizationUiDto$.value?.orgCode;
    this.user = this.userService.getUserEntity();
    await this.serverDataFetchService.loadMyFavoriteLotsSync();
    this.attachFirestoreListener()
    this.allAuctionLotFavSubscription$ = this.bidderService.getMyLotFavoriteData$.subscribe((data) => {
      if (data) {
        this.favouriteDataLoaded$.next(true);
        this.userLotFavouriteWrapperDtos = data;
        this.filterLotFavouriteWrapperDtos$.next(this.userLotFavouriteWrapperDtos);
      } else {
        this.filterLotFavouriteWrapperDtos$.next([]);
      }
    })

    this.ctrlSearch.valueChanges.subscribe((searchText) => {
      if (searchText) {
        this.filerLotFav(searchText);

      } else {
        this.filterLotFavouriteWrapperDtos$.next(this.userLotFavouriteWrapperDtos);
      }
    })
  }

  private filerLotFav(searchText: any) {
    let matchLotsByLotName = this.userLotFavouriteWrapperDtos?.filter((item) => (item.auctionLotEntityDto?.lotSequence == Number(searchText) || item.auctionLotEntityDto?.title?.toLowerCase().includes(searchText.toLowerCase())
      || item.auctionLotEntityDto?.preferenceCategory?.categoryName?.toLowerCase().includes(searchText.toLowerCase())
      || item.auctionLotEntityDto?.preferenceCategory?.categoryLevelOneName?.toLowerCase().includes(searchText.toLowerCase())
      || item.auctionLotEntityDto?.preferenceCategory?.categoryLevelTwoName?.toLowerCase().includes(searchText.toLowerCase())
      || item.auctionLotEntityDto?.preferenceCategory?.categoryLevelThreeName?.toLowerCase().includes(searchText.toLowerCase())
      || item.auctionLotEntityDto?.preferenceCategory?.categoryLevelFourName?.toLowerCase().includes(searchText.toLowerCase())
      || item.auctionLotEntityDto?.description?.toLowerCase().includes(searchText.toLowerCase())
      || item.auctionLotEntityDto?.hashtags?.map(item1 => item1.toLowerCase()).find(item1 => item1.includes(searchText.toLowerCase()))
      || item.auctionLotEntityDto?.highlights?.map(item1 => item1.toLowerCase()).find(item1 => item1.includes(searchText.toLowerCase()))
      || item.auctionLotEntityDto?.lotFeatures?.map(item1 => item1.featureKey?.toLowerCase()).find(item1 => item1?.includes(searchText.toLowerCase()))
      || item.auctionLotEntityDto?.inspectionDetails?.toLowerCase()?.includes(searchText.toLowerCase())
      || item.auctionLotEntityDto?.pickUpDetails?.toLowerCase()?.includes(searchText.toLowerCase())
    )
    );

    if (matchLotsByLotName && matchLotsByLotName.length > 0) {
      let clonedLotFavWrapperDtos = [...this.userLotFavouriteWrapperDtos];
      let filterFavoriteLotsList = clonedLotFavWrapperDtos.filter((item) => matchLotsByLotName?.find(lot => lot.auctionLotEntityDto?.lotId == item.auctionLotEntityDto?.lotId));
      this.filterLotFavouriteWrapperDtos$.next(filterFavoriteLotsList);
      this.changeDetector.detectChanges();
    } else {
      this.filterLotFavouriteWrapperDtos$.next([]);
      this.changeDetector.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    let navigationPage = sessionStorage.getItem('NAVIGATION_NAME');

    if (navigationPage == AuctionExtConstant.MY_DASHBOARD_BIDS) {
      this.changeStatus(AuctionExtConstant.MY_DASHBOARD_BIDS);
    } else if (navigationPage == AuctionExtConstant.MY_REGISTRATION) {
      this.changeStatus(AuctionExtConstant.MY_REGISTRATION);
    } else if (navigationPage == AuctionExtConstant.MY_INVOICES) {
      this.changeStatus(AuctionExtConstant.MY_INVOICES);
    }
  }

  changeStatus(newStatus: string) {
    this.currentStatus = newStatus;

    // MY_DASHBOARD => My Favourite Lots
    if (newStatus == AuctionExtConstant.MY_DASHBOARD) {
      this.dataRedirectionService.setCurrentPageInSession(AuctionExtConstant.MY_DASHBOARD);
      this.serverDataFetchService.loadMyFavoriteLotsSync();
    } else if (newStatus == AuctionExtConstant.MY_DASHBOARD_BIDS) {
      this.dataRedirectionService.setCurrentPageInSession(AuctionExtConstant.MY_DASHBOARD_BIDS);
    } else if (newStatus == AuctionExtConstant.MY_REGISTRATION) {
      this.dataRedirectionService.setCurrentPageInSession(AuctionExtConstant.MY_REGISTRATION);
    } else if (newStatus == AuctionExtConstant.MY_INVOICES) {
      this.dataRedirectionService.setCurrentPageInSession(AuctionExtConstant.MY_INVOICES);
    }
  }

  navigateToHomePage() {
    this.dataRedirectionService.navigationToHomePage();
  }

  attachHighestBidsListener(lastFetchTimestamp: Timestamp, auctionHouseId: string) {
    logEvent(getAnalytics(), 'attachHighestBidsListener');

    let highestBidsRef = collection(this.firestore, this.collectionBidsHighestData);
    let highestBidsQuery = query(highestBidsRef,
      where('auctionHouseId', '==', auctionHouseId),
      where('updateTimeUtc', '>', lastFetchTimestamp));

    this.bidsHighestDataUnsubscribe = onSnapshot(highestBidsQuery, documentSnapshots => {
      let highestBidsList = documentSnapshots.docChanges().map(change => change.doc.data() as AuctionBidsHighestData);

      logEvent(getAnalytics(), 'attachHighestBidsListener Data Received size : ' + highestBidsList.length);
      console.log("attachHighestBidsListener Data Received size: " + highestBidsList.length);

      if (highestBidsList.length > 0 && this.userLotFavouriteWrapperDtos && this.userLotFavouriteWrapperDtos.length > 0) {
        highestBidsList.sort((a, b) => b.updateTimeUtcMillis! - a.updateTimeUtcMillis!);
        let newHighestBidData = AuctionBidsHighestData.parseAuctionBidsHighestData(highestBidsList[0]);
        let oldHighestBidData =  this.userLotFavouriteWrapperDtos.find(item => item.bidsHighestData?.lotId == newHighestBidData.lotId);
        if(oldHighestBidData){
          let index = this.userLotFavouriteWrapperDtos?.findIndex(item => newHighestBidData.lotId == item.bidsHighestData?.lotId);
          Object.assign(this.userLotFavouriteWrapperDtos![index!].bidsHighestData!, newHighestBidData);
        }
        this.myFavLotCard?.populateInitialData();
        this.changeDetector.detectChanges();
      }
    });

  }




  attachFavListener(lastFetchTimestamp: Timestamp, auctionHouseId: string, userId: string) {
    logEvent(getAnalytics(), 'attachFavListener');

    let lotFavRef = collection(this.firestore, this.collectionUserLotFavourite);
    let lotFavQuery = query(lotFavRef,
      where('auctionHouseId', '==', auctionHouseId),
      where('userId', '==', userId),
      where('updateTimeUtc', '>', lastFetchTimestamp));

    this.userLotFavouriteUnsubscribe = onSnapshot(lotFavQuery, documentSnapshots => {
      documentSnapshots.docChanges().forEach(change => {
        let data = change.doc.data() as UserLotFavouriteDto;

          if (change.type == 'removed') {
              let index = this.userLotFavouriteWrapperDtos.findIndex(item => item.auctionLotEntityDto?.lotId == data.lotId);
            if (index > -1) {
              this.userLotFavouriteWrapperDtos.splice(index, 1);
              this.filterLotFavouriteWrapperDtos$.next(this.userLotFavouriteWrapperDtos);
            }
              this.filerLotFav(this.ctrlSearch.value);
        }

        this.myFavLotCard?.populateInitialData();
        this.changeDetector.detectChanges();
      });
    });

  }


  attachFirestoreListener() {

    let userId = this.user?.userId;
    let lastFetchTimestamp = Timestamp.now();

    this.attachHighestBidsListener(lastFetchTimestamp, this.auctionHouseId!);
    this.attachFavListener(lastFetchTimestamp, this.auctionHouseId!, userId!);
  }


  clearFirestoreListener() {


    if (this.bidsHighestDataUnsubscribe) {
      this.bidsHighestDataUnsubscribe();
    }

    if (this.userLotFavouriteUnsubscribe) {
      this.userLotFavouriteUnsubscribe();
    }
  }



  ngOnDestroy(): void {
    this.clearFirestoreListener()
    if (this.allAuctionLotFavSubscription$) {
      this.allAuctionLotFavSubscription$.unsubscribe();
    }
  }
}
