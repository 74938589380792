import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';
import { UserEntityInfoDto } from './../models/user/UserEntityInfoDto';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap } from 'rxjs';
import { OrganizationUiDto } from '../models/user/AuctionHouseDto';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { ErrorService } from './error.service';
import { SuperAdminDataUIWrapperDto } from '../models/user/SuperAdminDataUIWrapperDto';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { AddUserDto } from '../models/user/AddAdminDto';
import { EmailTemplateDto } from '../models/user/EmailTemplateDto';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminDashboardService {

  _selectedAuctionHouse$ = new BehaviorSubject<OrganizationUiDto | null>(null);
  _superAdminDashboardData$ = new BehaviorSubject<SuperAdminDataUIWrapperDto | null>(null);
  _emailTemplatesList$ = new BehaviorSubject<EmailTemplateDto[]>([]);

  get getSuperAdminDashboardData$() { return this._superAdminDashboardData$; };
  get getEmailTemplatesList$() { return this._emailTemplatesList$; };
  get getSelectedAuctionHouse$() { return this._selectedAuctionHouse$; };

  constructor(
    private httpClient: HttpClient,
    private errorService: ErrorService,
  ) { }

  // LOAD DATA
  loadSuperAdminDashboardData() {
    this._getSuperAdminDashboardData().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let _superAdminDashboardData = apiResponseDto.data as SuperAdminDataUIWrapperDto;
          this._superAdminDashboardData$.next(_superAdminDashboardData);
        }
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  updateAuctionHouseList(auctionHouseDto: OrganizationUiDto, isEdit: boolean) {
    let superAdminDashboardData = this._superAdminDashboardData$.value;

    if (isEdit) {
      let index = superAdminDashboardData?.auctionHouseList?.findIndex(item => item.orgCode == auctionHouseDto.orgCode);
      if (index! > -1) {
        superAdminDashboardData?.auctionHouseList?.splice(index!, 1);
        superAdminDashboardData?.auctionHouseList?.push(auctionHouseDto);
      }
    } else {
      superAdminDashboardData?.auctionHouseList?.push(auctionHouseDto);
    }

    this._superAdminDashboardData$.next(superAdminDashboardData);
  }

  updateAdminList(adminData: UserEntityInfoDto, auctionHouseId: string) {
    let superAdminDashboardData = this._superAdminDashboardData$.value;
    let auctionHouseAdminMap = superAdminDashboardData?.auctionHouseAdminMap;

    if (!(auctionHouseAdminMap instanceof Map)) {
      auctionHouseAdminMap = AuctionExtUtil.buildMap(superAdminDashboardData?.auctionHouseAdminMap)
    }

    if (auctionHouseAdminMap) {
      if (auctionHouseAdminMap.has(auctionHouseId)) {
        let value = auctionHouseAdminMap.get(auctionHouseId) as UserEntityInfoDto[];
        let userData = value.find(item => item.userId == adminData.userId);
        if (userData) {
          Object.assign(userData, adminData);
          auctionHouseAdminMap.set(auctionHouseId, value);
        } else {
          value.push(adminData);
          auctionHouseAdminMap.set(auctionHouseId, value);
        }
      } else {
        let value = [];
        value.push(adminData);
        auctionHouseAdminMap.set(auctionHouseId, value);

      }
    }

    superAdminDashboardData!.auctionHouseAdminMap = auctionHouseAdminMap;

    this._superAdminDashboardData$.next(superAdminDashboardData);
  }

  setSelectedAuctionHouse(auctionHouse: OrganizationUiDto) {
    this._selectedAuctionHouse$.next(auctionHouse);
  }

  updateEmailTemplates(emailTemplates: Array<EmailTemplateDto>) {
    this._emailTemplatesList$.next(emailTemplates);
  }

  /// API CALLS
  saveAuctionHouse(auctionHouse: OrganizationUiDto) {
    return this.httpClient.post<ServerAPIResponseDto>('auctionHouse', auctionHouse).pipe(
      tap(_ => console.log("Saved Auction House to DB" + auctionHouse.name)),
      catchError(this.errorService.handleError<any>("Error while Saving Auction House to DB" + auctionHouse.name)))
  }

  _getSuperAdminDashboardData() {
    return this.httpClient.get<ServerAPIResponseDto>('superAdminDashboardData').pipe(
      tap(_ => console.log("Getting Super Admin Dashboard Data")),
      catchError(this.errorService.handleError<any>("Error while getting superAdminDashboardData")))
  }

  addSuperAdmin(addAdminDto: AddUserDto): Observable<any> {
    return this.httpClient.post<ServerAPIResponseDto>('addUser', addAdminDto).pipe(
      tap(_ => console.log("Super Admin Add Successfully : " + addAdminDto.emailId)),
      catchError(this.errorService.handleError("Error while adding super admin : " + addAdminDto.emailId)))
  }

  addAdmin(addAdminDto: AddUserDto): Observable<any> {
    return this.httpClient.post<ServerAPIResponseDto>('addUser', addAdminDto).pipe(
      tap(_ => console.log("Super Admin Add Successfully : " + addAdminDto.emailId)),
      catchError(this.errorService.handleError("Error while adding super admin : " + addAdminDto.emailId)))
  }

  markResourceActiveOrInActive(resourceId: string, active: boolean, actionName: string) {
    let params = new HttpParams().set('resourceId', resourceId).set('active', active).set('actionName', actionName);
    return this.httpClient.post<ServerAPIResponseDto>('markResourceActiveOrInActive', null, { params }).pipe(
      tap(_ => console.log("User active status change" + resourceId)),
      catchError(this.errorService.handleError<any>("Error while changing user active status" + resourceId)))
  }

}
