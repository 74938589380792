import { Component, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.sass']
})
export class LoaderComponent {
  _loaderEnable$ = new BehaviorSubject<boolean>(false);

  actionName?: string;
  loadingText: string = "Loading Please Wait...";

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  openLoaderIcon(action: string, loadingText: string) {
    this._loaderEnable$.next(true);
    this.loadingText = loadingText;
    this.actionName = action;
    this.changeDetectorRef.detectChanges();
  }

  closeLoaderIcon(action: string) {
    if (this.actionName == action) {
      this._loaderEnable$.next(false);
      this.loadingText = '';
      this.actionName = '';
      this.changeDetectorRef.detectChanges();
    }
  }

  closeLoaderForcefully() {
    this._loaderEnable$.next(false);
    this.loadingText = '';
    this.actionName = '';
    this.changeDetectorRef.detectChanges();
  }
}
