<div class="mt-5">
  <div class="col-md-9 m-md-auto">
    <div class="text-orange" *ngIf="!userEntityDto?.address?.searchAddress">
      <img src="assets/icons/pending-icon.svg" class="svg-icon-class c-pointer me-2"> Your address update is pending
    </div>
    <div class="text-success" *ngIf="_showSuccessToast$ | async" >
      <img src="assets/icons/filter-check.svg" class="svg-icon-class-md c-pointer me-2"> Your address is saved
    </div>
    <div>
      <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit()">
        <div class="row mt-1">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-12  mb-4">
                <div class="form-floating">
                  <input ngx-google-places-autocomplete #placesRef="ngx-places" type="text" class="form-control"
                    [ngClass]="{'is-invalid': fc.searchAddress.invalid && (fc.searchAddress.dirty || fc.searchAddress.touched)}"
                    formControlName="searchAddress" id="searchAddress" (onAddressChange)="handleAddressChange($event)" />
                  <label for="searchAddress">Search Address</label>
                  <div *ngIf="fc.searchAddress.invalid && (fc.searchAddress.dirty || fc.searchAddress.touched)">
                    <p class="text-danger" *ngIf="fc.searchAddress.errors?.required">Required</p>
                    <p class="text-danger" *ngIf="fc.searchAddress.errors?.address">Invalid</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6  mb-4">
                <div class="form-floating">
                  <input class="form-control" type="text"
                    [ngClass]="{'is-invalid': fc.addressLine1.invalid && (fc.addressLine1.dirty || fc.addressLine1.touched)}"
                    formControlName="addressLine1" id="addressLine1"/>
                  <label for="addressLine1">Address Line 1</label>
                </div>
                <div *ngIf="fc.addressLine1.invalid && (fc.addressLine1.dirty || fc.addressLine1.touched)">
                  <p class="text-danger" *ngIf="fc.addressLine1.errors?.required">Required</p>
                </div>
              </div>
              <div class="col-md-6  mb-4">
                <div class="form-floating">
                  <input class="form-control" id="addressLine2" type="text" formControlName="addressLine2" />
                  <label for="addressLine2">Address Line 2</label>
                </div>
                <div *ngIf="fc.addressLine2.invalid && (fc.addressLine2.dirty || fc.addressLine2.touched)">
                  <p class="text-danger" *ngIf="fc.addressLine2.errors?.required">Required</p>
                </div>
              </div>
              <div class="col-md-6  mb-4">
                <div class="form-floating">
                  <input class="form-control" type="text" id="city"
                    [ngClass]="{'is-invalid': fc.city.invalid && (fc.city.dirty || fc.city.touched)}"
                    formControlName="city" />
                  <label for="city">City</label>
                </div>
                <div *ngIf="fc.city.invalid && (fc.city.dirty || fc.city.touched)">
                  <p class="text-danger" *ngIf="fc.city.errors?.required">Required</p>
                </div>
              </div>
              <div class="col-md-6  mb-4">
                <div class="form-floating">
                  <input class="form-control" type="text" id="state"
                    [ngClass]="{'is-invalid': fc.state.invalid && (fc.state.dirty || fc.state.touched)}"
                    formControlName="state" />
                  <label for="state">State</label>
                </div>
                <div *ngIf="fc.state.invalid && (fc.state.dirty || fc.state.touched)">
                  <p class="text-danger" *ngIf="fc.state.errors?.required">Required</p>
                </div>
              </div>
              <div class="col-md-6  mb-4">
                <div class="form-floating">
                  <input class="form-control" type="text" id="country"
                    [ngClass]="{'is-invalid': fc.country.invalid && (fc.country.dirty || fc.country.touched)}"
                    formControlName="country" />
                  <label for="country">Country</label>
                </div>
                <div *ngIf="fc.country.invalid && (fc.country.dirty || fc.country.touched)">
                  <p class="text-danger" *ngIf="fc.country.errors?.required">Required</p>
                </div>
              </div>
              <div class="col-md-6  mb-4">
                <div class="form-floating">
                  <input class="form-control" type="text" id="zipCode"
                    [ngClass]="{'is-invalid': fc.zipCode.invalid && (fc.zipCode.dirty || fc.zipCode.touched)}"
                    formControlName="zipCode" />
                  <label for="zipCode">Pin Code</label>
                </div>
                <div *ngIf="fc.zipCode.invalid && (fc.zipCode.dirty || fc.zipCode.touched)">
                  <p class="text-danger" *ngIf="fc.zipCode.errors?.required">Required</p>
                  <p class="text-danger" *ngIf="fc.zipCode.errors?.pattern">Invalid</p>
                </div>
              </div>

              <div class="my-3">
                <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
                  [message]="errorMsg"></app-message-toast>
              </div>

              <div class="d-grid gap-2 my-3" *ngIf="isLoading">
                <div class="e-spinner center-spinner" style="margin-top: -20px !important"></div>
              </div>

              <div class="col-12 text-center" *ngIf="(!isLoading && !(_showSuccessToast$ | async))">
                <button class="btn btn-primary-clr-class btn-sm" (click)="handleValidSubmit()">Save</button>
              </div>
            </div>
          </div>
          <div class="col-md-4  mb-4">
            <div class="border bg-white p-2">
              <div class=" text-center d-flex align-items-center justify-content-center m-auto" style="height: 46vh" *ngIf="latitude == undefined">
              Address is not updated.
              </div>
              <google-map height="46vh" width="100%" [options]="options" [center]="position!" [zoom]="zoom"
                *ngIf="latitude != undefined">
                <map-marker #markerElem="mapMarker" [position]="position!" [title]="marker.title"
                  [options]="marker.options" (mapClick)="openInfo(markerElem, marker.info)">
                </map-marker>
                <map-info-window>{{ infoContent }}</map-info-window>
              </google-map>
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>