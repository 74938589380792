import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private emitChangeSource = new Subject<any>();
  private emitLogoutEvent = new Subject<any>();
  private emitBidderMandatoryEvent = new Subject<any>();
  private emitBidderBasicDetailEvent = new Subject<any>()

  changeEmitted$ = this.emitChangeSource.asObservable();
  logoutEvenEmitted$ = this.emitLogoutEvent.asObservable();
  supplierMandatoryEvenEmitted$ = this.emitBidderMandatoryEvent.asObservable();
  emitBidderBasicDetailEventEmitted$ = this.emitBidderBasicDetailEvent.asObservable();

  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }

  emitSupplierMandatoryInfo(change: any) {
    this.emitBidderMandatoryEvent.next(change);
  }

  emitLogout() {
    this.emitLogoutEvent.next(void 0);
  }

  emitBasicDetail(change: any) {
    this.emitBidderBasicDetailEvent.next(change);
  }

  constructor() { }
}
