<div class="container mt-5">
  <div class="col-8 m-auto">
   <div class="border p-2 bg-white">
  <div class="row ">
    <div class="col-md-6 ">
     <img src="assets/images/avatar05.jpg" alt="" class="profile-image-xl" >
     <div>
       <h5 class="heading-h5 mb-0">
         NAME
       </h5>
       <div class="div-class ">
         mail
       </div>
     </div>
    </div>
    <div class="col-md-6 text-md-end ">
     <img src="assets/images/avatar05.jpg" alt="" class="profile-image-xl" >
     <div>
       <h5 class="heading-h5 mb-0">
        COMPANY NAME
       </h5>
       <div class="div-class">
         mail
       </div>
     </div>
    </div>
 </div>
</div>
<div class="border p-2 mt-2 bg-white">
<div >
<h5 class="heading-h5">About</h5>
<p>
  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
</p>
</div>
</div>
<div class="border p-2 mt-2 bg-white">
  <div >
  <h5 class="heading-h5">Active Listing</h5>
  <div class="col-12 mt-3">
    <div class="row justify-content-center">
      <div class="col-1 py-5 d-none d-lg-block">
        <button class="scroll-left-icon landing-scroll-margin d-none d-lg-block"
        >
          <img src="assets/icons/leftarrow_dark.svg" class="svg-icon-class c-pointer">
        </button>
      </div>
      <div class="col-lg-10 col-12 m-auto similarLots">
        <swiper 
          style="padding-bottom: 40px">
          <ng-template  >
           <app-premium-auction-card></app-premium-auction-card>
          </ng-template>
        </swiper>
      </div>
      <div class="col-1 py-5 d-none d-lg-block">
        <button class=" scroll-right-icon landing-scroll-margin d-none d-lg-block">
          <img src="assets/icons/rightarrow_dark.svg" class="svg-icon-class c-pointer">
        </button>
      </div>
    </div>
  </div>
  </div>
  </div>
   </div>
</div>
