
import { initializeApp, getApp, FirebaseApp } from '@angular/fire/app';
import { Injectable } from "@angular/core";
import { ClientSubscriptionDto } from '../models/ClientSubscriptionDto';
import { RemoteConfig, getRemoteConfig, fetchAndActivate, getValue } from "@angular/fire/remote-config";

@Injectable({
  providedIn: 'root'
})
export class ClientSubscriptionService {
  firebaseConfig = {
    apiKey: "AIzaSyDRdTB7ddSUo4CS0kmHeTJuuTSggeu8Cug",
    authDomain: "client-subscription-prod.firebaseapp.com",
    projectId: "client-subscription-prod",
    storageBucket: "client-subscription-prod.appspot.com",
    messagingSenderId: "954934536791",
    appId: "1:954934536791:web:37c072be589e0b98f01112",
    measurementId: "G-6DPQ5VRKZV"
  };

  // firestore!: Firestore
  remoteConfig!: RemoteConfig
  subscriptionKey: string = "wsqhRPqqBQ_ESossIJ1sl_SWNulj49vd";
  clientSubscriptionDto?: ClientSubscriptionDto
  collectionName: string = "client-subscription";

  remoteConfigValue: string = '{}'

  constructor() {
    initializeApp(this.firebaseConfig, 'clientSubscription');
    let firebaseApp = getApp('clientSubscription');
    this.remoteConfig = getRemoteConfig(firebaseApp);
    this.remoteConfig.settings.minimumFetchIntervalMillis = 36000;

    this.loadSubscriptionData()
  }

  async initializeRemoteConfig() {

  }

  async loadSubscriptionData() {
    try {
      await fetchAndActivate(this.remoteConfig);
      const value = getValue(this.remoteConfig, this.subscriptionKey);
      this.remoteConfigValue = value.asString() || '{}';
      // console.log(this.remoteConfigValue);
    } catch (error) {
      console.error('Error fetching subscription config:', error);
      // Use default value in case of an error
      this.remoteConfigValue = '{}';
      // console.log(this.remoteConfigValue);
    }

    let clientSubscriptionDto = null;
    if (this.remoteConfigValue) {
      clientSubscriptionDto = JSON.parse(this.remoteConfigValue);
    }

    this.clientSubscriptionDto = clientSubscriptionDto;
  }


  isSubscriptionValid() {
    let toReturn = false;
    if (this.clientSubscriptionDto) {
      if (this.clientSubscriptionDto.subscriptionValid) {
        toReturn = true;
      }
    }
    return toReturn;
  }

  isAllowToAdminActions() {
    let toReturn = false;
    if (this.clientSubscriptionDto) {
      if (this.clientSubscriptionDto.subscriptionValid && this.clientSubscriptionDto.allowAdminActions) {
        toReturn = true;
      }
    }
    return toReturn;
  }


  isAllowToAuctionCreate() {
    let toReturn = false;
    if (this.clientSubscriptionDto) {
      if (this.clientSubscriptionDto.subscriptionValid && this.clientSubscriptionDto.allowAdminActions) {
        toReturn = true;
      }
    }
    return toReturn;
  }

}
