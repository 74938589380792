<div class="position-relative" [ngClass]="{'c-progress e-new-spinner': isLoading}">
  <div class="card card-ripple border-0 mb-3 shadow zoom rounded-20 bg-white h-358 c-pointer" (click)="navigateAuctionView()">
    <div>
      <img *ngIf="auctionEntityDto?.auctionImage" src="/downloadLandingBlob?fileId={{auctionEntityDto?.auctionImage?.fileId}}"
        [alt]="auctionEntityDto?.auctionName" class="image-premium-auction">
      <img *ngIf="!auctionEntityDto?.auctionImage" src="assets/images/quote01.png" alt="" class="image-premium-auction">
    </div>
    <div class="badge  p-2 dark-btn-position-closed-auction">
      <span class="span-class-live">Closed</span>
    </div>
    <div class="premium-card-bids-position-closed p-2 badge text-white rounded-0">
      <img src="assets/icons/hoticon.svg" class="svg-icon-class-md c-pointer" *ngIf="auctionCardWrapperDto?.totalBids && auctionCardWrapperDto?.totalBids! > 10">
       {{auctionCardWrapperDto?.totalBids}} Bids
    </div>
    <div class="premium-lots-position-closed p-2 badge text-white rounded-0" *ngIf="auctionEntityDto!.noOfActiveLots! > 1 ">
      {{ auctionEntityDto?.noOfActiveLots+" Lots" }}
    </div>
    <div class="p-2">
      <div class="mt-2 row">
        <div class="col-xl-4 text-xl-start text-start">
          <button class="button-class badge-closed-class">
            CLOSED
          </button>
        </div>
        <div class="col-md-8 text-xl-end text-start">
          <div class="row">
            <!-- <div class="col-4 text-end">
              <span>
                <small class="small-class">
                  {{(auctionEntityDto?.notOfLots! > 1) ? auctionEntityDto?.notOfLots+" Lots" : ''}}
                </small>
              </span>

            </div> -->
            <div class="col-12 text-md-end">
              <span>
                <small class="small-class" *ngIf="auctionEntityDto?.addresses && auctionEntityDto?.addresses!.length > 0">
                  {{ auctionEntityDto?.addresses![0].city}}, {{auctionEntityDto?.addresses![0].countryShortName}}
                </small>
              </span>
            </div>
          </div>
        </div>

      </div>
      <div class="mt-3">
        <div class="bold-font text-start overflow-class" ngbTooltip="{{auctionEntityDto?.auctionName}}">
          {{auctionEntityDto?.sequenceNo}} # {{auctionEntityDto?.auctionName}}
        </div>
        <!-- <div class="row">
          <div class="col-6 text-start mt-3">
            <h6 class="heading-h6 text-capitalize">
              {{getCompanyDto(this.auctionEntityDto?.listingContactDtos![0]!.companyId)!.companyName}}
            </h6>
          </div>
          <div class="col-6 text-end">
            <img src="/downloadLandingBlob?fileId={{getCompanyDto(this.auctionEntityDto?.listingContactDtos![0]!.companyId)!.companyLogo!.fileId}}"
            alt="{{this.auctionEntityDto?.listingContactDtos![0].name}}"
            class="profile-image-sm">
          </div>
        </div> -->
        <div class="mt-3 row" >
          <div class="col-3 "  >
            <div [ngClass]="screenWidth > 991 ? 'rounded-border-end' : 'border-0'">
              <img src="/downloadLandingBlob?fileId={{this.auctionEntityDto?.listingContactDtos![0].profile?.fileId}}"  class="me-0 me-md-2"
              alt="{{this.auctionEntityDto?.listingContactDtos![0].name}}" [ngClass]="screenWidth > 768 ? 'profile-image-xl' : 'profile-image-md'">
            </div>
          </div>
          <div class="col-6 mt-2 text-start">
            <div class="overflow-class-small">
              <h4 class="heading-h6  text-capitalize mb-0" ngbTooltip="{{this.auctionEntityDto?.listingContactDtos![0].name}}">
                {{this.auctionEntityDto?.listingContactDtos![0].name}}</h4>
            </div>
            <div  class="div-class-small overflow-class-small" ngbTooltip=" {{this.auctionEntityDto?.listingContactDtos![0].emailId}}">
              <img src="assets/icons/mail-icon.svg" class="svg-icon-class-small left-padding-icon c-pointer mt-0 me-1">
              {{this.auctionEntityDto?.listingContactDtos![0].emailId}}
            </div>
            <div class="div-class-small overflow-class-small" ngbTooltip="{{this.auctionEntityDto?.listingContactDtos![0].mobileNo}}">
              <img src="assets/icons/mobile-icon.svg" class="svg-icon-class-small c-pointer mt-0 me-1">
               {{getCountryCode(this.auctionEntityDto?.listingContactDtos![0].countryCode)}} {{this.auctionEntityDto?.listingContactDtos![0].mobileNo}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
