import { Injectable } from '@angular/core';
import { Configuration, OpenAIApi } from 'openai';

@Injectable({
  providedIn: 'root'
})
export class OpenAiService {
  organization: string = "org-hi7zCONkJm1fTErxk4ZpRN9k"
  openAiApiKey: string = "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"

  openAi: OpenAIApi;

  configuration = new Configuration({
    organization: this.organization,
    apiKey: this.openAiApiKey,
  });

  constructor() {
    this.openAi = new OpenAIApi(this.configuration);
  }

  async getResponse(prompt: string, length: number) {
    const response = await this.openAi.createChatCompletion({
      model: "gpt-3.5-turbo-16k",
      messages: [
        { role: "user", content: prompt }
      ],
      temperature: 0,
      // max_tokens: length,
      frequency_penalty: 1,
      presence_penalty: 1,
    });

    if (response.data.choices.length > 0) {
      let data = response.data.choices[0].message?.content;
      return data;
    } else {
      return "";
    }
  }


}
