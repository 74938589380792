import { AbstractControl, ValidatorFn, FormArray } from '@angular/forms';

// Custom validator function
export function atLeastOneCheckboxChecked(): ValidatorFn {
  return (formArray: AbstractControl): { [key: string]: any } | null => {
    if (formArray instanceof FormArray) {
      const checkedCount = formArray.controls.filter(control => control.value).length;
      return checkedCount >= 1 ? null : { 'atLeastOneChecked': true };
    }
    return null;
  };
}
