<div class="container p-3">
  <div class="col-12">
    <div class="row align-items-center">
      <div class="col-5">
        <span class="me-3">
          <a class="c-pointer" [ngClass]="{'disabled' : isDisabledPrev()}" (click)="prevQuestion()">Previous</a>
        </span>
        <span class="me-3">Q {{selectedFinancialQuestion.sequenceNo}} to {{financialQuestionsList.length}}</span>
        <span class="me-3">
          <a class="c-pointer" [ngClass]="{'disabled' : isDisabledNext()}" (click)="nextQuestion()">Next</a>
        </span>
      </div>
      <div class="col-6 text-end">
        <span class="badge-gray-class badge me-3">Incomplete-{{countIncompleteQuestions}}</span>
        <span class="badge-blue-class badge">Complete-{{countCompleteQuestions}}</span>

      </div>
      <div class="col-1 text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>

    <div class="mt-3 col-11">
      <form [formGroup]="formGroup" >
        <!-- <div class="input-group">
          <div class="form-floating">
            <input class=" form-control" formControlName="questionText">
            <label for="floatingInput">Item Criteria
              <span class="ms-2 badge badge-white-class"
                *ngIf="selectedFinancialQuestion.responseMandatory">Mandatory</span>
            </label>
          </div>
        </div> -->
        <div class="border p-2 mb-3">
          <div>
            <span class="font-weight-normal mb-2 text-dark">ITEM CRITERIA
            </span>
          </div>
          {{this.selectedFinancialQuestion.preSequenceText}}.{{this.selectedFinancialQuestion.sequenceNo}} {{this.selectedFinancialQuestion.questionText}}
        </div>
        <div class="border p-2">
          <div>
            <span class="font-weight-normal mb-2 text-dark">ITEM DESCRIPTION
              <!-- <span class="ms-3 badge badge-gray-class-small-1 mt-1"
                *ngIf="selectedFinancialQuestion.responseMandatory">Mandatory</span> -->
            </span>
          </div>
          {{this.selectedFinancialQuestion.questionDescription}}
        </div>

        <!-- <div class="input-group mt-3">
          <div class="form-floating">
            <input class=" form-control" formControlName="questionDescription">
            <label for="floatingInput">Item Description
            </label>
          </div>
        </div> -->

        <div class="mt-3">
          <span class="badge-blue-class px-3 py-1 badge me-3">Qty - {{selectedFinancialQuestion.quantity}}</span>
          <span class="badge-blue-class px-3 py-1 badge me-3">UoM - {{selectedFinancialQuestion.uom}}</span>
          <span class="badge-blue-class px-3 py-1 badge me-3">Tax - {{selectedFinancialQuestion.tax ?
            selectedFinancialQuestion.tax + '%' : 'NA'}}</span>
        </div>

        <div class="mt-3">
          Enter Unit Price Bid
        </div>
        <div class="row">
          <div class="col-md-4 mb-2">
            <div class="input-group mb-2">
              <input class=" form-control" formControlName="bidderResponse" (keyup)="refreshAmountValues()"
                type="number" [ngClass]="{'border-danger': fc['bidderResponse'].invalid && (fc['bidderResponse'].dirty || fc['bidderResponse'].touched)}">
            </div>
            <div *ngIf="fc['bidderResponse'].invalid && (fc['bidderResponse']?.dirty || fc['bidderResponse']?.touched)">
              <p class="text-danger" *ngIf="fc['bidderResponse']?.errors?.['required']">Required</p>
            </div>

            <div class="mt-2" formArrayName="additionalPrices">
              <div class="row mb-2" *ngFor="let item of additionalPrices.controls; index as i" [formGroupName]="i">
                <div>
                  {{item.get('label')?.value}} ({{item.get('mandatory')?.value ? 'Mandatory' : 'Optional'}})
                </div>
                <div class="input-group mb-2">
                  <input class=" form-control" formControlName="additionalPrice" (keyup)="refreshAmountValues()" type="number"
                    [ngClass]="{'is-invalid': item.get('additionalPrice')?.invalid && (item.get('additionalPrice')?.dirty || item.get('additionalPrice')?.touched)}">
                </div>
                <div *ngIf="item.get('additionalPrice')?.invalid && (item.get('additionalPrice')?.dirty || item.get('additionalPrice')?.touched)">
                  <p class="text-danger" *ngIf="item.get('additionalPrice')?.errors?.['required']">Required</p>
                </div>
              </div>
            </div>

          </div>
          <div class="col-md-2"></div>

          <div class="col-md-6">
            <div class="mb-2 row">
              <div class="div-class col-6 col-md-4">Total Price</div>
              <div class="col-md-8 col-6">
                {{getFormattedPrice(totalBidPrice)}}
              </div>
            </div>
            <div class="mb-2 row">
              <div class="div-class col-6 col-md-4">Tax Value</div>
              <div class="col-md-8 col-6">
                {{getFormattedPrice(totalTaxAmount)}}
              </div>
            </div>
            <div class="mb-2 row">
              <div class="div-class col-6 col-md-4">Additional</div>
              <div class="col-md-8 col-6">
                {{getFormattedPrice(totalAdditionalAmount)}}
              </div>
            </div>
            <hr class="hr my-2 w-50 ">
            <div class="text-blue">
              Total Bid Amount: {{subcategoryEntityDto?.currency?.symbol}}{{getFormattedPrice(totalFinalAmount)}}<br>
              {{subcategoryEntityDto?.currency?.symbol}} {{getTotalFinalAmountInWords()}} (in words)
            </div>
          </div>
        </div>

        <div class="mb-3" *ngIf="!isQuestionSubmitted">
          <a class="link-class" (click)="clearOptionSelection()">Clear Value</a>
        </div>
        <div class="mb-3">
          <label for="floatingInput">Comments
          </label>
              <textarea class=" form-control"formControlName="bidderComments"></textarea>

        </div>


        <div class="my-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async"
            [message]="'Item saved successfully.'"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg"></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading"></div>
        </div>

        <div class=" text-center col-12" *ngIf="!(_showSuccessToast$ | async) && !isLoading && !isQuestionSubmitted">
          <button class="btn btn-sm btn-blue-clr-class" (click)="saveQuestion()"
            [disabled]="this.formGroup.invalid">Save Draft</button>
        </div>

      </form>
    </div>

  </div>
</div>
