export class AuctionExtConstant{
  static USER_REGISTRATION_PROGRESS = "USER_REGISTRATION_PROGRESS";
  static SIGNIN_IN = "SIGNIN_IN";
  static LOADER_NOTIFICATION = "LOADER_NOTIFICATION";
  static SAVING_DATA = "SAVING_DATA";
  static SUCCESS_CODE = "200";
  static VERIFING_OTP = "VERIFING_OTP";
  static FETCHING_DATA = "FETCHING_DATA";
  static ADMIN_DASHBOARD_AUCTION_TAB = "ADMIN_DASHBOARD_AUCTION_TAB";
  static ADMIN_DASHBOARD_ADMIN_TAB = "ADMIN_DASHBOARD_ADMIN_TAB";
  static ADMIN_DASHBOARD_AUCTIONEER_TAB = "ADMIN_DASHBOARD_AUCTIONEER_TAB";
  static ADMIN_DASHBOARD_IN_HOUSE_ADMIN_TAB = "ADMIN_DASHBOARD_IN_HOUSE_ADMIN_TAB";
  static ADMIN_DASHBOARD_BIDDER_TAB = "ADMIN_DASHBOARD_BIDDER_TAB";
  static ADMIN_DASHBOARD_POST_AUCTION_TAB = "ADMIN_DASHBOARD_POST_AUCTION_TAB";
  static ADMIN_DASHBOARD_GLOBAL_AUCTION_TAB = "ADMIN_DASHBOARD_GLOBAL_BIDDERS_TAB";
  static ADMIN_DASHBOARD_AUCTION_HOUSE_SETTING = "ADMIN_DASHBOARD_AUCTION_HOUSE_SETTING";
  static ADMIN_DASHBOARD_AUCTION_CREATOR = "ADMIN_DASHBOARD_AUCTION_CREATOR";
  static ADMIN_DASHBOARD_REGISTRATION = "ADMIN_DASHBOARD_REGISTRATION";
  static ADMIN_DASHBOARD_BIDDING = "ADMIN_DASHBOARD_BIDDING";
  static ADMIN_DASHBOARD_QUESTIONS = "ADMIN_DASHBOARD_QUESTIONS";
  static ADMIN_DASHBOARD_SINGLE_LOT_DETAILS = "ADMIN_DASHBOARD_SINGLE_LOT_DETAILS";
  static ADMIN_DASHBOARD_MULTI_LOT_DETAILS = "ADMIN_DASHBOARD_MULTI_LOT_DETAILS";
  static ADMIN_AUCTION_ANALYTICS_DASHBOARD = "ADMIN_AUCTION_ANALYTICS_DASHBOARD";

  static AUCTION_CALENDAR = "AUCTION_CALENDAR";
  static AUCTION_CATALOGUE = "AUCTION_CATALOGUE";
  static ALL_AUCTIONS = "ALL_AUCTIONS";
  static CLOSED_AUCTIONS = "CLOSED_AUCTIONS";
  static MY_DASHBOARD = "MY_DASHBOARD";
  static MY_DASHBOARD_BIDS = "MY_DASHBOARD_BIDS";
  static MY_REGISTRATION = "MY_REGISTRATION";
  static MY_INVOICES = "MY_INVOICES";
  static UNSUBSCRIBE_NOTIFICATION = "UNSUBSCRIBE_NOTIFICATION";

  static FOLLOW = "FOLLOW";
  static SEEN = "SEEN";
  static SHARE = "SHARE";

  static PAGE_SIZE = 10;
  static SHOW_MORE_SIZE = 100;

  /// Console
  static DEBUG_CONSOLE = "DEBUG_CONSOLE";
  static LOG_CONSOLE = "LOG_CONSOLE";
  static CONFIG_CONSOLE = "CONFIG_CONSOLE";
  static FORWARD_AUCTION = "FORWARD_AUCTION";
  static REVERSE_AUCTION = "REVERSE_AUCTION";
  static ADMIN_DASHBOARD_GLOBAL_BIDDERS_TAB = 'ADMIN_DASHBOARD_GLOBAL_BIDDERS_TAB';

  // Pages
  static SINGLE_LOT = "SINGLE_LOT";
  static MULTI_LOT = "MULTI_LOT";
  static ANY_CONTEXT = "ANY_CONTEXT";

  static AUCTION = "AUCTION";
  static RFX = "RFX";

  static ANY_PAGE = "ANY_PAGE";

  static AUCTION_CREATOR = "AUCTION_CREATOR";

  // Tabs
  static AUCTION_TAB = "AUCTION_TAB";
  static REGISTRATION_TAB = "REGISTRATION_TAB";
  static BIDDING_TAB = "BIDDING_TAB";
  static QUESTIONS_TAB = "QUESTIONS_TAB";
  static POST_AUCTION_TAB = "POST_AUCTION_TAB";

  // Page Name
  static LANDING = "LANDING";
  static SUPPLIER = "SUPPLIER";
  static ADMIN = "ADMIN";

  static DASHBOARD_PAGE = "DASHBOARD_PAGE";
  static LIVE_PROJECTS_PAGE = "LIVE_PROJECTS_PAGE";
  static PAST_PROJECTS_PAGE = "PAST_PROJECTS_PAGE";
  static PO_AND_INVOICE_PAGE = "PO_AND_INVOICE_PAGE";
  static ANALYTICS_PAGE = "ANALYTICS_PAGE";
  static REPORTING_PAGE = "REPORTING_PAGE";
  static CATALOGUE_PAGE = "CATALOGUE_PAGE";
  static SETTING_PAGE = "SETTING_PAGE";
  static PURCHASE_ORDER_PAGE = "PURCHASE_ORDER_PAGE";
  static INVOICE_PAGE = "INVOICE_PAGE";
  static QUESTIONNAIRE_PAGE = "QUESTIONNAIRE_PAGE";
  static MY_REGISTRATIONS_PAGE = "MY_REGISTRATIONS_PAGE";
  static MY_BIDS_PAGE = "MY_BIDS_PAGE";
  static MY_FAVOURITES_PAGE = "MY_FAVOURITES_PAGE";
  static MY_TEAM_MEMBERS = "MY_TEAM_MEMBERS";

  //Rfx
  static RFX_SUBCATEGORY_LIST = "RFX_SUBCATEGORY_LIST";
  static RFX_SUBCATEGORY_DETAILS = "RFX_SUBCATEGORY_DETAILS";
}



