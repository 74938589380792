<div class="bg-white-new">
  <div class="container">
    <!-- <div class="pt-1">
      <img src="assets/icons/home_light.svg" class="svg-icon-class c-pointer me-2" (click)="navigateToHomePage()">
      <h5 class=" heading-h3 text-behind-svg-icon">DASHBOARD</h5>
    </div> -->
    <div class="border p-2 mt-3 mb-3 bg-white">
      <div class="row">
        <div class="col-auto mt-1">
          <img src="assets/icons/home_light.svg" class="svg-icon-class c-pointer" (click)="navigateToHomePage()">
          <span><img src="assets/icons/forward-icon.svg"
              class="svg-icon-class-small opacity-svg-icon c-pointer mt-1  ps-2"></span>
        </div>
        <div class="col-auto mt-2 ps-0">
          <span>
            <span class="text-secondary span-class " routerLink="/allAuctions">
              DASHBOARD
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="">
      <section>
        <div class="col-12 text-center">
          <div class="btn btn-sm  c-pointer landing-button-width-change shadow zoom mt-2  me-md-3 py-3"
            [ngClass]="currentStatus == 'MY_DASHBOARD' ? 'selected-tiles' : 'btn-outline-secondary'"
            (click)="changeStatus('MY_DASHBOARD')">
            <div class="m-1">
              <!-- <div class="text-center bold-size-font"><b>
                  20
                </b></div> -->
              <div class="row">
                <div class="text-center mt-2 col-12">
                  <h4 class="heading-h4" [ngClass]="{'text-white': currentStatus == 'MY_DASHBOARD'}"> MY FAVOURITE LOTS
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="btn btn-sm  c-pointer landing-button-width-change shadow zoom mt-2  me-md-3 py-3"
            [ngClass]="currentStatus == 'MY_DASHBOARD_BIDS' ? 'selected-tiles' : 'btn-outline-secondary'"
            (click)="changeStatus('MY_DASHBOARD_BIDS')">
            <div class="m-1">
              <div class="row">
                <div class="text-center mt-2 col-12">
                  <h4 class="heading-h4" [ngClass]="{'text-white': currentStatus == 'MY_DASHBOARD_BIDS'}">MY BIDS</h4>
                </div>
              </div>
            </div>
          </div>

          <div class="btn btn-sm  c-pointer landing-button-width-change shadow zoom mt-2  me-md-3 py-3"
            [ngClass]="currentStatus == 'MY_REGISTRATION' ? 'selected-tiles' : 'btn-outline-secondary'"
            (click)="changeStatus('MY_REGISTRATION')">
            <div class="m-1">
              <div class="row">
                <div class="text-center mt-2 col-12">
                  <h4 class="heading-h4" [ngClass]="{'text-white': currentStatus == 'MY_REGISTRATION'}">MY REGISTRATIONS
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="btn btn-sm  c-pointer landing-button-width-change shadow zoom mt-2  py-3 me-md-3"
            [ngClass]="currentStatus == 'MY_INVOICES' ? 'selected-tiles' : 'btn-outline-secondary'"
            (click)="changeStatus('MY_INVOICES')">
            <div class="m-1">
              <div class="row">
                <div class="text-center mt-2 col-12">
                  <h4 class="heading-h4" [ngClass]="{'text-white': currentStatus == 'MY_INVOICES'}">MY INVOICES</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div class="mt-4">
        <div class="" *ngIf="currentStatus == 'MY_DASHBOARD'">
          <div class="row">
            <div class="col-md-6"></div>

            <div class="col-md-6 text-md-end">
              <div class="row mt-1 justify-content-md-end me-2">
                <div class="col-md-2">
                </div>
                <div class="col-2">
                  <!-- <button class="btn btn-new-class btn-sm me-2 mt-1" (click)="loadInvoiceData()">
              <img src="assets/icons/refresh_dark.svg" class=" c-pointer svg-icon-class">
            </button> -->
                </div>
                <div class="col-8 col-md-6">
                  <div class="input-group">
                    <input placeholder="Search" id="ctrlSearch" type="text" class="form-control border-end-0 rounded-0 border bg-white"
                      [formControl]="ctrlSearch" />
                    <span class="input-group-append">
                      <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
                        <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
                      </button>
                    </span>
                  </div>
                </div>

              </div>


              <!-- <div class=" input-group mt-1">
          <input placeholder="Search" type="text" class="form-control border-end-0 rounded-0 border bg-white"
          [formControl]="ctrlSearch" />
          <span class="input-group-append">
            <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5"
             type="button">
              <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
            </button>
          </span>
        </div> -->
            </div>
          </div>
          <hr class="hr my-3" />
          <div class="e-spinner" *ngIf="!(favouriteDataLoaded$ | async)"></div>
          <div *ngIf="(favouriteDataLoaded$ | async)">
            <div *ngFor="let item of filterLotFavouriteWrapperDtos$ | async">
              <div class="col-12">
                <app-my-favourite-lot-card #myFavLotCardRef
                  [userLotFavouriteWrapperDto]="item"></app-my-favourite-lot-card>
              </div>
            </div>
            <div class="col-12 text-center" *ngIf="(filterLotFavouriteWrapperDtos$ | async)?.length ==0">
              <div class="row justify-content-center">
                <div class="col-auto my-3">
                  <h3 class="heading-new text-dark">NO LOTS AVAILABLE</h3>
                </div>
              </div>
            </div>
          </div>

        </div>

        <app-my-bids *ngIf="currentStatus == 'MY_DASHBOARD_BIDS'"></app-my-bids>
        <app-my-registrations *ngIf="currentStatus == 'MY_REGISTRATION'"></app-my-registrations>
        <app-my-generated-invoice *ngIf="currentStatus == 'MY_INVOICES'" [selectedAuctionHouseId]="auctionHouseId"
          [selectedUserId]="this.user?.userId"></app-my-generated-invoice>
      </div>
    </div>
  </div>

</div>