
<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>preview  Image</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
 <div class="model-body-top">
  <div class="m-3 ">
    <div class="text-center">
      <img [src]="imageUrl" alt="" class="preview-modal-image">
    </div>
  
    </div>
 </div>
</div>
