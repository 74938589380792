import { Component } from '@angular/core';

@Component({
  selector: 'app-financial-question',
  templateUrl: './financial-question.component.html',
  styleUrls: ['./financial-question.component.sass']
})
export class FinancialQuestionComponent {
  isTechnicalActive = true;
  isQuestionnaireAvailable = true;
  isSelectOption = true;

}
