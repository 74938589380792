import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LotBidHistoryComponent } from './../../../shared/components/lot-bid-history/lot-bid-history.component';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { Currency } from 'src/app/shared/models/Currency';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuctionBidsHighestData } from 'src/app/shared/models/user/AuctionBidsHighestData';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';
import { UserBidsData } from 'src/app/shared/models/user/UserBidsData';
import { UserBidsHistoryUIData } from 'src/app/shared/models/user/UserBidsHistoryUIData';
import { UserMaxBidsDto } from 'src/app/shared/models/user/UserMaxBidsDto';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { FirestoreListenerService } from 'src/app/shared/services/firestore-listener.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';
import { ServerDataFetchService } from 'src/app/shared/services/server-data-fetch.service';
import { MyBidsWrapperDto } from 'src/app/shared/models/user/MyBidsWrapperDto';

@Component({
  selector: 'app-my-bids',
  templateUrl: './my-bids.component.html',
  styleUrls: ['./my-bids.component.sass']
})
export class MyBidsComponent implements OnInit, AfterViewInit {
  // lotBidsHighestDataList: Array<AuctionBidsHighestData> = []
  // myUserBids: Array<UserBidsData> = []
  // myUserMaxBids: Array<UserMaxBidsDto> = []
  // auctionsList: Array<AuctionEntityDto> = []
  // lotsList: Array<AuctionLotEntityDto> = []
  // myBidsData: Array<any> = []
  filteredMyBidsData$ = new BehaviorSubject<any[]>([]);
  userBidsHistoryUIDataList: Array<UserBidsHistoryUIData> = []
  myBidsWrapperDtos: MyBidsWrapperDto[] = []

  collapseRow?: number
  isLoading: boolean = false;
  isDataLoading: boolean = false;
  selectedLotTitle: string = 'Select Lot';
  selectedMyBidWrapper: any;
  pageSize: number = 50;
  page: number = 1;
  ctrlSearch: FormControl = new FormControl('')

  constructor(
    private ngbModal : NgbModal,
    private bidderService: LandingAndBidderService,
    private firestoreService: FirestoreListenerService,
    private userService: UserService,
    private serverDataFetchService: ServerDataFetchService
  ) {}

  ngOnInit(): void {
    // this.bidderService.getMyBidsData$.subscribe((data) => {

    //   if (data) {
    //     this.resetAllList();
    //     this.lotBidsHighestDataList = data.bidsHighestData!;
    //     this.myUserBids = data.userBidsData!;
    //     this.myUserMaxBids = data.userMaxBidsData!;
    //     this.auctionsList = data.auctionsData!;
    //     this.lotsList = data.lotsData!;

    //     this.myBidsData = []
    //     //populate bid and max bids data
    //     if (this.myUserBids && this.myUserBids.length) {

    //       for (let i = 0; i < this.myUserBids.length; i++) {
    //         let myUserBid = this.myUserBids[i];
    //         let myUserMaxBidAmount;
    //         if (this.myUserMaxBids && this.myUserMaxBids.length > 0) {
    //           let myUserMaxBid = this.myUserMaxBids.filter(item => item.auctionId == myUserBid.auctionId && item.lotId == myUserBid.lotId)[0];
    //           if (myUserMaxBid) {
    //             myUserMaxBidAmount = myUserMaxBid.bucketSize;
    //           }
    //         }

    //         myUserBid.bucketPrice = myUserMaxBidAmount;
    //         this.myBidsData.push(myUserBid)
    //       }
    //     }

    //     if(this.myUserMaxBids && this.myUserMaxBids.length > 0 &&  (!this.myUserBids || this.myUserBids.length == 0)){
    //       this.myBidsData = this.myUserMaxBids;
    //     }

    //     this.isLoading = false;



    //     for (const bidData of this.myBidsData) {
    //       let selectedAuction = this.auctionsList.find((item) => item.auctionId == bidData.auctionId);
    //       let selectedLot = this.lotsList.find((item) => item.lotId == bidData.lotId);

    //       bidData['auctionSequence'] = selectedAuction?.sequenceNo;
    //       bidData['auctionName'] = selectedAuction?.auctionName;
    //       bidData['lotSequence'] = selectedLot?.lotSequence;
    //       bidData['lotName'] = selectedLot?.title;
    //       bidData['timeZone'] = selectedAuction?.timeZone;
    //       bidData['currency'] = selectedAuction?.currency;
    //     }

    //     this.filteredMyBidsData$.next(this.myBidsData)
    //     this.paginateBidderHistoryList();
    //   }
    // })

    this.ctrlSearch.valueChanges.subscribe((val) => {
      if (val) {
        let cloneMyBidsWrapperDtos = [...this.myBidsWrapperDtos];
        let filteredMyBidsData = cloneMyBidsWrapperDtos.filter((item) => item.lotName?.toLowerCase()?.includes(val.toLowerCase()) || item.auctionName?.toLowerCase()?.includes(val.toLowerCase()));
        this.filteredMyBidsData$.next(filteredMyBidsData);

      } else {
        this.paginateBidderHistoryList();

      }
    })
  }

  ngAfterViewInit(): void {
    this.loadMyBidsData();
  }

  async loadMyBidsData() {
    let myBidsWrapperDtos = await this.serverDataFetchService.getMyBidsDetailsDataSync();
    if (myBidsWrapperDtos) {
      this.myBidsWrapperDtos = myBidsWrapperDtos;
      this.isLoading = false;

      this.filteredMyBidsData$.next(this.myBidsWrapperDtos)
      this.paginateBidderHistoryList();
    }
  }



  selectSingleLot(lotId: string, lotName: string) {
    if (lotId == 'ALL') {
      this.selectedLotTitle = 'Select Lot';
      this.filteredMyBidsData$.next(this.myBidsWrapperDtos);
    } else {
      this.selectedLotTitle = lotName;
      let cloneMyBidsData = [...this.myBidsWrapperDtos];
      let filteredMyBidsData = cloneMyBidsData.filter((item) => item.lotId == lotId);
      this.filteredMyBidsData$.next(filteredMyBidsData);
    }
  }

  isMyBidIsHighest(myBidsWrapper: MyBidsWrapperDto) {
    let toReturn = false;

    if (myBidsWrapper.userBid?.userId == myBidsWrapper?.highBidData?.highestBidUserId) {
      toReturn = true;
    }

    return toReturn;
  }

  isMyMaxBidIsHighest(myBidsWrapper: MyBidsWrapperDto) {
    let toReturn = false;

    if (myBidsWrapper.userBid?.userId == myBidsWrapper?.highBidData?.bucketUserId) {
      toReturn = true;
    }

    return toReturn;
  }

  rowCollapse(index: number, auctionId: string, lotId: string) {
    if (this.collapseRow == undefined || this.collapseRow != index) {
      this.collapseRow = index;
      this.getUserBidsHistoryData(auctionId, lotId);
    } else {
      this.collapseRow = undefined;
    }
  }

  getUserBidsHistoryData(auctionId: string, lotId: string) {
    this.isDataLoading = true;
    this.userBidsHistoryUIDataList = [];

    let userEntity = this.userService.getUserEntity();
    if (userEntity) {
      this.bidderService.getUserBidsHistoryData(auctionId, lotId, userEntity.userId!).subscribe({
        next: (apiResponseDto: ServerAPIResponseDto) => {
          if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
            this.isDataLoading = false;
            this.userBidsHistoryUIDataList = apiResponseDto.data as Array<UserBidsHistoryUIData>;
            this.userBidsHistoryUIDataList.sort((a, b) => b.bidTimeUtc! - a.bidTimeUtc!)
          }
        },
        error: (error) => {
          console.error(error);
          this.isDataLoading = false;
        }
      })
    }
  }

  refreshMyBids() {
    this.isLoading = true;
    this.ctrlSearch.reset();
    this.loadMyBidsData();
  }

  getBidTimestamp(myBidsWrapper: MyBidsWrapperDto) {
    if (myBidsWrapper.userBid) {
      return this.getDisplayDate(myBidsWrapper.userBid.updateTimeUtcMillis!, myBidsWrapper.timeZone!, false);
    } else {
      return this.getDisplayDate(myBidsWrapper.userMaxBid?.updateTimeUtcMillis!, myBidsWrapper.timeZone!, false);
    }
  }

  getDisplayDate(utcTime: number, timezone: string, isFirestoreTimestamp: boolean) {
    return AuctionExtUtil.getLocalTime(utcTime, timezone, isFirestoreTimestamp);
  }

  getFormattedPrice(currency: Currency, price?: number) {
    if (price != undefined) {
      let formattedPrice = AuctionExtUtil.getFormattedPrice(currency?.locale, price);
      return `${currency?.symbol}${formattedPrice}`;
    }

    return '';
  }


  openBidHistory(auctionId: string, lotId: string) {
    let modalRef = this.ngbModal.open(LotBidHistoryComponent, {size: 'md', backdrop: 'static', keyboard: false , centered: true});
    modalRef.componentInstance.auctionId = auctionId;
    modalRef.componentInstance.lotId = lotId;
    modalRef.componentInstance.isShowHeader = true;

  }

  openBidHistoryByAuctionIdAndLotIdAndUserId(content: any, selectedMyBidWrapper: any) {

    this.selectedMyBidWrapper = selectedMyBidWrapper;
    this.getUserBidsHistoryData(selectedMyBidWrapper.auctionId, selectedMyBidWrapper.lotId)
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    }
    );
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.paginateBidderHistoryList();
  }

    // Filter
    paginateBidderHistoryList() {
      if (this.myBidsWrapperDtos && this.myBidsWrapperDtos.length > 0) {
        this.filteredMyBidsData$.next(this.myBidsWrapperDtos.map((history, i) => ({ id: i + 1, ...history })).slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize,
        ));
      } else {
        this.filteredMyBidsData$.next([]);
      }
    }

  closeModal() {
    this.ngbModal.dismissAll();
  }
}
