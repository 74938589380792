<div class="">
  <div class="row">
    <div class="col-6">
      <h4 class="heading-h5">
        Lot Ratings
      </h4>
    </div>
    <div class="col-md-6 text-md-end div-class c-pointer text-capitalize btn-link" (click)="openLotRatingModal(lotRatingModal)">
      Rate the Lot
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-xl-8" (click)="openLotRatingModal(lotRatingModal)">
      <span *ngFor="let item of [1,2,3,4,5]">
        <img src="assets/icons/yellow-star.svg" class="svg-icon-class" *ngIf="averageRating >= item">
        <img src="assets/icons/half-fill-star.svg" class="svg-icon-class"
          *ngIf="(averageRating < item) && (averageRating > item - 1)">
        <img src="assets/icons/yellow-border-star.svg" class="svg-icon-class" *ngIf="averageRating <= (item - 1)">
      </span>
      <span class="span-class"> {{averageRating}} out of 5</span>
    </div>
    <div class="div-class col-xl-4 text-xl-end mt-2 mt-lg-0">
      {{userRatingDtoList.length}} Ratings
    </div>
  </div>

  <div class="row mt-3">
    <div class="row hover-effect mt-1" (click)="viewRatingListModal(ratingListModal, 5)">
      <div class="col-3  div-class">
        5 star
      </div>
      <div class="col-6 px-0">
        <progress id="file" [value]="getPercentOfRating(5)" max="100" class="progress-bar-class"> {{getPercentOfRating(5)}}% </progress>
      </div>
      <div class=" text-end col-3 div-class c-pointer margin-top-2px">
        {{getNoOfRatingCount(5)}}
      </div>
    </div>
    <div class="row hover-effect mt-1" (click)="viewRatingListModal(ratingListModal, 4)">
      <div class="col-3  div-class">
        4 star
      </div>
      <div class="col-6 px-0">
        <progress id="file" [value]="getPercentOfRating(4)" max="100" class="progress-bar-class">{{getPercentOfRating(4)}}%</progress>
      </div>
      <div class="col-3 text-end  div-class c-pointer margin-top-2px">
        {{getNoOfRatingCount(4)}}
      </div>
    </div>
    <div class="row hover-effect mt-1" (click)="viewRatingListModal(ratingListModal, 3)">
      <div class=" col-3  div-class">
        3 star
      </div>
      <div class="col-6 px-0">
        <progress id="file" [value]="getPercentOfRating(3)" max="100" class="progress-bar-class"> {{getPercentOfRating(3)}}% </progress>
      </div>
      <div class=" text-end col-3  div-class c-pointer margin-top-2px">
        {{getNoOfRatingCount(3)}}
      </div>
    </div>
    <div class="row hover-effect mt-1" (click)="viewRatingListModal(ratingListModal, 2)">
      <div class=" col-3 div-class">
        2 star
      </div>
      <div class="col-6 px-0">
        <progress id="file" [value]="getPercentOfRating(2)" max="100" class="progress-bar-class"> {{getPercentOfRating(2)}}% </progress>
      </div>
      <div class=" text-end col-3  div-class c-pointer margin-top-2px">
        {{getNoOfRatingCount(2)}}
      </div>
    </div>
    <div class="row hover-effect mt-1" (click)="viewRatingListModal(ratingListModal, 1)">
      <div class=" col-3  div-class">
        1 star
      </div>
      <div class="col-6 px-0">
        <progress id="file" [value]="getPercentOfRating(1)" max="100" class="progress-bar-class"> {{getPercentOfRating(1)}}% </progress>
      </div>
      <div class=" text-end col-3 div-class c-pointer margin-top-2px">
        {{getNoOfRatingCount(1)}}
      </div>
    </div>
  </div>
</div>


<ng-template #ratingListModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Rating List</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-class">
      <div class="m-3">
        <form class="needs-validation" novalidate>
          <div class="table-responsive">
            <table class="table">
              <tbody>
                <tr *ngFor="let item of userRatingDtoList; index as i">
                  <th scope="row" class="pt-3 position-relative">{{i+1}}</th>
                  <td class="">
                    <img src="/downloadLandingBlob?fileId={{item?.profileImage?.fileId}}" [alt]="item?.name" class="profile-image-sm mt-0" *ngIf="item?.profileImage">
                    <img src="assets/images/avatar05.jpg" [alt]="item?.name" class="profile-image-sm mt-0" *ngIf="!item?.profileImage">
                  </td>
                  <td class="pt-3 position-relative">{{item?.name}}</td>
                  <td class="pt-3 position-relative">{{getDisplayDateOfRating(item?.timestamp)}}</td>
                  <td class="pt-3 position-relative">
                    <div>
                      <span *ngFor="let rating of [1,2,3,4,5]">
                        <img src="assets/icons/yellow-star.svg" class="svg-icon-class" *ngIf="item.rating! >= rating">
                        <img src="assets/icons/yellow-border-star.svg" class="svg-icon-class" *ngIf="item.rating! <= (rating - 1)">
                      </span>
                    </div>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #lotRatingModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Rate the Lot</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-class">
      <div class="m-3">
        <form class="needs-validation" novalidate>
          <div class="text-center">

            <div class="mb-3">
              <span *ngFor="let item of [1,2,3,4,5]">
                <img src="assets/icons/yellow-star.svg" class="svg-icon-class" *ngIf="selectedRating >= item"
                  (click)="selectRating(item)">
                <img src="assets/icons/yellow-border-star.svg" class="svg-icon-class" *ngIf="selectedRating < item"
                  (click)="selectRating(item)">
              </span>
            </div>

            <div class="mb-3">
              <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'ThankYou for your feedback'"></app-message-toast>
              <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="'Error while submitting feedback!'"></app-message-toast>
            </div>

            <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
              <div class="e-spinner"></div>
            </div>

          </div>
          <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="btn btn-new-class btn-sm" (click)="saveRating()">SUBMIT</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
