<button class="btn btn-sm text-capitalize text-white m-1" [style.background]="getConfig.color" (click)="share()">
  <fa-icon [icon]="getIcon"></fa-icon> {{getConfig.text}}
</button>

<ng-template #barCodeTemp>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>QR Code</h2>
        </div>
        <div class="col-auto text-end">
          <fa-icon [icon]="['fas', 'download']" class=" fa-lg me-3 c-pointer" (click)="downloadQRCode()"></fa-icon>
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
  </div>
  <div class="page-divider"></div>
 <div class="model-body-top">
  <div class="m-3">
    <div class="col-12">
      <img src="/downloadLandingBlob?fileId={{fileInfoDto?.fileId!}}" alt="BarCode">
    </div>

  </div>
 </div>

  </ng-template>
