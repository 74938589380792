import { AllAuctionsComponent } from './../user/all-auctions/all-auctions.component';
import { BehaviorSubject, Observable, Subscription, filter } from 'rxjs';
import { CalendarService } from './../../services/calendar.service';
import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { FormControl } from '@angular/forms';
import { DatePipe, KeyValue } from '@angular/common';
import { AuctionExtUtil } from '../../util/AuctionExtUtil';
import { SourcingCalenderEventDto } from '../../models/user/SourcingCalenderEventDto';

@Component({
  selector: 'app-calendar-list',
  templateUrl: './calendar-list.component.html',
  styleUrls: ['./calendar-list.component.sass']
})
export class CalendarListComponent implements OnInit, OnDestroy{

  @Input() calenderEventList$ = new Observable<SourcingCalenderEventDto[]>();
  @Output() onCalenderEventSelected = new EventEmitter<SourcingCalenderEventDto>()

  auctionListSubscription$?: Subscription;
  ctrlLotSearch: FormControl = new FormControl('');

  allAuctions: SourcingCalenderEventDto[] = [];

  filterListData$ = new BehaviorSubject<SourcingCalenderEventDto[]>([]);
   auctionMap = new Map<string, SourcingCalenderEventDto[]>();
   calenderEventMap$ = new BehaviorSubject<Map<string, SourcingCalenderEventDto[]> | null>(null);


  constructor(private calendarService: CalendarService, private datePipe: DatePipe ){}


  ngOnInit(): void {
    this.auctionListSubscription$ = this.calenderEventList$.subscribe(allAuctions => {
      if(allAuctions && allAuctions.length > 0){
        this.allAuctions = allAuctions;
        this.allAuctions.sort((a, b) => AuctionExtUtil.convertedDate(a.startDate!, a.startTime!)!.getTime() - AuctionExtUtil.convertedDate(b.startDate!, b.startTime!)!.getTime());

        this.auctionFilterData();
        this.calendarService.updateSelectedSourcingEventDto(null);
      }else{
        this.allAuctions = []
        this.auctionFilterData();
      }
    })

    this.ctrlLotSearch.valueChanges.subscribe((val) => {
      this.auctionFilterData()
    })

  }




  auctionFilterData(){
    let searchText = this.ctrlLotSearch.value;
    let tempList  = this.allAuctions.filter(item => item.status == 'LIVE' || item.status == 'LIVE_WAIT');

    if (searchText && searchText.toString().trim().length > 0) {
      tempList = tempList.filter((item) =>
        item.sequenceNo == Number(searchText)
        || item.eventName?.toLowerCase().includes(searchText.toLowerCase())
        || item.auctionType?.toLowerCase().includes(searchText.toLowerCase())

      ); }
      this.filterListData$.next(tempList);
      this.createMap();
  }

  createMap() {
    const auctionMap = new Map<string, SourcingCalenderEventDto[]>();
    this.auctionMap.clear();
    let temp = this.filterListData$.value;

    // Iterate through the original auctions array
    for (const auction of temp) {
      let startDate = this.getDateFormate(AuctionExtUtil.convertedDate(auction.startDate!, auction.startTime!)!);
      if (auctionMap.has(startDate!)) {
        auctionMap.get(startDate!)!.push(auction);
        // If the date already exists in the map, add the auction to the existing array
      } else {
        auctionMap.set(startDate!, [auction]);
        // If it's the first occurrence, create a new array with this auction
      }
    }

    // Iterate through the map to find dates with duplicates
    auctionMap.forEach((auctions, date) => {
      this.auctionMap.set(date, auctions);
    });

    this.calenderEventMap$.next(this.auctionMap);
  }

  changeCalenderEventData(auction: SourcingCalenderEventDto){
    // this.calendarService.updateSelectAuction(auction);
    this.onCalenderEventSelected.emit(auction);
  }

  getDateFormate(date: Date){
    return this.datePipe.transform(date, 'dd MMM');
  }

  getEndDate(endDate : string, endTime: string){
     let date =  AuctionExtUtil.convertedDate(endDate, endTime);
      return this.datePipe.transform(date, 'dd MMM');
  }

  originalOrder = (a: KeyValue<string, SourcingCalenderEventDto[]>, b: KeyValue<string, SourcingCalenderEventDto[]>): number => {
    return 0;
  }


  ngOnDestroy(): void {
    if(this.auctionListSubscription$){
      this.auctionListSubscription$.unsubscribe();
    }
  }



}
