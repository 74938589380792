import { Timestamp } from '@angular/fire/firestore';


import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, catchError, firstValueFrom, tap } from "rxjs";
import { timeout } from "rxjs/operators";

import { ErrorService } from "./error.service";

import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { AuctionLotEntityDto } from '../models/user/AuctionLotEntityDto';
import { AuctionExtConstant } from '../util/AuctionExtConstant';
import { BulkLotCreationStatus } from '../models/BulkLotCreationStatus';


@Injectable({
  providedIn: 'root'
})
export class AdminLotsDataHolderService implements OnDestroy{
  allLots?: AuctionLotEntityDto[];

  _allLots$ = new BehaviorSubject<AuctionLotEntityDto[]>([]);
  _allLotsOfPostAuctions$ = new BehaviorSubject<AuctionLotEntityDto[]>([]);
  _selectedAuctionLot$ = new BehaviorSubject<AuctionLotEntityDto | null>(null);

  constructor(
    private http: HttpClient,
    private errorService: ErrorService
  ) { }

  get selectedAuctionLot$() { return this._selectedAuctionLot$.asObservable() }
  get getAllLots$() { return this._allLots$.asObservable() }
  get getAllLotsOfPostAuctions$() { return this._allLotsOfPostAuctions$.asObservable() }

  updateAllPostAuctionLots(lots: AuctionLotEntityDto[]){
    this._allLotsOfPostAuctions$.next(lots);
  }

  // This method to be used only on admin dashboard
  setSelectedAuctionLot(lot: AuctionLotEntityDto) {
    this._selectedAuctionLot$.next(lot!);
  }

  setSelectedAuctionLotByAuctionAndLotId(auctionId: string, lotId: string ) {
    if(this.allLots && this.allLots.length > 0){
      let lot = this.allLots.find(item => item.auctionId == auctionId && item.lotId == lotId);
      this._selectedAuctionLot$.next(lot!);
    }

  }


  setLotForSingleLotAuction(auctionId: string){
    if(this.allLots && this.allLots.length > 0){
      let lots = this.allLots.filter(item => item.auctionId == auctionId);
      if(lots && lots.length > 0){
        lots.sort((a, b) => a.lotSequence! - b.lotSequence!);
        this.setSelectedAuctionLot(lots[0]);
      }
    }
  }

  getAuctionLotEntityDtoById(lotId: string) {
    return this.allLots?.find(item => item.lotId == lotId);
  }

  updateAllLotsData(lots: AuctionLotEntityDto[]) {
    this.allLots = [];
    this._allLots$.next([]);

    if (lots && lots.length > 0) {
      this.allLots = lots;
      this._allLots$.next(this.allLots);
      // this.changeTimeStempTostring();
      if (this._selectedAuctionLot$.value) {
        let auctionId = this._selectedAuctionLot$.value.auctionId
        let lotId = this._selectedAuctionLot$.value.lotId

        let selectedLot = this.allLots.find(item => (item.auctionId == auctionId) && (item.lotId == lotId));
        if (selectedLot) {
          this.setSelectedAuctionLot(selectedLot!);
        }else{
          this.setLotForSingleLotAuction(this.allLots[0].auctionId!);
        }
      }else{
        this.setLotForSingleLotAuction(this.allLots[0].auctionId!);
      }

    }
  }


  removeFirstLotIfRequired() {
    if (this.allLots && this.allLots.length > 0) {
      // find lots by lot sequence - 1 , 1
      let lots = this.allLots.filter(item => item.lotSequence == 1) as AuctionLotEntityDto[];

      lots.forEach(lot => {
        // remove lot where lot name is blank and version = 0
        if (lot && !lot?.title && lot.version == 0) {
          let index = this.allLots!.findIndex(item => item.lotId == lot.lotId);
          if (index > -1) {
            this.allLots!.splice(index, 1)
            this.setSelectedAuctionLot(this.allLots![0])
            this._allLots$.next(this.allLots!);
          }

        }
      });
    }
  }

  updateLotChangesData(auctionLotEntityDtos: AuctionLotEntityDto[]) {
    auctionLotEntityDtos.forEach(updatedLot => {

       // Changed Firestore timestamp to server timestamp

       if(updatedLot.endDateUTC){
        let endDateUTC  = updatedLot.endDateUTC as Timestamp;
        updatedLot.endDateUTC = (endDateUTC.seconds * 1000).toString();
       }

       let updateTimeUtc  = updatedLot.updateTimeUtc as Timestamp;
       updatedLot.updateTimeUtc = (updateTimeUtc.seconds * 1000).toString();

       if (updatedLot.lotAllocateUtcTime) {
        let timestamp = updatedLot.lotAllocateUtcTime as Timestamp;
        updatedLot.lotAllocateUtcTime = (timestamp.seconds * 1000).toString();
      }

      let isLotExistsInAllLots = this.allLots?.find(item => item.lotId == updatedLot.lotId);
      if (isLotExistsInAllLots) {

        let index = this.allLots?.findIndex(item => item.lotId == updatedLot.lotId)!;
        Object.assign(this.allLots![index], updatedLot);
      }else{

        this.allLots?.push(updatedLot);
      }
    })

    this._allLots$.next(this.allLots!);

    if (this._selectedAuctionLot$.value) {
      let selectedLot = this.allLots?.find(item => item.lotId == this._selectedAuctionLot$.value?.lotId);
      if (selectedLot) {
        this._selectedAuctionLot$.next(selectedLot);
      }
    }
  }

  addLot(auctionLotEntityDto: AuctionLotEntityDto) {
    this.allLots?.push(auctionLotEntityDto);
    this._allLots$.next(this.allLots!);
  }

  // changeFirestoreTimestampToString() {
  //   this.allLots!.forEach(item => {
  //     console.log("Before convert: " + item)
  //     if (item.endDateUTC) {
  //       console.log("Before Convert Timestamp: " + item.endDateUTC)
  //       let timestamp = item.endDateUTC as Timestamp;
  //       item.endDateUTC = (timestamp.seconds * 1000).toString();

  //       if (item.updateTimeUtc) {
  //         console.log("Before Convert Timestamp: " + item.endDateUTC)
  //         let timestamp = item.updateTimeUtc as Timestamp;
  //         item.endDateUTC = (timestamp.seconds * 1000).toString();
  //       }
  //     }

  //     if (item.lotAllocateUtcTime) {
  //       console.log("Before Convert Timestamp: " + item.endDateUTC)
  //       let timestamp = item.lotAllocateUtcTime as Timestamp;
  //       item.lotAllocateUtcTime = (timestamp.seconds * 1000).toString();
  //     }

  //     console.log("After convert: " + item)
  //   })

  // }

  async checkUploadStatusOfBulkLot(auctionId: string): Promise<BulkLotCreationStatus|undefined> {
    let bulkLotCreationStatus: BulkLotCreationStatus | undefined;
    try {
      let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.getBulkLotStatus(auctionId));
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          if (apiResponseDto.data) {
            bulkLotCreationStatus = apiResponseDto.data as BulkLotCreationStatus
          }
        }
    } catch (error) {
      console.error(error);
    }
    return bulkLotCreationStatus;
  }

  saveAuctionLotDetails(auctionLotEntityDto: AuctionLotEntityDto) {
    return this.http.post<ServerAPIResponseDto>('auctionsLots', auctionLotEntityDto).pipe(
      tap(_ => console.log("Saved Auction Lot to DB" + auctionLotEntityDto.auctionId)),
      catchError(this.errorService.handleError<any>("Error while Saving Auction Lot to DB" + auctionLotEntityDto.auctionId)))
  }

  getLotBidsHistoryData(auctionId: string, lotId: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId)
    return this.http.get<ServerAPIResponseDto>('lotBidsHistoryData', {params}).pipe(
      tap(_ => console.log("Getting bidder history data to DB")),
      catchError(this.errorService.handleError<any>("Error while getting bidder history data from DB")))
  }

  getBidderHistoryDataAuctionIdLotIdAndUserId(auctionId: string, lotId: string, userId: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId).set('userId', userId)
    return this.http.get<ServerAPIResponseDto>('userBidsData', {params}).pipe(
      tap(_ => console.log("Getting bidder history data to DB")),
      catchError(this.errorService.handleError<any>("Error while getting bidder history data from DB")))
  }

  rejectBid(auctionId: string, lotId: string, bidPrice: number, userId: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId).set('bidPrice', bidPrice).set('userId', userId);
    return this.http.post<ServerAPIResponseDto>('rejectBid',null, { params: params }).pipe(
      tap(_ => console.log("Getting bidder history data to DB")),
      catchError(this.errorService.handleError<any>("Error while getting bidder history data from DB")))
  }

  downloadBulkLotExcel() {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
        'responseType'  : 'arraybuffer' as 'json',
         'headers' : headers,
      };
    return this.http.get<ServerAPIResponseDto>('createBulkLots', httpOptions).pipe(timeout(600000),
      tap(_ => console.log("Getting excel sheet for bulk lots of auction ")),
      catchError(this.errorService.handleError<any>("Error while getting excel sheet for bulk lots of auction ")))
  }

  createLotsByExcel(file: any, auctionId : string) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('auctionId', auctionId)
    return this.http.post<ServerAPIResponseDto>('createLotsByExcel', formData).pipe(timeout(1800000),
      tap(_ => console.log("Uploading excel sheet for bulk lots")),
      catchError(this.errorService.handleError<any>("Error while Uploading excel sheet for bulk lots")))
  }

  getBulkLotStatus(auctionId: string) {
    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('bulkLotStatus', { params }).pipe(
      tap(_ => console.log("Getting bulkLotStatus to DB")),
      catchError(this.errorService.handleError<any>("Error while getting bulkLotStatus from DB")))
  }

  ngOnDestroy(): void {
  }
}
