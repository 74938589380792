<div class="col-md-4 m-md-auto mt-5">
  <div class="mt-4">
    <div class="row mb-1 align-items-center">
      <div class="text-success" *ngIf="userEntityDto?.registrationStatus == registrationStatus.COMPLETED">
        <img src="assets/icons/filter-check.svg" class="svg-icon-class-md c-pointer me-2"> Your Email ID is verified
      </div>
      <div class="text-orange" *ngIf="userEntityDto?.registrationStatus == registrationStatus.INCOMPLETE">
        <img src="assets/icons/pending-icon.svg" class="svg-icon-class c-pointer me-2"> Email verification is pending
      </div>
    </div>

    <form class="needs-validation" novalidate [formGroup]="formGroup">
      <div class="form-floating mb-4">
        <input type="email" class="form-control" id="emailId" formControlName="emailId"
          placeholder="name@example.com" />
        <label for="emailId">Email address</label>
      </div>

      <div class="input-group">
        <span class="input-group-addon">
          <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEvent($event)"
            [countryCode$]="countryCodeForMobileNo$"></app-country-code-select>
        </span>
        <div class="form-floating mb-4">
          <input type="text" class="form-control " id="mobileNo" placeholder="Phone Number"
            formControlName="mobileNo">
          <label for="mobileNo">Phone Number</label>
        </div>
      </div>

      <div class="form-floating mb-3">
        <input type="text" id="userOtp" class="form-control" formControlName="userOtp"
          [ngClass]="{'is-invalid': fc.userOtp.invalid || fc.userOtp.invalid && (fc.userOtp.dirty || fc.userOtp.touched)}" />
        <label for="userOtp">Enter OTP</label>
        <div class="text-start"
          *ngIf="fc.userOtp.invalid || fc.userOtp.invalid && (fc.userOtp.dirty || fc.userOtp.touched)">
          <p class="e-fielderror e-fielderror-required text-danger" *ngIf="fc.userOtp.errors!['required']">
            Required
          </p>
        </div>
      </div>

      <div class="text-start medium-grey-font mb-3">
        Enter the One Time Password (OTP) which was just sent the email address you registered with. Be sure
        to check your spam folder
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToast$|async" [isError]="true"
          [message]="_showErrorMsg$|async"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$|async" [isError]="false"
          message="Successfully Verified"></app-message-toast>
      </div>

      <div class="d-grid gap-2 my-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>

      <div class="row" *ngIf="!isLoading">
        <div class="col-6 text-start mt-3">
          <a class="link-class"
            [ngClass]="{'disabled': userEntityDto?.registrationStatus == registrationStatus.COMPLETED}"
            (click)="generateOTP()">Generate Otp</a>
        </div>
        <div class="col-6 text-end mt-3">
          <button class="btn btn-sm btn-grey-clr-class"
            [disabled]="userEntityDto?.registrationStatus == registrationStatus.COMPLETED"
            (click)="verifyOTP()">Verify</button>
        </div>
      </div>
    </form>
  </div>
</div>