import { Timestamp } from '@angular/fire/firestore';

export class UserLotFavouriteDto {
    auctionId?: string;
    auctionHouseId?: string;
    lotId?: string;
    userId?: string;
    updateTimeUtc?: string|Timestamp
    updateTimeUtcMillis?: number;
}
