<div class="position-relative p-2" *ngIf="(_renderView$ | async)" [ngClass]="{'c-progress e-new-spinner ': isLoading}">
  <div class="card card-ripple shadow zoom border-0 mb-2 me-2 rounded-20 bg-white h-358 c-pointer"
    (click)="navigateAuctionView()">
    <div class="image-carousal-color">
      <img *ngIf="auctionEntityDto?.auctionImage"
        src="/downloadLandingBlob?fileId={{auctionEntityDto?.auctionImage?.fileId}}"
        [alt]="auctionEntityDto?.auctionName" class="image-premium-auction"
        [ngClass]="{'img-portrait': auctionEntityDto?.auctionImage?.dataType == 'Portrait'}">
      <img *ngIf="!auctionEntityDto?.auctionImage" src="assets/images/quote01.png" alt="" class="image-premium-auction">
    </div>
    <div class="timer-class-premium-auction">
      <div class="" id="countdown{{counterFunction}}{{auctionEntityDto?.auctionId}}"
        *ngIf="auctionEntityDto?.status != 'CLOSE'">
        <span class="cust-text danger-clrr" id="headline{{counterFunction}}{{auctionEntityDto?.auctionId}}"></span>
        <span class="badge p-2 similar-timer-premium-auction"
          id="lotcountdown{{counterFunction}}{{auctionEntityDto?.auctionId}}">
          <span class="cust-text danger-clrr span-class-live"
            id="days{{counterFunction}}{{auctionEntityDto?.auctionId}}"></span>
          <span class="cust-text danger-clrr span-class-live"
            id="hours{{counterFunction}}{{auctionEntityDto?.auctionId}}"></span>
          <span class="cust-text danger-clrr span-class-live"
            id="minutes{{counterFunction}}{{auctionEntityDto?.auctionId}}"></span>
          <span class="cust-text danger-clrr span-class-live"
            id="second{{counterFunction}}{{auctionEntityDto?.auctionId}}"></span>
        </span>
      </div>

      <div class="badge  p-2 rounded similar-timer-premium-auction " *ngIf="auctionEntityDto?.status == 'CLOSE'">
        <span class="span-class-live">Closed</span>
      </div>
    </div>

    <div class="similar-card-bids-position p-2 badge text-white rounded-0">
      <img src="assets/icons/hoticon.svg" class="svg-icon-class-md c-pointer" *ngIf="auctionCardWrapperDto?.totalBids && auctionCardWrapperDto?.totalBids! > 10">
      {{auctionCardWrapperDto?.totalBids}} Bids
    </div>
    <div class="similar-lots-position p-2 badge text-white rounded-0" *ngIf="auctionEntityDto!.noOfActiveLots! > 1 ">
      {{ auctionEntityDto?.noOfActiveLots+" Lots"}}
    </div>
    <div class="p-2">
      <div class="mt-2 row">
        <div class="col-12 mb-2 text-start">
          <span class="button-class"
            [ngClass]="{'badge-warning-class' : auctionEntityDto?.status == 'LIVE_WAIT', 'badge-primary-class': auctionEntityDto?.status == 'LIVE'}">
            {{auctionEntityDto?.status == 'LIVE_WAIT' ? 'UPCOMING' : 'RUNNING'}}
          </span>
        </div>
        <div class="col-12 text-start" *ngIf="auctionEntityDto?.addresses && auctionEntityDto?.addresses!.length > 0">
          <span >
            <small class="small-class">
              <span class="overflow-class-small"
                ngbTooltip=" {{auctionEntityDto?.addresses![0].city}}, {{auctionEntityDto?.addresses![0].countryShortName}}">
                {{auctionEntityDto?.addresses![0].city}}, {{auctionEntityDto?.addresses![0].countryShortName}}</span>
            </small>
          </span>
        </div>
      </div>
      <div class="mt-2">
        <div class="bold-font text-start overflow-class-large" ngbTooltip="{{auctionEntityDto?.auctionName}}">
          {{auctionEntityDto?.sequenceNo}} # {{auctionEntityDto?.auctionName}}</div>
      </div>
    </div>
  </div>
</div>