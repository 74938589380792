<div class="col-12 col-lg-10 m-auto position-relative" *ngIf="_renderView$ | async">
<div class=" card card-ripple border-0 mb-3 shadow zoom rounded-20 bg-white h-358 c-pointer" (click)="navigateAuctionView()">
  <div class="row p-2">
    <div class="col-lg-6">
      <img *ngIf="selectedCalenderEvent?.auctionImage"
      src="/downloadLandingBlob?fileId={{selectedCalenderEvent?.auctionImage?.fileId}}"
      [alt]="selectedCalenderEvent?.eventName" class="image-premium-auction"
      [ngClass]="{'img-portrait': selectedCalenderEvent?.auctionImage?.dataType == 'Portrait'}">
    <img *ngIf="!selectedCalenderEvent?.auctionImage" src="assets/images/quote01.png" alt="" class="image-premium-auction"
    >
    </div>
    <div class="col-lg-6">
      <div class="row">
        <div class="col-lg-4 text-start my-3 my-lg-0">
          <span class="button-class"
            [ngClass]="{'badge-warning-class' : selectedCalenderEvent?.status == 'LIVE_WAIT', 'badge-primary-class': selectedCalenderEvent?.status == 'LIVE'}">
            {{selectedCalenderEvent?.status == 'LIVE_WAIT' ? 'UPCOMING' : 'RUNNING'}}
          </span>
        </div>
        <div class="col-lg-8 text-lg-end text-start ps-3 ps-lg-0 mt-0 mt-3 mt-lg-0">
          <span class="btn-new-class badge me-3">
              <span class="" id="countdown{{counterFunction}}{{selectedCalenderEvent?.eventId}}"
                *ngIf="selectedCalenderEvent?.status != 'CLOSE'">
                <span class="cust-text danger-clrr" id="headline{{counterFunction}}{{selectedCalenderEvent?.eventId}}"></span>
                <span class=""
                  id="lotcountdown{{counterFunction}}{{selectedCalenderEvent?.eventId}}">
                  <span class="cust-text danger-clrr "
                    id="days{{counterFunction}}{{selectedCalenderEvent?.eventId}}"></span>
                  <span class="cust-text danger-clrr "
                    id="hours{{counterFunction}}{{selectedCalenderEvent?.eventId}}"></span>
                  <span class="cust-text danger-clrr "
                    id="minutes{{counterFunction}}{{selectedCalenderEvent?.eventId}}"></span>
                  <span class="cust-text danger-clrr "
                    id="second{{counterFunction}}{{selectedCalenderEvent?.eventId}}"></span>
                </span>
              </span>

              <div class="badge  p-2 rounded dark-btn-position-premium-auction "
                *ngIf="selectedCalenderEvent?.status == 'CLOSE'">
                <span class="">Closed</span>
              </div>

          </span>


          <span class="btn-new-class badge me-3" *ngIf="selectedCalenderEvent!.noOfActiveLots! > 1 "> {{ selectedCalenderEvent?.noOfActiveLots+" Lots"}}</span>
          <span class="btn-new-class badge ">

            <img src="assets/icons/hoticon.svg" class="svg-icon-class-small c-pointer" *ngIf="selectedCalenderEvent?.totalBids && selectedCalenderEvent?.totalBids! > 10">
             {{selectedCalenderEvent?.totalBids}} Bids

          <!-- <span class="premium-lots-position p-2 badge text-white rounded-0" *ngIf="selectedCalenderEvent!.noOfActiveLots! > 1 ">
            {{ selectedCalenderEvent?.noOfActiveLots+" Lots"}}
          </span> -->
          </span>
        </div>
        <div class="col-lg-6 text-start  mt-3 mt-lg-2">
         <div class="div-class overflow-class">
          {{selectedCalenderEvent?.sequenceNo}} # {{selectedCalenderEvent?.eventName}}
         </div>
        </div>
        <div class="col-lg-6 text-lg-end text-start mt-2">
            <div class="div-class"  *ngIf="selectedCalenderEvent?.address">
              <div class=" " ngbTooltip=" {{selectedCalenderEvent?.address!.city}}, {{selectedCalenderEvent?.address!.countryShortName}}">
                {{selectedCalenderEvent?.address!.city}}, {{selectedCalenderEvent?.address!.countryShortName}}</div>
          </div>
        </div>
        <div>

        </div>
      </div>
      <div class="row mt-2" >
        <div class="col-3 ps-2 ps-md-0" >

            <img src="/downloadLandingBlob?fileId={{selectedCalenderEvent?.listingContactDto!.profile?.fileId}}"
            alt="{{selectedCalenderEvent?.listingContactDto!.name}}"
            [ngClass]="screenWidth > 991 ? 'profile-image-xl' : 'profile-image-large'" >


        </div>
        <div class="col-6 mt-1 text-start ps-sm-4">
      <div class="overflow-class-small">
        <h4 class="heading-h6 text-capitalize mb-0" ngbTooltip="{{selectedCalenderEvent?.listingContactDto!.name}}">
          {{selectedCalenderEvent?.listingContactDto!.name}}</h4>
      </div>
      <div class="div-class-small overflow-class-small" ngbTooltip=" {{selectedCalenderEvent?.listingContactDto!.emailId}}">
        <img src="assets/icons/mail-icon.svg" class="svg-icon-class-small c-pointer mt-0 me-1 left-padding-icon">
         {{selectedCalenderEvent?.listingContactDto!.emailId}}
      </div>
      <div class="div-class-small overflow-class-small" ngbTooltip="  {{getCountryCode(selectedCalenderEvent?.listingContactDto!.countryCode)}} {{selectedCalenderEvent?.listingContactDto!.mobileNo}}">
        <img src="assets/icons/mobile-icon.svg" class="svg-icon-class-small c-pointer mt-0 me-1">
        {{getCountryCode(selectedCalenderEvent?.listingContactDto!.countryCode)}} {{selectedCalenderEvent?.listingContactDto!.mobileNo}}
      </div>
        </div>
        <div class="mt-2 col-lg-6 text-start ">
          <span class=" span-class">Start Date: </span>
          <span class=" span-class">{{convertDate(selectedCalenderEvent?.startDate!, selectedCalenderEvent?.startTime!)}}</span>
        </div>
        <div class="mt-2 col-lg-6 text-lg-end text-start">
          <span class=" span-class">End Date: </span>
          <span class=" span-class">{{convertDate(selectedCalenderEvent?.endDate!, selectedCalenderEvent?.endTime!)}}</span>
        </div>
      </div>
    </div>
  </div>

</div>
</div>
<div *ngIf="!(_renderView$ | async)"></div>
