import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuctionValidationErrorCodeDto } from '../../models/user/AuctionValidationErrorCodeDto';
import { AdminLotsDataHolderService } from '../../services/adminLotsDataHolderService.service';
import { AuctionLotEntityDto } from '../../models/user/AuctionLotEntityDto';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-lots-error-validation',
  templateUrl: './lots-error-validation.component.html',
  styleUrls: ['./lots-error-validation.component.sass']
})
export class LotsErrorValidationComponent implements OnInit {
  @Input() errorDtoList!: AuctionValidationErrorCodeDto[];
  @Output() onSubmit = new EventEmitter<string[]> ();
  @ViewChild('termsHeader') termsHeader?: ElementRef<HTMLElement>
  lotIdsList: string[] = []
  selectedLotIdsList: string[] = []
  allAuctionLots: AuctionLotEntityDto[] = []

  ctrlAllSelect: FormControl = new FormControl(false);

  constructor(
    private lotService: AdminLotsDataHolderService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.lotService.getAllLots$.subscribe((data) => {
      if (data && data.length > 0) {
        this.allAuctionLots = data;

        this.allAuctionLots.forEach(item => {
          if (!item.active && !this.lotIdsList.includes(item.lotId!)) {
            this.lotIdsList.push(item.lotId!)

            // Select Already Passed Items
            if (!this.haveAnyError(item.lotId!)) {
              this.selectLotForActive(item.lotId!);
            }
          }
        })
      }
    })

    this.ctrlAllSelect.valueChanges.subscribe((value) => {
      if (value) {
        this.lotIdsList.forEach(lotId => {
          if (!this.haveAnyError(lotId)) {
            if (!this.selectedLotIdsList.includes(lotId)) {
              this.selectedLotIdsList.push(lotId);
            }
          }
        })
      } else {
        this.selectedLotIdsList = [];
      }
    })
  }

  getLotById(lotId: string): AuctionLotEntityDto | undefined {
    if (this.allAuctionLots && this.allAuctionLots.length > 0) {
      return this.allAuctionLots.find(item => item.lotId == lotId);
    }
    return undefined;
  }

  haveAnyError(lotId: string): boolean {
    if (this.allAuctionLots && this.allAuctionLots.length > 0) {
      let error = this.errorDtoList.find(item => item.lotId == lotId);
      if (error) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  selectLotForActive(lotId: string) {
    if (!this.selectedLotIdsList.includes(lotId)) {
      this.selectedLotIdsList.push(lotId)
    } else {
      let index = this.selectedLotIdsList.findIndex(item => item == lotId);
      this.selectedLotIdsList.splice(index, 1);
    }
  }

  isLotSelected(lotId: string) {
    if (this.selectedLotIdsList.includes(lotId)) {
      return true;
    } else {
      return false;
    }
  }

  onButtonClick() {
    this.onSubmit.emit(this.selectedLotIdsList);
  }

  closeModal() {
    this.activeModal.close();
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.termsHeader?.nativeElement.scrollIntoView()
    })
  }
}
