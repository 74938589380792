<div class="col-12 p-2">
  <div class="row justify-content-between">
    <div class="col-9 d-flex align-items-center">
      <h2>Terms &amp; Conditions</h2>
    </div>
    <div class="col-auto text-end">
      <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
    </div>
  </div>
</div>
<div class="page-divider"></div>
<div class="modal-body">
  <div class="scroll-container">
    <div #termsHeader></div>
    <div class="m-3" style="white-space: normal;">

      Thank you for choosing AuctionExt as your online auction platform. To ensure a safe and enjoyable experience for
      all users, please read the following terms of use carefully:<br><br>

      <b>Registration</b>: In order to bid or sell on AuctionExt, you must first create an account and provide
      accurate
      and
      complete information.<br>
      <b>Prohibited Conduct</b>: You agree not to use AuctionExt for any illegal, harmful, or unethical purposes,
      including but
      not limited to bidding on your own items, shill bidding, or attempting to manipulate the auction process.<br>
      <b>Content</b>: You are solely responsible for any content you post on AuctionExt, including but not limited to
      item
      descriptions, images, and comments. You agree not to post any content that is misleading, defamatory, or
      infringes
      on the intellectual property rights of others.<br>
      <b>Payment</b>: Buyers must make payment for items won within the specified time period, and sellers are
      responsible for
      shipping items promptly after payment is received. AuctionExt is not responsible for any payment disputes or
      issues that may arise between buyers and sellers.<br>
      <b> Privacy</b>: AuctionExt values your privacy and will not share your personal information with third parties
      without
      your consent.<br>
      <b>Termination</b>: AuctionExt reserves the right to terminate or suspend your account at any time, without
      notice, if
      you violate these terms of use or for any other reason.<br>
      <b>Limitation of Liability</b>: AuctionExt is not responsible for any loss or damage resulting from the use of
      our
      platform, including but not limited to lost profits, data, or business opportunities.<br>
      <b>Dispute Resolution</b>: Any disputes arising from the use of AuctionExt will be resolved through binding
      arbitration
      in accordance with the rules of the American Arbitration Association.<br><br>
      By using AuctionExt, you agree to these terms of use and any updates or modifications that may be made in the
      future. If you have any questions or concerns, please contact us at <span class="text-primary"><b
          class="b-class btn-link"><a href="mailto:support@auctionext.com">support@auctionext.com</a></b></span>.
    </div>
    <button class="btn btn-sm btn-outline-primary p-3 floating-button" (click)="moveToTop(termsHeader)">
      <img src="assets/icons/arrowupward.svg" class="svg-icon-class c-pointer">
    </button>
  </div>
</div>