export class Pattern {
  static url: string = "(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})";
  static url2: string = "^(?:(ftp|http|https):\\/\\/)?(?:[\\w-]+\\.)+[a-z]{2,6}$"
  static mobile: string = "^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$";
  static email: string = "^[\\w-\\+\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$";
  static zipCode: string = "^[a-zA-Z0-9 -]{4,10}$";
  static onlyNumber: string = "^[0-9]*$";
  static numberGreaterZero: string = "^[1-9][0-9]*$";
  static percentage: string = "^(100(\\.0+)?|([1-9]\\d?(\\.\\d+)?|0\\.\\d*[1-9]\\d*))$";
  static percentage2: string = "^\\s*(?=.*[1-9])\\d*(?:\\.\\d{1,2})?(?!\\.00)?(?:%|$)\\s*$";
  static numberGreaterZeroWithDecimal: string = "^\\s*(?=.*[1-9])\\d*(?:\\.\\d{1,2})?\\s*$"
}
