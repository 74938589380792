<header class=" bg-white main-header  py-md-1 py-xl-0 head-pos mb-5" #headerPrimary>
 
    <div class="col-12" *ngIf="!IsAnonymous">
      <div class="row  align-items-center justify-content-between ps-3 pe-5 ps-md-0 pe-md-2">
        <div class="col-auto">
          <a>
            <img src="assets/logo/procurext-logo.svg" alt="Procurext" class="application-logo | img-fluid my-1 ms-md-5" />
          </a>
          <!-- <li ngbDropdownItem class="c-pointer" (click)="navigateToPage('/event')">
            <a><img src="assets/icons/date_light.svg" class="svg-icon-class-md c-pointer me-2">
              <small class="ps-1">Registration Event</small></a>
          </li> -->
        </div>
        <div class="d-block d-md-none col-auto text-end">
            <span ngbDropdown #profileDropdown1="ngbDropdown" autoClose="false" container="body" placement="bottom-right">
              <span ngbDropdownToggle data-bs-toggle="dropdown">
                <span class="icon-wrapper icon-wrapper-alt c-pointer">
                    <button class="header-button-ui btn btn-sm">
                      <img src="assets/icons/hamberger.svg" class=" c-pointer svg-icon-class-small  hamburger">
                    </button>
                </span>
              </span>
              <ul ngbDropdownMenu class="text-start mt-3 p-2 margin-right dropdown-menu shadow">
                <li ngbDropdownItem (click)="openMyDetailsModal(myDetails, profileDropdown1)">
                  <span class="me-3">
                    <img
                    src="/downloadLandingBlob?fileId={{userEntityDto?.profileImage?.fileId}}" ngbTooltip="My Profile" container="body"
                    alt="{{userEntityDto?.firstName}}" class="header-image" *ngIf="userEntityDto?.profileImage"  >
                  <img  src="./assets/images/avatar_default.png" ngbTooltip="My Profile" container="body"
                    alt="{{userEntityDto?.firstName}}" class="header-image" *ngIf="!userEntityDto?.profileImage" >
                    My Profile
                  </span>
                </li>
                <hr class="hr my-1" >
                <li ngbDropdownItem class="c-pointer" (click)="navigateToPage('/calendar')">
                  <span class="me-3 ">
                    <img src="assets/icons/date_light.svg" class="svg-icon-class-md c-pointer " ngbTooltip="Calendar" container="body">
                   Auction Calendar
                  </span>
                </li>
               
                <hr class="hr my-1" >
                <li ngbDropdownItem>
                  <span class="me-3 ">
                    <img src="assets/icons/notification-bell.svg" class="svg-icon-small c-pointer me-1" ngbTooltip="Notification" container="body" >
                    Notifications
                  </span>
  
                </li>
                <hr class="hr my-1" >
                <li ngbDropdownItem (click)="logout(profileDropdown1)">
                  <span class="me-3 text-danger">
                    <img src="assets/icons/logout.svg" class="svg-icon-class c-pointer " ngbTooltip="Logout" container="body">
                    Logout1
                  </span>
                </li>
              </ul>
            </span>
        </div>
        <div class="col-auto text-end d-none d-md-block">
  
          <span class="span-class me-3" *ngIf="!IsAnonymous" >
            Hi, {{userEntityDto?.firstName}} {{userEntityDto?.lastName}}
          </span>
          <!-- <span class="me-3 ">
            <img src="assets/icons/date_light.svg" class="svg-icon-class-md c-pointer " ngbTooltip="Calendar" container="body"
            (click)="navigateToPage('/calendar')">
          </span> -->

          <app-notifications layoutType="HEADER"></app-notifications>

          <span class="me-3" (click)="openMyDetailsModal(myDetails, profileDropdown1)">
            <img
            src="/downloadLandingBlob?fileId={{userEntityDto?.profileImage?.fileId}}" ngbTooltip="My Profile" container="body"
            alt="{{userEntityDto?.firstName}}" class="header-image" *ngIf="userEntityDto?.profileImage"  >
          <img  src="./assets/images/avatar_default.png" ngbTooltip="My Profile" container="body"
            alt="{{userEntityDto?.firstName}}" class="header-image" *ngIf="!userEntityDto?.profileImage" >
          </span>
          <span class="me-3">
            <img src="assets/icons/logout.svg" class="svg-icon-class c-pointer " ngbTooltip="Logout" container="body"
            (click)="logout()">
          </span>
        </div>
         <!-- <div class="col-xl-1 col-2 text-end" >
          <div>
            <span ngbDropdown #profileDropdown1="ngbDropdown" autoClose="true" container="body" placement="bottom-right">
              <span ngbDropdownToggle data-bs-toggle="dropdown">
                <span class="icon-wrapper icon-wrapper-alt c-pointer">
  
                    <button class="header-button-ui btn btn-sm">
                      <img src="assets/icons/hamberger.svg" class=" c-pointer svg-icon-class-small  hamburger">
                      <span *ngIf="!IsAnonymous" class="ms-2">
                        <img
                      src="/downloadLandingBlob?fileId={{userEntityDto?.profileImage?.fileId}}"
                      alt="{{userEntityDto?.firstName}}" class="header-image" *ngIf="userEntityDto?.profileImage"  >
                    <img  src="./assets/images/avatar_default.png"
                      alt="{{userEntityDto?.firstName}}" class="header-image" *ngIf="!userEntityDto?.profileImage" >
                      </span>
                    </button>
                </span>
              </span>
  
              <ul ngbDropdownMenu class="text-start mt-3 p-2 margin-right dropdown-menu shadow">
  
                <li ngbDropdownItem class="c-pointer" *ngIf="IsAnonymous" (click)="openLoginModal(loginModal)">
                  <a class="text-info">
                    <img src="assets/icons/login_light.svg" class="svg-icon-class-md c-pointer me-2">LOGIN</a>
                </li>
                <hr class="hr my-1" *ngIf="IsAnonymous">
                <li ngbDropdownItem class="c-defoult" *ngIf="!IsAnonymous">
                  <div class="row">
                    <div class="col-12">
                      <small class="small-class">Logged In as </small>
                      <div *ngIf="userEntityDto?.firstName">
                        {{userEntityDto?.firstName}} {{userEntityDto?.lastName}}
                      </div>
                      <div *ngIf="!userEntityDto?.firstName">
                        {{userEntityDto?.primaryEmailId}}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 text-center my-2 ">
                    <span class="button-class me-2 badge-primary-class c-pointer"
                      (click)="openMyDetailsModal(myDetails, profileDropdown1)">
                      My Profile
                    </span>
                  </div>
                </li>
                <hr class="hr my-1">
                <li ngbDropdownItem class="c-pointer" (click)="navigateToPage('/calendar')">
                  <a>
                    <img src="assets/icons/date_light.svg" class="svg-icon-class-md c-pointer me-2">
                    <small class="ps-1">Auction Calendar</small></a>
                </li>
                <hr class="hr my-1" *ngIf="!IsAnonymous">
                <li ngbDropdownItem class="c-pointer" *ngIf="!IsAnonymous" (click)="logout(profileDropdown1)">
                  <a class="text-danger"><img src="assets/icons/logout.svg"
                      class="svg-icon-class-md c-pointer me-2"><b>LOGOUT</b></a>
                </li>
              </ul>
            </span>
          </div>
        </div>  -->
      </div>
    </div>
    <div class="header-divider" *ngIf="!IsAnonymous">
  </div>
  
  </header>
  <div #headerSecondary>
  
  </div>


<ng-template #loginModal>
  <app-user-login></app-user-login>
</ng-template>
<ng-template #changePasswordModal>
  <app-user-password-change></app-user-password-change>
</ng-template>
<ng-template #registrationModal>
  <app-user-registration></app-user-registration>
</ng-template>

<!-- Templates -->
<ng-template #messageDialog>
  <app-message-dialog></app-message-dialog>
</ng-template>
<ng-template #myDetails>
  <app-user-details></app-user-details>
</ng-template>
