import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-message-toast',
  templateUrl: './message-toast.component.html',
  styleUrls: ['./message-toast.component.sass']
})
export class MessageToastComponent implements OnInit {
  @Input() message?: string | null;
  @Input() messageButton?: string | null;
  @Input() isError: boolean = false;
  @Input() isErrorButton: boolean = false;
  @Output() output = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
    console.log(this.message);
    
  }

  onButtonClick() {
    this.output.emit();
  }

}
