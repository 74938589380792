import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { UUID } from 'angular2-uuid';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AddressDto } from 'src/app/shared/models/Address';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { UserEntityDto } from 'src/app/shared/models/UserEntityDto';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';
import { Pattern } from 'src/app/shared/util/Patterns';

@Component({
  selector: 'app-user-address',
  templateUrl: './user-address.component.html',
  styleUrls: ['./user-address.component.sass']
})
export class UserAddressComponent {
  formGroup: FormGroup;
  
  userEntityDto?: UserEntityDto

  isLoading: boolean = false;
  errorMsg: string | undefined;
  latitude?: number;
  longitude?: number;
  countryShortName?: string
  zoom = 12;
  infoContent = ''
  position?: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    minZoom: 8,
  };
  marker = {
    label: { color: 'black', text: '' },
    title: '',
    options: { animation: google.maps.Animation.DROP },
    info: ''
  }

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  userEntityDtoSubscription$?: Subscription;
  
  @ViewChild(GoogleMap) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
  ) {    
    this.formGroup = this.formBuilder.group({
      searchAddress: new FormControl('', Validators.required),
      addressLine1: new FormControl('', Validators.required),
      addressLine2: new FormControl(''),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      zipCode: new FormControl('', [Validators.required, Validators.pattern(Pattern.zipCode)]),
    })
  }

  ngOnInit(): void {
    this.formGroup.reset();
    this.userEntityDtoSubscription$ = this.userService.getUserEntity$.subscribe(data => {
      if (data) {
        this.userEntityDto = data;
        this.populateUserDetails();
      }
    })
  }

  get fc(): any { return this.formGroup.controls; }
  
  populateUserDetails() {
    this.formGroup.controls['searchAddress'].patchValue(this.userEntityDto?.address?.searchAddress);
    this.formGroup.controls['addressLine1'].patchValue(this.userEntityDto?.address?.addressLine1);
    this.formGroup.controls['addressLine2'].patchValue(this.userEntityDto?.address?.addressLine2);
    this.formGroup.controls['city'].patchValue(this.userEntityDto?.address?.city);
    this.formGroup.controls['state'].patchValue(this.userEntityDto?.address?.state);
    this.formGroup.controls['country'].patchValue(this.userEntityDto?.address?.country);
    this.formGroup.controls['zipCode'].patchValue(this.userEntityDto?.address?.zipCode);

    if (this.userEntityDto?.address?.latitude && this.userEntityDto?.address?.longitude) {
      this.latitude = Number(this.userEntityDto?.address?.latitude);
      this.longitude = Number(this.userEntityDto?.address?.longitude);

      this.position = { lat: this.latitude!, lng: this.longitude! }
      this.marker.label.text = this.userEntityDto?.address?.searchAddress!;
      this.marker.title = this.userEntityDto?.address?.searchAddress!;
      this.marker.info = this.userEntityDto?.address?.searchAddress!;
    }

    this.formGroup.updateValueAndValidity();
  }

  handleAddressChange(address: Address) {
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();

    let city = AuctionExtUtil.getAddressByType(address, 'locality');
    if (!city) {
      city = AuctionExtUtil.getAddressByType(address, 'neighborhood');
    }
    let state = AuctionExtUtil.getAddressByType(address, 'administrative_area_level_1');
    let zip = AuctionExtUtil.getAddressByType(address, 'postal_code');
    let addressPart1 = AuctionExtUtil.getAddressByType(address, 'street_number');
    let addressPart2 = AuctionExtUtil.getAddressByType(address, 'route');
    let country = AuctionExtUtil.getAddressByType(address, 'country');
    this.countryShortName = AuctionExtUtil.getAddressShortNameByType(address, 'country');

    // Map View Init
    this.position = { lat: this.latitude, lng: this.longitude }
    this.marker.label.text = address.formatted_address;
    this.marker.title = address.formatted_address;
    this.marker.info = address.formatted_address;

    if (addressPart1 == '' || addressPart2 == '') {
      if (address.formatted_address.includes(',')) {
        let data = address.formatted_address.split(',')[0]
        this.formGroup.controls['addressLine1'].patchValue(data);
      } else {
        this.formGroup.controls['addressLine1'].patchValue(address.formatted_address)
      }
    } else {
      this.formGroup.controls['addressLine1'].patchValue(addressPart1 + " " + addressPart2);
    }
    this.formGroup.controls['searchAddress'].patchValue(address.formatted_address);
    this.formGroup.controls['state'].patchValue(state);
    this.formGroup.controls['city'].patchValue(city);
    this.formGroup.controls['country'].patchValue(country);
    this.formGroup.controls['zipCode'].patchValue(zip);
    this.formGroup.updateValueAndValidity();
  }

  mergeUserDetails() {
    let userEntityDto: UserEntityDto = AuctionExtUtil.clone(this.userEntityDto);

    let formValue = this.formGroup.value;

    if (!this.userEntityDto?.address) {
      userEntityDto.address = new AddressDto();
      userEntityDto.address.id = UUID.UUID().toString();
      userEntityDto.address!.addressType = 'Company Address';
    }

    userEntityDto.address!.searchAddress = formValue.searchAddress;
    userEntityDto.address!.addressLine1 = formValue.addressLine1;
    userEntityDto.address!.addressLine2 = formValue.addressLine2;
    userEntityDto.address!.city = formValue.city;
    userEntityDto.address!.state = formValue.state;
    userEntityDto.address!.country = formValue.country;
    userEntityDto.address!.zipCode = formValue.zipCode;
    userEntityDto.address!.countryShortName = this.countryShortName;

    userEntityDto.address!.latitude = this.latitude?.toString();
    userEntityDto.address!.longitude = this.longitude?.toString();
    return userEntityDto;
  }

  async handleValidSubmit() {
    this.errorMsg = "";
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.isLoading = true;

    let userEntityDto = this.mergeUserDetails();

    this.userService.updateUserDetails(userEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let data = apiResponseDto.data as UserEntityDto;
          this.userService.setUserDto(data);

          this.isLoading = false;
          this._showSuccessToast$.next(true);
          setTimeout(() => {
            this._showSuccessToast$.next(false);
          }, 2000)
        } else {
          this.isLoading = false;
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);

        }
      },
      error: (err) => {
        this.isLoading = false;
        this.errorMsg = "Error while updating address";
        this._showErrorToast$.next(true);
      }
    })
  }

  openInfo(marker: MapMarker, content: string) {
    this.infoContent = content;
    this.info.open(marker)
  }

  ngOnDestroy(): void {
    if(this.userEntityDtoSubscription$) {
      this.userEntityDtoSubscription$.unsubscribe();
    }
  }
}
