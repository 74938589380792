import { UserAuctionQuestionsDto } from "./questionnaire/UserAuctionQuestionsDto";
import { UserAuctionRegistrationDto } from "./user/UserAuctionRegistrationDto";

export class UserAuctionRegistrationWrapperDto {
  auctionId?: string;
  auctionSequence?: number;
  auctionName?: string;
  companyName?: string;
  userAuctionRegistrationDto?: UserAuctionRegistrationDto;
  userAuctionQuestionsDtoList?: UserAuctionQuestionsDto[];
}