export class CountryCodeDto {
  countryCode?: string;
  countryName?: string;
  countryFlag?: string;
  mobileNoLength?: string;

  static default(): CountryCodeDto {
    let countryCodeDto = new CountryCodeDto();
    countryCodeDto.countryCode = '(+91)';
    countryCodeDto.countryName = 'India';
    countryCodeDto.countryFlag = 'language-icon me-2 fi fi-in';
    countryCodeDto.mobileNoLength = '10';
    return countryCodeDto;
  }

  // static default(): CountryCodeDto {
  //   let countryCodeDto = new CountryCodeDto();
  //   countryCodeDto.countryCode = '(+1)';
  //   countryCodeDto.countryName = 'United States';
  //   countryCodeDto.countryFlag = 'language-icon me-2 fi fi-us';
  //   countryCodeDto.mobileNoLength = '10';
  //   return countryCodeDto;
  // }
}
