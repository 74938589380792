import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationResponseDto } from 'src/app/shared/models/AuthenticationResponseDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { RegistrationUserModalDto } from 'src/app/shared/models/user/RegistrationUserModalDto';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { Pattern } from 'src/app/shared/util/Patterns';
import { PasswordStrengthValidator } from 'src/app/shared/validators/password-strength.validators';
import { LoaderComponent } from '../../loader/loader.component';
import { UserLoginComponent } from '../user-login/user-login.component';
import { CompanyTermsConditionsComponent } from '../../company-terms-conditions/company-terms-conditions.component';
import { CompanyPrivacyPolicyComponent } from '../../company-privacy-policy/company-privacy-policy.component';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.sass']
})
export class UserRegistrationComponent implements OnInit {
  @Output() onChangeViewPage = new EventEmitter<string>();

  @ViewChild('appLoader', { static: false })
  appLoader?: LoaderComponent;

  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);
  _isSaveButtonDisable$ = new BehaviorSubject<boolean>(false);
  _isPasswordMatch$ = new BehaviorSubject<boolean>(false);
  otpId?: string;
  _showSuccessMsg$ = new BehaviorSubject<boolean>(false);
  _successMsg$ = new BehaviorSubject<string>("");
  isPasswordVisible: boolean = false;
  isRepeatPasswordVisible: boolean = false;
  _isPasswordFocused$ = new BehaviorSubject<boolean>(false);
  _isConfirmPasswordFocused$ = new BehaviorSubject<boolean>(false);
  countryCode$ = new BehaviorSubject<CountryCodeDto | null>(null);

  isShowOtpVerify: boolean = false;
  isLoading: boolean = false;

  @ViewChild('password') password?: ElementRef<HTMLInputElement>
  @ViewChild('confirmPassword') confirmPassword?: ElementRef<HTMLInputElement>

  formGroup: FormGroup;

  registrationModalType = 'USER_DETAILS';   // USER_DETAILS, ADDRESS, OTP_VERIFY

  selectedCountryCode = CountryCodeDto.default();
  // selectedFaxNoCountryCode = CountryCodeDto.default();

  // countryCodeForFaxNo$ = new BehaviorSubject<CountryCodeDto>(CountryCodeDto.default());
  countryCodeForMobileNo$ = new BehaviorSubject<CountryCodeDto>(CountryCodeDto.default());

  // latitude?: number;
  // longitude?: number;
  // countryShortName?: string

  // zoom = 12;
  // infoContent = ''
  // position?: google.maps.LatLngLiteral;
  // options: google.maps.MapOptions = {
  //   mapTypeId: google.maps.MapTypeId.ROADMAP,
  //   zoomControl: false,
  //   scrollwheel: true,
  //   disableDoubleClickZoom: true,
  //   minZoom: 8,
  // };
  // marker = {
  //   label: { color: 'black', text: '' },
  //   title: '',
  //   options: { animation: google.maps.Animation.DROP },
  //   info: ''
  // }

  // @ViewChild(GoogleMap) map!: GoogleMap;
  // @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;

  constructor(
    private formBuilder: FormBuilder,
    private ngbModal: NgbModal,
    public userService: UserService,
    public authService: AuthenticationService,
    private activeModal: NgbActiveModal
  ) {
    this.formGroup = this.formBuilder.group({
      // firstName: new FormControl('', [Validators.required]),
      // lastName: new FormControl('', [Validators.required]),
      // designation: new FormControl(''),
      // companyName: new FormControl('', [Validators.required]),
      // companyUrl: new FormControl(''),
      emailId: new FormControl('', [Validators.required, Validators.pattern(Pattern.email)]),
      mobileNo: new FormControl('', [Validators.required, Validators.pattern(Pattern.mobile)]),
      // faxNo: new FormControl('', Validators.pattern(Pattern.mobile)),
      password: new FormControl('', [Validators.required, Validators.minLength(9), PasswordStrengthValidator]),
      confirmPassword: new FormControl('', [Validators.required]),
      // agreeTermsAndCondition: new FormControl(false, [Validators.required, Validators.requiredTrue]),

      // searchAddress: new FormControl('', [Validators.required]),
      // addressLine1: new FormControl('', [Validators.required]),
      // addressLine2: new FormControl(''),
      // city: new FormControl('', [Validators.required]),
      // state: new FormControl('', [Validators.required]),
      // country: new FormControl('', [Validators.required]),
      // zipCode: new FormControl('', [Validators.required]),
      // zipCode: new FormControl('', [Validators.required]),
      userOtp: new FormControl(''),
    })
  }

  ngOnInit(): void {
    this.authService.errorMsg = "";
    this.formGroup.reset();
  }

  ngAfterViewInit(): void {
    this.password!.nativeElement.onfocus = (e) => {
      this._isPasswordFocused$.next(true);
    }
    this.password!.nativeElement.onblur = (e) => {
      this._isPasswordFocused$.next(false);
    }
    this.confirmPassword!.nativeElement.onfocus = (e) => {
      this._isConfirmPasswordFocused$.next(true);
    }
    this.confirmPassword!.nativeElement.onblur = (e) => {
      this._isConfirmPasswordFocused$.next(false);
    }

    this.handleCountryCodeEvent(this.selectedCountryCode);
    // this.handleCountryCodeEvent(this.selectedFaxNoCountryCode);
  }

  get fc(): any { return this.formGroup.controls; }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleRepeatPasswordVisibility() {
    this.isRepeatPasswordVisible = !this.isRepeatPasswordVisible;
  }

  onChange() {
    this.authService.errorMsg = null!;
    if (this.formGroup.controls['password'].value == this.formGroup.controls['confirmPassword'].value) {
      this._isPasswordMatch$.next(true);
    } else {
      this._isPasswordMatch$.next(false);
    }
  }

  generateOTP() {
    if (this.formValidationFailed() || this._isPasswordMatch$.value == false) {
      return;
    }

    this.isLoading = true;

    this.authService.errorMsg = "";
    let email = this.formGroup.controls['emailId'].value as string;
    this.userService.generateOTP(email, email, 'REGISTRATION').subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.isLoading = false;

        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.otpId = apiResponseDto.data as string;
          this.isShowOtpVerify = true;
        } else {
          this.authService.errorMsg = apiResponseDto.message!;
        }
      },
      error: (err) => {
        this.isLoading = false;
        this.authService.errorMsg = "Error While OTP Generate";
      }
    })
  }

  resendOTP() {
    this.authService.errorMsg = "";
    let email = this.formGroup.controls['emailId'].value as string;
    let name = this.formGroup.controls['firstName'].value as string + " " + this.formGroup.controls['lastName'].value as string;

    this.userService.resendOTP(email, name, this.otpId as string).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.otpId = apiResponseDto.data as string;
          this._showSuccessMsg$.next(true);
          this._successMsg$.next("OTP resent")
          setTimeout(() => {
            this._showSuccessMsg$.next(false);
            this._successMsg$.next("")
          }, 2000);
        } else {
          this.authService.errorMsg = "Error While sending OTP";
        }
      },
      error: (err) => {
        this.authService.errorMsg = "Error While sending OTP";
      }
    })
  }

  skipOTPVerification() {
    this.formGroup.controls['userOtp'].clearValidators();
    this.formGroup.controls['userOtp'].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();

    this.handleValidSubmit("", true);
  }

  formValidationFailed() {
    this._validationErrorPresent$.next(false);
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this._validationErrorPresent$.next(true);
      return true;
    }
    return false;
  }

  populateUserRegistrationData(skipOtp: boolean) {
    let userModalDto = new RegistrationUserModalDto();
    userModalDto.otpId = this.otpId;
    userModalDto.userEnteredOtp = skipOtp ? '' : this.formGroup.controls['userOtp'].value;

    // userModalDto.firstName = this.formGroup.controls['firstName'].value!;
    // userModalDto.lastName = this.formGroup.controls['lastName'].value!;
    // userModalDto.designation = this.formGroup.controls['designation'].value!;
    // userModalDto.companyName = this.formGroup.controls['companyName'].value!;
    // userModalDto.companyUrl = this.formGroup.controls['companyUrl'].value!;

    userModalDto.emailId = this.formGroup.controls['emailId'].value;
    userModalDto.mobileNo = this.formGroup.controls['mobileNo'].value;
    userModalDto.countryCode = this.selectedCountryCode;

    // userModalDto.faxNo = this.formGroup.controls['faxNo'].value!;
    // userModalDto.faxNoCountryCode = this.selectedFaxNoCountryCode;

    // userModalDto.agreeTermsAndCondition = this.formGroup.controls['agreeTermsAndCondition'].value!;

    // Address
    // userModalDto.addressId = UUID.UUID().toString();
    // userModalDto.addressType = 'Company Address';
    // userModalDto.searchAddress = this.formGroup.controls['searchAddress'].value;
    // userModalDto.addressLine1 = this.formGroup.controls['addressLine1'].value;
    // userModalDto.addressLine2 = this.formGroup.controls['addressLine2'].value;
    // userModalDto.city = this.formGroup.controls['city'].value;
    // userModalDto.state = this.formGroup.controls['state'].value;
    // userModalDto.country = this.formGroup.controls['country'].value;
    // userModalDto.zipCode = this.formGroup.controls['zipCode'].value;
    // userModalDto.latitude = this.latitude?.toString();
    // userModalDto.longitude = this.longitude?.toString();
    // userModalDto.countryShortName = this.countryShortName;

    return userModalDto;
  }

  handleValidSubmit(loaderMsg: string, skipOtp: boolean) {
    this.authService.errorMsg = "";

    if (!skipOtp) {
      this.formGroup.controls['userOtp'].addValidators(Validators.required);
      this.formGroup.controls['userOtp'].updateValueAndValidity();
      this.formGroup.updateValueAndValidity();
    }

    if (this.formValidationFailed() || this._isPasswordMatch$.value == false) {
      return;
    }
    if (this.formGroup.dirty) {
      if (this.appLoader) {
        this.appLoader.openLoaderIcon(AuctionExtConstant.VERIFING_OTP, loaderMsg);
      }
      let registrationUserModalDto = this.populateUserRegistrationData(skipOtp);
      let emailId = this.formGroup.controls['emailId'].value!;
      let password = this.formGroup.controls['password'].value!;
      this.userService.registerUsers(registrationUserModalDto, emailId, password).subscribe({
        next: (apiResponseDto: ServerAPIResponseDto) => {
          this.appLoader?.closeLoaderIcon(AuctionExtConstant.VERIFING_OTP);
          if (apiResponseDto && apiResponseDto.code == "200") {
            let data = apiResponseDto.data as AuthenticationResponseDto;
            this.userService.setUser(data.sessionInfoDto!);
            localStorage.setItem('AUC_SESSION_ID', data.sessionID!);
            this.authService.handleServerResponse(apiResponseDto.data, this._isSaveButtonDisable$);
          } else {
            this._isSaveButtonDisable$.next(false);
            this.appLoader?.closeLoaderIcon(AuctionExtConstant.USER_REGISTRATION_PROGRESS);
            this.authService.errorMsg = apiResponseDto.message!;

          }
        },
        error: (err) => {
          console.log(err);
          this._isSaveButtonDisable$.next(false);
          this.authService.errorMsg = "Error while Registration of User";
          this.appLoader?.closeLoaderIcon(AuctionExtConstant.VERIFING_OTP);
        }
      })
    }
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  // openInfo(marker: MapMarker, content: string) {
  //   this.infoContent = content;
  //   this.info.open(marker)
  // }

  handleCountryCodeEvent(countryCodeDto: CountryCodeDto) {
    this.selectedCountryCode = countryCodeDto;
    this.countryCodeForMobileNo$.next(countryCodeDto)

    let mobileNoLength = parseInt(countryCodeDto.mobileNoLength!);
    let pattern = "^[0-9]{" + mobileNoLength + "}"

    this.formGroup.controls['mobileNo'].clearValidators();
    this.formGroup.controls['mobileNo'].setValidators([Validators.required, Validators.pattern(pattern)]);
    this.formGroup.controls['mobileNo'].updateValueAndValidity();
  }

  // handleCountryCodeEventForFaxNo(countryCodeDto: CountryCodeDto) {

  //   this.selectedFaxNoCountryCode = countryCodeDto;
  //   this.countryCodeForFaxNo$.next(countryCodeDto)

  //   let faxNoLength = parseInt(countryCodeDto.mobileNoLength!);
  //   let pattern = "^[0-9]{" + faxNoLength + "}"

  //   this.formGroup.controls['faxNo'].clearValidators();
  //   this.formGroup.controls['faxNo'].setValidators([Validators.required, Validators.pattern(pattern)]);
  //   this.formGroup.controls['faxNo'].updateValueAndValidity();
  // }

  // handleAddressChange(address: Address) {
  //   this.latitude = address.geometry.location.lat();
  //   this.longitude = address.geometry.location.lng();

  //   let city = AuctionExtUtil.getAddressByType(address, 'locality');
  //   if (!city) {
  //     city = AuctionExtUtil.getAddressByType(address, 'neighborhood');
  //   }
  //   let state = AuctionExtUtil.getAddressByType(address, 'administrative_area_level_1');
  //   let zip = AuctionExtUtil.getAddressByType(address, 'postal_code');
  //   let addressPart1 = AuctionExtUtil.getAddressByType(address, 'street_number');
  //   let addressPart2 = AuctionExtUtil.getAddressByType(address, 'route');
  //   let country = AuctionExtUtil.getAddressByType(address, 'country');
  //   this.countryShortName = AuctionExtUtil.getAddressShortNameByType(address, 'country');

  //   // Map View Init
  //   this.position = { lat: this.latitude, lng: this.longitude }
  //   this.marker.label.text = address.formatted_address;
  //   this.marker.title = address.formatted_address;
  //   this.marker.info = address.formatted_address;

  //   if (addressPart1 == '' || addressPart2 == '') {
  //     if (address.formatted_address.includes(',')) {
  //       let data = address.formatted_address.split(',')[0]
  //       this.formGroup.controls['addressLine1'].patchValue(data);
  //     } else {
  //       this.formGroup.controls['addressLine1'].patchValue(address.formatted_address)
  //     }
  //   } else {
  //     this.formGroup.controls['addressLine1'].patchValue(addressPart1 + " " + addressPart2);
  //   }
  //   this.formGroup.controls['searchAddress'].patchValue(address.formatted_address);
  //   this.formGroup.controls['state'].patchValue(state);
  //   this.formGroup.controls['city'].patchValue(city);
  //   this.formGroup.controls['country'].patchValue(country);
  //   this.formGroup.controls['zipCode'].patchValue(zip);
  //   this.formGroup.updateValueAndValidity();
  // }

  navigateToLogin() {
    this.onChangeViewPage.emit('LOGIN');
  }
}
