<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>Auction House Analytics</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="p-2">
        <div class="e-spinner" *ngIf="isLoading"></div>
        <div echarts [options]="chartOption" class="demo-chart" *ngIf="!isLoading && chartOption"></div>
    
        <hr class="my-2">
        <div class="mt-1 table-responsive">
          <table class="table table-bordered align-middle">
            <tbody class="table-group-divider">
              <tr>
                <td>No of bidders participated</td>
                <td class="font-weight-bold fs-5">{{auctionHouseChartData?.noOfBidderParticipated ?? 0}}</td>
              </tr>
              <tr>
                <td>No of Bids Placed</td>
                <td class="font-weight-bold fs-5">{{auctionHouseChartData?.noOfBidPlaced ?? 0}}</td>
              </tr>
              <!-- <tr>
                <td>Awarded Amount transacted</td>
                <td class="font-weight-bold fs-5">{{getFormattedPrice(auctionHouseChartData?.awardedAmountTransacted ?? 0)}}</td>
              </tr> -->
              <!-- <tr>
                <td>Total Lots Amount on Auction</td>
                <td class="font-weight-bold fs-5">{{auctionHouseChartData?.totalLotsAmountOnAuction ?? 0}}</td>
              </tr> -->
            </tbody>
          </table>
        </div>
      </div>
    </div>


</div>