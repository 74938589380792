import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { MyDashboardComponent } from 'src/app/shared/components/user/my-dashboard/my-dashboard.component';
import { UserPrivilege } from 'src/app/shared/enums/UserPrivilege';
import { UserEntityDto } from 'src/app/shared/models/UserEntityDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { OrganizationUiDto } from 'src/app/shared/models/user/AuctionHouseDto';
import { AdminAuctionDataHolderService } from 'src/app/shared/services/AdminAuctionDataHolder.service';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { DataRedirectionService } from 'src/app/shared/services/data-redirection.service';
import { NavigationBackService } from 'src/app/shared/services/navigation-back.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';

@Component({
  selector: 'app-new-header',
  templateUrl: './new-header.component.html',
  styleUrls: ['./new-header.component.sass']
})


export class NewHeaderComponent implements OnInit, AfterViewInit{
  public screenWidth: any;
  @Input() auctionName?: string;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }
  IsAnonymous = true;
  IsBidder = false;
  isAdmin = false;
  isSuperAdmin = false;
  IsAuctionNeer = false;

  navToggle?: HTMLInputElement | null;
  primaryNav?: HTMLInputElement | null;

  isShowProgressBar: boolean = false;

  userEntitySubscription$?: Subscription;
  auctionHouseSubscription$?: Subscription;
  lastMessageSubscription$?: Subscription;

  auctionHouse?: OrganizationUiDto;
  userEntityDto?: UserEntityDto;
  auctionEntityDto?: AuctionEntityDto | null;

  @ViewChild('menuDropdown') menuDropdown?: NgbDropdown;
  @ViewChild('headerPrimary') headerPrimary?: ElementRef<HTMLElement>;
  @ViewChild('headerSecondary') headerSecondary?: ElementRef<HTMLElement>;
  @ViewChild('appLoader', { static: false }) appLoader?: LoaderComponent;

  progress: number = 0;
  enableCustomHeader: boolean = false;
  currentScrollHeight: number = 0;
  scrollHeight: number = 0;

  @HostListener('window:scroll', ['$event']) // Listen for scroll events on the window
  onWindowScroll(event: Event) {
    const currentScrollHeight = window.scrollY;
    this.currentScrollHeight = currentScrollHeight;

    if (currentScrollHeight > 75 && this.enableCustomHeader) {
      this.headerPrimary!.nativeElement.style.opacity = '0';
      this.headerPrimary!.nativeElement.style.zIndex = '-1';
      this.headerSecondary!.nativeElement.style.opacity = '1';
      this.headerSecondary!.nativeElement.style.zIndex = '99';
      this.isShowProgressBar = true;
    } else {
      this.headerPrimary!.nativeElement.style.opacity = '1';
      this.headerPrimary!.nativeElement.style.zIndex = '99';
      this.headerSecondary!.nativeElement.style.opacity = '0';
      this.headerSecondary!.nativeElement.style.zIndex = '-1';
      this.isShowProgressBar = false;
    }

    this.getProgressBarValue();
  }

  constructor(
    private elementRef: ElementRef,
    private auctionService: AdminAuctionDataHolderService,
    private ngbModal: NgbModal,
    private _router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
    public bidderService: LandingAndBidderService,
    private activatedRoute: ActivatedRoute,
    public navigationBackService: NavigationBackService,
    private dataRedirectionService: DataRedirectionService,
  ) {}

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.bidderService._selectedAuction$.subscribe((auctionEntityDto) => {
      this.auctionEntityDto = auctionEntityDto;
    });

    // this.adminSourcingEventsDataHolderService.getNotificationAvailable$.subscribe((isNotificationAvailable) => {
    //   this.isNotificationAvailable = isNotificationAvailable;
    //   this.adminSourcingEventsDataHolderService.setShowNotification(isNotificationAvailable);
    // });

    this.bidderService.enableCustomHeader$.subscribe((enableCustomHeader) => {
      this.enableCustomHeader = enableCustomHeader;
    });
  }

  ngAfterViewInit() {
    this.headerPrimary!.nativeElement.style.opacity = '1';
    this.headerPrimary!.nativeElement.style.zIndex = '99';
    this.headerSecondary!.nativeElement.style.opacity = '0';
    this.headerSecondary!.nativeElement.style.zIndex = '-1';

    this.clickNavToggle();

    this.userEntitySubscription$ = this.userService.getUserEntity$.subscribe(
      (userEntity) => {
        if (userEntity) {
          this.userEntityDto = userEntity;
          this.IsAnonymous = false;
          this.IsBidder = AuctionExtUtil.checkIfRoleIsAssigned(userEntity.userPrivileges!, UserPrivilege.BIDDER);
        } else {
          this.IsAnonymous = true;
          this.IsBidder = false;
          this.isAdmin = false;
          this.isSuperAdmin = false;
          this.IsAuctionNeer = false;
        }
      }
    );

    this.auctionHouseSubscription$ =
      this.bidderService.getCurrentOrganizationUiDto$.subscribe((data) => {
        if (data) {
          this.auctionHouse = data;
        }
      });
  }

  getProgressBarValue() {
    var winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    var height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    this.progress = scrolled;
  }

  getAuctionName() {
    let auction = this.auctionService.getSelectedAuction();
    if (auction) {
      return auction.title;
    }
    return '';
  }

  clickNavToggle() {
    this.primaryNav = document.querySelector(
      '.primary-navigation'
    ) as HTMLInputElement | null;
    this.navToggle = document.querySelector(
      '.mobile-nav'
    ) as HTMLInputElement | null;
    // const navToggle = document.querySelector(".mobile-nav-toggle") as HTMLInputElement | null
    this.navToggle?.addEventListener('click', () => {
      const visibility = this.primaryNav?.getAttribute('data-visible');
      // console.log(primaryNav);
      // console.log(navToggle);
      // console.log(visibility);
      if (visibility === 'false') {
        this.primaryNav?.setAttribute('data-visible', 'true');
        this.navToggle?.setAttribute('aria-expanded', 'true');
      } else if (visibility === 'true') {
        this.primaryNav?.setAttribute('data-visible', 'false');
        this.navToggle?.setAttribute('aria-expanded', 'false');
      }

      const navButtonState = this.navToggle?.getAttribute('data-state');
      if (!navButtonState || navButtonState === 'closed') {
        this.navToggle?.setAttribute('data-state', 'opened');
      } else {
        this.navToggle?.setAttribute('data-state', 'closed');
      }
    });
  }

  navigateToPage(path: string, subItem?: string) {
    this.closeNavMenu();
    this.dataRedirectionService.clearData();

    if (path == '/allAuctions') {
      this._router
        .navigateByUrl('/redirect-loader', { skipLocationChange: true })
        .then(() =>
          this._router.navigate(['/allAuctions'], {
            queryParams: { status: subItem },
            skipLocationChange: true,
          })
        );
      return;

    }
    else if (path == '/event') {
      this.dataRedirectionService.setCurrentPageInSession(
        AuctionExtConstant.CLOSED_AUCTIONS
      );
    }else if (path == '/closedAuctions') {
      this.dataRedirectionService.setCurrentPageInSession(
        AuctionExtConstant.CLOSED_AUCTIONS
      );
    } else if (path == '/Bidder/myDashboard') {
      if (subItem == AuctionExtConstant.MY_DASHBOARD) {
        this.dataRedirectionService.setCurrentPageInSession(
          AuctionExtConstant.MY_DASHBOARD
        );
      } else if (subItem == AuctionExtConstant.MY_DASHBOARD_BIDS) {
        this.dataRedirectionService.setCurrentPageInSession(
          AuctionExtConstant.MY_DASHBOARD_BIDS
        );
      } else if (subItem == AuctionExtConstant.MY_REGISTRATION) {
        this.dataRedirectionService.setCurrentPageInSession(
          AuctionExtConstant.MY_REGISTRATION
        );
      } else if (subItem == AuctionExtConstant.MY_INVOICES) {
        this.dataRedirectionService.setCurrentPageInSession(
          AuctionExtConstant.MY_INVOICES
        );
      }
    } else if (path == '/calendar') {
      this.dataRedirectionService.setCurrentPageInSession(
        AuctionExtConstant.AUCTION_CALENDAR
      );
    }

    this._router
      .navigateByUrl('/redirect-loader', { skipLocationChange: true })
      .then(() => this._router.navigate([path], { skipLocationChange: true }));
  }

  navigateToHome() {
    this.dataRedirectionService.clearData();
    this.closeNavMenu();
    this._router.navigateByUrl('/', { skipLocationChange: true });
  }

  openLoginModal(content: any) {
    this.closeNavMenu();
    this.ngbModal.open(content, {
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  openMyDetailsModal(content: any, dropdown: NgbDropdown) {
    this.closeNavMenu();
    dropdown.close();
    this.ngbModal.open(content, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  openRegistrationModal(content: any) {
    this.closeNavMenu();
    this.ngbModal.open(content, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      keyboard: false,
    });
  }


  openMyRegistration(dropdown: NgbDropdown) {
    this.closeNavMenu();
    dropdown.close();
    this.ngbModal.open(MyDashboardComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  closeNavMenu() {
    this.primaryNav?.setAttribute('data-visible', 'false');
    this.navToggle?.setAttribute('aria-expanded', 'false');
    this.navToggle?.setAttribute('data-state', 'closed');
    this.menuDropdown?.close();
  }

  logout(dropdown?: NgbDropdown) {
    this.appLoader?.openLoaderIcon(
      AuctionExtConstant.USER_REGISTRATION_PROGRESS,
      'Please wait logging out...'
    );
    this.authService.logout(this.appLoader);
    this.closeNavMenu();
    dropdown?.close();
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  openMessageDialog(content: any) {
    this.ngbModal.open(content, { centered: true });
  }

  getCompanyByLogoFileId(companyId?: string){
    if(this.auctionHouse ){
      return this.auctionHouse.logo?.fileId;
    }
    return "";
  }

  ngOnDestroy(): void {
    if (this.userEntitySubscription$) {
      this.userEntitySubscription$.unsubscribe();
    }
    if (this.auctionHouseSubscription$) {
      this.auctionHouseSubscription$.unsubscribe();
    }
    if (this.lastMessageSubscription$) {
      this.lastMessageSubscription$.unsubscribe();
    }
  }
}


