<div class="container mt-2" *ngIf="layoutType == 'DASHBOARD'">
  <div class="notification border border-2 border-danger rounded p-2 mb-2"
    *ngFor="let item of newApplicationNotifications; index as i">
    <span class="me-4"><img src="assets/icons/notifications.gif" width="32" /></span>
    <span class="span-class">
      <ng-container [ngTemplateOutlet]="
        item.code == 'APPLICATION_NOTIFICATION_CODE_101' ? APPLICATION_NOTIFICATION_CODE_101
          : item.code == 'APPLICATION_NOTIFICATION_CODE_102' ? APPLICATION_NOTIFICATION_CODE_102
          : item.code == 'APPLICATION_NOTIFICATION_CODE_103' ? APPLICATION_NOTIFICATION_CODE_103
          : item.code == 'APPLICATION_NOTIFICATION_CODE_104' ? APPLICATION_NOTIFICATION_CODE_104
          : defaultNotification" [ngTemplateOutletContext]="{applicationNotification: item}">
      </ng-container>
    </span>

    <button class="btn btn-link" *ngIf="currentIndex != i" (click)="navigateToEventDetails(item, i)">
      Click here to see details
    </button>
    <div class="spinner-border text-primary ms-2" role="status" *ngIf="currentIndex == i && isNavLoading">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>

<span *ngIf="layoutType == 'HEADER'">
  <span class="me-3" ngbDropdown #notificationDropdown="ngbDropdown" autoClose="true" container="body"
    placement="bottom-right">
    <span ngbDropdownToggle data-bs-toggle="dropdown" container="body" placement="top" ngbTooltip="Notification">
      <img src="assets/icons/notification-bell.svg" class="svg-icon-small c-pointer" 
        container="body" *ngIf="!isNotificationAvailable" (click)="showNotification()" />
      <img src="assets/icons/notifications.gif" class="svg-icon-class-md c-pointer" 
        container="body" *ngIf="isNotificationAvailable" (click)="showNotification()" />
    </span>
    <ul ngbDropdownMenu class="text-start mt-3 margin-right dropdown-menu scroll-notification shadow" style="width: 24rem;">
      <li class="c-default li-white my-2 px-2">
        <div class="row">
          <div class="col-9 div-class">
            Notifications
            <span *ngIf="countAllNotifications > 0">({{countAllNotifications}})</span>
          </div>
          <div class="col-3 text-end" *ngIf="countNewNotifications > 0">
            <span class="new-notification-badge">New
              <span class="position-absolute blink top-10 start-97 translate-middle badge rounded-pill bg-danger">
                {{ countNewNotifications }}
              </span>
            </span>
          </div>
        </div>
        <hr class="hr my-2" />
      </li>
      <li ngbDropdownItem class="c-pointer px-2" *ngFor="let item of allApplicationNotifications">
        <div class="card mb-2 p-2">
          <div class="row">
            <div class="col-12 medium-font overflow-class-sort">
              <ng-container [ngTemplateOutlet]="
                item.code == 'APPLICATION_NOTIFICATION_CODE_101' ? APPLICATION_NOTIFICATION_CODE_101
                  : item.code == 'APPLICATION_NOTIFICATION_CODE_102' ? APPLICATION_NOTIFICATION_CODE_102
                  : item.code == 'APPLICATION_NOTIFICATION_CODE_103' ? APPLICATION_NOTIFICATION_CODE_103
                  : item.code == 'APPLICATION_NOTIFICATION_CODE_104' ? APPLICATION_NOTIFICATION_CODE_104
                  : defaultNotification" [ngTemplateOutletContext]="{applicationNotification: item}">
              </ng-container>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6 small-font" *ngIf="item.createdTimeUtc">
              {{ getDisplayDate(item.createdTimeUtc) }}
            </div>
            <div class="col-6 text-end small-font" *ngIf="item.expiredDate">
              <span class="text-danger">Expiry: {{ getDisplayDate(item.expiredDate) }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </span>
</span>

<ng-template #APPLICATION_NOTIFICATION_CODE_101 let-applicationNotification="applicationNotification">
  Normalization Request on Event: {{ applicationNotification.attributes['EVENT'] }} of
  status : REQUESTED is pending for Review.
</ng-template>

<ng-template #APPLICATION_NOTIFICATION_CODE_102 let-applicationNotification="applicationNotification">
  Normalization Request on Event: {{ applicationNotification.attributes['EVENT'] }} of
  status : ACCEPTED is pending for Review.
</ng-template>

<ng-template #APPLICATION_NOTIFICATION_CODE_103 let-applicationNotification="applicationNotification">
  Normalization Request on Event: {{ applicationNotification.attributes['EVENT'] }} of
  status : REVOKED is pending for Review.
</ng-template>

<ng-template #APPLICATION_NOTIFICATION_CODE_104 let-applicationNotification="applicationNotification">
  Normalization Request on Event: {{ applicationNotification.attributes['EVENT'] }} of
  status : REJECTED is pending for Review.
</ng-template>

<ng-template #defaultNotification></ng-template>