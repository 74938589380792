import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';
import { AuctionExtConstant } from '../../util/AuctionExtConstant';
import { UserService } from '../../services/user.service';
import { DataRedirectionService } from '../../services/data-redirection.service';
import { BehaviorSubject } from 'rxjs';
import { UserEntityDto } from '../../models/UserEntityDto';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserCategorySelectionComponent } from '../user-category-selection/user-category-selection.component';

@Component({
  selector: 'app-email-verify-modal',
  templateUrl: './email-verify-modal.component.html',
  styleUrls: ['./email-verify-modal.component.sass']
})
export class EmailVerifyModalComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;

  _showSuccessMsg$ = new BehaviorSubject<boolean>(false);
  _showErrorMsg$ = new BehaviorSubject<boolean>(false);
  _successMsg$ = new BehaviorSubject<string>("");
  isLoading$ = new BehaviorSubject<boolean>(false);
  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);

  errorMsg?: string;
  successMsg?: string;
  otpId?: string;
  isShowOtpModal: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private ngbModal: NgbModal,
    private formBuilder: FormBuilder,
    private userService: UserService,
  ) {
    this.formGroup = this.formBuilder.group({
      otp: new FormControl('', Validators.required)
    })
  }

  ngOnInit(): void {
    this.isShowOtpModal = false;
  }

  ngOnDestroy(): void {
    this.isShowOtpModal = false;
  }

  get fc(): any { return this.formGroup.controls; }

  showOtpModal() {
    this.generateOTP();
    this.isShowOtpModal = true;
  }

  closeModal() {
    this.activeModal.close();
  }

  generateOTP() {
    this.errorMsg = "";
    let email = this.userService.getUserEntity().primaryEmailId!;
    let name = this.userService.getUserEntity().name!;
    this.userService.generateOTP(email, name, 'EMAIL_VERIFICATION').subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.otpId = apiResponseDto.data as string;
        } else {
          this.errorMsg = "Error While OTP Generate";
        }
      },
      error: (err) => {
        this.errorMsg = "Error While OTP Generate";
      }
    })
  }

  resendOTP() {
    this.formGroup.controls['otp'].reset();
    this.isLoading$.next(true);
    this.errorMsg = "";
    let email = this.userService.getUserEntity().primaryEmailId!;
    let name = this.userService.getUserEntity().name!;
    this.userService.resendOTP(email, name, this.otpId as string).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.otpId = apiResponseDto.data as string;
          this._showSuccessMsg$.next(true);
          this._successMsg$.next("OTP resent")
          this.isLoading$.next(false);
          setTimeout(() => {
            this._showSuccessMsg$.next(false);
            this._successMsg$.next("")
          }, 2000);
        } else {
          this.errorMsg = "Error While sending OTP";
          this.isLoading$.next(false);
        }
      },
      error: (err) => {
        this.errorMsg = "Error While sending OTP";
        this.isLoading$.next(false);
      }
    })
  }

  verifyOTP() {
    this.isLoading$.next(true);
    this.errorMsg = "";
    let userEnteredOtp = this.formGroup.controls['otp'].value;
    this.userService.verifyOTP(this.otpId as string, userEnteredOtp).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let userEntityDto = apiResponseDto.data as UserEntityDto;
          this.userService.setUserDto(userEntityDto);
          this._showSuccessMsg$.next(true);
          this._successMsg$.next("Successfully Verified");
          this.isLoading$.next(false);

          setTimeout(() => {
            this._showSuccessMsg$.next(false);
            this._successMsg$.next("");
            this.closeModal();
          }, 2000);
        } else {
          this.errorMsg = apiResponseDto.message!;
          this.isLoading$.next(false);
        }
      },
      error: (err) => {
        this.errorMsg = "Error While verifying OTP";
        this.isLoading$.next(false);;
      }
    })
  }
}
