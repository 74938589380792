import { Timestamp } from "@angular/fire/firestore";
import { FileInfoDto } from "../FileInfoDto";

export class UserRatingDto {
  id?: string;
  auctionHouseId?: string;
  auctionId?: string;
  lotId?: string;
  userId?: string;
  name?: string;
  emailId?: string;
  profileImage?: FileInfoDto;
  rating?: number;
  timestamp?: Timestamp;
}
