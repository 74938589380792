import { AuctionPremiumSuppliersEntityDto } from './../models/AuctionPremiumSuppliersEntityDto';


import { AuctionEntityDto } from '../models/user/AuctionEntityDto';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, firstValueFrom, tap, timeout } from "rxjs";

import { ErrorService } from "./error.service";
import { AuctionStatus } from '../models/user/AuctionStatus';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { AuctionLotEntityDto } from '../models/user/AuctionLotEntityDto';
import { AuctionExtUtil } from '../util/AuctionExtUtil';
import { CalendarService } from './calendar.service';
import { AuctionExtConstant } from '../util/AuctionExtConstant';
import { AuctionDataCardsWrapperDto } from '../models/AuctionDataCardsWrapperDto';
import { AuctionCardWrapperDto } from '../models/AuctionCardWrapperDto';

@Injectable({
  providedIn: 'root'
})
export class AdminAuctionDataHolderService {

  allAuctionCardWrapperDtos?: AuctionCardWrapperDto[] = [];
  private allAuctions?: AuctionEntityDto[] = [];

  filteredAuctions$ = new BehaviorSubject<AuctionEntityDto[]>([]);
  allAuctionsList$ = new BehaviorSubject<AuctionEntityDto[]>([]);
  private postAuctionsList$ = new BehaviorSubject<AuctionEntityDto[]>([]);

  private runningAuctionsList$ = new BehaviorSubject<AuctionEntityDto[]>([]);
  private upcomingAuctionsList$ = new BehaviorSubject<AuctionEntityDto[]>([]);
  private draftAuctionsList$ = new BehaviorSubject<AuctionEntityDto[]>([]);

  sortBy?: string = 'Recent';
  currentAuctionType?: string = '';

  _selectedAuction$ = new BehaviorSubject<AuctionEntityDto | null>(null);
  selectedAuction?: AuctionEntityDto;
  _allAuctionsDataLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private calendarService: CalendarService
  ) { }


  get getFilteredAuctions$() { return this.filteredAuctions$.asObservable() }
  getSelectedAuction() { return this.selectedAuction; }
  getSort() { return this.sortBy }
  getCurrentAuctionType() { return this.currentAuctionType }
  get selectedAuction$() { return this._selectedAuction$.asObservable() }
  get getAllAuctionsList$() { return this.allAuctionsList$.asObservable() }
  get getPostAuctionsList$() { return this.postAuctionsList$.asObservable() }
  get getAllAuctionsDataLoaded$(){ return this._allAuctionsDataLoaded$.asObservable( )}

  get getRunningAuctionsList$() { return this.runningAuctionsList$; };
  get getUpcomingAuctionsList$() { return this.upcomingAuctionsList$; };
  get getDraftAuctionsList$() { return this.draftAuctionsList$; };

  setSort(sort: string) { this.sortBy = sort }
  setCurrentAuctionType(filter: string) { this.currentAuctionType = filter }

  setSelectedAuction(auction: AuctionEntityDto) {
    this.selectedAuction = auction;
    this._selectedAuction$.next(this.selectedAuction!);
  }

  setSelectedAuctionByAuctionId(auctionId: string) {
    if(this.allAuctions && this.allAuctions.length > 0){
      let auction = this.allAuctions?.find(item => item.auctionId == auctionId);
      this.selectedAuction = auction;
      this._selectedAuction$.next(this.selectedAuction!);
    }else{
      console.error("All auctions data not available, setSelectedAuctionByAuctionId() will fail");
    }

  }

  addNewAuction(auctionEntityDto: AuctionEntityDto){
    this.allAuctions?.push(auctionEntityDto);
  }

  // filterMultipleAuctionData(status: string[]) {
  //   let allAuctionsList = [...this.allAuctions ?? []];
  //   let tempAuctionList: AuctionEntityDto[] = [];

  //   if (status.includes("ALL")) {
  //     tempAuctionList = allAuctionsList;
  //   } else {
  //     allAuctionsList.forEach((item) => {
  //       if ( status.includes(item.status as string)) {
  //         tempAuctionList.push(item);
  //       }
  //     })
  //   }

  //   this.filteredAuctions$.next(tempAuctionList);
  //   this.sortAuctionData()
  // }

  // filterAuctionData(searchText: string) {
  //   let auctionList: AuctionEntityDto[] = this.filteredAuctions$.value;

  //   if (searchText != '') {
  //     let auctions = auctionList.filter(item => item.auctionName?.toLowerCase().includes(searchText.toLowerCase()) || item.sequenceNo?.toString()?.includes(searchText))
  //     this.filteredAuctions$.next(auctions!);
  //   }

  //   this.sortAuctionData()
  // }

  // filterData(filterType: string) {
  //   let auctionList: AuctionEntityDto[] = this.filteredAuctions$.value;

  //  if(filterType == '1'){
  //     let closeAuction = auctionList?.filter(item => item.status == 'CLOSE' ).sort((a, b) => Date.parse(b.endDate!) - Date.parse(a.endDate!) );;
  //      let remainingAuction = auctionList?.filter(item => item.status != 'CLOSE').sort((a, b) => Date.parse(a.endDate!) - Date.parse(b.endDate!) );;

  //     let combineAuction = remainingAuction.concat(closeAuction);
  //     this.filteredAuctions$.next(combineAuction!);
  //   }else  if(filterType == '2'){
  //     let auctions = auctionList?.sort((a, b) => a.sequenceNo! - b.sequenceNo!);
  //     this.filteredAuctions$.next(auctions!);
  //    } else  if(filterType == '3'){
  //       let auctions = auctionList?.sort((a, b) => Date.parse(b.createdDate!) - Date.parse(a.createdDate!) );;
  //       this.filteredAuctions$.next(auctions!);

  //     }else{
  //     this.sortAuctionData()
  //   }
  // }

  // sortAuctionData() {
  //   let auctionList = [...this.filteredAuctions$.value ?? []]
  //   if (this.sortBy == 'A to Z') {
  //     let auctions = auctionList?.sort((a, b) => a.auctionName!.toLowerCase() > b.auctionName!.toLowerCase() ? 1 : -1);
  //     this.filteredAuctions$.next(auctions!);
  //   } else {
  //     let auctions = auctionList?.sort((a, b) => Date.parse(a.startDate!) < Date.parse(b.startDate!) ? 1 : -1);
  //     this.filteredAuctions$.next(auctions);
  //   }
  // }

  // updateRunningAuctionsData(auctionsList: AuctionEntityDto[]) {
  //   let runningAuctions = this.runningAuctionsList$.value;

  //   auctionsList.forEach(item => {
  //     let auction = runningAuctions.find(m => m.auctionId == item.auctionId);
  //     if (auction) {
  //       Object.assign(runningAuctions[runningAuctions.indexOf(auction)], item);
  //     } else {
  //       runningAuctions.push(item);
  //     }
  //   })
  //   this.runningAuctionsList$.next(runningAuctions);
  // }

  // updateUpcomingAuctionsData(auctionsList: AuctionEntityDto[]) {
  //   let upcomingAuctions = this.upcomingAuctionsList$.value;

  //   auctionsList.forEach(item => {
  //     let auction = upcomingAuctions.find(m => m.auctionId == item.auctionId);
  //     if (auction) {
  //       Object.assign(upcomingAuctions[upcomingAuctions.indexOf(auction)], item);
  //     } else {
  //       upcomingAuctions.push(item);
  //     }
  //   })
  //   this.upcomingAuctionsList$.next(upcomingAuctions);
  // }

  // updateClosedAuctionsData(auctionsList: AuctionEntityDto[]) {
  //   let closedAuctions = this.postAuctionsList$.value;

  //   auctionsList.forEach(item => {
  //     let auction = closedAuctions.find(m => m.auctionId == item.auctionId);
  //     if (auction) {
  //       Object.assign(closedAuctions[closedAuctions.indexOf(auction)], item);
  //     } else {
  //       closedAuctions.push(item);
  //     }
  //   })
  //   this.postAuctionsList$.next(closedAuctions);
  // }

  // updateAllAuctionsData(auctionsList: AuctionEntityDto[]) {
  //   let allAuctions = this.allAuctionsList$.value;

  //   auctionsList.forEach(item => {
  //     let auction = allAuctions.find(m => m.auctionId == item.auctionId);
  //     if (auction) {
  //       Object.assign(allAuctions[allAuctions.indexOf(auction)], item);
  //     } else {
  //       allAuctions.push(item);
  //     }
  //   })
  //   this.allAuctionsList$.next(allAuctions);
  // }

  // updateDraftAuctionsData(auctionsList: AuctionEntityDto[]) {
  //   let draftAuctions = this.draftAuctionsList$.value;

  //   auctionsList.forEach(item => {
  //     let auction = draftAuctions.find(m => m.auctionId == item.auctionId);
  //     if (auction) {
  //       Object.assign(draftAuctions[draftAuctions.indexOf(auction)], item);
  //     } else {
  //       draftAuctions.push(item);
  //     }
  //   })
  //   this.draftAuctionsList$.next(draftAuctions);
  // }


  // loadAuctionCardWrapperDtos() {
  //   this.getAuctionCardWrapperDtosInitial().subscribe({
  //     next: (apiResponseDto: ServerAPIResponseDto) => {
  //       if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
  //         let auctionCardWrapperDtosInitial = apiResponseDto.data as AuctionDataCardsWrapperDto;
  //         this.updateAuctionData(auctionCardWrapperDtosInitial);

  //         // Load Remaining AuctionCardWrapperDtos
  //         this.loadAuctionCardWrapperDtosRemaining();
  //       }
  //     },
  //     error: (error) => {
  //       console.error(error);
  //     }
  //   })
  // }

  loadAuctionCardWrapperDtos() {
    this.getAuctionCardWrapperDtosRemaining().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let auctionCardWrapperDtosRemaining = apiResponseDto.data as AuctionDataCardsWrapperDto;

          this.updateAuctionData(auctionCardWrapperDtosRemaining);
        }
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  async loadAuctionBasedOnSequenceNo(sequenceNo: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getAuctionBasedOnSequenceNo(sequenceNo));
      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        let auctionEntityDto = apiResponseDto.data as AuctionEntityDto;
        this.setSelectedAuction(auctionEntityDto);
      }
    } catch (error) {
      console.error(error);
    }
  }

  updateAuctionsChangesFromFirestore(auctionsList: AuctionEntityDto[]) {
    let runningAuctionsList = this.runningAuctionsList$.value;
    let upcomingAuctionsList = this.upcomingAuctionsList$.value;
    let closedAuctionsList = this.postAuctionsList$.value;
    let allAuctionsList = this.allAuctionsList$.value;
    let draftAuctionsList = this.draftAuctionsList$.value;

    auctionsList.forEach(updateDto => {
      let isAuctionExistsInRunningList = runningAuctionsList.find(m => m.auctionId == updateDto.auctionId);
      let isAuctionExistsInUpcomingList = upcomingAuctionsList.find(m => m.auctionId == updateDto.auctionId);
      let isAuctionExistsInClosedList = closedAuctionsList.find(m => m.auctionId == updateDto.auctionId);
      let isAuctionExistsInAllList = allAuctionsList.find(m => m.auctionId == updateDto.auctionId);
      let isAuctionExistsInDraftList = draftAuctionsList.find(m => m.auctionId == updateDto.auctionId);

      if (isAuctionExistsInAllList) {
        let index = allAuctionsList.findIndex(m => m.auctionId == updateDto.auctionId);
        if (updateDto.status == AuctionStatus.DISCARD) {
          allAuctionsList.splice(index, 1)
        } else {
          Object.assign(allAuctionsList[index], updateDto);
        }
        this.allAuctionsList$.next(allAuctionsList);
      } else {
        if (updateDto?.status != AuctionStatus.DISCARD) {
          allAuctionsList.push(updateDto);
          this.allAuctionsList$.next(allAuctionsList);
        }
      }

      if (isAuctionExistsInDraftList) {
        let index = draftAuctionsList.findIndex(m => m.auctionId == updateDto.auctionId);
        if ((updateDto.status == AuctionStatus.DISCARD) ||
          (updateDto.status == AuctionStatus.LIVE_WAIT) ||
          (updateDto.status == AuctionStatus.LIVE) ||
          (updateDto.status == AuctionStatus.CLOSE)) {
          draftAuctionsList.splice(index, 1)
        } else if (updateDto.status == AuctionStatus.DRAFT) {
          Object.assign(draftAuctionsList[index], updateDto);
        }
        this.draftAuctionsList$.next(draftAuctionsList);
      } else {
        // add item to draft list
        if (updateDto?.status! == AuctionStatus.DRAFT) {
          draftAuctionsList.push(updateDto);
          this.draftAuctionsList$.next(draftAuctionsList);
        }
      }

      if (isAuctionExistsInUpcomingList) {
        let index = upcomingAuctionsList.findIndex(m => m.auctionId == updateDto.auctionId);
        if ((updateDto.status == AuctionStatus.DISCARD) ||
          (updateDto.status == AuctionStatus.DRAFT) ||
          (updateDto.status == AuctionStatus.LIVE) ||
          (updateDto.status == AuctionStatus.CLOSE)) {
          upcomingAuctionsList.splice(index, 1)
        } else {
          Object.assign(upcomingAuctionsList[index], updateDto);
        }
        this.upcomingAuctionsList$.next(upcomingAuctionsList);
      } else {
        if (updateDto?.status == AuctionStatus.LIVE_WAIT) {
          upcomingAuctionsList.push(updateDto);
          this.upcomingAuctionsList$.next(upcomingAuctionsList);
        }
      }

      if (isAuctionExistsInRunningList) {
        let index = runningAuctionsList.findIndex(m => m.auctionId == updateDto.auctionId);
        if ((updateDto.status == AuctionStatus.DISCARD) ||
          (updateDto.status == AuctionStatus.DRAFT) ||
          (updateDto.status == AuctionStatus.LIVE_WAIT) ||
          (updateDto.status == AuctionStatus.CLOSE)) {
          runningAuctionsList.splice(index, 1)
        } else {
          Object.assign(runningAuctionsList[index], updateDto);
        }
        this.runningAuctionsList$.next(runningAuctionsList);
      } else {
        if (updateDto?.status == AuctionStatus.LIVE) {
          runningAuctionsList.push(updateDto);
          this.runningAuctionsList$.next(runningAuctionsList);
        }
      }

      if (isAuctionExistsInClosedList) {
        let index = closedAuctionsList.findIndex(m => m.auctionId == updateDto.auctionId);
        if ((updateDto.status == AuctionStatus.DISCARD) ||
          (updateDto.status == AuctionStatus.DRAFT) ||
          (updateDto.status == AuctionStatus.LIVE_WAIT) ||
          (updateDto.status == AuctionStatus.LIVE)) {
          closedAuctionsList.splice(index, 1)
        } else {
          Object.assign(closedAuctionsList[index], updateDto);
        }
        this.postAuctionsList$.next(closedAuctionsList);
      } else {
        if (updateDto?.status == AuctionStatus.CLOSE) {
          closedAuctionsList.push(updateDto);
          this.postAuctionsList$.next(closedAuctionsList);
        }
      }

      if(this.selectedAuction && this.selectedAuction.auctionId == updateDto.auctionId){
        Object.assign(this.selectedAuction, updateDto);
        this.setSelectedAuction(this.selectedAuction);
      }

    })
  }

  clearAuctionData() {
    this.allAuctionsList$.next([]);
    this.runningAuctionsList$.next([]);
    this.upcomingAuctionsList$.next([]);
    this.draftAuctionsList$.next([]);

    this.filteredAuctions$.next([]);
    this.postAuctionsList$.next([]);
  }

  getAuctionSearchData(auctionHouseId: string, statusList: string[]) {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId).set('statusString', JSON.stringify(statusList));
    return this.http.get<ServerAPIResponseDto>('auctionSearchData', { params }).pipe(timeout(600000),
      tap(_ => console.log("Search data fetched successfully for auctionHouseId: " + auctionHouseId)),
      catchError(this.errorService.handleError<any>("Error while fetching search data for auctionHouseId: " + auctionHouseId)));
  }

  // updateAuctionData(auctions: AuctionEntityDto[]) {
  //   this.allAuctions = [];
  //   this.clearAuctionData();

  //   let sortedAuctions = auctions.sort((a, b) => AuctionExtUtil.getRemainingTime(a.endDate!, a.timeZone!) - AuctionExtUtil.getRemainingTime(b.endDate!, b.timeZone!));
  //   this.allAuctionsList$.next(sortedAuctions);

  //   if (sortedAuctions && sortedAuctions.length > 0) {
  //     this.allAuctions = sortedAuctions;
  //     this.calendarService.setAllAuctions(sortedAuctions);

  //     let upcoming = auctions.filter(item => item.status == AuctionStatus.LIVE_WAIT);
  //     if (upcoming && upcoming.length > 0) {
  //       this.upcomingAuctionsList$.next(upcoming);
  //     } else {
  //       this.upcomingAuctionsList$.next([]);
  //     }

  //     let running = auctions.filter(item => item.status == AuctionStatus.LIVE);
  //     if (running && running.length > 0) {
  //       this.runningAuctionsList$.next(running)
  //     } else {
  //       this.runningAuctionsList$.next([])
  //     }

  //     let past = auctions.filter(item => item.status == AuctionStatus.CLOSE);
  //     if (past && past.length > 0) {
  //       this.postAuctionsList$.next(past);
  //     } else {
  //       this.postAuctionsList$.next([]);
  //     }

  //     let draftAuctions = auctions.filter(item => item.status == AuctionStatus.DRAFT);
  //     if (draftAuctions && draftAuctions.length > 0) {
  //       this.draftAuctionsList$.next(draftAuctions);
  //     } else {
  //       this.draftAuctionsList$.next([]);
  //     }

  //     // this.filterAuctionData('');
  //     // this.filterMultipleAuctionData(["ALL"]);

  //     if (this._selectedAuction$.value && this.allAuctions && this.allAuctions.length > 0) {
  //       let auctionId = this._selectedAuction$.value.auctionId;
  //       let auctionEntityDto = this.allAuctions.find(item => item.auctionId == auctionId);
  //       if (auctionEntityDto) {
  //         this.setSelectedAuction(auctionEntityDto);
  //       }

  //     }
  //   }


  //   this._allAuctionsDataLoaded$.next(true);
  // }

  updatePostAuctionList(auction: AuctionEntityDto){
    this.postAuctionsList$.next([auction]);
  }

  updateAuctionData(auctionCardWrapperDtos: AuctionDataCardsWrapperDto) {
    this.allAuctions = [];
    // this.clearAuctionData();
    this.draftAuctionsList$.next([]);
    this.upcomingAuctionsList$.next([]);
    this.runningAuctionsList$.next([]);
    this.postAuctionsList$.next([]);

    if(auctionCardWrapperDtos.draftAuctionWrapperDtos && auctionCardWrapperDtos.draftAuctionWrapperDtos.length > 0){
      let draftList = auctionCardWrapperDtos.draftAuctionWrapperDtos.map(item => item.auctionEntityDto!);
      this.allAuctions = this.allAuctions.concat(draftList);
      this.draftAuctionsList$.next(draftList);
    }

    if(auctionCardWrapperDtos.liveAuctionWrapperDtos && auctionCardWrapperDtos.liveAuctionWrapperDtos.length > 0){
      let liveList = auctionCardWrapperDtos.liveAuctionWrapperDtos.map(item => item.auctionEntityDto!);
      this.allAuctions = this.allAuctions.concat(liveList);
      this.runningAuctionsList$.next(liveList)
    }

    if(auctionCardWrapperDtos.upcomingAuctionWrapperDtos && auctionCardWrapperDtos.upcomingAuctionWrapperDtos.length > 0){
      let upcomingList = auctionCardWrapperDtos.upcomingAuctionWrapperDtos.map(item => item.auctionEntityDto!);
      this.allAuctions = this.allAuctions.concat(upcomingList);
      this.upcomingAuctionsList$.next(upcomingList);
    }

    if(auctionCardWrapperDtos.closeAuctionWrapperDtos && auctionCardWrapperDtos.closeAuctionWrapperDtos.length > 0){
      let closeList = auctionCardWrapperDtos.closeAuctionWrapperDtos.map(item => item.auctionEntityDto!);
      this.allAuctions = this.allAuctions.concat(closeList)
      this.postAuctionsList$.next(closeList);
    }


    let sortedAuctions = this.allAuctions.sort((a, b) => AuctionExtUtil.getRemainingTime(a.endDate!, a.timeZone!) - AuctionExtUtil.getRemainingTime(b.endDate!, b.timeZone!));
    this.allAuctionsList$.next(sortedAuctions);

    if (sortedAuctions && sortedAuctions.length > 0) {
      this.allAuctions = sortedAuctions;

      if (this._selectedAuction$.value && this.allAuctions && this.allAuctions.length > 0) {
        let auctionId = this._selectedAuction$.value.auctionId;
        let auctionEntityDto = this.allAuctions.find(item => item.auctionId == auctionId);
        if (auctionEntityDto) {
          this.setSelectedAuction(auctionEntityDto);
        }

      }
    }


    this._allAuctionsDataLoaded$.next(true);
  }


    // API CALLS

  getAuctionBasedOnSequenceNo(sequenceNo: string) {
    let params = { sequenceNo: sequenceNo };
    return this.http.get<ServerAPIResponseDto>('auctionByIndex', { params }).pipe(
      tap(_ => console.log("Get auctionByIndex")),
      catchError(this.errorService.handleError<any>("Error while getting auctionByIndex")));
  }

    getAuctionCardWrapperDtosInitial() {
      let params = { pageSize: AuctionExtConstant.PAGE_SIZE };
      return this.http.get<ServerAPIResponseDto>('auctionCardWrapperDtosInitialForAdmin', { params: params }).pipe(
        tap(_ => console.log("Get AuctionCardWrapperDtosInitial")),
        catchError(this.errorService.handleError<any>("Error while getting AuctionCardWrapperDtosInitial")));
    }

    getAuctionCardWrapperDtosRemaining() {
      return this.http.get<ServerAPIResponseDto>('auctionCardWrapperDtosRemainingForAdmin').pipe(
        tap(_ => console.log("Get AuctionCardWrapperDtosRemaining")),
        catchError(this.errorService.handleError<any>("Error while getting AuctionCardWrapperDtosRemaining")));
    }

  getAuctionPremiumSuppliers(auctionHouseId: string, auctionId: string) {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId).set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('auctionPremiumSuppliers', { params }).pipe(
      tap(_ => console.log("Get auctionPremiumSuppliers")),
      catchError(this.errorService.handleError<any>("Error while getting auctionPremiumSuppliers")));
  }

  getGlobalBidder() {
    // let params = new HttpParams().set('auctionHouseId', auctionHouseId);
    return this.http.get<ServerAPIResponseDto>('onboardUsers').pipe(
      tap(_ => console.log("Get onboardUsers")),
      catchError(this.errorService.handleError<any>("Error while getting onboardUsers")));
  }


  getAuctionActivity(auctionId: string) {
    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('auctionActivities', { params }).pipe(
      tap(_ => console.log("Get auctionActivities")),
      catchError(this.errorService.handleError<any>("Error while getting auctionPremiumSuppliers")));
  }

     /// API CALLS
  saveAuctionPremiumSuppliers(auctionPremiumSuppliersDto: AuctionPremiumSuppliersEntityDto) {
    return this.http.post<ServerAPIResponseDto>('auctionPremiumSuppliers', auctionPremiumSuppliersDto).pipe(
      tap(_ => console.log("Saved auctionPremiumSuppliersDto to DB" + auctionPremiumSuppliersDto.auctionId)),
      catchError(this.errorService.handleError<any>("Error while Saving Auction House to DB" + auctionPremiumSuppliersDto.auctionId)))
  }
}
