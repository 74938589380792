import { Component } from '@angular/core';

@Component({
  selector: 'app-listing-contact-detail',
  templateUrl: './listing-contact-detail.component.html',
  styleUrls: ['./listing-contact-detail.component.sass']
})
export class ListingContactDetailComponent {

}
