import { UserRatingDto } from './../models/user/UserRatingDto';
import { Injectable, OnDestroy } from '@angular/core';
import { collection, doc, DocumentData, Firestore, onSnapshot, query, setDoc, where } from '@angular/fire/firestore';
import { AdminDashboardService } from './admin-dashboard.service';
import { UserService } from './user.service';
import { LandingAndBidderService } from './LandingAndBidder.service';
import { AuctionQuestionDto } from '../models/user/AuctionQuestionDto';
import { getAnalytics, logEvent } from '@angular/fire/analytics';
import { EmailTemplateDto } from '../models/user/EmailTemplateDto';
import { SuperAdminDashboardService } from './super-admin-dashboard.service';
import { Unsubscribe } from '@angular/fire/firestore';
import { MasterDataDto } from '../models/MasterDataDto';
import { DeploymentConfigurationDto } from '../models/user/DeploymentConfigurationDto';

@Injectable({
  providedIn: 'root'
})
export class FirestoreListenerService implements OnDestroy{
  collectionAuctions: string = "AUCTIONS";
  collectionAuctionLots: string = "AUCTION_LOTS";
  collectionMasterData: string = "MASTER_DATA";
  collectionUserMaxBids: string = "USER_MAX_BIDS";
  collectionUserBids: string = "USER_BIDS";
  collectionBidsHighestData: string = "BIDS_HIGHEST_DATA";
  collectionUserAuctionRegistration: string = "USER_AUCTION_REGISTRATION";
  collectionUserOffer: string = "USER_OFFER";
  collectionUserLotFavourite: string = "USER_LOT_FAVOURITE";
  collectionDeploymentConfiguration: string = "DEPLOYMENT_CONFIGURATION";
  collectionUserRating: string = "USER_RATING";
  collectionAuctionQuestions: string = "AUCTION_QUESTIONS";
  collectionEmailTemplate: string = "EMAIL_TEMPLATE";



  auctionHouseUnsubscribe: Unsubscribe | undefined;
  allLotsOfPostAuctionsUnsubscribe: Unsubscribe | undefined;
  maxOfferPriceOnLotUnsubscribe: Unsubscribe | undefined;
  userOfferPriceOnLotUnsubscribe: Unsubscribe | undefined;
  userLotFavoriteUnsubscribe: Unsubscribe | undefined;
  userAuctionRegistrationUnsubscribe: Unsubscribe | undefined;
  myAuctionRegistrationsUnsubscribe: Unsubscribe | undefined;
  allUserAuctionRegistrationsUnsubscribe: Unsubscribe | undefined;
  userBidsOfLotUnsubscribe: Unsubscribe | undefined;
  userMaxBidsOfLotUnsubscribe: Unsubscribe | undefined;
  highestDataOfLotUnsubscribe: Unsubscribe | undefined;
  totalBidsOfAuctionUnsubscribe: Unsubscribe | undefined;
  auctionQuestionsUnsubscribe: Unsubscribe | undefined;
  userRatingsUnsubscribe: Unsubscribe | undefined;
  emailTemplateUnsubscribe: Unsubscribe | undefined;
  auctionQuestionsForAdminUnsubscribe: Unsubscribe | undefined;

  constructor(
    private firestore: Firestore,
    private adminService: AdminDashboardService,
    private bidderService: LandingAndBidderService,
    private superAdminService: SuperAdminDashboardService,
  ) { }

  //save rating
  async saveUserRating(userRatingDto: UserRatingDto) {

    logEvent(getAnalytics(), 'saveUserRating');

    let docId = userRatingDto.id;
    let userRatingRef = doc(this.firestore, this.collectionUserRating, docId!);
    return setDoc(userRatingRef, Object.assign({}, userRatingDto), { merge: true });
  }


  listenUserRatings(auctionId: string, lotId: string) {

    logEvent(getAnalytics(), 'listenUserRatings');

    let userRatingRef = collection(this.firestore, this.collectionUserRating);
    let userRatingQuery = query(userRatingRef, where('auctionId', '==', auctionId), where('lotId', '==', lotId));
    this.userRatingsUnsubscribe = onSnapshot(userRatingQuery, querySnapshot => {
      let userRatingDtos = querySnapshot.docs.map(item => item.data());

      logEvent(getAnalytics(), 'listenUserRatings Data Recieved size : ' +  userRatingDtos.length);
        console.log("listenUserRatings Data Recieved size: " + userRatingDtos.length);

      this.bidderService.updateUserRatingDtos(userRatingDtos);
    })
  }

  // Save Auction Questions
  async saveAuctionQuestion(auctionQuestionDto: AuctionQuestionDto) {

    logEvent(getAnalytics(), 'saveAuctionQuestion');

    let docId = auctionQuestionDto.id;

    let auctionQuestionRef = doc(this.firestore, this.collectionAuctionQuestions, docId!);
    return setDoc(auctionQuestionRef, Object.assign({}, auctionQuestionDto), { merge: true });
  }

  listenAuctionQuestions(auctionId: string, lotId: string) {

    logEvent(getAnalytics(), 'listenAuctionQuestions');

    let auctionQuestionRef = collection(this.firestore, this.collectionAuctionQuestions);
    let auctionQuestionQuery = query(auctionQuestionRef, where('auctionId', '==', auctionId), where('lotId', '==', lotId));
    this.auctionQuestionsUnsubscribe = onSnapshot(auctionQuestionQuery, querySnapshot => {
      let auctionQuestions = querySnapshot.docs.map(item => item.data());

      logEvent(getAnalytics(), 'listenAuctionQuestions Data Recieved size : ' +  auctionQuestions.length);
      console.log("listenAuctionQuestions Data Recieved size: " + auctionQuestions.length);

      this.bidderService.updateAuctionQuestions(auctionQuestions);
    })
  }

  // Email Template
  async saveEmailTemplate(emailTemplateDto: EmailTemplateDto) {

    logEvent(getAnalytics(), 'saveEmailTemplate');

    let documentRef = doc(this.firestore, this.collectionEmailTemplate, emailTemplateDto?.documentId!);
    return await setDoc(documentRef, Object.assign({}, emailTemplateDto), { merge: true });

  }

  listenEmailTemplate(auctionHouseId: string) {

    logEvent(getAnalytics(), 'listenEmailTemplate');

    let emailTemplateRef = collection(this.firestore, this.collectionEmailTemplate);
    let emailTemplateQuery = query(emailTemplateRef, where('auctionHouseId', '==', auctionHouseId));

    this.emailTemplateUnsubscribe = onSnapshot(emailTemplateQuery, querySnapshot => {
      let emailTemplates: DocumentData[] = [];

      logEvent(getAnalytics(), 'listenEmailTemplate Data Recieved size : ' +  emailTemplates.length);
      console.log("listenEmailTemplate Data Recieved size: " + emailTemplates.length);

      querySnapshot.docs.map(item => {
        let updatedItem = item.data();
        updatedItem['documentId'] = item.id;
        emailTemplates.push(updatedItem);
      });

      this.superAdminService.updateEmailTemplates(emailTemplates as Array<EmailTemplateDto>);
    })
  }

  listenAuctionQuestionsForAdmin(auctionId: string) {

    logEvent(getAnalytics(), 'listenAuctionQuestionsForAdmin');

    let auctionQuestionRef = collection(this.firestore, this.collectionAuctionQuestions);
    let auctionQuestionQuery = query(auctionQuestionRef, where('auctionId', '==', auctionId));
    this.auctionQuestionsForAdminUnsubscribe = onSnapshot(auctionQuestionQuery, querySnapshot => {
      let auctionQuestions = querySnapshot.docs.map(item => item.data());

      logEvent(getAnalytics(), 'listenAuctionQuestionsForAdmin Data Recieved size : ' +  auctionQuestions.length);
        console.log("listenAuctionQuestionsForAdmin Data Recieved size: " + auctionQuestions.length);

      this.adminService.updateAuctionQuestions(auctionQuestions);
    })
  }

  /**
   * Clear all the snapshots used for firestore
   */
  clearUserFirestoreSnapshotListener() {

    if(this.allLotsOfPostAuctionsUnsubscribe){
      this.allLotsOfPostAuctionsUnsubscribe();
    }

    if(this.maxOfferPriceOnLotUnsubscribe){
      this.maxOfferPriceOnLotUnsubscribe();
    }

    if(this.userOfferPriceOnLotUnsubscribe){
      this.userOfferPriceOnLotUnsubscribe();
    }

    if(this.userLotFavoriteUnsubscribe){
      this.userLotFavoriteUnsubscribe();
    }

    if(this.userAuctionRegistrationUnsubscribe){
      this.userAuctionRegistrationUnsubscribe();
    }

    if(this.myAuctionRegistrationsUnsubscribe){
      this.myAuctionRegistrationsUnsubscribe();
    }

    if(this.allUserAuctionRegistrationsUnsubscribe){
      this.allUserAuctionRegistrationsUnsubscribe();
    }

    if(this.userBidsOfLotUnsubscribe){
      this.userBidsOfLotUnsubscribe();
    }

    if(this.userMaxBidsOfLotUnsubscribe){
      this.userMaxBidsOfLotUnsubscribe();
    }

    if(this.highestDataOfLotUnsubscribe){
      this.highestDataOfLotUnsubscribe();
    }

    if(this.totalBidsOfAuctionUnsubscribe){
      this.totalBidsOfAuctionUnsubscribe();
    }

    if(this.auctionQuestionsUnsubscribe){
      this.auctionQuestionsUnsubscribe();
    }

    if(this.userRatingsUnsubscribe){
      this.userRatingsUnsubscribe();
    }

    if(this.emailTemplateUnsubscribe){
      this.emailTemplateUnsubscribe();
    }

    if(this.auctionQuestionsForAdminUnsubscribe){
      this.auctionQuestionsForAdminUnsubscribe();
    }
  }

  async updateMasterData(updatedMasterData: MasterDataDto, masterDataType: string) {
    let documentName = '';
    let updatedDocument = {}
    if (masterDataType == 'AUCTION_FEATURES') {
      documentName = 'auction_features';
      updatedDocument = { auction_features: updatedMasterData.auctionFeatures };
    } else if (masterDataType == 'CURRENCIES') {
      documentName = 'currencies';
      updatedDocument = { currencies: updatedMasterData.currencies };
    } else if (masterDataType == 'TIMEZONES') {
      documentName = 'timezones';
      updatedDocument = { timezones: updatedMasterData.timezones };
    }

    let documentRef = doc(this.firestore, this.collectionMasterData, documentName);
    await setDoc(documentRef, updatedDocument, { merge: true });
  }

  async updateAllMasterData(initialMasterData: MasterDataDto) {
    let auctionFeaturesRef = doc(this.firestore, this.collectionMasterData, 'auction_features');
    let currenciesRef = doc(this.firestore, this.collectionMasterData, 'currencies');
    let timezonesRef = doc(this.firestore, this.collectionMasterData, 'timezones');

    await setDoc(auctionFeaturesRef, { auction_features : initialMasterData.auctionFeatures }, { merge: true });
    await setDoc(currenciesRef, { currencies: initialMasterData.currencies }, { merge: true });
    await setDoc(timezonesRef, { timezones: initialMasterData.timezones }, { merge: true });
  }

  async updateDeploymentConfig(deploymentConfig: DeploymentConfigurationDto) {
    let documentRef = doc(this.firestore, this.collectionDeploymentConfiguration, 'DEPLOYMENT_CONFIGURATION');
    await setDoc(documentRef, Object.assign({}, deploymentConfig), { merge: true });
  }

  // Add Master Data to Firestore
  _createInitialMasterData() {



    // setDoc(deploymentConfigRef, DEPLOYMENT_CONFIGURATION, {merge: true});
    // setDoc(auctionFeaturesRef, auction_features, { merge: true });
    // setDoc(currenciesRef, currencies, { merge: true });
    // setDoc(timezonesRef, timezones, { merge: true });
    // setDoc(categoriesRef, categories, { merge: true });
  }

  ngOnDestroy(): void {
    this.clearUserFirestoreSnapshotListener();
  }

}
