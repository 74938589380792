import { DeploymentConfigurationDto } from './../models/user/DeploymentConfigurationDto';
import { AdminDataUIWrapperDto } from './../models/user/AdminDataUIWrapperDto';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, firstValueFrom, Observable, tap } from "rxjs";
import { ServerAPIResponseDto } from "../models/ServerAPIResponseDto";
import { AddUserDto } from "../models/user/AddAdminDto";
import { ErrorService } from "./error.service";
import { AuctionEntityDto } from '../models/user/AuctionEntityDto';
import { DocumentData } from '@angular/fire/firestore';
import { OrganizationUiDto } from '../models/user/AuctionHouseDto';
import { AdminAuctionBidderRegistrationDto } from '../models/user/AdminAuctionBidderRegistrationDto ';
import { UserMaxBidsDto } from '../models/user/UserMaxBidsDto';
import { AuctionLotEntityDto } from '../models/user/AuctionLotEntityDto';
import { AuctionEnableWrapperDto } from '../models/AuctionEnableWrapperDto';
import { UserAuctionRegistrationDto } from '../models/user/UserAuctionRegistrationDto';
import { AuctionQuestionDto } from '../models/user/AuctionQuestionDto';
import { UserRegistrationInfoWrapper } from '../models/user/UserRegistrationInfoWrapper';
import { RequestGraphChart } from '../models/RequestGraphChart';
import { InvoiceRequestDto } from '../models/InvoiceRequestDto';
import { MasterDataDto } from '../models/MasterDataDto';
import { AuctionBidsDto } from '../models/user/AuctionBidsDto';
import { LotDetailsWrapperDto } from '../models/LotDetailsWrapperDto';
import { ClientSubscriptionService } from './client-subscription.service';
import { GlobalBidderEntityDto } from '../models/GlobalBidderEntityDto';
import { UserEntityDto } from '../models/UserEntityDto';
import { TimeZoneDto } from '../models/TimeZoneDto';

@Injectable({
  providedIn: 'root'
})
export class AdminDashboardService {

  _adminDashboardData$ = new BehaviorSubject<AdminDataUIWrapperDto | null>(null);
  _adminCurrentViewTabName$ = new BehaviorSubject<string>('AUCTIONS_ADMIN')
  _adminAuctionsList$ = new BehaviorSubject<Array<DocumentData>>([]);
  _currentAuctionHouse$ = new BehaviorSubject<OrganizationUiDto | null>(null);
  _auctionFeatures$ = new BehaviorSubject<any | null>(null);
  _auctionTimezones$ = new BehaviorSubject<any | null>(null);
  _auctionCategories$ = new BehaviorSubject<any | null>(null);

  public _globalBidderLoadDataEvent$ = new BehaviorSubject<boolean>(false);

  _notAwardedAuctionNotification$ = new BehaviorSubject<AuctionEntityDto| null>(null);

  _auctionBidderRegistrationDtos$ = new BehaviorSubject<AdminAuctionBidderRegistrationDto[]>([]);
  _deploymentConfiguration$ = new BehaviorSubject<DeploymentConfigurationDto | null>(null);

  _auctionHouseDataLoaded$ = new BehaviorSubject<boolean>(false);
  // _allAuctionDataLoaded$ = new BehaviorSubject<boolean>(false);
  _allLotsDataLoaded$ = new BehaviorSubject<boolean>(false);
  _masterDataLoaded$ = new BehaviorSubject<boolean>(false);
  _isShowAuctionsListView$ = new BehaviorSubject<boolean>(false);

  _allUserAuctionRegistrationsList$ = new BehaviorSubject<Array<UserAuctionRegistrationDto>>([]);
  _allBiddersList$ = new BehaviorSubject<Array<UserRegistrationInfoWrapper>>([]);
  auctionQuestionsList$ = new BehaviorSubject<AuctionQuestionDto[]>([]);

  _notificationPreset$ = new BehaviorSubject<string>("");
  _globalBidderCount$ = new BehaviorSubject<number>(0);


  get getAuctionHouseDataLoaded$() { return this._auctionHouseDataLoaded$.asObservable(); }
  // get getAllAuctionDataLoaded$() { return this._allAuctionDataLoaded$.asObservable(); }
  get getAllLotsDataLoaded$() { return this._allLotsDataLoaded$.asObservable(); }
  get getMasterDataLoaded$() { return this._masterDataLoaded$.asObservable(); }


  get getDeploymentConfiguration$() { return this._deploymentConfiguration$.asObservable() }
  get getAdminDashboardData$() { return this._adminDashboardData$; };
  get getAdminCurrentView$() { return this._adminCurrentViewTabName$; };
  get getCurrentAuctionHouse$() { return this._currentAuctionHouse$; };
  get getAuctionFeatures$() { return this._auctionFeatures$ };
  get getAuctionTimezones$() { return this._auctionTimezones$ };
  get getAuctionCategories$() { return this._auctionCategories$ };
  get getAuctionBidderRegistrationDtos$() { return this._auctionBidderRegistrationDtos$ };
  get getAllUserAuctionRegistrationsList$() { return this._allUserAuctionRegistrationsList$ };
  get getAllBiddersList$() { return this._allBiddersList$ };
  get getAuctionQuestionsList$() { return this.auctionQuestionsList$; };
  get getNotificationPreset$(){ return this._notificationPreset$; };

  constructor(
    private clientSubscriptionService: ClientSubscriptionService,
    private http: HttpClient,
    private errorService: ErrorService
  ) {

  }

  setAuctionHouseDataLoaded(value: boolean) {
    this._auctionHouseDataLoaded$.next(value);
  }

  setNotificationPreset(notificationPreset: string){
    this._notificationPreset$.next(notificationPreset);
  }

  // setAllAuctionDataLoaded(value: boolean) {
  //   this._allAuctionDataLoaded$.next(value);
  // }

  setAllLotsDataLoaded(value: boolean) {
    this._allLotsDataLoaded$.next(value);
  }

  setGlobalBidderCount(value: number) {
    this._globalBidderCount$.next(value);
  }

  setMasterDataLoaded(value: boolean) {
    this._masterDataLoaded$.next(value);
  }

  updateDeploymentConfiguration(deploymentConfigurationDto: DeploymentConfigurationDto) {
    this._deploymentConfiguration$.next(deploymentConfigurationDto)
  };

  updateCurrentView(newView: string) {
    this._adminCurrentViewTabName$.next(newView);
  }

  updateAuctionsList(auctionsList: Array<DocumentData>) {
    this._adminAuctionsList$.next(auctionsList);
  }

  updateCurrentAuctionHouse(doc: DocumentData) {
    let auctionHouse = doc as OrganizationUiDto
    this._currentAuctionHouse$.next(auctionHouse);
  }

  isShowAuctionsListView(value: boolean) {
    this._isShowAuctionsListView$.next(value);
  }

  updateAllUserAuctionRegistrationsList(userAuctionRegistrations: UserAuctionRegistrationDto[]) {
    this._allUserAuctionRegistrationsList$.next(userAuctionRegistrations);
  }

  updateTimezonesData(timezones: TimeZoneDto[]) {
    this._auctionTimezones$.next(timezones);
  }

  updateCategoriesData(categories: any[]) {
    this._auctionCategories$.next(categories);
  }

  updateMasterData(masterData: MasterDataDto) {
    this._auctionFeatures$.next(masterData.auctionFeatures);
    this._auctionTimezones$.next(masterData.timezones);

    let activeCategories = masterData.categories.filter(item => Boolean(item.active) == true);
    this._auctionCategories$.next(activeCategories);

    // data.forEach(item => {
    //   for (let key in item) {
    //     if (key == 'auction_features') {
    //       this._auctionFeatures$.next(item[key]);
    //     } else if (key == 'currencies') {
    //       this._auctionCurrencies$.next(item[key]);
    //     } else if (key == 'timezones') {
    //       this._auctionTimezones$.next(item[key]);
    //     } else if (key == 'categories') {
    //       let activeCategories = item['categories'].filter((item: { active: boolean; }) => item.active == true);
    //       this._auctionCategories$.next(activeCategories);
    //     }
    //   }
    // })
  }

  // LOAD DATA
  loadAdminDashboardData() {
    this._getAdminDashboardData().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let _adminDashboardData = apiResponseDto.data as AdminDataUIWrapperDto;
          this._adminDashboardData$.next(_adminDashboardData);
        }
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  updateRegistrationUserList(data: AdminAuctionBidderRegistrationDto[]) {
    this._auctionBidderRegistrationDtos$.next(data);
  }

  updateAuctionQuestions(auctionQuestions: AuctionQuestionDto[]) {
    this.auctionQuestionsList$.next(auctionQuestions);
  }

  loadBidderRegistrationList(auctionId: string) {
    this.getAllBidderRegistrationList(auctionId).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let _auctionBidderRegistrationDtos = apiResponseDto.data as AdminAuctionBidderRegistrationDto[];
          this._auctionBidderRegistrationDtos$.next(_auctionBidderRegistrationDtos);
        }
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  loadSuppliersList(auctionHouseId: string) {
    this.fetchSuppliers(auctionHouseId).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let _allBiddersList = apiResponseDto.data as UserRegistrationInfoWrapper[];
          this._allBiddersList$.next(_allBiddersList);
        }
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  async loadAndGetLoadDetailsWrapperDtoSync(auctionId: string, lotId: string) {
    let lotDetailsWrapperDto: LotDetailsWrapperDto | undefined;

    try {
      let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.getLandingLotDetailsWrapperDto(auctionId, lotId));

      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        if (apiResponseDto.data) {
          lotDetailsWrapperDto = apiResponseDto.data as LotDetailsWrapperDto
        }
      }
    } catch (error) {
      console.error(error);
    }
    return lotDetailsWrapperDto;
  }


  getUserBidChartData(userId: string) {
    let params = new HttpParams().set('userId', userId);
    return this.http.get<ServerAPIResponseDto>('userBidChartData', { params }).pipe(
      tap(_ => console.log("Getting  userBidChartData ")),
      catchError(this.errorService.handleError<any>("Error while getting userBidChartData")))
  }

  _getAdminDashboardData() {
    return this.http.get<ServerAPIResponseDto>('adminDashboardData').pipe(
      tap(_ => console.log("Getting  Admin Dashboard Data")),
      catchError(this.errorService.handleError<any>("Error while getting AdminDashboardData")))
  }


  _getNotAwardAuction(auctionHouseId: string) {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId);
    return this.http.get<ServerAPIResponseDto>('notAwardAuction', {params}).pipe(
      tap(_ => console.log("Getting  notAwardAuction Data")),
      catchError(this.errorService.handleError<any>("Error while getting notAwardAuction")))
  }


  addAdmin(addAdminDto: AddUserDto): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('addUser', addAdminDto).pipe(
      tap(_ => console.log("Super Admin Add Successfully : " + addAdminDto.emailId)),
      catchError(this.errorService.handleError("Error while adding super admin : " + addAdminDto.emailId)))
  }

  addGlobalBidder(globalUser: GlobalBidderEntityDto): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('globalBidder', globalUser).pipe(
      tap(_ => console.log("Global Bidder Add Successfully : " + globalUser.primaryEmailId)),
      catchError(this.errorService.handleError("Error while adding Global Bidder : " + globalUser.primaryEmailId)))
  }

  manageGlobalBidderNotification(globalBidderDto: GlobalBidderEntityDto) {
    return this.http.post<ServerAPIResponseDto>('manageGlobalBidderNotification', globalBidderDto).pipe(
      tap(_ => console.log("Getting  manageNotification Data")),
      catchError(this.errorService.handleError<any>("Error while  manageNotification")))
  }

  manageRegisteredBidderNotification(userEntityDto: UserEntityDto) {
    return this.http.post<ServerAPIResponseDto>('manageRegisteredBidderNotification', userEntityDto).pipe(
      tap(_ => console.log("Getting  manageNotification Data")),
      catchError(this.errorService.handleError<any>("Error while  manageNotification")))
  }


  markResourceActiveOrInActive(resourceId: string, active: boolean, actionName: string) {
    let params = new HttpParams().set('resourceId', resourceId).set('active', active).set('actionName', actionName);
    return this.http.post<ServerAPIResponseDto>('markResourceActiveOrInActive', null, { params }).pipe(
      tap(_ => console.log("User active status change" + resourceId)),
      catchError(this.errorService.handleError<any>("Error while changing user active status" + resourceId)))
  }

  saveAuctionDetails(auctionEntityDto: AuctionEntityDto) {

    if (!this.clientSubscriptionService.isAllowToAdminActions()) {
      return this.getSubscriptionExpiredServerResponseDto();
    }

    return this.http.post<ServerAPIResponseDto>('auction', auctionEntityDto).pipe(
      tap(_ => console.log("Saved Auction to DB" + auctionEntityDto.auctionId)),
      catchError(this.errorService.handleError<any>("Error while Saving Auction to DB" + auctionEntityDto.auctionId)))
  }

  private getSubscriptionExpiredServerResponseDto() {
    let response = new BehaviorSubject<ServerAPIResponseDto>(new ServerAPIResponseDto());
    let serverApiResponse = new ServerAPIResponseDto();
    serverApiResponse.code = "COMMON-118";
    serverApiResponse.message = "Subscription Expired.";
    response.next(serverApiResponse);
    return response.asObservable();
  }

  saveInitialAuctionDetails(auctionEntityDto: AuctionEntityDto) {
    if (!this.clientSubscriptionService.isAllowToAuctionCreate()) {
      return this.getSubscriptionExpiredServerResponseDto();
    }

    return this.http.post<ServerAPIResponseDto>('addAuctionsInitial', auctionEntityDto).pipe(
      tap(_ => console.log("Saved Auction to DB" + auctionEntityDto.auctionId)),
      catchError(this.errorService.handleError<any>("Error while Saving Auction to DB" + auctionEntityDto.auctionId)))
  }

  markAuctionActiveOrInActive(auctionEnableWrapper: AuctionEnableWrapperDto) {
    if (!this.clientSubscriptionService.isAllowToAdminActions()) {
      return this.getSubscriptionExpiredServerResponseDto();
    }

    return this.http.post<ServerAPIResponseDto>('markAuctionActiveOrInActive', auctionEnableWrapper).pipe(
      tap(_ => console.log("mark auction Saved Auction to DB" + auctionEnableWrapper?.auctionEntityDto?.auctionId)),
      catchError(this.errorService.handleError<any>("Error while mark auction Saving Auction to DB" + auctionEnableWrapper?.auctionEntityDto?.auctionId)))
  }

  auctionDiscard(auctionId: string) {
    let params = new HttpParams().set('auctionId', auctionId)
    return this.http.get<ServerAPIResponseDto>('discardAuction', { params }).pipe(
      tap(_ => console.log("auction discard auctionId" + auctionId)),
      catchError(this.errorService.handleError<any>("Error while auction discard" + auctionId)))
  }

  auctionLotDiscard(auctionId: string, lotId: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId);
    return this.http.get<ServerAPIResponseDto>('discardAuctionLot', { params }).pipe(
      tap(_ => console.log("auction lot discard auctionId" + auctionId + " lotId" + lotId)),
      catchError(this.errorService.handleError<any>("Error while auction lot discard" + auctionId + " lotId" + lotId)))
  }

  markAuctionLotActiveOrInActive(auctionLotEntityDto: AuctionLotEntityDto) {
    if (!this.clientSubscriptionService.isAllowToAdminActions()) {
      return this.getSubscriptionExpiredServerResponseDto();
    }

    return this.http.post<ServerAPIResponseDto>('markLotActiveOrInActive', auctionLotEntityDto).pipe(
      tap(_ => console.log("mark auction lot Saved to DB" + auctionLotEntityDto.lotId)),
      catchError(this.errorService.handleError<any>("Error while mark auction lot Saving to DB" + auctionLotEntityDto.lotId)))
  }

  markLotsActive(auctionEnableWrapper: AuctionEnableWrapperDto) {
    if (!this.clientSubscriptionService.isAllowToAdminActions()) {
      return this.getSubscriptionExpiredServerResponseDto();
    }

    return this.http.post<ServerAPIResponseDto>('markLotsActive', auctionEnableWrapper).pipe(
      tap(_ => console.log("mark lots active to DB" + auctionEnableWrapper?.auctionEntityDto?.auctionId)),
      catchError(this.errorService.handleError<any>("Error while mark lots active to DB" + auctionEnableWrapper?.auctionEntityDto?.auctionId)))
  }

  updateAuctionHouse(auctionHouse: OrganizationUiDto) {
    return this.http.post<ServerAPIResponseDto>('auctionHouse', auctionHouse).pipe(
      tap(_ => console.log("Saved Auction House to DB" + auctionHouse.name)),
      catchError(this.errorService.handleError<any>("Error while Saving Auction House to DB" + auctionHouse.name)));
  }

  getAllBidderRegistrationList(auctionId: string) {
    let params = new HttpParams().set('auctionId', auctionId)
    // let temp_url = 'registrationList?auctionId=016f3652-9f67-4be9-b632-a8f502681f49afd87';
    return this.http.get<ServerAPIResponseDto>('registrationList', { params }).pipe(
      tap(_ => console.log("Getting bidder registration list" + auctionId)),
      catchError(this.errorService.handleError<any>("Error while getting bidder registration list" + auctionId)));
  }

  changeRegistrationStatus(auctionId: string, userId: string, auctionRegistartionStatus: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('userId', userId).set('registrationStatus', auctionRegistartionStatus);
    return this.http.post<ServerAPIResponseDto>('changeRegistrationStatus', null, { params: params }).pipe(
      tap(_ => console.log(`Change Status : userId => " + ${userId} | status => ${status}`)),
      catchError(this.errorService.handleError<any>("Error while change user status" + userId)));
  }

  fetchBidderDetailsData(userId: string, auctionId: string, auctionHouseId: string, lotId: string, timeZone: string) {
    let params = new HttpParams().set('userId', userId).set('auctionId', auctionId).set('auctionHouseId', auctionHouseId)
      .set('lotId', lotId).set('timeZone', timeZone);
    return this.http.get<ServerAPIResponseDto>('fetchBidderDetailsData', { params }).pipe(
      tap(_ => console.log("Fetch Bidder Data Successfully")),
      catchError(this.errorService.handleError<any>("Error while getting data")))
  }

  doMaxBids(auctionBidsDto: UserMaxBidsDto) {
    return this.http.post<ServerAPIResponseDto>('managed-bucketSize', auctionBidsDto).pipe(
      tap(_ => console.log("Proxy Bids Placed" + auctionBidsDto.bucketSize)),
      catchError(this.errorService.handleError<any>("Error while Proxy Bids Placed" + auctionBidsDto.bucketSize)));
  }

  acceptOffer(auctionId: string, lotId: string, userId: string, offerPrice: number): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId).set('userId', userId).set('offerPrice', offerPrice);
    return this.http.post<ServerAPIResponseDto>('acceptOffer', null, { params }).pipe(
      tap(_ => console.log("Offer Accept Successfully for User : " + userId)),
      catchError(this.errorService.handleError("Error while accepting offer : " + userId)))
  }

  downloadListBidderOfferData(auctionId: string, lotId: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json',
      'headers': headers,
      'params': params
    };
    return this.http.get<any>('downloadBidderOfferData', httpOptions);
  }

  downloadRegistrationData(auctionId: string) {
    let params = new HttpParams().set('auctionId', auctionId);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json',
      'headers': headers,
      'params': params
    };
    return this.http.get<any>('downloadListBidderRegistrationData', httpOptions);
  }

  downloadListBidHistoryData(auctionId: string, lotId: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json',
      'headers': headers,
      'params': params
    };
    return this.http.get<any>('downloadListBidHistoryData', httpOptions);
  }

  downloadUserOfferData(auctionId: string, lotId: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json',
      'headers': headers,
      'params': params
    };
    return this.http.get<any>('downloadListBidHistoryData', httpOptions);
  }

  fetchSuppliers(auctionHouseId: string) {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId);
    return this.http.get<ServerAPIResponseDto>('fetchSuppliers', { params }).pipe(
      tap(_ => console.log("Fetch Supplier Data Successfully")),
      catchError(this.errorService.handleError<any>("Error while getting data")))
  }

  fetchGlobalBidderCount() {
    return this.http.get<ServerAPIResponseDto>('globalBidderCount').pipe(
      tap(_ => console.log("Fetch Global Bidder count Successfully")),
      catchError(this.errorService.handleError<any>("Error while getting Global Bidder Count")))
  }

  userLotAllocate(auctionId: string, lotId: string, userId: string, bidPrice: number): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId).set('userId', userId).set('bidPrice', bidPrice);
    return this.http.post<ServerAPIResponseDto>('userLotAllocate', null, { params }).pipe(
      tap(_ => console.log("Lot Allocate Successfully for User : " + userId)),
      catchError(this.errorService.handleError("Error while allocating lot : " + userId)))
  }

  rejectLotAllocate(auctionId: string, lotId: string, userId: string, bidPrice: number, offer: boolean): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId).set('userId', userId).set('bidPrice', bidPrice).set('offer', offer);
    return this.http.post<ServerAPIResponseDto>('rejectLotAllocation', null, { params }).pipe(
      tap(_ => console.log("Lot reject Allocation Successfully for auctionId : " + auctionId)),
      catchError(this.errorService.handleError("Error while allocating lot : " + auctionId)))
  }

  charData(requestGraphChart: RequestGraphChart): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('charData', requestGraphChart).pipe(
      tap(_ => console.log("Getting charData Successfully for auctionId : " + requestGraphChart.auctionId)),
      catchError(this.errorService.handleError("Error while getting charData : " + requestGraphChart.auctionId)))
  }

  getWinnerBidders(auctionId: string): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('winnerBidders', { params }).pipe(
      tap(_ => console.log("Getting awarded bidders Successfully for auctionId : " + auctionId)),
      catchError(this.errorService.handleError("Error while getting awarded bidders : " + auctionId)))
  }

  getUserActivity(userId: string , searchType: string): Observable<any> {
    let params = new HttpParams().set('userId', userId).set('searchType', searchType);
    return this.http.get<ServerAPIResponseDto>('userActivities', { params }).pipe(
      tap(_ => console.log("Getting User Activity Successfully for userId : " + userId)),
      catchError(this.errorService.handleError("Error while getting user activities : " + userId)))
  }

  getNotAwardedLots(auctionId: string): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('notAwardedLots', { params }).pipe(
      tap(_ => console.log("Getting not awarded lot List  Successfully for auctionId : " + auctionId)),
      catchError(this.errorService.handleError("Error while getting not Awarded Lots : " + auctionId)))
  }

  getWinnerBidder(auctionId: string, userId: string): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId).set('userId',userId);
    return this.http.get<ServerAPIResponseDto>('winnerBidder', { params }).pipe(
      tap(_ => console.log("Getting awarded bidders Successfully for auctionId : " + auctionId)),
      catchError(this.errorService.handleError("Error while getting awarded bidders : " + auctionId)))
  }

  updateInvoicePaymentStatus(auctionId: string, lotIds: Array<string| undefined>, userId: string, status: string ): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId).set('status', status).set('userId',userId).set('lotIds', lotIds.toString());
    return this.http.get<ServerAPIResponseDto>('updateInvoicePaymentStatus', { params }).pipe(
      tap(_ => console.log("update invoice payment status Successfully for auctionId : " + auctionId)),
      catchError(this.errorService.handleError("Error while update invoice payment status : " + auctionId)))
  }

  downloadInvoice(invoiceRequest: InvoiceRequestDto): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json',
      'headers': headers,
    };
    return this.http.post<ServerAPIResponseDto>('downloadInvoice', invoiceRequest, httpOptions).pipe(
      tap(_ => console.log("Downloading Invoice Successfully for userId : " + invoiceRequest.userId)),
      catchError(this.errorService.handleError("Error while downloading invoice : " + invoiceRequest.userId)))
  }

  emailInvoice(invoiceRequest: InvoiceRequestDto): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('sendInvoiceEmail', invoiceRequest).pipe(
      tap(_ => console.log("Downloading Invoice Successfully for userId : " + invoiceRequest.userId)),
      catchError(this.errorService.handleError("Error while downloading invoice : " + invoiceRequest.userId)))
  }

  getGeneratedInvoiceList(auctionHouseId: string, auctionId: string, userId: string,): Observable<any> {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId).set('auctionId', auctionId).set('userId', userId);
    return this.http.get<ServerAPIResponseDto>('invoiceList', { params }).pipe(
      tap(_ => console.log("Getting invoiceList  Successfully for auctionId : " + auctionId)),
      catchError(this.errorService.handleError("Error while getting invoiceList bidders : " + auctionId)))
  }

  getAuctionDashboardData(auctionId: string): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('auctionDashboardData', { params }).pipe(
      tap(_ => console.log("Getting dashboard data successfully for auctionId : " + auctionId)),
      catchError(this.errorService.handleError("Error while getting dashboard data : " + auctionId)));
  }

  getAuctionsData(auctionHouseId: string): Observable<any> {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId);
    return this.http.get<ServerAPIResponseDto>('auctions', { params }).pipe(
      tap(_ => console.log("Getting auctions data successfully for auctionHouseId : " + auctionHouseId)),
      catchError(this.errorService.handleError("Error while getting auctions data : " + auctionHouseId)));
  }

  getLandingLotDetailsWrapperDto(auctionId: string, lotId: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId);
    return this.http.get<ServerAPIResponseDto>('landingLotDetailsWrapperDto', { params: params }).pipe(
      tap(_ => console.log("Get landingLotDetailsWrapperDto")),
      catchError(this.errorService.handleError<any>("Error while getting landingLotDetailsWrapperDto")));
  }

  doBids(auctionBidsDto: AuctionBidsDto) {
    return this.http.post<ServerAPIResponseDto>('doBids', auctionBidsDto).pipe(
      tap(_ => console.log("Bids Placed" + auctionBidsDto.bidPrice)),
      catchError(this.errorService.handleError<any>("Error while Bids Placed" + auctionBidsDto.bidPrice)));
  }

  getAuctionReportWrapper(auctionId: string): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('auctionReportWrapper', { params }).pipe(
      tap(_ => console.log("Getting auctions data successfully for auctionId : " + auctionId)),
      catchError(this.errorService.handleError("Error while getting auctions data : " + auctionId)));
  }

  downloadAuctionReport(auctionId: string) {
    let params = new HttpParams().set('auctionId', auctionId);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json',
      'headers': headers,
      'params': params
    };
    return this.http.get<any>('downloadAuctionReport', httpOptions);
  }
}
