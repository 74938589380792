import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserPrivilege } from '../enums/UserPrivilege';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { AuctionExtUtil } from '../util/AuctionExtUtil';

@Injectable({
  providedIn: 'root'
})
export class DashboardRedirectionService {

  private _updateBreadcrumbs$ = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private userService: UserService,
  ) { }

  get getUpdateBreadcrumbs$() { return this._updateBreadcrumbs$.asObservable() }

  setUpdateBreadcrumbs(value: boolean) {
    this._updateBreadcrumbs$.next(value);
  }

  async storeNavigationContext(contextName: string, subContextName: string, pageName: string, tabName?: string, resourceNo?: string, subResourceNo?: string) {
    //clear old session cookies
    this.clearSessionCookies()

    sessionStorage.setItem("CONTEXT_NAME", contextName);
    sessionStorage.setItem("SUB_CONTEXT_NAME", subContextName);
    sessionStorage.setItem("PAGE_NAME", pageName);

    if(tabName){
      sessionStorage.setItem("TAB_NAME", tabName);
    }
    if(resourceNo){
      sessionStorage.setItem("RESOURCE_NO", resourceNo);
    }
    if (subResourceNo) {
      sessionStorage.setItem("SUB_RESOURCE_NO", subResourceNo);
    }
  }

  navigationToHomePage() {
    if (this.userService.getUserEntity() && this.userService.getUserEntity().userId) {
      let userPrivilege = this.userService.getUserEntity().userPrivileges;
      if (AuctionExtUtil.checkIfRoleIsAssigned(userPrivilege!, UserPrivilege.BIDDER)) {
        // If User Is Bidder then Navigate to Bidder Dashboard
        this.router.navigate(['Bidder/Dashboard'], { skipLocationChange: true })
      } else {
        this.router.navigate(['landing'], { skipLocationChange: true })
      }
    } else {
      this.router.navigate(['landing'], { skipLocationChange: true });
    }
  }

  clearSessionCookies(){
    sessionStorage.removeItem("CONTEXT_NAME");
    sessionStorage.removeItem("SUB_CONTEXT_NAME");
    sessionStorage.removeItem("PAGE_NAME");
    sessionStorage.removeItem("TAB_NAME");
    sessionStorage.removeItem("RESOURCE_NO");
    sessionStorage.removeItem("SUB_RESOURCE_NO");
    sessionStorage.removeItem("AUC_SESSION_ID");

  }
}
