import { LoaderComponent } from './../../loader/loader.component';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { BehaviorSubject, interval, takeUntil, takeWhile } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from './../../../services/authentication.service';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Pattern } from 'src/app/shared/util/Patterns';
import { PasswordStrengthValidator } from 'src/app/shared/validators/password-strength.validators';
import { UserRegistrationComponent } from '../user-registration/user-registration.component';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.sass']
})
export class UserLoginComponent implements AfterViewInit, OnInit {
  @Output() onChangeViewPage = new EventEmitter<string>();

  @ViewChild('appLoader', { static: false })
  appLoader?: LoaderComponent;

  _isSaveButtonDisable$ = new BehaviorSubject<boolean>(false);
  _isPasswordFocused$ = new BehaviorSubject<boolean>(false);

  currentModal?: string;
  otpId?: string;
  isPasswordVisible: boolean = false;
  isShowOtpVerify: boolean = false;

  @ViewChild('password') password?: ElementRef<HTMLInputElement>

  noOfResendCount: number = 0;
  countdown: number = 0;
  disableResendButton: boolean = false;
  isCountdownRunning: boolean = false


  formGroupLogin = new FormGroup({
    emailId: new FormControl('', [Validators.required, Validators.pattern(Pattern.email)]),
    password: new FormControl('', [Validators.required, Validators.minLength(9), PasswordStrengthValidator])
  });

  formGroupOtp = new FormGroup({
    emailId: new FormControl('', [Validators.required, Validators.pattern(Pattern.email)]),
    userOtp: new FormControl('', [Validators.required, Validators.pattern(Pattern.onlyNumber)])
  });

  constructor(
    public authService: AuthenticationService,
    public ngbModal: NgbModal,
    public userService: UserService,
  ) { }

  ngOnInit() {
    this.authService.errorMsg = "";
    if (this.formGroupLogin) {
      this.formGroupLogin.get('emailId')?.valueChanges.subscribe(val => {
        this.authService.errorMsg = "";
      });
      this.formGroupLogin.get('password')?.valueChanges.subscribe(val => {
        this.authService.errorMsg = "";
      });
    }
  }


  ngAfterViewInit(): void {
    this.password!.nativeElement.onfocus = (e) => {
      this._isPasswordFocused$.next(true);
    }
    this.password!.nativeElement.onblur = (e) => {
      this._isPasswordFocused$.next(false);
    }
  }

  get fcLogin() { return this.formGroupLogin.controls; }

  get fcOtp() { return this.formGroupOtp.controls; }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  navigateToRegister() {
    this.onChangeViewPage.emit('REGISTER');
  }

  showOtpVerifyScreen() {
    this.noOfResendCount = 0;
    this.disableResendButton = false;

    if (this.formGroupLogin.controls.emailId.invalid) {
      this.formGroupLogin.controls.emailId.markAsTouched();
      return;
    }

    let emailId = this.formGroupLogin.controls.emailId.value;

    this.formGroupLogin.reset();
    this.formGroupOtp.reset();
    this.isShowOtpVerify = true;

    this.formGroupOtp.controls.emailId.patchValue(emailId);
    this.formGroupOtp.controls.emailId.updateValueAndValidity();

    this.generateOTP();
  }

  hideOtpVerifyScreen() {
    let emailId = this.formGroupOtp.controls.emailId.value;

    this.formGroupLogin.reset();
    this.formGroupOtp.reset();
    this.isShowOtpVerify = false;

    this.formGroupLogin.controls.emailId.patchValue(emailId);
    this.formGroupLogin.controls.emailId.updateValueAndValidity();
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  resendOtp(): void {
    if (!this.disableResendButton) {
      if (this.noOfResendCount >= 2) {
        this.disableResendButton = true;
      }

      this.resendOTP();
    }
  }


  resendOTP() {
    if (this.formGroupOtp.controls.emailId.invalid) {
      this.formGroupOtp.controls.emailId.markAsTouched()
      return;
    }

    this.authService.errorMsg = "";
    let email = this.formGroupOtp.controls['emailId'].value as string;

    this.startCountdown();
    this.noOfResendCount++
    this.userService.resendOTP(email, "", this.otpId as string).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.otpId = apiResponseDto.data as string;
        } else {
          this.authService.errorMsg = apiResponseDto.message!;
        }
      },
      error: (err) => {
        this.authService.errorMsg = "Error While OTP Generate";
      }
    })
  }



  startCountdown(): void {
    this.isCountdownRunning = true;
    this.countdown = 30;
    interval(1000).pipe(
      takeWhile(() => this.countdown > 0 ),
    ).subscribe(() => {
      this.countdown--;
      if (this.countdown === 0) {
        this.isCountdownRunning = false;
      }
    });
  }

  openModal(content: any) {
    this.closeModal()
    this.ngbModal.open(content, {
      size: 'sm', backdrop: 'static', keyboard: false , centered: true
    });
  }

  openForgotPasswordModal(content: any) {
    this.closeModal()
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }


  loginWithPassword() {
    let formValue = this.formGroupLogin.value;

    if (this.formGroupLogin.invalid) {
      this.formGroupLogin.markAllAsTouched()
      return;
    }

    this._isSaveButtonDisable$.next(true);
    this.appLoader?.openLoaderIcon(AuctionExtConstant.USER_REGISTRATION_PROGRESS, "Please wait logging in...");
    let emailId = formValue.emailId;
    let password = formValue.password?.trim();
    this.authService.doAuthentication(emailId!, password!, this.appLoader!, this._isSaveButtonDisable$);
  }

  loginWithOtp() {
    let formValue = this.formGroupOtp.value;

    if (this.formGroupOtp.invalid) {
      this.formGroupOtp.markAllAsTouched()
      return;
    }

    this._isSaveButtonDisable$.next(true);
    this.appLoader?.openLoaderIcon(AuctionExtConstant.USER_REGISTRATION_PROGRESS, "Please wait logging in...");
    let emailId = formValue.emailId;
    let userOtp = formValue.userOtp;
    this.authService.doAuthentication(emailId!, userOtp!, this.appLoader!, this._isSaveButtonDisable$, this.otpId);
  }

  generateOTP() {
    if (this.formGroupOtp.controls.emailId.invalid) {
      this.formGroupOtp.controls.emailId.markAsTouched()
      return;
    }

    this.authService.errorMsg = "";
    let email = this.formGroupOtp.controls['emailId'].value as string;

    this.startCountdown();
    this.noOfResendCount++
    this.userService.generateOTP(email, email, 'LOGIN').subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.otpId = apiResponseDto.data as string;
        } else {
          this.authService.errorMsg = apiResponseDto.message!;
        }
      },
      error: (err) => {
        this.authService.errorMsg = "Error While OTP Generate";
      }
    })
  }


}
