import { QuestionResponseType } from "../../enums/questionnaire/QuestionResponseType";
import { SourcingEnvelopeType } from "../../enums/questionnaire/SourcingEnvelopeType";
import { UserQuestionStatus } from "../../enums/questionnaire/UserQuestionStatus";
import { FileInfoDto } from "../FileInfoDto";

export class UserAuctionQuestionsDto {
  id?: string;
  questionId?: string;
  sectionId?: string;
  questionnaireId?: string;

  auctionHouseId?: string;
  auctionId?: string;
  envelopeType?: SourcingEnvelopeType;

  name?: string;
  firstName?: string;
  lastName?: string;
  userId?: string;
  emailId?: string;
  paddleNo?: string;

  responseType?: QuestionResponseType;
  bidderResponse: any;
  bidderDocuments?: FileInfoDto[];
  bidderComments?: string;
  status?: UserQuestionStatus;
  registrationTimeInternal?: string;
  registrationTimeStr?: string;

  updateTimeUtc?: string;
  updateTimeUtcMillis?: number;
}