import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError, BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { ErrorService } from './error.service';
import { QuestionnaireTemplate } from '../models/questionnaire/QuestionnaireTemplate';
import { SectionTemplate } from '../models/questionnaire/SectionTemplate';
import { QuestionnaireWrapperDto } from '../models/questionnaire/QuestionnaireWrapperDto';
import { AuctionExtConstant } from '../util/AuctionExtConstant';
import { SourcingEnvelopeType } from '../enums/questionnaire/SourcingEnvelopeType';
import { QuestionnaireValidationDto } from '../models/questionnaire/QuestionnaireValidationDto';
import { TechnicalQuestionTemplate } from '../models/questionnaire/TechnicalQuestionTemplate';
import { ResourceSwapWrapperDto } from '../models/questionnaire/ResourceSwapWrapperDto';
import { UserAuctionQuestionsDto } from '../models/questionnaire/UserAuctionQuestionsDto';
import { UserRfxQuestionsUiDto } from '../models/questionnaire/UserRfxQuestionsDto';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {
  private _questionnaireWrapperDto?: QuestionnaireWrapperDto;
  private _questionnaireWrapperDto$ = new BehaviorSubject<QuestionnaireWrapperDto | undefined>(undefined);
  private _questionnaireValidationList$ = new BehaviorSubject<QuestionnaireValidationDto[]>([]);

  private _selectedSection$ = new BehaviorSubject<SectionTemplate | undefined>(undefined);
  private _selectedTechQuestion$ = new BehaviorSubject<TechnicalQuestionTemplate | undefined>(undefined);
  private _userAuctionQuestions$ = new BehaviorSubject<UserAuctionQuestionsDto[]>([]);
  private _userRfxQuestions$ = new BehaviorSubject<UserRfxQuestionsUiDto[]>([]);

  get getQuestionnaireWrapper() { return this._questionnaireWrapperDto; }
  get getQuestionnaireWrapper$() { return this._questionnaireWrapperDto$.asObservable(); }
  get getQuestionnaireValidationList$() { return this._questionnaireValidationList$.asObservable(); }

  get getSelectedSection$() { return this._selectedSection$.asObservable(); }
  get getSelectedTechQuestion$() { return this._selectedTechQuestion$.asObservable(); }
  get getUserAuctionQuestions$() { return this._userAuctionQuestions$.asObservable(); }
  get getUserRfxQuestions$() { return this._userRfxQuestions$.asObservable(); }
  constructor(
    private http: HttpClient,
    private errorService: ErrorService
  ) { }

  getRemainingScoreOfSection(sectionId: string) {
    let sectionTemplate = (this._questionnaireWrapperDto?.sectionTemplateList ?? []).find(item => item.sectionId == sectionId);
    let totalQuestionScore = this.getTotalScoreOfQuestions(sectionId);
    return Number(sectionTemplate?.sectionScore) - Number(totalQuestionScore);
  }

  getTotalScoreOfSections(envelopeType: SourcingEnvelopeType): number {
    let sectionTemplateList = (this._questionnaireWrapperDto?.sectionTemplateList ?? []).filter(item => item.envelopeType == envelopeType);
    let sectionCalculatedScore = sectionTemplateList.reduce((prev, cur) => Number(prev) + Number(cur.sectionScore ?? 0), 0)
    return sectionCalculatedScore ?? 0;
  }

  checkSectionScoreValidation(envelopeType: SourcingEnvelopeType) {
    if (this._questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate && this._questionnaireWrapperDto.sectionTemplateList && this._questionnaireWrapperDto.sectionTemplateList?.length > 0) {
      let sectionTemplateList = (this._questionnaireWrapperDto.sectionTemplateList ?? []).filter(item => item.envelopeType == envelopeType);

      let maximumScore = this._questionnaireWrapperDto?.questionnaireTemplate?.maximumScore;
      let sectionCalculatedScore = sectionTemplateList.reduce((prev, cur) => Number(prev) + Number(cur.sectionScore ?? 0), 0)

      return sectionCalculatedScore != Number(maximumScore);
    } else {
      return false;
    }
  }

  checkQuestionScoreValidation(sectionId: string) {
    if (this._questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate && this._questionnaireWrapperDto.technicalQuestionTemplates && this._questionnaireWrapperDto.technicalQuestionTemplates?.length > 0) {
      let technicalQuestions = this._questionnaireWrapperDto?.technicalQuestionTemplates?.filter(item => item.sectionId == sectionId) ?? [];
      let calculatedScore = technicalQuestions.reduce((prev, cur) => Number(prev) + Number(cur.score ?? 0), 0);
      let sectionTemplate = this._selectedSection$.value;

      return Number(sectionTemplate?.sectionScore) != calculatedScore;
    } else {
      return false;
    }
  }

  getTotalScoreOfQuestions(sectionId: string) {
    let technicalQuestions = this._questionnaireWrapperDto?.technicalQuestionTemplates?.filter(item => item.sectionId == sectionId) ?? [];
    let calculatedScore = technicalQuestions.reduce((prev, cur) => Number(prev) + Number(cur.score ?? 0), 0);
    return calculatedScore;
  }

  async loadUserAuctionQuestions(auctionId: string, userId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getUserAuctionQuestions(auctionId, userId));

      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        let userAuctionQuestionsDtos = apiResponseDto.data as UserAuctionQuestionsDto[];
        this.updateUserAuctionQuestions(userAuctionQuestionsDtos);
      } else {
        console.error("Error while calling loadUserAuctionQuestions : " + JSON.stringify(apiResponseDto));
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadUserRfxQuestions(rfxId: string, subcategoryId: string, companyId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getUserRfxQuestions(rfxId, subcategoryId, companyId));

      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        let userRfxQuestionsDtos = apiResponseDto.data as UserRfxQuestionsUiDto[];
        this.updateUserRfxQuestions(userRfxQuestionsDtos);
      } else {
        console.error("Error while calling loadUserRfxQuestions : " + JSON.stringify(apiResponseDto));
      }
    } catch (error) {
      console.error(error);
    }
  }

  updateUserAuctionQuestions(userAuctionQuestionsDtos: UserAuctionQuestionsDto[]) {
    this._userAuctionQuestions$.next(userAuctionQuestionsDtos);
  }

  updateUserRfxQuestions(userRfxQuestionsDtos: UserRfxQuestionsUiDto[]) {
    this._userRfxQuestions$.next(userRfxQuestionsDtos);
  }

  updateQuestionnaireWrapperDto(newQuestionnaireWrapperDto?: QuestionnaireWrapperDto) {
    this._questionnaireWrapperDto = newQuestionnaireWrapperDto;
    this._questionnaireWrapperDto$.next(newQuestionnaireWrapperDto);
  }

  setSelectedSection(sectionTemplate: SectionTemplate) {
    this._selectedSection$.next(sectionTemplate);
  }

  setSelectedTechQuestion(questionTemplate: TechnicalQuestionTemplate) {
    this._selectedTechQuestion$.next(questionTemplate);
  }

  runTechQuestionnaireValidation() {
    this._questionnaireValidationList$.next([]);
    let _questionnaireValidationList: QuestionnaireValidationDto[] = [];

    if (this._questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate) {
      let isScoreMismatch = this.checkSectionScoreValidation(SourcingEnvelopeType.TECHNICAL);

      if (isScoreMismatch) {
        let totalScoreOfSections = this.getTotalScoreOfSections(SourcingEnvelopeType.TECHNICAL);
        let maximumScore = this._questionnaireWrapperDto?.questionnaireTemplate?.maximumScore;

        let questionnaireValidationDto = new QuestionnaireValidationDto();
        questionnaireValidationDto.message = `The sum of section scores (${totalScoreOfSections}) does not match the questionnaire total score (${maximumScore})`;

        _questionnaireValidationList.push(questionnaireValidationDto);
      }

      let selectedSectionTemplate = this._selectedSection$.value;
      if (selectedSectionTemplate && (selectedSectionTemplate.questionIds ?? []).length > 0) {
        let totalScoreOfQuestions = this.getTotalScoreOfQuestions(selectedSectionTemplate.sectionId!);

        if (totalScoreOfQuestions != selectedSectionTemplate.sectionScore) {
          let questionnaireValidationDto = new QuestionnaireValidationDto();
          questionnaireValidationDto.message = `The sum of questions scores (${totalScoreOfQuestions}) does not match the section score (${selectedSectionTemplate.sectionScore})`;

          _questionnaireValidationList.push(questionnaireValidationDto);
        }
      }
    }

    this._questionnaireValidationList$.next(_questionnaireValidationList);
  }

  haveQuestionnaireValidationIssues(): boolean {
    if (this._questionnaireWrapperDto) {
      if (this._questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate) {
        let sectionTemplateList = this._questionnaireWrapperDto.sectionTemplateList;

        if (!sectionTemplateList || sectionTemplateList.length == 0) {
          return true;
        }

        let isScoreMismatch = this.checkSectionScoreValidation(SourcingEnvelopeType.TECHNICAL);
        if (isScoreMismatch) {
          return true;
        }

        for (let i = 0; i < sectionTemplateList.length; i++) {
          const sectionTemplate = sectionTemplateList[i];

          if (!sectionTemplate.questionIds || sectionTemplate.questionIds.length == 0) {
            return true;
          }

          let totalScoreOfQuestions = this.getTotalScoreOfQuestions(sectionTemplate.sectionId!);
          if (totalScoreOfQuestions != Number(sectionTemplate.sectionScore)) {
            return true
          }
        }
        return false;
      } else {
        let sectionTemplateList = this._questionnaireWrapperDto.sectionTemplateList;

        if (!sectionTemplateList || sectionTemplateList.length == 0) {
          return true;
        }

        for (let i = 0; i < sectionTemplateList.length; i++) {
          const sectionTemplate = sectionTemplateList[i];
          if (!sectionTemplate.questionIds || sectionTemplate.questionIds.length == 0) {
            return true;
          }
        }
        return false;
      }
    } else {
      return false;
    }

  }

  async loadQuestionnaireWrapperDto(templateId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getQuestionnairesWrapper(templateId));

      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        let questionnaireWrapperDto = apiResponseDto.data as QuestionnaireWrapperDto;
        this.updateQuestionnaireWrapperDto(questionnaireWrapperDto);
      }
    } catch (error) {
      console.error(error);
    }
  }

  saveQuestionnaires(auctionId: string, questionnaireTemplate: QuestionnaireTemplate) {
    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.post<ServerAPIResponseDto>('questionnaires', questionnaireTemplate, { params }).pipe(
      tap(_ => console.log("Saved questionnaireTemplate to DB" + questionnaireTemplate.templateName)),
      catchError(this.errorService.handleError<any>("Error while Saving Questionnaire to DB: " + questionnaireTemplate.templateName)))
  }

  saveSectionTemplate(sectionTemplate: SectionTemplate) {
    return this.http.post<ServerAPIResponseDto>('questionnaires-sections', sectionTemplate).pipe(
      tap(_ => console.log("Saved questionnaireTemplate to DB" + sectionTemplate.sectionName)),
      catchError(this.errorService.handleError<any>("Error while Saving Questionnaire to DB: " + sectionTemplate.sectionName)))
  }

  saveTechQuestionTemplate(technicalQuestionTemplate: TechnicalQuestionTemplate) {
    return this.http.post<ServerAPIResponseDto>('questionnaires-technicalQuestions', technicalQuestionTemplate).pipe(
      tap(_ => console.log("Saved saveTechQuestionTemplate to DB" + technicalQuestionTemplate.questionText)),
      catchError(this.errorService.handleError<any>("Error while Saving saveTechQuestionTemplate to DB: " + technicalQuestionTemplate.questionText)))
  }

  getQuestionnairesWrapper(templateId: string) {
    let params = new HttpParams().set('templateId', templateId);
    return this.http.get<ServerAPIResponseDto>('questionnaires', { params }).pipe(
      tap(_ => console.log("Getting questionnaires ")),
      catchError(this.errorService.handleError<any>("Error while getting questionnaires")))
  }

  deleteQuestionnaire(questionnaireId: string, auctionId: string) {
    let params = new HttpParams().set('questionnaireId', questionnaireId).set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('deleteQuestionnaires', { params }).pipe(
      tap(_ => console.log("Deleting questionnaire ")),
      catchError(this.errorService.handleError<any>("Error while getting questionnaire")))
  }

  deleteSection(sectionId: string) {
    let params = new HttpParams().set('sectionId', sectionId);
    return this.http.get<ServerAPIResponseDto>('deleteSections', { params }).pipe(
      tap(_ => console.log("Deleting sections ")),
      catchError(this.errorService.handleError<any>("Error while deleting sections")))
  }

  deleteQuestion(questionId: string) {
    let params = new HttpParams().set('questionId', questionId);
    return this.http.get<ServerAPIResponseDto>('deleteQuestions', { params }).pipe(
      tap(_ => console.log("Deleting questions ")),
      catchError(this.errorService.handleError<any>("Error while getting questions")))
  }

  updateSectionOrdering(resourceSwapWrapperDto: ResourceSwapWrapperDto) {
    return this.http.post<ServerAPIResponseDto>('reorderSections', resourceSwapWrapperDto).pipe(
      tap(_ => console.log("Saved reorderSections to DB" + resourceSwapWrapperDto.sourcingEnvelopeType)),
      catchError(this.errorService.handleError<any>("Error while Saving reorderSections to DB: " + resourceSwapWrapperDto.sourcingEnvelopeType)))
  }

  updateQuestionOrdering(resourceSwapWrapperDto: ResourceSwapWrapperDto) {
    return this.http.post<ServerAPIResponseDto>('reorderQuestions', resourceSwapWrapperDto).pipe(
      tap(_ => console.log("Saved reorderQuestions to DB" + resourceSwapWrapperDto.sourcingEnvelopeType)),
      catchError(this.errorService.handleError<any>("Error while Saving reorderQuestions to DB: " + resourceSwapWrapperDto.sourcingEnvelopeType)))
  }

  markQuestionnairePublish(questionnaireId: string) {
    let params = new HttpParams().set('questionnaireId', questionnaireId);
    return this.http.post<ServerAPIResponseDto>('markQuestionnairePublish', null, { params }).pipe(
      tap(_ => console.log("Saved markQuestionnairePublish to DB" + questionnaireId)),
      catchError(this.errorService.handleError<any>("Error while Saving markQuestionnairePublish to DB: " + questionnaireId)))
  }

  userAuctionQuestions(userAuctionQuestionsDto: UserAuctionQuestionsDto) {
    return this.http.post<ServerAPIResponseDto>('userAuctionQuestions', userAuctionQuestionsDto).pipe(
      tap(_ => console.log("Saved userAuctionQuestions to DB" + userAuctionQuestionsDto.questionId)),
      catchError(this.errorService.handleError<any>("Error while Saving userAuctionQuestions to DB: " + userAuctionQuestionsDto.questionId)))
  }

  getUserAuctionQuestions(auctionId: string, userId: string) {
    let params = new HttpParams().set('userId', userId).set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('userAuctionQuestions', { params }).pipe(
      tap(_ => console.log("Saved userAuctionQuestions to DB" + auctionId)),
      catchError(this.errorService.handleError<any>("Error while Saving userAuctionQuestions to DB: " + auctionId)))
  }

  userRfxQuestions(userRfxQuestionsDto: UserRfxQuestionsUiDto) {
    return this.http.post<ServerAPIResponseDto>('userRfxQuestions', userRfxQuestionsDto).pipe(
      tap(_ => console.log("Saved userRfxQuestions to DB" + userRfxQuestionsDto.questionId)),
      catchError(this.errorService.handleError<any>("Error while Saving userRfxQuestions to DB: " + userRfxQuestionsDto.questionId)))
  }

  getUserRfxQuestions(rfxId: string, subcategoryId: string, companyId: string) {
    let params = new HttpParams().set('companyId', companyId).set('rfxId', rfxId).set('subcategoryId', subcategoryId);
    return this.http.get<ServerAPIResponseDto>('userRfxQuestions', { params }).pipe(
      tap(_ => console.log("Saved userRfxQuestions to DB" + subcategoryId)),
      catchError(this.errorService.handleError<any>("Error while Saving userRfxQuestions to DB: " + subcategoryId)))
  }

  uploadBulkFinancialQuestionsExcel(formData: FormData): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>('bulkQuestionsResponse', formData).pipe(
      tap(_ => console.log("Saved bulkQuestionsResponse excel")),
      catchError(this.errorService.handleError<any>("Error while uploading bulkQuestionsResponse excel: ")))
  }
}
