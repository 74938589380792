<div class="container p-3">
  <div class="col-12 border-bold p-2">
    <div class="row align-items-center">
      <div class="col-5">
        <span class="me-3">
          <a class="c-pointer" [ngClass]="{'disabled' : isDisabledPrev()}" (click)="prevQuestion()">Previous</a>
        </span>
        <span class="me-3">Q {{selectedTechnicalQuestion.sequenceNo}} to {{technicalQuestionsList.length}}</span>
        <span class="me-3">
          <a class="c-pointer" [ngClass]="{'disabled' : isDisabledNext()}" (click)="nextQuestion()">Next</a>
        </span>
      </div>
      <div class="col-6 text-end">
        <span class="badge-gray-class badge me-3">Incomplete-{{countIncompleteQuestions}}</span>
        <span class="badge-blue-class badge">Complete-{{countCompleteQuestions}}</span>

      </div>
      <div class="col-1 text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
    <div class="mt-3 col-11">

      <!-- <div class="input-group">
        <div class="form-floating">
          <input class="form-control mt-1" [formControl]="ctrlQuestion" id="ctrlQuestion">
          <label for="ctrlQuestion" class="font-weight-normal mb-2 text-dark">QUESTION CRITERIA
            <span class="ms-3 badge badge-white-class mt-1"
              *ngIf="selectedTechnicalQuestion.responseMandatory">Mandatory</span>
          </label>
        </div>
      </div> -->
      <div class="border p-2">
        <div>
          <span class="font-weight-normal mb-2 text-dark">QUESTION CRITERIA
            <span class="ms-3 badge badge-gray-class-small-1 mt-1"
              *ngIf="selectedTechnicalQuestion.responseMandatory">Mandatory</span>
          </span>
        </div>
        {{this.selectedTechnicalQuestion.preSequenceText}}.{{this.selectedTechnicalQuestion.sequenceNo}} {{this.selectedTechnicalQuestion.questionText}}
      </div>
      <div class="row mt-3">
        <!-- SINGLE_CHOICE -->
        <div class="div-class mt-2"
          *ngIf="selectedTechnicalQuestion.responseType == questionResponseType.SINGLE_CHOICE">Single Choice Response
        </div>
        <div class="div-class mt-2" *ngIf="selectedTechnicalQuestion.responseType == questionResponseType.MULTI_CHOICE">
          Multi Choice Response</div>
        <div class="col-md-4" *ngIf="selectedTechnicalQuestion.responseType == questionResponseType.SINGLE_CHOICE">
          <div class="border p-3 mt-4 c-pointer" [ngClass]="{'bidder-selected' : isOptionSelected(item)}"
            (click)="selectBidderResponse(item)"
            *ngFor="let item of selectedTechnicalQuestion.questionOptions; index as i">
            <span>
              {{item.optionPreSequence}}.{{item.optionSequence}} {{item.optionText}}
            </span>
          </div>
        </div>

        <!-- MULTI_CHOICE -->
        <div class="col-md-4" *ngIf="selectedTechnicalQuestion.responseType == questionResponseType.MULTI_CHOICE">
          <div class="border p-3 mt-4 c-pointer" [ngClass]="{'bidder-selected' : isOptionSelected(item)}"
            (click)="selectBidderResponse(item)"
            *ngFor="let item of selectedTechnicalQuestion.questionOptions; index as i">
            <span>
              {{item.optionPreSequence}}.{{item.optionSequence}} {{item.optionText}}
            </span>
          </div>
        </div>

        <!-- DATE -->
        <div class="col-md-4 mb-3" *ngIf="selectedTechnicalQuestion.responseType == questionResponseType.DATE">
          <div class="form-floating mb-3">
            <input ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" type="text" class="form-control"
              [formControl]="ctrlDateStart" id="ctrlDateStart">
            <label for="ctrlDateStart">Date</label>
            <div *ngIf="ctrlDateStart.invalid && (ctrlDateStart.dirty || ctrlDateStart.touched)">
              <p class="text-danger" *ngIf="ctrlDateStart.errors?.['required']">Required</p>
            </div>
          </div>
        </div>

        <!-- DATE_RANGE -->
        <div class="col-md-4 mb-3" *ngIf="selectedTechnicalQuestion.responseType == questionResponseType.DATE_RANGE">
          <div class="form-floating mb-3">
            <input ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" type="text" class="form-control"
              [formControl]="ctrlDateStart" id="ctrlDateStart1">
            <label for="ctrlDateStart1">Start Date</label>
            <div *ngIf="ctrlDateStart.invalid && (ctrlDateStart.dirty || ctrlDateStart.touched)">
              <p class="text-danger" *ngIf="ctrlDateStart.errors?.['required']">Required</p>
            </div>
          </div>
          <div class="form-floating mb-3">
            <input ngbDatepicker #d2="ngbDatepicker" (click)="d2.toggle()" type="text" class="form-control"
              [formControl]="ctrlDateEnd" id="ctrlDateEnd">
            <label for="ctrlDateEnd">End Date</label>
            <div *ngIf="ctrlDateEnd.invalid && (ctrlDateEnd.dirty || ctrlDateEnd.touched)">
              <p class="text-danger" *ngIf="ctrlDateEnd.errors?.['required']">Required</p>
            </div>
          </div>
        </div>

        <!-- ALPHANUMERIC -->
        <div class="col-md-4 mb-3" *ngIf="selectedTechnicalQuestion.responseType == questionResponseType.ALPHANUMERIC">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" [formControl]="ctrlAlphaNumeric" id="ctrlAlphaNumeric">
            <label for="ctrlAlphaNumeric">Response</label>
            <div *ngIf="ctrlAlphaNumeric.invalid && (ctrlAlphaNumeric.dirty || ctrlAlphaNumeric.touched)">
              <p class="text-danger" *ngIf="ctrlAlphaNumeric.errors?.['required']">Required</p>
            </div>
          </div>
        </div>

        <div class="col-md-8 my-3">
          <div class="row" *ngIf="!isQuestionSubmitted">
            <div class="col-1"></div>
            <div class="col-5 pe-0">
              <div class="col-12 mb-3">
                <label for="file-upload" class="custom-file-upload">
                  <div>
                    <img src="assets/icons/cloud-upload.svg" class="svg-icon-class-md c-pointer me-2">
                  </div>
                  Drag or drop here or browse file to upload
                </label>
                <input id="file-upload" type="file" onclick="this.value=null;" (change)="chooseFile($event)" />
              </div>
            </div>
            <div class="col-1 px-0 text-center mt-4">
              or
            </div>
            <div class="col-5 ps-0">
              <div class="col-12 mb-3">
                <label class="custom-file-upload" (click)="openDocumentModal(documentArchives)">
                  <div>
                    <img src="assets/icons/archive.svg" class="svg-icon-class-md c-pointer me-2">
                  </div>
                  Upload from your document archive
                </label>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="row medium-grey-font ps-5" *ngFor="let document of selectedDocuments; index as i">
              <div class="col-6">
                <img src="assets/icons/file_danger.svg" class="svg-icon-class c-pointer">
                {{document.displayName}}
              </div>
              <div class="col-2 text-end">
                {{formatBytes(document.fileSize)}}
              </div>
              <div class="col-3 text-end">
                <span>Expiry - </span>
                <span *ngIf="!getExpiryDate(document.fileId!)">NA</span>
                <span *ngIf="getExpiryDate(document.fileId!)">
                  <span>{{getExpiryDate(document.fileId!)}}</span>
                </span>
              </div>
              <div class="col-1 text-end" *ngIf="!isQuestionSubmitted">
                <img src="assets/icons/delete_dark.svg" class="svg-icon-class-small c-pointer"
                  (click)="removeSelectedDocument(document.fileId!)">
              </div>
            </div>

            <div class="mb-3" *ngIf="isUploadLoading">
              <div class="e-spinner"></div>
            </div>
          </div>
        </div>
        <div class=" my-3" *ngIf="!isQuestionSubmitted">
          <a class="link-class" (click)="clearOptionSelection()">
            Clear Selection
          </a>
        </div>
        <div class="mb-3">
          <label for="ctrlBidderComments">Comments</label>
          <div class="input-group">
            <div class="form-floating">
              <textarea class=" form-control" [formControl]="ctrlBidderComments" id="ctrlBidderComments" rows="3"></textarea>
            </div>
          </div>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async"
            [message]="'Question saved successfully.'"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg"></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading"></div>
        </div>

        <div class="col-12 text-center" *ngIf="!(_showSuccessToast$ | async) && !isLoading && !isQuestionSubmitted">
          <button class="btn btn-sm btn-blue-clr-class" (click)="saveQuestion()"
            [disabled]="!isValidBidderResponse()">Save Draft</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #documentArchives>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>DOCUMENTS</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(documentModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="over-flow-scroll">
          <div class="text-center" *ngIf="documentArchiveList.length == 0">
            <h6>No Documents Available!</h6>
          </div>
          <div class="card bg-white p-2 mb-3"
            [ngClass]="{'border border-primary' : isDocumentSelected(documentArchive.fileId!)}"
            *ngFor="let documentArchive of documentArchiveList index as i;"
            (click)="selectDocumentArchive(documentArchive)">
            <h6>{{documentArchive.documentDisplayName}}</h6>
            <div class="col-12 mb-3">
              <div class="row medium-grey-font">
                <div class="col-6 px-2">
                  <img src="assets/icons/file_light.svg" class="svg-icon-class c-pointer">
                  {{documentArchive.documentName}}
                </div>
                <div class="col-2 px-2 text-end">
                  {{formatBytes(documentArchive.size)}}
                </div>
                <div class="col-4 px-2 text-end">
                  <span> Expiry - </span>
                  <span *ngIf="!documentArchive.expiryDate">NA</span>
                  <span *ngIf="documentArchive.expiryDate"> <span>{{documentArchive.expiryDate}}</span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>