<div class="col-9 m-auto border bg-white mb-3 hover-class" *ngIf="(renderView$ | async)">
  <div class="row  p-3">
    <div class="col-md-5 p-0 c-pointer position-relative" >
      <img src="/downloadLandingBlob?fileId={{userLotFavouriteWrapperDto?.auctionLotEntityDto?.lotImages![0].fileId}}"
        [alt]="userLotFavouriteWrapperDto?.auctionLotEntityDto?.title" class="image-who-we-are" (click)="navigateLotDetails()">
      <span class="follow-icon-position">
        <button class="follow-btn" [ngClass]="isLoading? 'disabled': '' " ngbTooltip="Unollow"
          (click)="discardLotFavourite()">
          <img src="assets/icons/favorite_filled_light.svg" class="svg-icon-class-md c-pointer">

          <span class="spinner-border spinner-border-sm ms-2 loader-spinner" role="status" aria-hidden="true"
            *ngIf="isLoading"></span>
        </button>

      </span>
      <!-- <div class="col-md-6 text-md-end mb-1">
          <button class="btn btn-new-class btn-sm  mb-2"  (click)="discardLotFavourite()">
          <img src="assets/icons/favorite_filled_dark.svg" class="svg-icon-small c-pointer me-1">
          <span class="d-md-inline-block d-none">UNFOLLOW</span>
          <span class="spinner-border spinner-border-sm ms-2 loader-spinner" role="status" aria-hidden="true" *ngIf="isLoading"></span>
        </button>

       </div> -->
    </div>
    <div class="col-md-7 ">
      <div class="row justify-content-between align-items-center mb-2">
        <div class="col-12">
          <h5 class="heading-h5"> {{auctionEntityDto?.sequenceNo}} # {{auctionEntityDto?.auctionName}}
            {{userLotFavouriteWrapperDto?.auctionLotEntityDto?.lotSequence}} #
            {{userLotFavouriteWrapperDto?.auctionLotEntityDto?.title}}</h5>
        </div>
        <div class="col-auto">
          <span class="badge multilot-badge"
            [ngClass]="{'bg-success': bidStatus == 'WIN', 'bg-danger': bidStatus == 'LOSE', 'bg-info': bidStatus == 'NEUTRAL'}">
            {{title}}
          </span>
        </div>
      </div>
      <div class=" row align-items-center'">
        <div class="col-5 col-md-4">
          <b>Address:</b>
        </div>
        <div class="div-class mb-1 col-7 col-md-8 overflow-class-multilot ps-1">
          {{getAddressFromAction(userLotFavouriteWrapperDto?.auctionLotEntityDto?.address!)}}
        </div>
      </div>
      <div class="col-12">
        <div class="mt-2 row">
          <div class="col-5 col-md-4"><b>Ends in:</b> </div>
          <div class="col-7 col-md-8 text-start ps-1 ">

            <div *ngIf="remainingDurations < 0">
              <span class="badge gredient-timer p-0 ">
                <span class=" badge rounded-0 ">
                  <span class="span-class text-danger">
                    <b>
                      Bidding Closed
                    </b>
                  </span>
                </span>
              </span>
            </div>
            <div *ngIf="remainingDurations > 0 && countdownTimerDays == 0">
              <span class="badge gredient-timer p-0 ">
                <span class=" badge rounded-0 border-end me-1">
                  <span class="span-class text-danger">
                    <b>
                      {{('0' + countdownTimerHours).slice(-2)}}
                    </b>
                  </span>
                  <br>
                  <small class="text-danger">
                    Hrs
                  </small>
                </span>
                <span class=" badge rounded-0 border-end me-1">
                  <span class="span-class text-danger">
                    <b>
                      {{('0' + countdownTimerMinutes).slice(-2)}}
                    </b>
                  </span>
                  <br>
                  <small class="text-danger">
                    Min
                  </small>
                </span>
                <span class=" badge rounded-0 me-1">
                  <span class="span-class text-danger">
                    <b>
                      {{('0' + countdownTimerSeconds).slice(-2)}}
                    </b>
                  </span>
                  <br>
                  <small class="text-danger">
                    Sec
                  </small>
                </span>
              </span>
            </div>
            <div *ngIf="remainingDurations > 0 && countdownTimerDays > 0">
              <span class="badge gredient-timer p-0">
                <span class=" badge rounded-0 border-end me-1">
                  <span class="span-class text-danger">
                    <b>
                      {{countdownTimerDays}}
                    </b>
                  </span>
                  <br>
                  <small class="text-danger">
                    Days
                  </small>
                </span>
                <span class=" badge rounded-0 me-1">
                  <span class="span-class text-danger">
                    <b>
                      {{countdownTimerHours}}
                    </b>
                  </span>
                  <br>
                  <small class="text-danger">
                    Hrs
                  </small>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="">
        <span class="span-class me-2">Ending By: </span>
        <span class="span-class" id="headline{{counterFunction}}{{userLotFavouriteWrapperDto?.auctionLotEntityDto?.lotId}}"></span>
        <span class="span-class" id="countdown{{counterFunction}}{{userLotFavouriteWrapperDto?.auctionLotEntityDto?.lotId}}">
          <span class="span-class text-danger" id="days{{counterFunction}}{{userLotFavouriteWrapperDto?.auctionLotEntityDto?.lotId}}"></span>
          <span class="span-class text-danger" id="hours{{counterFunction}}{{userLotFavouriteWrapperDto?.auctionLotEntityDto?.lotId}}"></span>
          <span class="span-class text-danger" id="minutes{{counterFunction}}{{userLotFavouriteWrapperDto?.auctionLotEntityDto?.lotId}}"></span>
          <span class="span-class text-danger" id="second{{counterFunction}}{{userLotFavouriteWrapperDto?.auctionLotEntityDto?.lotId}}"></span>
        </span>
      </div> -->
      <div class="row mt-2">
        <div class="col-5 col-md-4">
          <b>Number of Bids:</b>
        </div>
        <div class="div-class mb-1 col-7 col-md-8 overflow-class-multilot ps-1">
          {{bidsHighestData?.totalBidPlaced ? bidsHighestData?.totalBidPlaced : 0}}
        </div>
        <div class="col-5 col-md-4">
          <b>Current Bid Amount:</b>
        </div>
        <div class="div-class mb-1 col-7 col-md-8 overflow-class-multilot ps-1">
          {{auctionEntityDto?.currency?.symbol}}{{bidsHighestData?.highestBidPrice? bidsHighestData?.highestBidPrice :
          userLotFavouriteWrapperDto?.auctionLotEntityDto?.startingBid}}
        </div>
      </div>
      <!-- <div class="mt-2">
        <div class="col-12">
          <span *ngFor="let item of userLotFavouriteWrapperDto?.auctionLotEntityDto?.lotFeatures;">
            <button class="btn-new-landing">{{item?.featureKey}}</button>
            <button class="btn-new-landing-2 me-2 ">{{item?.featureValue}}</button>
          </span>
        </div>
      </div> -->
      <div class="col-12 text-end">
        <!-- <span class="btn-link c-pointer me-2">SHOW MORE</span>
        <button class="btn btn-new-class btn-sm me-2 btn-width-small" (click)="navigateLotDetails()"> BID</button> -->
        <button class="btn btn-sm btn-new-class px-3" (click)="navigateLotDetails()">
          <fa-icon [icon]="['fas', 'angles-right']" class=" icon-class-live-md"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</div>
