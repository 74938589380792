import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap, timeout } from 'rxjs/operators';
import { ErrorService } from './error.service';

/**
 * Service to load json file based on language.
 * Default mapped to en-us
 */

@Injectable({
  providedIn: 'root'
})
export class TranslateService  {

  translateObject: any = {};

  constructor(private http: HttpClient, private errorService: ErrorService) {
  }

  use(lang: string = 'en') {
    sessionStorage.setItem("language", lang);

    const langPath = `/assets/locales/${lang}.json`;
    return this.http.get<any>(langPath).pipe(
      tap(_ => console.log("got data for locals" + lang)),
      catchError(this.errorService.handleError<any>('Error in loading locals for ' + lang))
    ).subscribe(data => {
        this.translateObject =  data;
        if(!data){
          alert("File not correct");
        }
    });
}

  /**
   * Get the translated message for a given key
   */
  transform(key: any): any {
    return this.translateObject[key] || key;
  }

  getLanguage(){
    const langPath = `/assets/json/language.json`;
    return this.http.get<any>(langPath).pipe(
      tap(_ => console.log("Language Loading Successfully")),
      catchError(this.errorService.handleError<any>("Error while Loading Language"))
    )
  }
}
