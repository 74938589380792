<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>NEW AUCTION REGISTRATION</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-class">
    <div class="m-3">
      <table class="table table-bordered mb-0">
        <thead class="table-secondary">
          <tr>
            <th scope="col" ngbAutoFocus>#</th>
            <th scope="col">Auction No.</th>
            <th scope="col">Auction Name</th>
            <th scope="col">Pending Registrations</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of auctionIdsList; index as i">
            <td>{{i + 1}}</td>
            <td>{{getAuction(item).sequenceNo}}</td>
            <td>{{getAuction(item).auctionName}}</td>
            <td>{{getRegistrationCount(item)}}</td>
            <td>
              <button class="btn  btn-new-class" (click)="navigateToRegistration(item)">VIEW</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>