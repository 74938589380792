<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>Verify OTP</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
    <div class="model-body-class">
      <div class="m-3">
        <div class="text-start">
          <div class="mb-2">
            OTP not received ? <a>Resend</a>
          </div>
          <div class="mb-2">
            Verify One Time Password (OTP)
          </div>
        </div>
          <form class="needs-validation" novalidate>
            <div class="form-floating mb-3">
              <input type="email" class="form-control" id="otp" placeholder="OTP">
              <label for="otp">OTP</label>
            </div>
            <div class="d-grid gap-2 mb-3">
              <button type="button" class="btn btn-new-class">VERIFY</button>
            </div>
          </form>
    
          <div>
            <p><a href="">Verify Later</a></p>
            <p>You will not be able to participate in bidding unless verified</p>
          </div>
    
        
      </div>
    </div>

</div>