import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HtmlViewComponent } from 'src/app/shared/components/html-view/html-view.component';
import { QuestionResponseType } from 'src/app/shared/enums/questionnaire/QuestionResponseType';
import { SourcingEnvelopeType } from 'src/app/shared/enums/questionnaire/SourcingEnvelopeType';
import { UserQuestionStatus } from 'src/app/shared/enums/questionnaire/UserQuestionStatus';
import { PrivacyAndTermsOfUseDto } from 'src/app/shared/models/PrivacyAndTermsOfUseDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { QuestionnaireTemplate } from 'src/app/shared/models/questionnaire/QuestionnaireTemplate';
import { QuestionnaireWrapperDto } from 'src/app/shared/models/questionnaire/QuestionnaireWrapperDto';
import { SectionTemplate } from 'src/app/shared/models/questionnaire/SectionTemplate';
import { TechnicalQuestionTemplate } from 'src/app/shared/models/questionnaire/TechnicalQuestionTemplate';
import { UserRfxQuestionsUiDto } from 'src/app/shared/models/questionnaire/UserRfxQuestionsDto';
import { RfxSubcategoryEntityDto } from 'src/app/shared/models/rfx/RfxSubcategoryEntityDto ';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxEntityDto';
import { FinancialQuestionTemplate } from 'src/app/shared/models/questionnaire/FinancialQuestionTemplate';
import { RfxQuestionnaireTechnicalQuestionComponent } from '../rfx-questionnaire-technical-question/rfx-questionnaire-technical-question.component';
import { UserRfxSubcategoryUiDto } from 'src/app/shared/models/questionnaire/UserRfxSubcategoryDto';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';
import { RfxQuestionnaireFinancialQuestionComponent } from '../rfx-questionnaire-financial-question/rfx-questionnaire-financial-question.component';
import { UserRfxSubcategoryStatus } from 'src/app/shared/models/rfx/UserRfxSubcategoryStatus';
import { MenuItem } from 'primeng/api';
import { ExcelExportService } from 'src/app/shared/services/excel-export.service';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { BulkQuestionBidderResponseWrapperDto } from 'src/app/shared/models/questionnaire/BulkQuestionBidderResponseWrapperDto';

@Component({
  selector: 'app-rfx-questionnaire',
  templateUrl: './rfx-questionnaire.component.html',
  styleUrls: ['./rfx-questionnaire.component.sass']
})
export class RfxQuestionnaireComponent implements OnInit, OnDestroy {
  @Input() selectedSubcategory?: RfxSubcategoryEntityDto;
  @Input() isReadOnly: boolean = false;

  bulkExcelFormControl: FormControl = new FormControl(null, Validators.required);

  stepItems: MenuItem[] = [];

  activeStepIndex: number = 0;

  questionnaireWrapperDto?: QuestionnaireWrapperDto;
  questionnaireTemplate?: QuestionnaireTemplate;
  sectionTemplateList: SectionTemplate[] = []
  technicalQuestionsList: TechnicalQuestionTemplate[] = []
  financialQuestionsList: FinancialQuestionTemplate[] = []
  userRfxQuestions: UserRfxQuestionsUiDto[] = [];
  guidelinesList: string[] = []
  sourcingEnvelopeType: typeof SourcingEnvelopeType = SourcingEnvelopeType;

  selectedSectionTemplate?: SectionTemplate;

  totalIncompleteQuestions: number = 0;
  totalCompleteQuestions: number = 0;
  isSectionCompleted: boolean = false;
  isTechnicalCompleted: boolean = false;
  isFinancialCompleted: boolean = false;
  totalBidAmount: number = 0;

  isShowFinancialTab?: boolean = false;
  isShowTechnicalTab?: boolean = false;

  isDataLoading: boolean = false;
  isDownloading: boolean = false;
  isLoading: boolean = false;
  isEnableTermsConditions: boolean = false;
  questionModalRef?: NgbModalRef;
  errorMsg: string | undefined;
  // activeQuestionTab: SourcingEnvelopeType = SourcingEnvelopeType.TECHNICAL;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  questionnaireWrapperDtoSubscription$?: Subscription;
  userRfxQuestionsSubscription$?: Subscription;

  selectedRfxDto?: RfxUiDto | null;

  constructor(
    private ngbModal: NgbModal,
    private landingAndBidderService: LandingAndBidderService,
    private questionnaireService: QuestionnaireService,
    private userService: UserService,
    private excelExportService: ExcelExportService
  ) { }

  ngOnInit(): void {
    this.selectedRfxDto = this.landingAndBidderService._selectedRfxDto$.value;
    this.loadQuestionnaire();

    this.questionnaireWrapperDtoSubscription$ = this.questionnaireService.getQuestionnaireWrapper$.subscribe(data => {
      if (data) {
        this.questionnaireWrapperDto = data;
        this.questionnaireTemplate = data.questionnaireTemplate;

        // Sort Sections List
        let technicalSections = (this.questionnaireWrapperDto?.sectionTemplateList ?? []).filter(item => item.envelopeType == SourcingEnvelopeType.TECHNICAL);
        let financialSections = (this.questionnaireWrapperDto?.sectionTemplateList ?? []).filter(item => item.envelopeType == SourcingEnvelopeType.FINANCIAL);

        let sortedTechnicalSections = this.sortSectionTemplates(technicalSections);
        let sortedFinancialSections = this.sortSectionTemplates(financialSections);
        this.sectionTemplateList = sortedTechnicalSections.concat(sortedFinancialSections);

        this.isShowFinancialTab = this.questionnaireWrapperDto?.financialQuestionTemplates && this.questionnaireWrapperDto?.financialQuestionTemplates.length > 0;
        this.isShowTechnicalTab = this.questionnaireWrapperDto?.technicalQuestionTemplates && this.questionnaireWrapperDto?.technicalQuestionTemplates.length > 0;

        if (!this.isShowTechnicalTab && this.isShowFinancialTab) {
          this.toggleQuestionTab(this.sourcingEnvelopeType.FINANCIAL);
        }

        if (!this.selectedSectionTemplate) {
          this.selectedSectionTemplate = this.sectionTemplateList[0];
        }

        if (this.questionnaireTemplate?.guideLines) {
          this.guidelinesList = this.questionnaireTemplate?.guideLines?.split('\n');
        }

        this.technicalQuestionsList = this.questionnaireWrapperDto?.technicalQuestionTemplates?.filter(item => item.sectionId == this.selectedSectionTemplate?.sectionId) ?? [];
        this.technicalQuestionsList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));

        this.financialQuestionsList = this.questionnaireWrapperDto?.financialQuestionTemplates?.filter(item => item.sectionId == this.selectedSectionTemplate?.sectionId) ?? [];
        this.financialQuestionsList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));

        this.initializeSteps()
        this.checkQuestionsCompletion();
      } else {
        this.questionnaireWrapperDto = undefined;
        this.questionnaireTemplate = undefined;
        this.sectionTemplateList = []
        this.technicalQuestionsList = []
        this.financialQuestionsList = []
      }
    })

    this.userRfxQuestionsSubscription$ = this.questionnaireService.getUserRfxQuestions$.subscribe(data => {
      if (data) {
        this.userRfxQuestions = data;
        this.checkQuestionsCompletion();
      } else {
        this.userRfxQuestions = []
      }
    })
  }

  async loadQuestionnaire() {
    let userEntityDto = this.userService.getUserEntity();
    if (this.selectedSubcategory?.questionnaireId) {
      this.isDataLoading = true;
      await this.questionnaireService.loadQuestionnaireWrapperDto(this.selectedSubcategory.questionnaireId!);
      await this.questionnaireService.loadUserRfxQuestions(this.selectedSubcategory.rfxId!, this.selectedSubcategory.subcategoryId!, userEntityDto.companyId!);
      this.isDataLoading = false;

      this.checkQuestionsCompletion();
    }
  }

  onActiveIndexChange(event: any) {
    this.activeStepIndex = event;
    this.changeSection(this.sectionTemplateList[event]);
  }

  initializeSteps() {
    this.stepItems = this.sectionTemplateList.map(item => {
      return { label: `${item.preSequenceText}.${item.sequenceNo} ${item.sectionName}` }
    })
  }

  toggleQuestionTab(currentTab: SourcingEnvelopeType) {
    // this.sectionTemplateList = this.questionnaireWrapperDto?.sectionTemplateList?.filter(item => item.envelopeType == this.activeQuestionTab) ?? [];
    // this.sectionTemplateList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));
    this.selectedSectionTemplate = this.sectionTemplateList[0];

    this.technicalQuestionsList = this.questionnaireWrapperDto?.technicalQuestionTemplates?.filter(item => item.sectionId == this.selectedSectionTemplate!.sectionId) ?? []
    this.technicalQuestionsList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));

    this.financialQuestionsList = this.questionnaireWrapperDto?.financialQuestionTemplates?.filter(item => item.sectionId == this.selectedSectionTemplate!.sectionId) ?? []
    this.financialQuestionsList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));

    this.checkQuestionsCompletion();
  }

  checkQuestionsCompletion() {
    this.totalCompleteQuestions = this.userRfxQuestions.filter(item => item.status == UserQuestionStatus.COMPLETED
      && item.sectionId == this.selectedSectionTemplate!.sectionId).length;

    let mandatoryQuestionsTech = this.questionnaireWrapperDto?.technicalQuestionTemplates?.filter(item => item.responseMandatory).map(item => item.questionId) ?? [];
    let mandatoryQuestionsFin = this.questionnaireWrapperDto?.financialQuestionTemplates?.map(item => item.questionId) ?? [];
    let mandatoryQuestions = mandatoryQuestionsTech.concat(mandatoryQuestionsFin);

    if (this.selectedSectionTemplate?.envelopeType == SourcingEnvelopeType.TECHNICAL) {
      this.totalIncompleteQuestions = this.technicalQuestionsList.length - this.totalCompleteQuestions;

      let mandatoryQuestions = this.technicalQuestionsList.filter(item => item.responseMandatory).map(item => item.questionId) ?? [];
      this.isSectionCompleted = mandatoryQuestions.every(id => this.userRfxQuestions.some(item => item.questionId === id))
    } else {
      this.totalIncompleteQuestions = this.financialQuestionsList.length - this.totalCompleteQuestions;

      let mandatoryQuestions = this.financialQuestionsList.map(item => item.questionId) ?? [];
      this.isSectionCompleted = mandatoryQuestions.every(id => this.userRfxQuestions.some(item => item.questionId === id))

      this.totalBidAmount = this.userRfxQuestions.reduce((prev, curr) => prev + Number(curr.unitPriceQtyTaxAdditionalPricesTotal), 0);
    }

    this.isTechnicalCompleted = mandatoryQuestionsTech.every(id => this.userRfxQuestions.some(item => item.questionId === id));
    this.isFinancialCompleted = mandatoryQuestionsFin.every(id => this.userRfxQuestions.some(item => item.questionId === id));

    this.isEnableTermsConditions = mandatoryQuestions.every(id => this.userRfxQuestions.some(item => item.questionId === id));
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  changeSection(sectionTemplate: SectionTemplate) {
    this.selectedSectionTemplate = sectionTemplate;
    this.technicalQuestionsList = this.questionnaireWrapperDto?.technicalQuestionTemplates?.filter(item => item.sectionId == this.selectedSectionTemplate!.sectionId) ?? []
    this.technicalQuestionsList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));

    this.financialQuestionsList = this.questionnaireWrapperDto?.financialQuestionTemplates?.filter(item => item.sectionId == this.selectedSectionTemplate!.sectionId) ?? []
    this.financialQuestionsList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));

    this.checkQuestionsCompletion();
  }

  openTechnicalQuestionModal(technicalQuestion: TechnicalQuestionTemplate) {
    this.questionModalRef = this.ngbModal.open(RfxQuestionnaireTechnicalQuestionComponent, {
      size: 'xl', centered: true,
      backdrop: 'static', keyboard: false,
    })
    this.questionModalRef.componentInstance.selectedTechnicalQuestion = technicalQuestion;

    // Handle data emitted from modal
    this.questionModalRef.componentInstance.openNewSectionEvent.subscribe((data: boolean) => {
      if (data) {
        this.openSectionQuestion();
      }
    });
  }

  private openSectionQuestion() {
    if ((this.stepItems.length - 1) > this.activeStepIndex) {
      this.activeStepIndex++;
      this.changeSection(this.sectionTemplateList[this.activeStepIndex]);
      let envelopeType = this.selectedSectionTemplate?.envelopeType;
      if (envelopeType == SourcingEnvelopeType.FINANCIAL) {
        this.openFinancialQuestionModal(this.financialQuestionsList[0]);
      } else {
        this.openTechnicalQuestionModal(this.technicalQuestionsList[0]);
      }
    }
  }

  openFinancialQuestionModal(financialQuestion: FinancialQuestionTemplate) {
    this.questionModalRef = this.ngbModal.open(RfxQuestionnaireFinancialQuestionComponent, {
      size: 'xl', centered: true,
      backdrop: 'static', keyboard: false,
    })

    this.questionModalRef.componentInstance.selectedFinancialQuestion = financialQuestion;

      // Handle data emitted from modal
      this.questionModalRef.componentInstance.openNewSectionEvent.subscribe((data: boolean) => {
        if(data){
          this.openSectionQuestion();
        }
      });
  }






  getTitleResponseType(responseType: QuestionResponseType) {
    if (responseType == QuestionResponseType.SINGLE_CHOICE) {
      return "Single Choice Response";
    } else if (responseType == QuestionResponseType.MULTI_CHOICE) {
      return "Multi Choice Response";
    } else if (responseType == QuestionResponseType.DATE) {
      return "Date Response";
    } else if (responseType == QuestionResponseType.DATE_RANGE) {
      return "Date Range Response";
    } else if (responseType == QuestionResponseType.ALPHANUMERIC) {
      return "Alphanumeric Response";
    }
    return "Select Response Type";
  }

  getQuestionStatus(questionId: string) {
    let currentQuestion = this.userRfxQuestions.find(item => item.questionId == questionId);
    if (currentQuestion) {
      return currentQuestion.status == UserQuestionStatus.COMPLETED ? 'Complete' : 'Incomplete';
    }
    return 'Incomplete';
  }

  getQuestionResponse(questionId: string) {
    let currentQuestion = this.userRfxQuestions.find(item => item.questionId == questionId);
    return currentQuestion?.bidderResponse ? `${this.selectedRfxDto?.currency?.symbol}${currentQuestion?.bidderResponse}` : '';
  }

  openHtmlViewModal(type: string) {
    if (type == 'TERMS') {
      let modalRef = this.ngbModal.open(HtmlViewComponent, { size: 'md', backdrop: 'static', keyboard: false, centered: true });
      modalRef.componentInstance.title = 'Terms & Conditions';
    } else {
      let modalRef = this.ngbModal.open(HtmlViewComponent, { size: 'md', backdrop: 'static', keyboard: false, centered: true });
      modalRef.componentInstance.title = 'Privacy Policy';
    }
  }

  openViewGuidelinesModal(content: any) {
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static',
      keyboard: false, centered: true
    });
  }

  openUploadQuestionsExcelModal(content: any) {
    this.bulkExcelFormControl.reset();
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);

    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false,
      centered: true,
    })
  }

  saveUserRfxSubcategory() {
    this._showErrorToast$.next(false);
    this.errorMsg = "";

    if (!this.isEnableTermsConditions) {
      return;
    }

    let userRfxSubcategory = new UserRfxSubcategoryUiDto();
    userRfxSubcategory.orgCode = this.userService.getSessionInfo?.orgCode;
    userRfxSubcategory.rfxId = this.selectedRfxDto?.rfxId;
    userRfxSubcategory.orgCode = this.selectedSubcategory?.orgCode;
    userRfxSubcategory.subcategoryId = this.selectedSubcategory?.subcategoryId;
    userRfxSubcategory.categorySequence = this.selectedSubcategory?.subcategorySequence;
    userRfxSubcategory.companyId = this.userService.getUserEntity().companyId;
    userRfxSubcategory.emailId = this.userService.getUserEntity().primaryEmailId;
    userRfxSubcategory.companyName = this.userService.getUserEntity().companyName;
    userRfxSubcategory.status = UserRfxSubcategoryStatus.SUBMITTED;

    this.isLoading = true;
    this.landingAndBidderService.saveUserRfxSubcategory(userRfxSubcategory).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let userRfxSubcategoryDto = apiResponseDto.data as UserRfxSubcategoryUiDto;
          this.landingAndBidderService.setUserRfxSubcategoryDto(userRfxSubcategoryDto);
          this.isReadOnly = userRfxSubcategoryDto?.status == UserRfxSubcategoryStatus.SUBMITTED;

          this._showSuccessToast$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();

          }, 2000)

        } else {
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.errorMsg = "Error while saving event registration. Try again.";
        this._showErrorToast$.next(true);
        this.isLoading = false;
      }
    })
  }

  // Bulk
  async downloadBulkQuestionsExcel(content: any) {
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isDownloading = false;

    this.generateBulkQuestionsExcel();

    this.ngbModal.open(content, {
      size: 'sm', backdrop: 'static', keyboard: false, centered: true
    });
  }

  generateBulkQuestionsExcel() {
    this.isDownloading = true;
    const aoaData = this.prepareExcelData();

    setTimeout(() => {
      const excelFileName = 'FinancialQuestions';
      this.excelExportService.exportAsExcelFileByAoA(aoaData, excelFileName);
      this.isDownloading = false;
      this.closeModal();
    }, 5000);
  }

  prepareExcelData(): any[][] {
    const aoaData: any[][] = [];
  
    // Create header row
    const tableHeader = ['Question', 'Item Name', 'Quantity', 'UoM', 'Tax', 'Bid Amount'];
    const styledHeader = tableHeader.map(header => ({
      v: header,
      s: { font: { bold: true } }
    }));
    aoaData.push(styledHeader);
  
    // Create data rows
    this.financialQuestionsList.forEach(question => {
      const row = [];
      row.push({ v: `${question.preSequenceText}.${question.sequenceNo}` });
      row.push({ v: question.questionText });
      row.push({ v: question.quantity || '' });
      row.push({ v: question.uom || '' });
      row.push({ v: question.tax || '' });
      row.push({ v: '' }); // Empty cell for Bid Amount
  
      (question.additionalPrices ?? []).forEach(additionalPrice => {
        row.push({
          v: additionalPrice ? `Enter ${additionalPrice.label}` : '',
          s: {
            font: { sz: 8 }
          }
        });
      });
  
      aoaData.push(row);
    });
  
    return aoaData;
  }
  

  onBulkExcelChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.bulkExcelFormControl.patchValue(file)
    } else {
      this.bulkExcelFormControl.patchValue(null)
    }
    this.bulkExcelFormControl.updateValueAndValidity();
  }

  uploadBulkQuestionsExcel() {
    if (this.bulkExcelFormControl.invalid) {
      this.bulkExcelFormControl.markAllAsTouched();
      return;
    }

    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = true;

    let currentFile = this.bulkExcelFormControl.value;

    let metaData = {
      'mimeType': currentFile.type,
      'version': 0,
      'publicApi': true,
      'dataType': 'Square'
    };

    let questionnaireId = this.selectedSectionTemplate?.questionnaireId!;
    let sectionId = this.selectedSectionTemplate?.sectionId!;

    let formData = new FormData();
    formData.append("file", currentFile);
    formData.append("questionnaireId", questionnaireId);
    formData.append("sectionId", sectionId);
    formData.append('metaData', JSON.stringify(metaData));

    this.questionnaireService.uploadBulkFinancialQuestionsExcel(formData).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.isLoading = false;

          let bulkQuestionBidderResponseWrapperDto = apiResponseDto.data as BulkQuestionBidderResponseWrapperDto;

          let errorCodeDtos = bulkQuestionBidderResponseWrapperDto.errorCodeDtos ?? [];
          if (errorCodeDtos.length > 0) {
            let errorModalRef = this.ngbModal.open(ErrorsListModalComponent, {
              size: 'md', backdrop: 'static', keyboard: false , centered: true
            });
            errorModalRef.componentInstance.auctionValidationErrorCodeDtoList = errorCodeDtos;
            return;
          }

          this._showSuccessToast$.next(true);

          let userRfxQuestionsEntityDtos = bulkQuestionBidderResponseWrapperDto.userRfxQuestionsEntityDtos as UserRfxQuestionsUiDto[];
          this.questionnaireService.updateUserRfxQuestions(userRfxQuestionsEntityDtos);

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)

        } else {
          this.isLoading = false;
          this._showErrorToast$.next(true);
          this.errorMsg = apiResponseDto?.message;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this.errorMsg = "Error while saving question. Try again.";
      }
    })
  }

  sortSectionTemplates(data: SectionTemplate[]): SectionTemplate[] {
    return data.sort((a, b) => {
      // Split the strings at the dot (.) to separate letter and number parts
      const [letterA, numberA] = `${a.preSequenceText}.${a.sequenceNo}`.split('.');
      const [letterB, numberB] = `${b.preSequenceText}.${b.sequenceNo}`.split('.');

      // Sort by letter (ascending)
      if (letterA < letterB) return -1;
      if (letterA > letterB) return 1;

      // If letters are the same, sort by number (ascending)
      return Number(numberA) - Number(numberB);
    });
  }

  ngOnDestroy(): void {
    if (this.questionnaireWrapperDtoSubscription$) {
      this.questionnaireWrapperDtoSubscription$.unsubscribe();
    }
    if (this.userRfxQuestionsSubscription$) {
      this.userRfxQuestionsSubscription$.unsubscribe()
    }

    this.selectedSectionTemplate = undefined;
    this.questionnaireWrapperDto = undefined;
    this.questionnaireTemplate = undefined;
    this.sectionTemplateList = []
    this.technicalQuestionsList = []
    this.questionnaireService.updateQuestionnaireWrapperDto(undefined);
  }

}
