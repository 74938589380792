import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-financial-section',
  templateUrl: './financial-section.component.html',
  styleUrls: ['./financial-section.component.sass']
})
export class FinancialSectionComponent {

  isTechnicalActive = true;
  isQuestionnaireAvailable = true;
  isSelectOption = true;
   
  constructor(
    private ngbModal: NgbModal,
    private formBuilder: FormBuilder,
  ){
  }

  openFinEditModal(content: any) {
    this.ngbModal.open(content, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }
  closeModal() {
      this.ngbModal.dismissAll();
    }
  
}
