import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminDashboardService } from '../../services/admin-dashboard.service';
import { UserAuctionEligibleStatus } from '../../enums/UserAuctionEligibleStatus';
import { UserAuctionRegistrationDto } from '../../models/user/UserAuctionRegistrationDto';
import { AdminAuctionDataHolderService } from '../../services/AdminAuctionDataHolder.service';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { Subscription } from 'rxjs';
import { AuctionExtConstant } from '../../util/AuctionExtConstant';
import { DataRedirectionService } from '../../services/data-redirection.service';
import { FirestoreListenerService } from '../../services/firestore-listener.service';
import { Router } from '@angular/router';
import { ServerDataFetchService } from '../../services/server-data-fetch.service';

@Component({
  selector: 'app-user-auction-registrations-notification',
  templateUrl: './user-auction-registrations-notification.component.html',
  styleUrls: ['./user-auction-registrations-notification.component.sass']
})
export class UserAuctionRegistrationsNotificationComponent implements OnInit, OnDestroy {
  awaitingApprovalsList: UserAuctionRegistrationDto[] = [];
  allAuctionsList: AuctionEntityDto[] = [];
  auctionIdsList: string[] = [];

  userRegistrationsSubscription$?: Subscription;

  constructor(
    private activeModal: NgbActiveModal,
    private adminDashboardService: AdminDashboardService,
    private auctionService: AdminAuctionDataHolderService,
    private dataRedirectionService: DataRedirectionService,
    private firestoreListenerService: FirestoreListenerService,
    private serverDataFetchService: ServerDataFetchService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.adminDashboardService.getAllUserAuctionRegistrationsList$.subscribe((data) => {
      if (data) {
        this.awaitingApprovalsList = data.filter(item => item.userAuctionRegistrationStatus == UserAuctionEligibleStatus.AWAITING_APPROVAL);
        this.auctionIdsList = this.awaitingApprovalsList.map(item => item.auctionId!);
        this.auctionIdsList = [...new Set(this.auctionIdsList)];
      }
    })

    this.auctionService.getAllAuctionsList$.subscribe((data) => {
      if (data) {
        this.allAuctionsList = data;
      }
    })
  }

  getAuction(auctionId: string): AuctionEntityDto {
    let auction = this.allAuctionsList.find(item => item.auctionId == auctionId);
    return auction!;
  }

  getRegistrationCount(auctionId: string) {
    let awaitingApprovalsList = [...this.awaitingApprovalsList];
    let registrations = awaitingApprovalsList.filter(item => item.auctionId == auctionId);
    return registrations.length ?? 0;
  }

  async navigateToRegistration(auctionId: string) {
    this.auctionService.setSelectedAuctionByAuctionId(auctionId);
    await this.serverDataFetchService.loadAllLotsOfAuctionForAdminSync(auctionId!);
    this.adminDashboardService.loadBidderRegistrationList(auctionId);

    this.activeModal.close();

    this.dataRedirectionService.setCurrentPageInSession(AuctionExtConstant.ADMIN_DASHBOARD_REGISTRATION, auctionId);
    this.dataRedirectionService.setSubNavigation(AuctionExtConstant.ADMIN_DASHBOARD_REGISTRATION);
    this.router.navigate(['creator'], { skipLocationChange: true });
  }

  closeModal() {
    this.activeModal.close();
  }

  ngOnDestroy(): void {
    if (this.userRegistrationsSubscription$) {
      this.userRegistrationsSubscription$.unsubscribe();
    }
  }
}
