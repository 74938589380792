<div class="container-fluid">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-8 d-flex align-items-center">
        <h2>ALL IMAGES</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="modalClose()" />
      </div>
    </div>
  </header>
  <div class="modal-body p-0">
    <div class="row">
      <div class="col-12 position-relative">
        <button class=" d-none d-md-block scroll-left-icon carousel-left-button" *ngIf="isShowCarouselNavigation()"
          (click)="onNavigationPrev($event)">
          <img src="assets/icons/leftarrow_dark.svg" class="svg-icon-class c-pointer">
        </button>

        <swiper #swiperCarousel [virtual]="true" [config]="swiperConfig" (slideChange)="onSlideChange()">
          <ng-template swiperSlide *ngFor="let item of selectedAuctionLot?.lotImages; index as i">
            <div class="card border-0 image-carousal-color rounded-0">
              <!-- Lot Image -->
                <pinch-zoom [transition-duration]="1000" *ngIf="!isValidUrl(item.fileId!)">
                  <img class="gallery-image" src="/downloadLandingBlob?fileId={{item.fileId}}" [alt]="selectedAuctionLot?.title">
                </pinch-zoom>

              <!-- Lot Video (MP4) -->
              <video #videoCarousel [src]="item.fileId" *ngIf="isValidUrl(item.fileId!) && item.fileType! != 'embed'"
                [muted]="true" controls></video>

              <!-- Lot Embed Video (Youtube, Vimeo) -->
              <iframe #iframeCarousel [src]="item.fileId! | safe : 'url'" style="height: 9vh;"
                *ngIf="isValidUrl(item.fileId!) && item.fileType! == 'embed'"></iframe>
            </div>
          </ng-template>
        </swiper>

        <button class=" d-none d-md-block scroll-right-icon carousel-right-button" *ngIf="isShowCarouselNavigation()"
          (click)="onNavigationNext($event)">
          <img src="assets/icons/rightarrow_dark.svg" class="svg-icon-class c-pointer">
        </button>
      </div>
    </div>
  </div>
</div>