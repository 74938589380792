export class AuctionValidationErrorCodeDto {
  code?: string;
  type?: string;
  message?: string;
  param1?: string;
  param2?: string;
  lotSequence?: string;
  lotId?: string;
  lotName?: string;

}


