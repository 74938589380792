import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { UserRegistrationStatus } from 'src/app/shared/enums/UserRegistrationStatus';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { UserEntityDto } from 'src/app/shared/models/UserEntityDto';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { Pattern } from 'src/app/shared/util/Patterns';
import { PasswordStrengthValidator } from 'src/app/shared/validators/password-strength.validators';

@Component({
  selector: 'app-user-verification',
  templateUrl: './user-verification.component.html',
  styleUrls: ['./user-verification.component.sass']
})
export class UserVerificationComponent implements OnInit {

  userEntityDto?: UserEntityDto;

  registrationStatus: typeof UserRegistrationStatus = UserRegistrationStatus;
  selectedCountryCode = CountryCodeDto.default();

  countryCodeForMobileNo$ = new BehaviorSubject<CountryCodeDto>(CountryCodeDto.default());
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorMsg$ = new BehaviorSubject<string>("");

  otpId?: string;

  isLoading: boolean = false;

  formGroup = new FormGroup({
    emailId: new FormControl(''),
    mobileNo: new FormControl(''),
    userOtp: new FormControl('', Validators.required),
  });

  constructor(
    private ngbModal: NgbModal,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.userEntityDto = this.userService.getUserEntity();

    if (this.userEntityDto.registrationStatus == UserRegistrationStatus.COMPLETED) {
      this.fc.userOtp.clearValidators();
      this.fc.userOtp.disable();
      this.formGroup.updateValueAndValidity();
    }

    this.fc.emailId.patchValue(this.userEntityDto.primaryEmailId!);
    this.fc.emailId.updateValueAndValidity();

    let primaryMobile = this.userEntityDto.mobileList?.find(item => item.primary);
    this.fc.mobileNo.patchValue(primaryMobile?.mobileNo!);
    this.fc.mobileNo.updateValueAndValidity();

    this.fc.emailId.disable();
    this.fc.mobileNo.disable();
  }

  get fc() { return this.formGroup.controls; }

  handleCountryCodeEvent(countryCodeDto: CountryCodeDto) {
    this.selectedCountryCode = countryCodeDto;
    this.countryCodeForMobileNo$.next(countryCodeDto)
  }

  generateOTP() {
    this.fc.userOtp.clearValidators();
    this.formGroup.updateValueAndValidity();

    this._showErrorMsg$.next("");
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    this.isLoading = true;

    let email = this.userService.getUserEntity().primaryEmailId!;

    this.userService.generateOTP(email, email, 'EMAIL_VERIFICATION').subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.otpId = apiResponseDto.data as string;
          this.isLoading = false;
        } else {
          this._showErrorMsg$.next("Error While OTP Generate");
          this.isLoading = false;
        }
      },
      error: (err) => {
        this._showErrorToast$.next(true);
        this._showErrorMsg$.next("Error While OTP Generate");
        this.isLoading = false;
      }
    })
  }

  verifyOTP() {
    this.fc.userOtp.addValidators(Validators.required);
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.isLoading = true;
    this._showErrorMsg$.next("");
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);

    let userEnteredOtp = this.formGroup.controls['userOtp'].value!;

    this.userService.verifyOTP(this.otpId as string, userEnteredOtp).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.userEntityDto = apiResponseDto.data as UserEntityDto;
          this.userService.setUserDto(this.userEntityDto);

          this._showSuccessToast$.next(true);
          this.isLoading = false;

          setTimeout(() => {
            this._showSuccessToast$.next(false);
          }, 2000);
        } else {
          this._showErrorToast$.next(true);
          this._showErrorMsg$.next(apiResponseDto.message!);
          this.isLoading = false;
        }
      },
      error: (err) => {
        this._showErrorToast$.next(true);
        this._showErrorMsg$.next("Error While verifying OTP");
        this.isLoading = false;
      }
    })
  }
}
