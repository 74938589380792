import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
// import { LandingDashboardComponent } from './layouts/landing/landing-dashboard/landing-dashboard.component';
// import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicAuctionAuthHttpInterceptor } from './shared/interceptors/basic-auction-auth-http.interceptor';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas, IconPack as SolidIconPack } from '@fortawesome/free-solid-svg-icons';
import { far, IconPack as RegularIconPack } from '@fortawesome/free-regular-svg-icons';
import { fab, IconPack as BrandIconPack } from '@fortawesome/free-brands-svg-icons';
import { environment } from 'src/environments/environment';
import { provideFirebaseApp, initializeApp, FirebaseApp } from '@angular/fire/app';
import { provideFirestore, getFirestore } from "@angular/fire/firestore";
import { getAnalytics, provideAnalytics } from "@angular/fire/analytics";
import { getPerformance, initializePerformance, providePerformance } from "@angular/fire/performance";
import { TagInputModule } from 'ngx-chips';
import { NgxEchartsModule } from 'ngx-echarts';
import { TimeAgoPipe } from './shared/pipes/time-ago.pipe';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarDateFormatter, CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AuctionCalendarDateFormatter } from "./shared/providers/AuctionCalendarDateFormatter";


@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    FontAwesomeModule,
    TagInputModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideAnalytics(() => getAnalytics()),
    providePerformance(() => getPerformance()),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicAuctionAuthHttpInterceptor, multi: true,
    },
    NgbActiveModal, DecimalPipe, DatePipe, TimeAgoPipe,
    { provide: CalendarDateFormatter, useClass: AuctionCalendarDateFormatter }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    iconLibrary: FaIconLibrary,
    firebaseApp: FirebaseApp
  ) {
    iconLibrary.addIconPacks(fab as BrandIconPack, fas as SolidIconPack, far as RegularIconPack);
    initializePerformance(firebaseApp);
  }
}
