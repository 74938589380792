
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';
import { UserAuctionRegistrationDto } from 'src/app/shared/models/user/UserAuctionRegistrationDto';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { FormControl } from '@angular/forms';
import { QuestionnaireComponent } from '../questionnaire/questionnaire.component';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';
import { ApplicationListenerService } from 'src/app/shared/services/application-listener.service';
import { EventEnum } from 'src/app/shared/enums/EventEnum';
import { RfxQuestionnaireComponent } from '../rfx-questionnaire/rfx-questionnaire.component';
import { UserResourceRegistrationWrapperDto } from 'src/app/shared/models/UserResourceRegistrationWrapperDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { UserService } from 'src/app/shared/services/user.service';
import { ServerDataFetchService } from 'src/app/shared/services/server-data-fetch.service';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';

@Component({
  selector: 'app-my-registrations',
  templateUrl: './my-registrations.component.html',
  styleUrls: ['./my-registrations.component.sass']
})
export class MyRegistrationsComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  page: number = 1;
  pageSize: number = 50;
  selectedFilter: string = 'Recent';
  ctrlSearch: FormControl = new FormControl('');

  currentIndex: number = -1;
  isNavLoading: boolean = false;

  userAuctionRegistrationWrappers: Array<UserResourceRegistrationWrapperDto> = [];

  filteredUserAuctionRegistrationWrappers$ = new BehaviorSubject<Array<UserResourceRegistrationWrapperDto>>([]);
  userAuctionRegistrationWrappersSubscription$?: Subscription;
  allAuctionsSubscription$?: Subscription;

  @ViewChild('search') searchField?: ElementRef<HTMLInputElement>;

  constructor(
    private serverDataFetchService: ServerDataFetchService,
    private userService: UserService,
    private activeModal: NgbActiveModal,
    private ngbModal: NgbModal,
    private bidderService: LandingAndBidderService,
    private questionnaireService: QuestionnaireService,
    private applicationService: ApplicationListenerService
  ) {}

  ngOnInit(): void {
    this.loadMyUserResourceRegistrationWrappersSync();
    this.isLoading = true;
    this.ctrlSearch.valueChanges.subscribe(() => {
      this.searchData()
    })
  }
  

  searchData(){
    let searchText = this.ctrlSearch.value;
    if(searchText.trim() != ''){
      let tempData = [...this.userAuctionRegistrationWrappers];
      tempData = tempData.filter(item => item.resourceParentSequence!.toString().includes(searchText.trim()) || item.resourceParentName!.toLowerCase().includes(searchText.trim().toLowerCase())
      )
      this.filteredUserAuctionRegistrationWrappers$.next(tempData);
    }else{
      this.filteredUserAuctionRegistrationWrappers$.next(this.userAuctionRegistrationWrappers);
    }
  }

  async openQuestionnaireModal(userAuctionRegistrationWrapper: UserResourceRegistrationWrapperDto, index: number) {
    this.currentIndex = index;
    this.isNavLoading = true;

    let modalRef ;
    if (userAuctionRegistrationWrapper.type == EventEnum.AUCTION) {
      let data = await this.bidderService.getAuctionByAuctionId(userAuctionRegistrationWrapper.resourceParentId!);
      modalRef = this.ngbModal.open(QuestionnaireComponent, {
        size: 'lg', backdrop: 'static', keyboard: false, centered: true
      })
      modalRef.componentInstance.selectedAuction = data;
    }else{
       await this.bidderService.loadRfxDtoByRfxId(userAuctionRegistrationWrapper.resourceParentId!);
       await this.bidderService.loadRfxSubcategoryDto(userAuctionRegistrationWrapper.resourceParentId!, userAuctionRegistrationWrapper.resourceId!);
        let data =this.bidderService._selectedRfxSubcategory$.value;
       modalRef = this.ngbModal.open(RfxQuestionnaireComponent, {
        size: 'lg', backdrop: 'static', keyboard: false, centered: true
      })
      modalRef.componentInstance.selectedSubcategory = data;
    }


    modalRef.componentInstance.isReadOnly = true;

    this.isNavLoading = false;
    this.currentIndex = -1;
  }

  

  paginateBidderHistoryList() {
    if (this.userAuctionRegistrationWrappers && this.userAuctionRegistrationWrappers.length > 0) {
      let data: Array<UserAuctionRegistrationDto> = AuctionExtUtil.clone(this.userAuctionRegistrationWrappers);

      this.filteredUserAuctionRegistrationWrappers$.next(data.map((history: any, i: number) => ({ id: i + 1, ...history })).slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize,
      ));
    }
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.paginateBidderHistoryList()
  }

  closeModal() {
    this.activeModal.close()
  }

  ngOnDestroy(): void {
    if (this.userAuctionRegistrationWrappersSubscription$) {
      this.userAuctionRegistrationWrappersSubscription$.unsubscribe();
    }
    if (this.allAuctionsSubscription$) {
      this.allAuctionsSubscription$.unsubscribe();
    }
    this.currentIndex = -1;
  }

  async loadMyUserResourceRegistrationWrappersSync() {
    // Load all the registration of user
    let sessionInfoDto = this.userService?.getSessionInfo;
    if (sessionInfoDto) {
      try {
        let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.serverDataFetchService.getUserAuctionRegistrationWrappers(sessionInfoDto.orgCode!));
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          if (apiResponseDto.data) {
            let data = apiResponseDto.data as UserResourceRegistrationWrapperDto[];
            this.isLoading = false;
            if (data && data.length > 0) {
              this.userAuctionRegistrationWrappers = data as UserResourceRegistrationWrapperDto[];
              this.filteredUserAuctionRegistrationWrappers$.next(this.userAuctionRegistrationWrappers);
              this.paginateBidderHistoryList();
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

}
