import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EChartsOption } from 'echarts';
import { AuctionHouseChartData } from '../../models/AuctionHouseChartData';
import { LandingAndBidderService } from '../../services/LandingAndBidder.service';
import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { Currency } from '../../models/Currency';
import { AuctionExtUtil } from '../../util/AuctionExtUtil';

@Component({
  selector: 'app-auction-house-data-charts',
  templateUrl: './auction-house-data-charts.component.html',
  styleUrls: ['./auction-house-data-charts.component.sass']
})
export class AuctionHouseDataChartsComponent implements OnInit {
  chartOption?: EChartsOption;

  auctionHouseChartData?: AuctionHouseChartData;
  currency: Currency = Currency.default();

  public screenWidth: any = window.innerWidth;

  isLoading: boolean = true;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private activeModal: NgbActiveModal,
    private bidderService: LandingAndBidderService
  ) { }

  ngOnInit(): void {
    this.bidderService.auctionHouseChartData().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.auctionHouseChartData = apiResponseDto.data as AuctionHouseChartData;
          this.populateChartsData();
        }
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false
      }
    })

    this.bidderService.getAllAuctionCardWrapperDtos$.subscribe((data) => {
      if (data) {
        this.currency = data[0].auctionEntityDto?.currency!;
      }
    })
  }

  populateChartsData() {
    let colors = ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'];
    let dataTypesList = ["Upcoming Lots", "Live Lots", "Awaiting Results", "Awarded Lots", "Unsold Lots"];

    let valuesList = [
      { value: this.auctionHouseChartData?.totalUpcomingLots ?? 0, itemStyle: { color: colors[0] } },
      { value: this.auctionHouseChartData?.totalLiveLots ?? 0, itemStyle: { color: colors[1] } },
      { value: this.auctionHouseChartData?.totalAwaitingLots ?? 0, itemStyle: { color: colors[2] } },
      { value: this.auctionHouseChartData?.totalAwardedLots ?? 0, itemStyle: { color: colors[3] } },
      { value: this.auctionHouseChartData?.totalNotSoldLots ?? 0, itemStyle: { color: colors[4] } },
    ];

    this.chartOption = {
      grid: {
        containLabel: true
      },
      tooltip: {},
      legend: {
        data: ["Upcoming Lots", "Live Lots", "Awaiting Results", "Awarded Lots", "Unsold Lots"]
      },
      xAxis: {
        type: 'category',
        data: dataTypesList,
        axisLabel: {
          interval: 0,
          rotate: 30
        }
      },
      yAxis: {
        type: 'value',
        name: 'No. of Lots',
        nameLocation: 'middle',
        nameGap: this.screenWidth < 768 ? 40 : 50
      },
      series: [
        {
          data: valuesList,
          type: 'bar',
        }
      ]
    }

    this.isLoading = false;
  }

  getFormattedPrice(price: any) {
    return this.currency.symbol + AuctionExtUtil.getFormattedPrice(this.currency.locale, price);
  }

  closeModal() {
    this.activeModal.close();
  }
}
