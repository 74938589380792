import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
// import { DashboardRedirectionService } from '../../services/dashboard-redirection.service';
import { Subscription } from 'rxjs';
// import { ApplicationConstants } from '../../util/ApplicationConstants';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { AuctionExtConstant } from '../../util/AuctionExtConstant';
import { DashboardRedirectionService } from '../../services/dashboard-redirection.service';
import { LandingAndBidderService } from '../../services/LandingAndBidder.service';
import { RfxUiDto } from '../../models/rfx/RfxEntityDto';
import { RfxSubcategoryEntityDto } from '../../models/rfx/RfxSubcategoryEntityDto ';
// import { AdminSourcingEventsDataHolderService } from '../../services/AdminSourcingEventsDataHolder.service ';
// import { RfxEntityDto } from '../../models/rfx/RfxEntityDto';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.sass']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  selectedAuction?: AuctionEntityDto;
  selectedRfx?: RfxUiDto;
  selectedRfxSubcategory?: RfxSubcategoryEntityDto;

  isSingleActivity: boolean = false;
  isShowEventStagePage: boolean = false;
  pageName: string | null = AuctionExtConstant.DASHBOARD_PAGE;

  updateBreadcrumbsSubscription$?: Subscription

  constructor(
    private dashboardRedirectionService: DashboardRedirectionService,
    private landingAndBidderService: LandingAndBidderService,
    // private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService
  ) { }

  ngOnInit(): void {
    this.updateBreadcrumbsSubscription$ = this.dashboardRedirectionService.getUpdateBreadcrumbs$.subscribe(data => {
      if (data) {
        this.pageName = sessionStorage.getItem('PAGE_NAME');

        if (this.pageName == 'QUESTIONNAIRE_PAGE') {
          this.selectedAuction = this.landingAndBidderService.getSelectedAuction;
          this.isSingleActivity = this.selectedAuction?.noOfActiveLots == 1;
        }

        if (this.pageName == AuctionExtConstant.RFX_SUBCATEGORY_LIST) {
          this.selectedRfx = this.landingAndBidderService.getSelectedRfx;
          this.isSingleActivity = this.selectedRfx?.noOfActiveSubcategories == 1;
        }

        if (this.pageName == AuctionExtConstant.RFX_SUBCATEGORY_DETAILS) {
          this.selectedRfx = this.landingAndBidderService.getSelectedRfx;
          this.selectedRfxSubcategory = this.landingAndBidderService.getSelectedRfxSubcategory;
          this.isSingleActivity = this.selectedRfx?.noOfActiveSubcategories == 1;
        }
      }
    })
  }

  navigateToPage(pageName: string, subContext?: string) {
    this.dashboardRedirectionService.storeNavigationContext(
      AuctionExtConstant.SUPPLIER, subContext ?? AuctionExtConstant.ANY_CONTEXT, pageName
    );
    this.dashboardRedirectionService.setUpdateBreadcrumbs(true);
  }

  redirectToHomePage(pageName: string) {
    if (pageName == AuctionExtConstant.DASHBOARD_PAGE) {
      this.dashboardRedirectionService.clearSessionCookies();
      this.dashboardRedirectionService.navigationToHomePage();
    } else {
      this.dashboardRedirectionService.storeNavigationContext(
        AuctionExtConstant.SUPPLIER, AuctionExtConstant.ANY_CONTEXT,
        pageName
      );
      this.dashboardRedirectionService.setUpdateBreadcrumbs(true);
      this.dashboardRedirectionService.navigationToHomePage();
    }
  }

  ngOnDestroy(): void {
    if (this.updateBreadcrumbsSubscription$) {
      this.updateBreadcrumbsSubscription$.unsubscribe();
    }
  }
}

