import { Timestamp } from "@angular/fire/firestore";
import { AddressDto } from "../Address";
import { CompanyDetailsDto } from "../CompanyDetailsDto";
import { CountryCodeDto } from "../CountryCodeDto";
import { EmailConfigurationDto } from "../EmailConfigurationDto";
import { FileInfoDto } from "../FileInfoDto";
import { ListingContactDto } from "../ListingContactDto";
import { PrivacyAndTermsOfUseDto } from "../PrivacyAndTermsOfUseDto";
import { Currency } from "../Currency";

export class OrganizationUiDto {
  name?: string;
  corporateUrl?: string;
  orgCode?: string;
  description?: string;
  domainName?: string;
  logo?: FileInfoDto;
  active: boolean = false;
  bannerImage?: FileInfoDto;
  supportEmail?: string;
  phoneNo?: string;
  countryCode?: CountryCodeDto;
  currencyDto?: Currency
  primaryColor?: string;
  secondaryColor?: string;
  isAvailable?: boolean
  version?: number;
  demoAccount?: boolean;
  allowQuestionsAndRating:boolean = false;

  privacyAndTermsOfUseList?: Array<PrivacyAndTermsOfUseDto>;
  updateTimeUtc?:string | Timestamp
}
