export enum UserQuestionStatus {

  NEW = "NEW",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",

  INCOMPLETE = "INCOMPLETE",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  REJECTED_BIDDERS = "REJECTED_BIDDERS",
  APPROVED_BIDDERS = "APPROVED_BIDDERS",
  DISABLED_BIDDERS = "DISABLED_BIDDERS"
}