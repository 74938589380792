import { UserRfxSubcategoryStatus } from "../rfx/UserRfxSubcategoryStatus";

export class UserRfxSubcategoryUiDto {


  orgCode?: string;
  companyCode?: string;
  plantCode?: string;
  rfxId?: string;
  subcategoryId?: string;
  categorySequence?: number;
  companyId?: string;
  emailId?: string;
  companyName?: string;
  status?: UserRfxSubcategoryStatus;
  updateTimeUtc?: string;
}
