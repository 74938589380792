import { Component, Input } from '@angular/core';
import { FileInfoDto } from '../../models/FileInfoDto';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.sass']
})
export class ImagePreviewComponent {
  @Input() imageUrl?: string

  
  constructor(
    private activeModal: NgbActiveModal
  ) { }

  closeModal() {
    this.activeModal.close();
  }
}
