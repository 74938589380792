import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { RegistrationStages } from 'src/app/shared/enums/RegistrationStages';
import { UserRegistrationStatus } from 'src/app/shared/enums/UserRegistrationStatus';
import { UserEntityDto } from 'src/app/shared/models/UserEntityDto';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-profile-stage',
  templateUrl: './profile-stage.component.html',
  styleUrls: ['./profile-stage.component.sass']
})
export class ProfileStageComponent implements OnInit, OnDestroy {
  userEntityDto?: UserEntityDto;
  registrationStages: typeof RegistrationStages = RegistrationStages;

  isShowSkipButton: boolean = false;
  isDisabledPrevButton: boolean = false;
  isDisabledNextButton: boolean = false;

  activeStage: RegistrationStages = RegistrationStages.VERIFICATION;
  currentStageIndex: number = 1;

  currentRegistrationStage$ = new BehaviorSubject<RegistrationStages>(RegistrationStages.VERIFICATION);

  userEntityDtoSubscription$?: Subscription;

  constructor(
    private router: Router,
    private dashboardRedirectionService: DashboardRedirectionService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.userEntityDtoSubscription$ = this.userService.getUserEntity$.subscribe(data => {
      if (data) {
        this.userEntityDto = data;
        this.checkCurrentStage();
      }
    })
  }

  toggleCurrentStage(newStage: RegistrationStages) {
    this.activeStage = newStage;
    this.currentRegistrationStage$.next(this.activeStage);

    this.checkFooterButtons();
  }

  checkCurrentStage() {
    let currentUser = this.userService.getUserEntity();
    if (currentUser.registrationStatus == UserRegistrationStatus.INCOMPLETE) {
      this.currentStageIndex = 1;
      this.toggleCurrentStage(RegistrationStages.VERIFICATION);
    } else {
      if (!currentUser.companyName) {
        this.currentStageIndex = 2;
        this.toggleCurrentStage(RegistrationStages.BASIC_DETAILS);
      } else if (!currentUser.address?.searchAddress) {
        this.currentStageIndex = 3;
        this.toggleCurrentStage(RegistrationStages.ADDRESS);
      } else {
        this.currentStageIndex = 4;
        this.toggleCurrentStage(RegistrationStages.DOCUMENTS);
      }

      if (currentUser.preferenceCategories && currentUser.preferenceCategories.length > 0) {
        this.currentStageIndex = 6;
        this.toggleCurrentStage(RegistrationStages.PREFERENCES);
      }
    }

    this.checkFooterButtons();
  }

  checkFooterButtons() {
    let currentUser = this.userService.getUserEntity();

    switch (this.activeStage) {
      case RegistrationStages.VERIFICATION:
        this.isShowSkipButton = false;
        this.isDisabledPrevButton = true;
        if (currentUser.registrationStatus == UserRegistrationStatus.INCOMPLETE) {
          this.isDisabledNextButton = true;
        } else {
          this.isDisabledNextButton = false;
        }
        break;

      case RegistrationStages.BASIC_DETAILS:
        this.isShowSkipButton = false;
        this.isDisabledPrevButton = false;
        if (currentUser.companyName) {
          this.isDisabledNextButton = false;
        } else {
          this.isDisabledNextButton = true;
        }
        break;

      case RegistrationStages.ADDRESS:
        this.isShowSkipButton = false;
        this.isDisabledPrevButton = false;
        if (currentUser.address && currentUser.address.searchAddress) {
          this.isDisabledNextButton = false;
        } else {
          this.isDisabledNextButton = true;
        }
        break;

      case RegistrationStages.DOCUMENTS:
        this.isShowSkipButton = true;
        this.isDisabledPrevButton = false;
        this.isDisabledNextButton = false;
        break;

      case RegistrationStages.PREFERENCES:
        this.isShowSkipButton = false;
        this.isDisabledPrevButton = false;
        this.isDisabledNextButton = true;
        break;

      default:
        this.isShowSkipButton = false;
        this.isDisabledPrevButton = true;
        this.isDisabledNextButton = false;
        break;
    }
  }

  nextStep() {
    switch (this.activeStage) {
      case RegistrationStages.VERIFICATION:
        this.toggleCurrentStage(RegistrationStages.BASIC_DETAILS);
        break;
      case RegistrationStages.BASIC_DETAILS:
        this.toggleCurrentStage(RegistrationStages.ADDRESS);
        break;
      case RegistrationStages.ADDRESS:
        this.toggleCurrentStage(RegistrationStages.DOCUMENTS);
        break;
      case RegistrationStages.DOCUMENTS:
        this.toggleCurrentStage(RegistrationStages.PREFERENCES);
        break;

      default:
        break;
    }
  }

  prevStep() {
    switch (this.activeStage) {
      case RegistrationStages.BASIC_DETAILS:
        this.toggleCurrentStage(RegistrationStages.VERIFICATION);
        break;
      case RegistrationStages.ADDRESS:
        this.toggleCurrentStage(RegistrationStages.BASIC_DETAILS);
        break;
      case RegistrationStages.DOCUMENTS:
        this.toggleCurrentStage(RegistrationStages.ADDRESS);
        break;
      case RegistrationStages.PREFERENCES:
        this.toggleCurrentStage(RegistrationStages.DOCUMENTS);
        break;

      default:
        break;
    }
  }

  redirectToHomePage(){
    this.dashboardRedirectionService.clearSessionCookies();
    this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['Bidder/Dashboard'], { skipLocationChange: true, queryParamsHandling: 'merge' }));
  }

  ngOnDestroy(): void {
    this.currentStageIndex = 1;

    if(this.userEntityDtoSubscription$) {
      this.userEntityDtoSubscription$.unsubscribe();
    }
  }
}

