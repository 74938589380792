import { QuestionResponseType } from "../../enums/questionnaire/QuestionResponseType";
import { SourcingEnvelopeType } from "../../enums/questionnaire/SourcingEnvelopeType";
import { UserQuestionStatus } from "../../enums/questionnaire/UserQuestionStatus";
import { FileInfoDto } from "../FileInfoDto";
import { AdditionalFinancialPrice } from "./AdditionalFinancialPrice";

export class UserRfxQuestionsUiDto {
  id?: string;
  questionId?: string;
  sectionId?: string;
  questionnaireId?: string;

  orgCode?: string;
  rfxId?: string;
  rfxSubcategoryId?: string;
  envelopeType?: SourcingEnvelopeType;

  name?: string;
  firstName?: string;
  lastName?: string;
  companyId?: string;
  emailId?: string;
  paddleNo?: string;
  companyName?: string;

  responseType?: QuestionResponseType;
  bidderResponse: any;
  bidderDocuments?: FileInfoDto[];
  bidderComments?: string;
  status?: UserQuestionStatus;
  registrationTimeInternal?: string;
  registrationTimeStr?: string;

  unitPriceQtyTotal?: number;
  unitPriceQtyTaxTotal?: number;
  unitPriceQtyTaxAdditionalPricesTotal?: number;
  bidderQuantity?: number;
  bidderUom?: string;
  bidderTax?: number;

  additionalPrices?: AdditionalFinancialPrice[]

  updateTimeUtc?: string;
  updateTimeUtcMillis?: number;
}
