import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { Subscription } from 'rxjs';
import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy, ElementRef, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { AuctionLotEntityDto } from '../../models/user/AuctionLotEntityDto';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperComponent } from 'swiper/angular';
import { Navigation, Pagination, SwiperOptions } from 'swiper';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';

@Component({
  selector: 'app-gallery-view',
  templateUrl: './gallery-view.component.html',
  styleUrls: ['./gallery-view.component.sass']
})
export class GalleryViewComponent implements OnInit, AfterViewInit, OnDestroy {
  selectedAuctionLot?: AuctionLotEntityDto
  selectedAuction?: AuctionEntityDto
  isZoomIn = false;

  selectedAuctionLotSubscription$?: Subscription;
  selectedAuctionSubscription$?: Subscription;

  @ViewChild('swiperCarousel', { static: false }) swiperCarousel?: SwiperComponent;
  @ViewChildren('videoCarousel') videoCarousels?: Array<ElementRef<HTMLVideoElement>>;
  @ViewChildren('iframeCarousel') iframeCarousels?: Array<ElementRef<HTMLIFrameElement>>;

  swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: false,
    pagination: { clickable: true, enabled: true, dynamicBullets: true, dynamicMainBullets: 4 },
    scrollbar: false,
    allowTouchMove: false,
    modules: [Pagination, Navigation],
    centerInsufficientSlides: true
  };

  constructor(
    private bidderService: LandingAndBidderService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.selectedAuctionLotSubscription$ = this.bidderService.getSelectedAuctionLot$.subscribe((data) => {
      if (data) {
        this.selectedAuctionLot = data;
      }
    })

    this.selectedAuctionSubscription$ = this.bidderService.getSelectedAuction$.subscribe((data) => {
      if (data) {
        this.selectedAuction = data;
      }
    })
  }

  ngAfterViewInit(): void {
  }

  modalClose() {
    this.activeModal.close()
  }

  ngOnDestroy(): void {
    if (this.selectedAuctionLotSubscription$) {
      this.selectedAuctionLotSubscription$.unsubscribe()
    }
    if (this.selectedAuctionSubscription$) {
      this.selectedAuctionSubscription$.unsubscribe()
    }
  }

  isValidUrl = (urlString: string) => {
    try {
      return Boolean(new URL(urlString));
    }
    catch (e) {
      return false;
    }
  }

  isShowCarouselNavigation() {
    return this.selectedAuction?.liveStreaming || (this.selectedAuctionLot?.lotImages && this.selectedAuctionLot?.lotImages!.length > 1)
  }

  onSlideChange() {
    this.stopCarouselVideo()
  }

  onNavigationPrev(event: any) {
    event.stopPropagation();
    this.swiperCarousel?.swiperRef.slidePrev()
  }

  onNavigationNext(event: any) {
    event.stopPropagation();
    this.swiperCarousel?.swiperRef.slideNext();
  }

  stopCarouselVideo() {
    if (this.videoCarousels && this.videoCarousels.length > 0) {
      this.videoCarousels.forEach((item) => {
        item?.nativeElement.pause();
      })
    }

    if (this.iframeCarousels && this.iframeCarousels.length > 0) {
      this.iframeCarousels.forEach((item) => {
        let element = item.nativeElement;
        if (element) {
          var iframeSrc = element.src;
          element.src = iframeSrc;
        }
      })
    }
  }
}
