<div class="w-50">
  <div class="card shadow border-0 bg-white">
    <div class="p-4">
      <P class="p-class">
        This website uses cookies to ensure you get the best experience on our website.<br>
        <span class="btn-link  span-class" (click)="openPrivacyModal()">Learn more</span>
      </P>

      <div class="mt-3">
        <button class="btn-outline-primary btn btn-sm w-100" (click)="saveCookieAlert()">
          Got it!
        </button>
      </div>

    </div>
  </div>
</div>