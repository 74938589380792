import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { CountryCodeDto } from '../../models/CountryCodeDto';

@Component({
  selector: 'app-country-code-select',
  templateUrl: './country-code-select.component.html',
  styleUrls: ['./country-code-select.component.sass']
})
export class CountryCodeSelectComponent implements OnInit, OnDestroy {
  @Input() countryCode$?: Observable<CountryCodeDto|null>;

  selectedClass?: string;
  selectedCountry?: string;
  selectedCode?: string;

  @Output() countryCodeEvent = new EventEmitter<CountryCodeDto>();

  searchText$ = new Subject<string>();

  private searchSubscription?: Subscription;

  constructor(
    public zone: NgZone,
  ) {}

  ngOnInit(): void {
    this.selectedCode = '(+1)';
    this.selectedCountry = 'United States';
    this.selectedClass = 'language-icon me-2 fi fi-us';

    this.countryCode$?.subscribe((data) => {
      if (data) {
        this.selectedCode = data.countryCode;
        this.selectedCountry = data.countryName;
        this.selectedClass = data.countryFlag;
      }
    })

    this.searchSubscription = this.searchText$.pipe(
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe( data => {
      this.handleUserSelection(data);
    });
  }

  search(searchInput: any) {
    this.searchText$.next(searchInput);
  }

  updateSelectedCode(selectedCountryCode: CountryCodeDto) {
    this.selectedCode = selectedCountryCode.countryCode;
    this.selectedCountry = selectedCountryCode.countryName;
    this.selectedClass = selectedCountryCode.countryFlag;
  }

  handleCountySelection(index: number) {
    let parentElement: any = document.getElementsByClassName('country-code-list')[index];

    console.log(parentElement.childNodes[0].className);
    console.log(parentElement.childNodes[1].innerText);
    console.log(parentElement.childNodes[2].innerText);

    this.selectedClass = parentElement.childNodes[0].className;
    this.selectedCountry = parentElement.childNodes[1].innerText;
    this.selectedCode = parentElement.childNodes[2].innerText;
    let mobileNoLength = parentElement.childNodes[3].innerText;
    
    let event: CountryCodeDto = {
      countryName: this.selectedCountry,
      countryCode: this.selectedCode,
      countryFlag: this.selectedClass,
      mobileNoLength: mobileNoLength
    }
    this.countryCodeEvent.next(event);
  }

  handleUserSelection(data: any) {
    console.log("handling user selection");
 
    this.zone.run(() => {
      let elements = document.getElementsByClassName("country-code-list");
 
      for (var key in elements) {
        let element:any = elements[key];
        if (element.className == "dropdown-item country-code-list" && element.nodeName == "BUTTON") {
            element.hidden = false;
        }
      }
 
      for (var key in elements) {
        let element:any = elements[key];
        if (element.className == "dropdown-item country-code-list" && element.nodeName == "BUTTON" && data.trim().length > 0) {
          let dataToCompare = data.trim().toUpperCase();
          let elementText = element.innerText.trim().toUpperCase();
          if(!elementText.includes(dataToCompare)) {
            element.hidden = true;
          }
        }
      }
    })
 
  }

  ngOnDestroy(): void {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
      this.searchSubscription = undefined;
    }
  }
}
