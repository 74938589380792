<!-- <div class="container py-3">
  <div [ngSwitch]="view">
    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
      [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
      (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-month-view>
  </div>
</div>  -->

<div class="container py-3">
  <div class="row  justify-content-between">
    <div class="col-lg-7 text-center text-lg-start">
      <button class="btn btn-sm btn-primary rounded-0" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay($event)">
        Previous
      </button>
      <button class="btn btn-sm btn-outline-secondary rounded-0 padding-class" mwlCalendarToday [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay($event)">
        Today
      </button>
      <button class="btn btn-sm btn-primary rounded-0" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay($event)">
        Next
      </button>
      <!-- <div class="btn-group">
        <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay($event)">
          Previous
        </div>
        <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
          Today
        </div>
        <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay($event)">
          Next
        </div>
      </div> -->
    </div>
    <div class="col-lg-5 text-center text-lg-end mt-2 mt-lg-0">
      <h5 class="heading-h5">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h5>
    </div>
  </div>
  <br />

  <div [ngSwitch]="view">
    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
      [cellTemplate]="customCellTemplate" [weekendDays]="[]" (beforeViewRender)="beforeMonthViewRender($event)"
      (dayClicked)="dayClicked($event.day)" [refresh]="refresh" (eventTimesChanged)="eventTimesChanged($event)" mon>
    </mwl-calendar-month-view>
  </div>
</div>

<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-top">
    <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
  </div>
  <span class="event-dot" *ngIf="day.badgeTotal > 0"></span>
</ng-template>